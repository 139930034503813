import React from 'react';
import { ChannelType, CoverKindParam } from '../../../../types';
import { CoverActionControlBox } from './action';
import { CoverChannelControlBox } from './channel';

type CoverControlBoxCommonProps = {
  isListItem?: boolean;
};

type ChannelParams = CoverControlBoxCommonProps & {
  kind: CoverKindParam.Channel;
  channel: ChannelType;
};

type ActionParams = CoverControlBoxCommonProps & {
  kind: CoverKindParam.Action;
  channels: ChannelType[];
};

type CoverControlBoxProps = ChannelParams | ActionParams;

export const CoverControlBox: React.FC<CoverControlBoxProps> = (props) => {
  return props.kind === CoverKindParam.Channel ? (
    <CoverChannelControlBox isListItem={props.isListItem} channel={props.channel} />
  ) : (
    <CoverActionControlBox isListItem={props.isListItem} channels={props.channels} />
  );
};
