import React, { useEffect } from 'react';
import { IconLogoWisniowscy, NavHeader, Page } from '../../../../../components';
import { useBluetoothContext } from '../../../context';
import { GateTypePrefix } from '../../../types';
import './index.scss';
import { WisniowskiDriveAddStartSmartAWGate } from './smart-aw-gate';
import { WisniowskiDriveAddStartSmartAWSC } from './smart-awsc';

const WisniowskiDriveAddStart: React.FC = () => {
  const { checkIfConnected, deviceNameBT } = useBluetoothContext();

  useEffect(() => {
    checkIfConnected();
  }, []);

  const renderComponent = () => {
    if (deviceNameBT.includes(GateTypePrefix.SmartAwGate)) {
      return <WisniowskiDriveAddStartSmartAWGate />;
    }

    return <WisniowskiDriveAddStartSmartAWSC />;
  };

  return (
    <Page contentClassName="drive-add start" isStickyHeader header={<NavHeader isIconClose />}>
      <IconLogoWisniowscy className="m-b-64" />
      {renderComponent()}
    </Page>
  );
};

export default WisniowskiDriveAddStart;
