import React, { createContext, useContext, useState } from 'react';
import { cloneDeep } from 'lodash';
import {
  ChannelTypeInternal,
  OptimizerConfigValidation,
  UserChannel,
  UserDevice,
} from '../../data-access/gql-types/graphql';
import { DeviceType, ChannelType, DevicesAndChannelsState } from '../../types';
import { ChannelOptimizerType } from '../../types/channel/optimizer';
import { parseChannel } from '../../utils/channels/helpers';
import { parseDevice } from '../../utils/devices';
import { useChannelsState } from '../channels-state';
import { getParsedState } from './utils';

const initialState: DevicesAndChannelsState = {
  deviceList: [],
  setDeviceList: () => null,
  channelList: [],
  setChannelList: () => null,
  channel: undefined,
  setChannelDetails: () => null,
  optimizerConfigValidation: undefined,
  setOptimizerConfigValidation: () => null,
  updateChannelDetails: () => null,
  clearChannelDetails: () => null,
  device: undefined,
  setDeviceDetails: () => null,
  updateDeviceDetails: () => null,
  clearDeviceDetails: () => null,
};

export const DevicesAndChannelsContext = createContext(initialState);

export const useDevicesAndChannelsContext = (): DevicesAndChannelsState =>
  useContext(DevicesAndChannelsContext);

const DevicesAndChannelsProvider: React.FC = ({ children }) => {
  const [deviceList, setDeviceList] = useState<DeviceType[]>(initialState.deviceList);
  const [channelList, setChannelList] = useState<ChannelType[]>(initialState.channelList);
  const [channel, setChannel] = useState<ChannelType | undefined>(undefined);
  const [device, setDevice] = useState<DeviceType | undefined>(undefined);
  const [optimizerConfigValidation, setOptimizerConfigValidation] = useState<OptimizerConfigValidation | undefined>(
    undefined,
  );
  const { setChannelState } = useChannelsState();

  const setChannelDetails = (userChannel: UserChannel) => {
    const ch = parseChannel(userChannel);

    if (ch) {
      setChannel(ch);

      setChannelList((prev) => {
        const tempList = cloneDeep(prev);
        const index = tempList.findIndex((x) => x.id === ch.id);

        if (index !== -1) tempList[index] = ch;
        return tempList;
      });

      setChannelState((oldState) => {
        const newState = { ...oldState };
        const parsedState = getParsedState(newState[ch.id], userChannel);

        if (parsedState) {
          newState[ch.id] = {
            ...newState[ch.id],
            ...parsedState,
          };
        }

        return newState;
      });

      if (ch.data.type === ChannelTypeInternal.Optimizer) {
        setOptimizerConfigValidation((ch.data as ChannelOptimizerType).payload?.configValidation);
      }
    }
  };

  const setDeviceDetails = (userDevice: UserDevice) => {
    const dvc = parseDevice(userDevice);

    if (dvc) {
      setDevice(dvc);

      setDeviceList((prev) => {
        const tempList = cloneDeep(prev);
        const index = tempList.findIndex((x) => x.id === dvc.id);
        if (index !== -1) tempList[index] = dvc;

        return tempList;
      });
    }
  };

  const clearChannelDetails = () => {
    setChannel(undefined);
    setOptimizerConfigValidation(undefined);
  };

  const clearDeviceDetails = () => setDevice(undefined);

  const values: DevicesAndChannelsState = {
    deviceList,
    setDeviceList,
    channelList,
    setChannelList,
    channel,
    optimizerConfigValidation,
    setChannelDetails,
    updateChannelDetails: setChannel,
    setOptimizerConfigValidation,
    clearChannelDetails,
    device,
    setDeviceDetails,
    updateDeviceDetails: setDevice,
    clearDeviceDetails,
  };

  return <DevicesAndChannelsContext.Provider value={values}>{children}</DevicesAndChannelsContext.Provider>;
};

export default DevicesAndChannelsProvider;
