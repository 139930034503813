import React from 'react';
import { ChannelType, GateKindParam } from '../../../../types';
import { GateActionControlBox } from './action';
import { GateChannelControlBox } from './channel';

type GateControlBoxCommonProps = {
  isListItem?: boolean;
};

type ChannelParams = GateControlBoxCommonProps & {
  kind: GateKindParam.Channel;
  channel: ChannelType;
};

type ActionParams = GateControlBoxCommonProps & {
  kind: GateKindParam.Action;
  channels: ChannelType[];
};

type GateControlBoxProps = ChannelParams | ActionParams;

export const GateControlBox: React.FC<GateControlBoxProps> = (props) => {
  return props.kind === GateKindParam.Channel ? (
    <GateChannelControlBox isListItem={props.isListItem} channel={props.channel} />
  ) : (
    <GateActionControlBox isListItem={props.isListItem} channels={props.channels} />
  );
};
