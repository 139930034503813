import { useMemo, useState } from 'react';
import * as dndCore from '@dnd-kit/core';
import * as dndSortable from '@dnd-kit/sortable';
import { InstallationSortable } from '../../../types';
import { InstallationListSortableProps } from '../components';

export const useListSortable = ({
  sortedInstallationList,
  setSortedInstallationList,
  setChangeInstallationVariables,
  setIsValid,
}: InstallationListSortableProps) => {
  const [dndActiveId, setDndActiveId] = useState<string | null>(null);

  const onDragStart = (event: dndCore.DragStartEvent) => setDndActiveId(event.active.id);

  const onDragEnd = (event: dndCore.DragEndEvent) => {
    if (!event.active || !event.over) return;
    if (event.active.id !== event.over.id && sortedInstallationList) {
      const newList = Array.from(sortedInstallationList);
      const oldIndex = newList.findIndex((installation: InstallationSortable) => installation.id === event.active.id);
      const newIndex = newList.findIndex((installation: InstallationSortable) => installation.id === event.over?.id);

      const sortedInstallations = dndSortable.arrayMove(newList, oldIndex, newIndex);
      const changeOrderItems = sortedInstallations.map((installation, index) => ({
        installationId: installation.id,
        order: index,
      }));

      setChangeInstallationVariables(changeOrderItems);
      setSortedInstallationList(sortedInstallations);
    }

    setIsValid(true);
    setDndActiveId(null);
  };

  const dragOverlayInstallation = useMemo(
    () => sortedInstallationList.find((x) => x.id === dndActiveId),
    [sortedInstallationList, dndActiveId],
  );

  return {
    onDragStart,
    onDragEnd,
    dragOverlayInstallation,
  };
};
