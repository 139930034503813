import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { Carousel, CarouselItem, CustomBackdrop, EmptyStateBox, IconWarning } from '../../../components';
import { ROUTES } from '../../../routes';
import { useExalusDevicesContext } from '../context/devices';
import { useExalusStorageContext } from '../context/storage';
import { useLoggedUser } from '../hooks/common/logged-user';
import DeviceGroup from '../pages/device-list/components/device-group';
import { useDeviceListContext } from '../pages/device-list/context';
import { GroupedDevice } from '../pages/device-list/types';

type ExalusDevicesProps = {
  itemGroupsList: GroupedDevice[];
  isFetched: boolean;
};

const ExalusDevices: React.FC<ExalusDevicesProps> = ({ itemGroupsList, isFetched }) => {
  const { t } = useTranslation('device-list');
  const { t: tc } = useTranslation('common');
  const { activeDeviceTabIndex, setActiveDeviceTabIndex } = useExalusStorageContext();
  const { updatesAvailability, checkUpdatesLoading, checkDevicesUpdates } = useExalusDevicesContext();
  const { canAddRemoveConfigureDevice } = useLoggedUser();
  const { updateMode } = useDeviceListContext();

  if (updateMode && checkUpdatesLoading) {
    return (
      <EmptyStateBox
        header={t('exalus.updatesChecking.header')}
        content={t('exalus.updatesChecking.content')}
        icon={<CircularProgress color="inherit" size={25} />}
      />
    );
  }

  if (updateMode && !updatesAvailability.length) {
    return (
      <EmptyStateBox
        header={t('exalus.updatesEmptyState.header')}
        btnText={t('exalus.updatesEmptyState.btnText')}
        content={t('exalus.updatesEmptyState.content')}
        onClick={() => checkDevicesUpdates(true)}
        icon={<IconWarning />}
      />
    );
  }

  return (
    <>
      {isFetched ? (
        <>
          {itemGroupsList.length > 0 ? (
            <Carousel setActiveIndex={setActiveDeviceTabIndex} activeIndex={activeDeviceTabIndex}>
              {itemGroupsList.map((groupDevice: GroupedDevice) => {
                return (
                  <CarouselItem key={groupDevice.label}>
                    {groupDevice.label !== tc('all') ? (
                      <DeviceGroup groupDevice={groupDevice} hiddenHeader updatesAvailability={updatesAvailability} />
                    ) : (
                      itemGroupsList.map(
                        (groupDevice: GroupedDevice) =>
                          groupDevice.label !== tc('all') && (
                            <div key={groupDevice.label}>
                              <DeviceGroup groupDevice={groupDevice} updatesAvailability={updatesAvailability} />
                            </div>
                          ),
                      )
                    )}
                  </CarouselItem>
                );
              })}
            </Carousel>
          ) : (
            <EmptyStateBox
              header={t('devicesEmptyState.header')}
              btnText={t('devicesEmptyState.missingDevice.btnText')}
              content={t('devicesEmptyState.missingDevice.content')}
              icon={<IconWarning />}
              {...(canAddRemoveConfigureDevice ? { linkTo: ROUTES.AddExalusDevices() } : {})}
            />
          )}
        </>
      ) : (
        <CustomBackdrop loading />
      )}
    </>
  );
};

export default ExalusDevices;
