import React, { useMemo } from 'react';
import { UserAction } from '../../../data-access/gql-types/graphql';
import { DashboardUserAction, ChannelActionDetails, ChannelType } from '../../../types';
import { sortByOrder } from '../../../utils/helpers';
import { ChannelIcon } from '../../channel';
import { IconMore, IconArrowLink, IconPlay } from '../../icons';
import './index.scss';

type EventListProps = {
  channels: ChannelType[];
  userAction: DashboardUserAction | UserAction;
};

export const EventList: React.FC<EventListProps> = ({ channels, userAction }) => {
  const mappedArray = useMemo(
    () =>
      userAction?.action.channelActions
        .map((channel) => ({
          ...channel,
          ...channels.find((element) => element && element.id === channel.channelId),
        }))
        .filter((channel) => channel.id)
        .sort(sortByOrder) as ChannelActionDetails[],
    [userAction, channels.length],
  );

  const items = useMemo(
    () => [
      {
        icon: <IconPlay circle={false} />,
      },
      {
        icon: <IconArrowLink />,
      },
      ...mappedArray.map((channel) => ({
        icon: <ChannelIcon channel={channel} statusOn />,
      })),
    ],
    [mappedArray],
  );

  const showMoreIcon = useMemo(() => items.length > 4, [items]);
  const events: { icon: React.ReactElement }[] = useMemo(() => (showMoreIcon ? items.splice(0, 4) : items), [items]);

  return (
    <div className="event-list">
      {events.map((item, i) => (
        <div key={i} className="event-list__item event-list__item--background">
          <div className="event-list__item-icon">{item.icon}</div>
        </div>
      ))}
      {showMoreIcon ? (
        <div className="event-list__item event-list__item--background">
          <div className="event-list__item-icon">
            <IconMore shape="square" />
          </div>
        </div>
      ) : null}
    </div>
  );
};
