import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ISsr_4_0_0_0 } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SsrConfigService/VersionedOptions/^4.0.0.0/ISsrVersionedService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { InputSelect, SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../hooks';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';

type HeadSensorFormProps = {
  device: IDevice;
  params: boolean | null;
  channel: IDeviceChannel;
  configService: ISsr_4_0_0_0;
};

const HeadSensorForm: React.FC<HeadSensorFormProps> = ({ device, channel, params, configService }) => {
  const { t } = useTranslation('device-info');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse } = useConfigurationService(device);
  const form = useForm({
    defaultValues: {
      HeadSensor: 0,
    },
  });

  const { HeadSensor } = form.watch();

  useEffect(() => {
    form.setValue('HeadSensor', params ? 1 : 0);
  }, [params]);

  const onSubmit = form.handleSubmit(async (values) => {
    turnOnBackdrop();

    const result = await configService.SwitchHeadSensorStatusAsync(!!values.HeadSensor, device, channel.Number);
    handleSubmitResponse(result);
    turnOffBackdrop();
  });

  const onChangeState = (value: number) => {
    form.setValue('HeadSensor', value);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="grid-list-24">
        <InputSelect
          onChange={onChangeState}
          value={HeadSensor}
          options={[
            { label: t('exalus.params.SsrParams.Delicate'), value: 1 },
            { label: t('exalus.params.SsrParams.Resistance'), value: 0 },
          ]}
          label={t('exalus.params.SsrParams.HeadSensor')}
        />
        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default HeadSensorForm;
