import React, { useMemo } from 'react';
import { EcoLevel } from '../../../types';
import './index.scss';

type IndicatorElementProps = {
  ecoLevel: EcoLevel;
};

export const IndicatorElement: React.FC<IndicatorElementProps> = ({ ecoLevel }) => {
  const style = useMemo(
    () =>
      ({
        '--background': ecoLevel.value,
      } as React.CSSProperties),
    [ecoLevel],
  );

  return <li className="indicator-element" style={style} />;
};
