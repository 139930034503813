import React, { useCallback, useState } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { InstallationColorBtn } from '../../../components';
import {
  InstallationModelResponse,
  IntegrationType,
  UserInstallation,
  UserPermissionType,
} from '../../../data-access/gql-types/graphql';
import { useInstallation } from '../../../hooks';
import { usePermissionsContext } from '../../../hooks/user-permissions';
import { isLocalApp } from '../../../utils/helpers/local-app';
import * as storage from '../../../utils/storage/lavva';
import { ExalusRemoteStorage, useExalusRemoteStorage } from '../../exalus/hooks/storage.hooks';
import InstallationIconEdit from '../../installation-create/components/installation-icon';
import InstallationName from '../../installation-create/components/installation-name';
import LocationEdit from '../../installation-create/components/location';
import { DialogContextProvider } from '../../installation-create/components/location/context/location.context';
import TimeZoneEdit from '../../installation-create/components/time-zone';
import { CreateInstallationControlForm } from '../../installation-create/types';
import '../index.scss';

type InstallationFormProps = {
  editForm: UseFormReturn<CreateInstallationControlForm>;
};

const InstallationForm: React.FC<InstallationFormProps> = ({ editForm }) => {
  const { installationId } = useParams<{ installationId: string }>();
  const [disabled, setDisabled] = useState<boolean>(true);
  const { installationList, updateInstallationList, selectedInstallationId, integrationType } = useInstallation();
  const { saveExalusDataStorage } = useExalusRemoteStorage();
  const { permissions } = usePermissionsContext();

  const updateInstallation = useCallback(
    async (key: keyof UserInstallation, value: string | InstallationModelResponse) => {
      const newInstallationList: UserInstallation[] = Array.from(installationList);
      const installationIndex = newInstallationList.findIndex(
        (installation: UserInstallation) => installation.installationId === (installationId || selectedInstallationId),
      );

      if (installationIndex !== -1) {
        newInstallationList[installationIndex] = {
          ...newInstallationList[installationIndex],
          [key]: value,
        };

        updateInstallationList(newInstallationList);

        if (isLocalApp) {
          storage.setLocalItem('installationList', newInstallationList);
        }

        if (newInstallationList[installationIndex].integrationType === IntegrationType.Exalus) {
          if (['iconName', 'hexColor', 'name'].includes(key)) {
            await saveExalusDataStorage(key as keyof ExalusRemoteStorage, value as string);
          }
        }
      }
    },
    [installationList, installationId],
  );

  return (
    <FormProvider {...editForm}>
      <form className="installation_edit">
        <InstallationIconEdit updateInstallation={updateInstallation} />
        <div className="grid-list-24">
          <InstallationName disabled={disabled} setDisabled={setDisabled} updateInstallation={updateInstallation} />
          <InstallationColorBtn updateInstallation={updateInstallation} />
          {integrationType !== IntegrationType.Exalus && permissions[UserPermissionType.ChangeTimeZone] && (
            <TimeZoneEdit updateInstallation={updateInstallation} />
          )}
          {permissions[UserPermissionType.ChangeGeolocation] && (
            <DialogContextProvider>
              <LocationEdit updateInstallation={updateInstallation} />
            </DialogContextProvider>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default InstallationForm;
