import React from 'react';
import { TriggerEvent } from '../../../../../types';

type TriggerEventItemProps = {
  event: TriggerEvent;
};

export const TriggerEventItem: React.FC<TriggerEventItemProps> = ({ event }) => (
  <div className="trigger-event-list__item trigger-event-list__item--background">
    <div className="trigger-event-list__item-icon">{event.icon}</div>
  </div>
);
