import React from 'react';
import { ChannelTypeInternal } from '../../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../../hooks';
import { ChannelType, ChannelLightStateType } from '../../../../types';

type ChannelBoxPreNameProps = {
  channel: ChannelType;
};

export const ChannelBoxPreName: React.FC<ChannelBoxPreNameProps> = ({ channel }) => {
  const { channelState } = useChannelsState();

  if (channel.data.type === ChannelTypeInternal.Light) {
    const color = `${(channelState[channel.id] as ChannelLightStateType)?.color?.r}, ${
      (channelState[channel.id] as ChannelLightStateType)?.color?.g
    }, ${(channelState[channel.id] as ChannelLightStateType)?.color?.b}`;

    if (!color) return null;

    return <span className="rgb-value" style={{ backgroundColor: `rgb(${color})` }}></span>;
  }

  return null;
};
