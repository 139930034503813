import { Section } from '../../types';

type ListSectionProps = {
  section: Section;
};

const ListSection: React.FC<ListSectionProps> = ({ section }) => (
  <div className="section">
    <div className="section__date">{section.date}</div>
    {section.items.map((y) => (
      <div key={y.period} className="list-item">
        <div className="icon-state">
          <div className="icon">{y.icon}</div>
          <div>{y.state}</div>
        </div>
        <div className="period">{y.period}</div>
      </div>
    ))}
  </div>
);

export default ListSection;
