import { useBlindSetDirection, useBlindSetPosition } from '../../api/modules/blind/blind.hooks';
import { ChannelType, CoverDirection } from '../../types';

export const useCover = (channel: ChannelType) => {
  const setBlindDirection = useBlindSetDirection();
  const setBlindPosition = useBlindSetPosition();

  const handleAction = (value: string | number) => {
    if (typeof value === 'string') {
      setBlindDirection.mutate({
        channelId: channel.id,
        deviceId: channel.deviceId,
        direction: CoverDirection[value],
      });
    } else {
      setBlindPosition.mutate({ channelId: channel.id, deviceId: channel.deviceId, position: value });
    }
  };

  return {
    handleAction,
  };
};
