import React from 'react';
import classNames from 'classnames';
import VerticalSlider from '@mui/material/Slider';
import { Slats } from '../slats';
import './index.scss';

type SlatsSectionProps = {
  tiltValue: number;
  fullRange: boolean;
  defaultValue: number;
  value: number;
  onChange: (_, value) => void;
  onChangeCommitted?: () => void;
  activeTilts?: boolean;
  activeBlind?: boolean;
};

export const SlatsSection: React.FC<SlatsSectionProps> = ({
  tiltValue,
  fullRange = false,
  activeTilts,
  activeBlind,
  value,
  defaultValue,
  onChange,
  onChangeCommitted,
}) => (
  <div className={classNames('slats-section', { active: activeTilts, disabled: activeBlind })}>
    <div className="slats-section__wrapper">
      <Slats tilt={tiltValue} fullRange={fullRange} />
      <VerticalSlider
        classes={{
          root: 'vertical-slider',
          rail: 'vertical-slider__rail',
          thumb: 'vertical-slider__thumb',
        }}
        disabled={activeBlind}
        orientation="vertical"
        track={false}
        valueLabelDisplay="off"
        defaultValue={value}
        value={defaultValue}
        onChange={onChange}
        {...(onChangeCommitted && { onChangeCommitted })}
      />
    </div>
  </div>
);
