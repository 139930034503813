import { ChangeEvent } from 'react';
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import { Checkbox } from '../../../../../../components';
import { TariffBody } from '../../types';

type CheckboxControllerProps = {
  name: string;
  label: string;
  handleWatchForCustom: (values: TariffBody) => void;
};

export const CheckboxController: React.FC<CheckboxControllerProps> = ({ name, label, handleWatchForCustom }) => {
  const { control, getValues } = useFormContext();

  const handleChange = (e: ChangeEvent<HTMLInputElement>, field: ControllerRenderProps<FieldValues, string>) => {
    field.onChange(e);
    handleWatchForCustom(getValues() as TariffBody);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Checkbox onChange={(e) => handleChange(e, field)} checked={field.value} reverse>
          {label}
        </Checkbox>
      )}
    />
  );
};
