import React, { ReactNode, createContext, useContext, useMemo, useState, useCallback } from 'react';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import { ApolloClient, HttpLink, from, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
// import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
// import { generatePersistedQueryIdsFromManifest } from '@apollo/persisted-query-lists';
import { useKeycloak } from '@react-keycloak/web';
import { environment } from '../../environment';
import { useSentry } from '../../hooks/common/use-sentry';
import { useErrors } from '../../hooks/use-errors';
import { GraphqlState, Sub } from '../../types/grapql';

const { link: networkStatusNotifierLink } = createNetworkStatusNotifier();

type GraphqlProviderProps = {
  children: ReactNode;
};

const initialState: GraphqlState = {
  client: new ApolloClient({
    cache: new InMemoryCache(),
  }),
  API_URL: environment.API_URL || '',
  LAVVA_MANAGER: `${environment.API_URL}graphql/` || '',
  updateBrokerUrl: () => null,
  subs: [],
  addSub: () => null,
};

export const GraphqlContext = createContext(initialState);

export const useGraphqlContext = (): GraphqlState => useContext(GraphqlContext);

const GraphqlProvider: React.FC<GraphqlProviderProps> = ({ children }) => {
  const { keycloak } = useKeycloak();
  const { handleGraphQLError } = useErrors();
  const [brokerUrl, setBrokerUrl] = useState<string>(initialState.API_URL);
  const [subs, setSubs] = useState<Sub[]>([]);
  const { setSentryTags } = useSentry();

  const addSub = useCallback(
    (sub) => {
      setSubs((prev: Sub[]) => [...prev, sub]);
    },
    [subs],
  );

  const API_URL = useMemo(() => {
    if (brokerUrl.charAt(brokerUrl.length - 1) === '/') return brokerUrl;
    else return `${brokerUrl}/graphql/`;
  }, [brokerUrl]);

  const LAVVA_MANAGER = useMemo(() => API_URL.replace('graphql/', ''), [API_URL]);

  const authLink = setContext(({ operationName }, { headers }) =>
    Promise.resolve(keycloak.token).then((token) => ({
      headers: { ...headers, Authorization: token ? `Bearer ${token}` : '', 'APOLLO-QUERY-NAME': operationName },
    })),
  );

  // const persistedQueriesLink = createPersistedQueryLink(
  //   generatePersistedQueryIdsFromManifest({
  //     loadManifest: () => import('../../persisted-query-manifest.json'),
  //   }),
  // );

  const wsLink = new WebSocketLink({
    uri: (location.protocol === 'http:' ? API_URL.replace('https', 'ws') : API_URL.replace('https', 'wss')) || '',
    options: {
      reconnect: true,
      connectionParams: () => {
        return Promise.resolve(keycloak.token).then((token) => ({ Authorization: `Bearer ${token}` }));
      },
      connectionCallback: async (error: any) => {
        if (error?.message) {
          await reset();
          window.location.reload();
        }
      },
    },
  });

  const link = useMemo(() => {
    // We use `Promise.resolve` with `keycloak.token` because we want to have
    // fresh token value for each request.  It's necessary because `useKeycloak`
    // is actually not a hook in sence that it will not trigger re-render.  Read
    // duscussion in repo on GitHub for full context.
    //
    // https://github.com/react-keycloak/react-keycloak/issues/116#issuecomment-832823810
    return split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      networkStatusNotifierLink.concat(
        authLink
          // .concat(persistedQueriesLink)
          .concat(new HttpLink({ uri: (context) => `${API_URL}?opname=${context.operationName}` })),
      ),
    );
  }, [API_URL, keycloak]);

  const reset = async () => {
    await keycloak.updateToken(-1);
    await client.resetStore();

    await client.setLink(from([onError((error) => handleGraphQLError(error)), link]));
  };

  const client = useMemo(() => {
    return new ApolloClient({
      link: from([onError((error) => handleGraphQLError(error)), link]),
      cache: new InMemoryCache(),
      defaultOptions: {
        mutate: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore',
        },
      },
      connectToDevTools: process.env.NODE_ENV === 'development',
    });
  }, [link, API_URL, keycloak]);

  keycloak.onAuthRefreshSuccess = async () => {
    await client.resetStore();
    await client.setLink(from([onError((error) => handleGraphQLError(error)), link]));
  };

  keycloak.onAuthRefreshError = async () => window.location.reload();

  keycloak.onAuthError = async () => window.location.reload();

  const updateBrokerUrl = (url: string) => {
    setBrokerUrl(url);
    setSentryTags({ brokerUrl: url });
  };

  const values = {
    client,
    API_URL,
    LAVVA_MANAGER,
    updateBrokerUrl,
    subs,
    addSub,
  };

  return <GraphqlContext.Provider value={values}>{children}</GraphqlContext.Provider>;
};

export default GraphqlProvider;
