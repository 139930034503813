import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { LightThresholdStateEnum } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { IconToastWarning2 } from '../../../../../../../components/icons/icon-toast-warning-2';
import { useExalusUtils } from '../../../../../hooks/scenarios.hooks';
import { useBrightnessScale } from '../../../../../pages/action-create/hooks/use-brightness-scale';
import { ChannelBox } from '../../../box';
import { ChannelBoxIcon } from '../../../box-icon';
import { useBrightnessControl } from '../../../hooks/use-brightness-control';
import { PowerState } from '../../../power';
import SensorDetailsWrapper from '../../../sensor-details-wrapper';
import MeasuredBrightnessControl from '../controls';

type MeasuredBrightnessDetailsControlsProps = {
  channel: IDeviceChannel;
};

export const MeasuredBrightnessDetailsControls: React.FC<MeasuredBrightnessDetailsControlsProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { brightness, brightnessThreshold, time } = useBrightnessControl(channel);
  const { getBrightnessStateByScale } = useBrightnessScale();
  const { data } = useExalusUtils();

  const linearText = useMemo(() => {
    if (data && brightness !== null) {
      const scale = data.parseLuxToLinearScale(brightness);
      return getBrightnessStateByScale(scale);
    }

    return '';
  }, [data, brightness]);

  return (
    <SensorDetailsWrapper channel={channel}>
      <div className="details-control grid-list-16">
        <div>
          <div>
            <ChannelBox channel={channel} isListItem className="device-box--details">
              <div className="device-box__head">
                <ChannelBoxIcon channel={channel} />
                <MeasuredBrightnessControl channel={channel} isListItem details />
              </div>
              {brightnessThreshold === LightThresholdStateEnum.Exceeded && (
                <div className="device-box__warning">
                  <IconToastWarning2 size={24} colorError />
                  <span className={'m-l-8'}>{t('exalus.lightThresholdExceeded.heading')}</span>
                </div>
              )}
            </ChannelBox>

            {brightnessThreshold === LightThresholdStateEnum.Exceeded && (
              <div className="details-controls__additional-text">{t('exalus.lightThresholdExceeded.text')}</div>
            )}
            {time && <div className="details-controls__detection-time">{`${t('exalus.checked')} ${time}`}</div>}
          </div>
          {linearText && (
            <ChannelBox channel={channel} isListItem className="device-box--details">
              <div className="device-box__head">
                <ChannelBoxIcon channel={channel} />
                <span>{linearText}</span>
              </div>
            </ChannelBox>
          )}
        </div>
        <PowerState channel={channel} />
      </div>
    </SensorDetailsWrapper>
  );
};
