import React, { useMemo } from 'react';
import { DeviceTaskType as ChannelTaskType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { canChannelDo } from '../../../../../utils';
import { EntryPhoneControlButtons } from '../../../../control-buttons/entry-phone';
import { useEntryPhoneControl } from '../../../hooks/use-entry-phone.control';

type EntryPhoneControlsProps = {
  channel: IDeviceChannel;
  isListItem?: boolean;
};

const EntryPhoneControls: React.FC<EntryPhoneControlsProps> = ({ channel, isListItem }) => {
  const { openGateway, openGate, call } = useEntryPhoneControl(channel);

  const gate = useMemo(() => canChannelDo(channel, [ChannelTaskType.SetGatePosition]), [channel]);
  const gateway = useMemo(() => canChannelDo(channel, [ChannelTaskType.SetGatewayPosition]), [channel]);

  return (
    <EntryPhoneControlButtons
      {...(gateway ? { openGateway: openGateway } : {})}
      {...(gate ? { openGate: openGate } : {})}
      call={call}
      isListItem={isListItem}
    />
  );
};

export default EntryPhoneControls;
