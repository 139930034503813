import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { TabType } from '../../../components';
import { IntegrationType, UserPermissionType } from '../../../data-access/gql-types/graphql';
import { environment } from '../../../environment';
import { useInstallation, useNative } from '../../../hooks';
import { usePermissionsContext } from '../../../hooks/user-permissions';
import { isLocalApp } from '../../../utils/helpers/local-app';
import { pathnameWithParameters } from '../../../utils/location';
import { useLoggedUser } from '../../exalus/hooks/common/logged-user';
import ExalusControllerSettings from '../../exalus/pages/controller-settings';
import InstallationSettings from '../../installation-settings';
import ApplicationSettings from '../application';
import Device from '../device';
import Feedback from '../feedback';
import Notifications from '../notifications';
import Policy from '../policy';
import ProfileSettings from '../profile';
import Terms from '../terms';

export const useSettings = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { t } = useTranslation('profile');
  const { t: ti } = useTranslation('installation');
  const { integrationType, selectedInstallationId } = useInstallation();
  const { canDeviceEdit } = useLoggedUser();
  const { checkForVibrationSupported } = useNative();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { permissions } = usePermissionsContext();

  useEffect(() => {
    const tab = new URLSearchParams(search).get('tab');
    if (tab) setActiveTabIndex(parseInt(tab));
  }, []);

  const deviceListVisible = useMemo(() => {
    if (integrationType === IntegrationType.Exalus) return canDeviceEdit;

    return permissions[UserPermissionType.DeviceList];
  }, [integrationType, permissions, canDeviceEdit]);

  const isExalus = useMemo(() => integrationType === IntegrationType.Exalus, [integrationType]);

  const tabsAndPages = useMemo(
    () => [
      {
        tabLabel: ti('exalus.settingsController'),
        component: <ExalusControllerSettings key={1} onlyContent />,
        enabled: isExalus,
      },
      {
        tabLabel: ti('settings'),
        component: <InstallationSettings id={selectedInstallationId} />,
        enabled: !!selectedInstallationId && !isLocalApp,
      },
      {
        tabLabel: t('devicesList'),
        component: <Device />,
        enabled: deviceListVisible && !isLocalApp,
      },
      {
        tabLabel: t('yourApp'),
        component: <ApplicationSettings />,
        enabled: true,
      },
      {
        tabLabel: t('notifications'),
        component: <Notifications />,
        enabled: !isLocalApp,
      },
      {
        tabLabel: t('yourProfile'),
        component: <ProfileSettings />,
        enabled: true,
      },
      {
        tabLabel: t('support'),
        component: <Feedback />,
        enabled: true,
      },
      {
        tabLabel: t('policy'),
        component: <Policy />,
        enabled: true,
      },
      {
        tabLabel: t('terms'),
        component: <Terms />,
        enabled: true,
      },
    ],
    [
      selectedInstallationId,
      deviceListVisible,
      isExalus,
      isLocalApp,
      environment.INTEGRATION_DEFAULT,
      t,
      ti,
    ],
  );

  const handleTabChange = (tabIndex: number) => {
    const appSettingsIndex = tabsAndPages.filter((x) => x.enabled).findIndex((x) => x.tabLabel === t('yourApp'));
    if (appSettingsIndex === tabIndex) checkForVibrationSupported();

    setActiveTabIndex(tabIndex);
    history.replace(pathnameWithParameters(pathname, search, { key: 'tab', value: tabIndex }));
  };

  const tabs: TabType[] = useMemo(
    () => tabsAndPages.filter((x) => x.enabled).map((x) => ({ label: x.tabLabel })),
    [tabsAndPages],
  );

  const components: React.ReactNode[] = useMemo(
    () => tabsAndPages.filter((x) => x.enabled).map((x) => x.component),
    [tabsAndPages],
  );

  return {
    tabs,
    activeTabIndex,
    handleTabChange,
    components,
    tabsAndPages,
  };
};
