import React, { useMemo } from 'react';
import { orderBy } from 'lodash';
import { InstallationItem } from '../../../../components';
import { ExalusInstallationPayload, UserInstallation } from '../../../../data-access/gql-types/graphql';
import { useInstallation } from '../../../../hooks';

type InstallationListSectionProps = {
  searchText: string;
};

export const InstallationListSection: React.FC<InstallationListSectionProps> = ({ searchText }) => {
  const { installationList } = useInstallation();

  const orderedInstallationList = useMemo(
    () =>
      orderBy(
        installationList.filter(
          (x) =>
            x.name.toLowerCase().includes(searchText.toLowerCase()) ||
            (x.externalPayload as ExalusInstallationPayload)?.serialNumber
              .toLowerCase()
              .includes(searchText.toLowerCase()),
        ),
        'order',
      ).map((installation: UserInstallation) => (
        <InstallationItem key={installation.installationId} installation={installation} />
      )),
    [installationList, searchText],
  );

  return <>{orderedInstallationList}</>;
};
