import React, { useMemo } from 'react';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  RemoteButtonDeviceState,
  RemoteButtonStateEnum,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { ControlWrapper } from '../../../../../../components';
import { IconSignalState } from '../../../../../../components/icons';

type TransmitterDetailsControlsProps = {
  channel: IDeviceChannel;
};

export const TransmitterDetailsControls: React.FC<TransmitterDetailsControlsProps> = ({ channel }) => {
  const signal = useMemo(() => {
    const remoteState = channel?.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.RemoteButtonState,
    ) as RemoteButtonDeviceState;

    if (remoteState) {
      if (
        remoteState?.Data.State === RemoteButtonStateEnum.Pressed ||
        remoteState?.Data.State === RemoteButtonStateEnum.PressedAndRelased
      ) {
        return 100;
      }
    }

    return 6;
  }, [channel?.States]);

  const isState = useMemo(() => {
    const remoteState = channel?.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.RemoteButtonState,
    ) as RemoteButtonDeviceState;

    return !!remoteState;
  }, [channel.States]);

  return (
    <ControlWrapper className="control-wrapper--full-space">
      <IconSignalState signal={signal} noState={!isState} big />
    </ControlWrapper>
  );
};
