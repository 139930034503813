import React from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { BlindControlButtons } from '../../../../control-buttons/blind';
import { useCentralActionFacadeControl } from '../../../hooks/use-central-action-facade-control';

type CentralActionFacadeControlBoxProps = {
  channels: IDeviceChannel[];
  isListItem?: boolean;
};

export const CentralActionFacadeControlBox: React.FC<CentralActionFacadeControlBoxProps> = ({
  channels,
  isListItem,
}) => {
  const { open, close, stop } = useCentralActionFacadeControl(channels);

  return <BlindControlButtons close={close} stop={stop} open={open} isListItem={isListItem} />;
};
