import {
  ActionConfigResponse,
  BlindSupportedSlotsInternal,
  ChannelTypeInternal,
  GateSupportedSlotsInternal,
  InputTriggerInternal,
  LightSupportedSlotsInternal,
  SwitchSupportedSlotsInternal,
} from '../../../../data-access/gql-types/graphql';

export type BindingListItem = {
  channelId: string;
  channelType: ChannelTypeInternal | 'Cloud';
  inputTrigger: InputTriggerInternal | null;
  actionType: ActionBindingType | null;
  slot: string | null;
  [ChannelActions.SetOnTimeValue]?: string;
  [ChannelActions.Position]?: number;
  [ChannelActions.Dir]?: number;
  [ChannelActions.Brightness]?: number;
  [ChannelActions.Temperature]?: number;
  [ChannelActions.R]?: number;
  [ChannelActions.G]?: number;
  [ChannelActions.B]?: number;
  [ChannelActions.OnSignalActionId]?: string;
  [ChannelActions.OffSignalActionId]?: string;
};

export type BindingsItem = {
  inputId: string;
  single: BindingListItem[];
  double: BindingListItem[];
};

export type DeviceBindingsForm = {
  bindings: BindingsItem[];
};

export enum ActionBindingType {
  Default = 'default',
  Switch = 'Switch',
  Light = 'Light',
  Blind = 'Blind',
  BlindDefault1 = 'default-1',
  BlindDefault2 = 'default-2',
  Gate = 'Gate',
  GateDefault1 = 'default-1',
  GateDefault2 = 'default-2',
  GateDefault3 = 'default-3',
  GateDefault4 = 'default-4',
  GateDefault5 = 'default-5',
  GateDefault6 = 'default-6',
  GateDefault7 = 'default-7',
  Cloud = 'Cloud',
}

export enum ChannelActions {
  SetOnTimeValue = 'setOnTimeValue',
  Position = 'position',
  Dir = 'dir',
  Brightness = 'brightness',
  Temperature = 'temperature',
  R = 'r',
  G = 'g',
  B = 'b',
  OffSignalActionId = 'offSignalActionId',
  OnSignalActionId = 'onSignalActionId',
}

export enum LightActionTypeEnum {
  SetOnTimeValue = 'setOnTimeValue',
  RGB = 'rgb',
  Brightness = 'brightness',
  Temperature = 'temperature',
}

export enum GateActionTypeEnum {
  Position = 'position',
  Direction = 'direction',
}

export enum GateDirection {
  Unknown = 0,
  Stop = 1,
  Open = 2,
  Close = 3,
}

export type ActionConfigResponseMap = ActionConfigResponse & {
  slot:
    | SwitchSupportedSlotsInternal
    | BlindSupportedSlotsInternal
    | LightSupportedSlotsInternal
    | GateSupportedSlotsInternal;
};
