import React from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  ControlWrapper,
  IconConnectionError,
  IconInitiate,
  InitiateButton,
} from '../../../../../../../../../components';
import { useSomethingWrong } from '../../../../../../../pages/channel-list/hooks/use-something-wrong';
import { useGateControl } from '../../../../../hooks/use-gate-control';

type GateCommonDetailsControlsProps = {
  channel: IDeviceChannel;
  active: boolean;
};

export const GateCommonDetailsControls: React.FC<GateCommonDetailsControlsProps> = ({ channel, active }) => {
  const { connectionIcon } = useSomethingWrong(channel, { detailsView: true });
  const { handlePositionPulse } = useGateControl(channel);

  return (
    <ControlWrapper className="control-wrapper--full-space">
      {connectionIcon && <IconConnectionError big size={14} />}
      <InitiateButton isOn={false} onClickControl={handlePositionPulse} active={active}>
        <IconInitiate />
      </InitiateButton>
    </ControlWrapper>
  );
};
