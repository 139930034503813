import React from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { BlindControlButtons } from '../../../../control-buttons/blind';
import { useCentralActionBlindControl } from '../../../hooks/use-central-action-blind-control';

type CentralActionBlindControlBoxProps = {
  channels: IDeviceChannel[];
  isListItem?: boolean;
};

export const CentralActionBlindControlBox: React.FC<CentralActionBlindControlBoxProps> = ({ channels, isListItem }) => {
  const { open, close, stop } = useCentralActionBlindControl(channels);

  return <BlindControlButtons close={close} stop={stop} open={open} isListItem={isListItem} />;
};
