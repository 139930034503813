import React from 'react';
import './index.scss';

export const ViewFinder: React.FC = () => (
  <div className="view-finder">
    <svg width="260" height="260" viewBox="0 0 260 260" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0H26.7302V6H9C7.34315 6 6 7.34315 6 9V26.8955H0V9C0 4.02944 4.02944 0 9 0ZM0 233.105H6V251.001C6 252.658 7.34315 254.001 9 254.001H26.7302V260.001H9C4.02944 260.001 0 255.971 0 251.001V233.105ZM231.513 260.001H251.001C255.971 260.001 260.001 255.971 260.001 251.001V233.105H254.001V251.001C254.001 252.658 252.658 254.001 251.001 254.001H231.513V260.001ZM254.001 26.8955V9C254.001 7.34315 252.658 6 251.001 6H231.513V0H251.001C255.971 0 260.001 4.02944 260.001 9V26.8955H254.001Z"
        fill="#009EE3"
      />
    </svg>
  </div>
);
