import React, { useMemo } from 'react';
import { isNumber } from 'lodash';
import { ControlSourceType, GateEventTypeInternal } from '../../../../../../data-access/gql-types/graphql';
import { useDevicesAndChannelsContext } from '../../../../../../hooks/devices-and-channels/provider';
import { ChannelGateType } from '../../../../../../types';
import { gateIcons } from '../../../../../channel-configuration/components/icon-edit/icons/gate';
import { GateControlIcon } from '../gate-control-icon';
import './index.scss';

type HistoryGateIconProps = {
  eventType: GateEventTypeInternal;
  source: ControlSourceType;
  value: number | null;
};

const eventTypePosition: Record<string, number> = {
  [GateEventTypeInternal.SetOpen]: 0,
  [GateEventTypeInternal.SetClose]: 100,
  [GateEventTypeInternal.SetTilt]: 90,
  [GateEventTypeInternal.SetPartialOpen]: 50,
  [GateEventTypeInternal.SetPosition]: 50,
};

export const HistoryGateIcon: React.FC<HistoryGateIconProps> = ({ eventType, source, value }) => {
  const { channel } = useDevicesAndChannelsContext();

  const gateIcon = useMemo(() => {
    let position = eventTypePosition[eventType];

    if (!isNumber(position)) position = 100;
    if (
      [GateEventTypeInternal.SetPosition, GateEventTypeInternal.SetPartialOpen].includes(eventType) &&
      value !== null
    ) {
      position = value;
    }

    const availableIcons = gateIcons((channel?.data as ChannelGateType)?.gateKind, position);
    const icon = availableIcons.find((x) => x.iconName === channel?.iconName || x.oldIconName === channel?.iconName);
    if (icon) return icon.component;
    return availableIcons[0].component;
  }, [channel, eventType]);

  return (
    <div className="history-gate-icon">
      {gateIcon}
      <GateControlIcon source={source} />
    </div>
  );
};
