import React from 'react';
import { useTranslation } from 'react-i18next';
import { SupportedDevice } from 'lavva.webbluetooth';
import { useHorizontalScroll } from '../../hooks/use-scroll';
import { ProductListItem } from '../product-list-item';
import './index.scss';

type SeeMoreListProps = {
  channelsSeeMore: SupportedDevice[];
};

const SeeMoreList: React.FC<SeeMoreListProps> = ({ channelsSeeMore }) => {
  const { t } = useTranslation('store');
  const { scrollableRef } = useHorizontalScroll<HTMLDivElement>();

  if (!channelsSeeMore.length) return null;

  return (
    <div className="see-more">
      <p className="see-more--header">{t('seeMore')}</p>
      <div className="see-more--list" ref={scrollableRef}>
        {channelsSeeMore.map((device, index) => (
          <ProductListItem key={index} device={device} type="more" />
        ))}
      </div>
    </div>
  );
};

export default SeeMoreList;
