import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { CustomBackdrop, Header, NavHeader, Page, SubmitButton } from '../../../../../../../components';
import { ROUTES } from '../../../../../../../routes';
import { useFacadeControl } from '../../../../../components/channel/hooks/use-facade-control';
import { BlindDetailsSimpleControlButtons } from '../../../../../components/control-buttons/blind-details/simple';
import { useHandleDataFrameErrors } from '../../../../../hooks';
import { useTasks } from '../../../../channel-list/hooks/use-tasks';
import { useCalibration } from '../../common/hooks/use-calibration';
import './index.scss';

type ZfBidiCalibrationStep1Props = {
  setStep: Dispatch<SetStateAction<number>>;
};

const ZfBidiCalibrationStep1: React.FC<ZfBidiCalibrationStep1Props> = ({ setStep }) => {
  const { channel, history, t, turnOnBackdrop, turnOffBackdrop, getZfBidiConfigService } = useCalibration();
  const { open, stop, close } = useFacadeControl(channel);
  const { handleError } = useHandleDataFrameErrors();
  const { active } = useTasks(channel);

  const handleClick = useCallback(() => {
    if (channel) {
      setStep(2);
      history.push(`${ROUTES.FacadeCalibration(channel.ChannelId)}?step=2`);
    }
  }, [channel]);

  const changeDirections = useCallback(async () => {
    if (channel) {
      turnOnBackdrop();
      const configService = await getZfBidiConfigService();
      const result = await configService.ChangeMovementDirectionAsync(channel?.GetDevice(), channel.Number);

      if (result !== Status.OK) handleError(result);
      turnOffBackdrop();
    }
  }, [channel]);

  return (
    <Page
      className="calibration-flow"
      isStickyHeader
      whiteContent
      header={
        <>
          <NavHeader />
          <Header title={t('exalus.calibration')} />
        </>
      }
    >
      <div>
        <BlindDetailsSimpleControlButtons open={open} stop={stop} close={close} active={active} />
        <p className="info m-t-40">{t('exalus.params.ZfBidiParams.calibrationFlow.checkDirections')}</p>
        <button className="button button--secondary m-t-24" onClick={changeDirections}>
          {t('exalus.params.ZfBidiParams.calibrationFlow.changeDirections')}
        </button>
        <SubmitButton type="button" onClick={handleClick} tranparent>
          {t('exalus.params.ZfBidiParams.calibrationFlow.next')}
        </SubmitButton>
      </div>
      {!channel && <CustomBackdrop loading />}
    </Page>
  );
};

export default ZfBidiCalibrationStep1;
