import { DeviceConnectionState, SwitchStateChangePayload } from '../../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../../hooks';
import { ChannelSwitchStateType } from '../../../../types';

export const useLavvaDeviceSwitchUpdate = () => {
  const { setChannelState } = useChannelsState();

  const updateLavvaSwitchState = ({ channelId, state }: SwitchStateChangePayload) => {
    setChannelState((oldState) => {
      const newState = { ...oldState };
      newState[channelId] = {
        ...newState[channelId],
        deviceConnectionState: DeviceConnectionState.Connected,
        lavvaState: state,
      } as ChannelSwitchStateType;

      return newState;
    });
  };

  return {
    updateLavvaSwitchState,
  };
};
