import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { DialogConfirmation, Header, IconAutomatic, IconEdit } from '../../../components';
import { ChannelTypeInternal, RecoveryDeviceStatus, UserPermissionType } from '../../../data-access/gql-types/graphql';
import { useDevicesAndChannelsContext } from '../../../hooks/devices-and-channels/provider';
import { usePermissionsContext } from '../../../hooks/user-permissions';
import ConfigurationName from '../../channel-configuration/components/configuration-name';
import { useConfiguration } from '../../channel-configuration/hooks/use-configuration';
// import DeviceResetToDefaults from '../device-reset-defaults';
import DeviceRestart from '../device-restart';
import { useConfigurationDetails } from '../hooks/use-confguration-details';
import RemoveDevice from '../remove-device';
import Detail from './components/detail';
import Rssi from './components/rssi';
import UpdateNetwork from './components/update-network';
import { RecoveryDeviceProgress } from './components/update-progress';
import './index.scss';

type ConfigurationDetailsProps = {
  rssiSupported: boolean;
};

const ConfigurationDetails: React.FC<ConfigurationDetailsProps> = ({ rssiSupported }) => {
  const history = useHistory();
  const { t } = useTranslation('device-info');
  const { t: tc } = useTranslation('common');
  const { t: ta } = useTranslation('action');
  const [updateNetwork, setUpdateNetwork] = useState<boolean>(false);
  const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
  const [nameDisabled, setNameDisabled] = useState<boolean>(true);
  const { deviceId } = useParams<{ channelType: ChannelTypeInternal; deviceId: string }>();
  const { device } = useDevicesAndChannelsContext();
  const { updateDevice, setDeviceName, updateDeviceLoading, updateRecoveryDevice } = useConfiguration({ device });
  const { permissions } = usePermissionsContext();
  const {
    isDeviceInRecovery,
    registeredAt,
    status,
    buttonContent,
    progress,
    isDeviceConnected,
    isDeviceDisconnected,
    lastConnectedAt,
    rssi,
    rssiLoading,
    refetchRssi,
  } = useConfigurationDetails();
  const formMethods = useForm({
    defaultValues: {
      name: '',
    },
  });

  useEffect(() => {
    if (device) formMethods.setValue('name', device.payload.name || '');
  }, [device?.payload.name]);

  const onSubmit = formMethods.handleSubmit((values) => {
    setNameDisabled(true);
    setDeviceName(values.name);
    setShowSaveDialog(false);
  });

  const onCancel = () => {
    setShowSaveDialog(false);
    history.goBack();
  };

  const onSave = () => onSubmit();

  const openNetworkSettings = () => setUpdateNetwork(true);

  if (!device) return null;

  return (
    <>
      {!updateNetwork ? (
        <div className="configuration_details">
          <FormProvider {...formMethods}>
            <form onSubmit={onSubmit}>
              <Header subheader title={t('device_identification')} />
              <ConfigurationName
                label={t('device_name')}
                placeholder={t('device_name')}
                edited
                disabled={nameDisabled}
                {...(permissions[UserPermissionType.DeviceList] ? { setDisabled: setNameDisabled } : {})}
              />
              <Detail label="type" value={tc(`types.${device?.type}`) || tc('types.UNKNOWN')} />
              <hr />
              <div className="edit_section max-width-desktop">
                <Header subheader title={t('network_settings')} />
                {isDeviceConnected && permissions[UserPermissionType.DeviceList] && (
                  <button onClick={openNetworkSettings} type="button">
                    <IconEdit />
                  </button>
                )}
              </div>
              <div className="grid-list-24">
                <div className="ssid-container">
                  <Detail label="SSID" value={device.payload.connectedToSsid} />
                  <Rssi rssiLoading={rssiLoading} rssi={rssi} rssiSupported={rssiSupported} refetchRssi={refetchRssi} />
                </div>
                <Detail label={t('mac-address')} value={device.payload.macAddress || '-'} copyToClipboardAvailable />
                <Detail label={t('lastConnected')} value={lastConnectedAt} />
                <Detail label={t('registeredAt')} value={registeredAt} />
              </div>
              <hr />
              <Header subheader title={t('updateSoftware.version')} />
              <p>
                {device.payload.isUpdateAvailable
                  ? t('updateSoftware.versionAvailable', {
                      current: device?.payload.currentFirmwareVersion,
                      available: device?.payload.newestFirmwareVersion,
                    })
                  : device?.payload.currentFirmwareVersion || '-'}
                {isDeviceDisconnected ? <span> ({t('updateSoftware.lastKnownVersion')})</span> : null}
              </p>
              {isDeviceInRecovery ? (
                <>
                  <hr />
                  <div className="recovery-section">
                    <Header subheader title={t('recoveryMode.header')} />
                    <p>{t('recoveryMode.content')}</p>
                    <div className="recovery-section__box">
                      <RecoveryDeviceProgress progress={progress} status={status} />
                      <button
                        className="button update m-t-32"
                        onClick={updateRecoveryDevice}
                        type="button"
                        disabled={status !== RecoveryDeviceStatus.FirmwareDownloaded}
                      >
                        <IconAutomatic className="update-icon" />
                        {buttonContent}
                      </button>
                    </div>
                    <div className="recovery-section__progress"></div>
                  </div>
                </>
              ) : null}
              <div className="buttons_container grid-list-24">
                {!isDeviceInRecovery && permissions[UserPermissionType.DeviceList] ? (
                  <button
                    className="button update m-b-24"
                    disabled={!device.payload.isUpdateAvailable || updateDeviceLoading || !isDeviceConnected}
                    onClick={updateDevice}
                    type="button"
                  >
                    <IconAutomatic className="update-icon" />
                    {!updateDeviceLoading
                      ? isDeviceDisconnected
                        ? t('updateSoftware.noData')
                        : device.payload.isUpdateAvailable
                        ? t('updateSoftware.update')
                        : t('updateSoftware.updated')
                      : tc('buttons.loading')}
                  </button>
                ) : null}
                {permissions[UserPermissionType.DeviceRemove] && !isDeviceInRecovery && (
                  <RemoveDevice deviceId={deviceId} />
                )}
                {permissions[UserPermissionType.DeviceList] && !isDeviceInRecovery && (
                  <DeviceRestart deviceId={deviceId} />
                )}
                {/* <DeviceResetToDefaults deviceId={deviceId} /> */}
              </div>
            </form>
          </FormProvider>
          <DialogConfirmation
            show={showSaveDialog}
            setShow={setShowSaveDialog}
            header={ta('confirmDialog.header')}
            primaryBtnText={tc('buttons.save')}
            secondaryBtnText={tc('buttons.cancel')}
            primaryBtnAction={onSave}
            secondaryBtnAction={onCancel}
          />
        </div>
      ) : (
        <UpdateNetwork setUpdateNetwork={setUpdateNetwork} />
      )}
    </>
  );
};

export default ConfigurationDetails;
