import React from 'react';
import '../index.scss';

type IconDuplicateProps = {
  clearCard?: boolean;
};

export const IconDuplicate: React.FC<IconDuplicateProps> = ({ clearCard }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="popup-icon popup-icon--duplicate"
  >
    <g filter="url(#filter0_b_24581_6603)">
      <rect x="3" y="2" width="12" height="12" rx="2.8125" fill="white" />
      <rect x="2.5" y="1.5" width="13" height="13" rx="3.3125" stroke="#15141D" />
    </g>
    <g filter="url(#filter1_b_24581_6603)">
      <g filter="url(#filter2_b_24581_6603)">
        <rect x="6" y="5" width="12" height="12" rx="2.8125" fill="white" />
        <rect x="5.5" y="4.5" width="13" height="13" rx="3.3125" stroke="#15141D" />
      </g>
      {!clearCard && (
        <>
          <path d="M15 11.0001H9" stroke="#15141D" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M11.9999 14V8" stroke="#15141D" strokeLinecap="round" strokeLinejoin="round" />
        </>
      )}
    </g>
    <defs>
      <filter
        id="filter0_b_24581_6603"
        x="-4.09375"
        y="-5.09375"
        width="26.1875"
        height="26.1875"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.04688" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_24581_6603" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_24581_6603" result="shape" />
      </filter>
      <filter
        id="filter1_b_24581_6603"
        x="-1.09375"
        y="-2.09375"
        width="26.1875"
        height="26.1875"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.04688" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_24581_6603" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_24581_6603" result="shape" />
      </filter>
      <filter
        id="filter2_b_24581_6603"
        x="-1.09375"
        y="-2.09375"
        width="26.1875"
        height="26.1875"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.04688" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_24581_6603" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_24581_6603" result="shape" />
      </filter>
    </defs>
  </svg>
);
