import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { CustomBackdrop, Header, NavHeader, Page, SubmitButton } from '../../../../../../../components';
import { ROUTES } from '../../../../../../../routes';
import { ChannelBoxIcon } from '../../../../../components/channel/box-icon';
import { useCalibration } from '../../common/hooks/use-calibration';
import './index.scss';

type ZfBidiCalibrationStep0Props = {
  setStep: Dispatch<SetStateAction<number>>;
};

const ZfBidiCalibrationStep0: React.FC<ZfBidiCalibrationStep0Props> = ({ setStep }) => {
  const { channel, history, t } = useCalibration();

  const handleClick = useCallback(() => {
    if (channel) {
      setStep(1);
      history.push(`${ROUTES.FacadeCalibration(channel.ChannelId)}?step=1`);
    }
  }, [channel]);

  return (
    <Page
      className="calibration-flow"
      isStickyHeader
      whiteContent
      header={
        <>
          <NavHeader />
          <Header title={t('exalus.calibration')} />
        </>
      }
    >
      {channel && <ChannelBoxIcon channel={channel} className="facade-icon" />}
      <p className="channel-name">{channel?.Name}</p>

      <p className="info m-t-48">{t('exalus.params.ZfBidiParams.calibrationFlow.welcome')}</p>
      <p className="info--sub">{t('exalus.params.ZfBidiParams.calibrationFlow.nextInfo')}</p>
      <SubmitButton type="button" onClick={handleClick} tranparent>
        {t('exalus.params.ZfBidiParams.calibrationFlow.next')}
      </SubmitButton>
      {!channel && <CustomBackdrop loading />}
    </Page>
  );
};

export default ZfBidiCalibrationStep0;
