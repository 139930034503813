import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ChannelType } from '../../../../../types';
import { useOptimizerConfigurationContext } from '../../context/configuration-context';
import { OptimizerConfigurationRelays } from '../components/relays';
import { WorkingHours } from '../components/working-hours';
import { useOptimizerRelaysConsumption } from '../hooks/use-optimizer-relays-consumption';
import { useOptimizerSetData } from '../hooks/use-optimizer-set-data';
import { RelaysConsumptionFormBody, initialRelay } from '../types';

type OptimizerConfigurationWorkModeAutoConsumptionRelaysProps = {
  channel: ChannelType;
};

export const OptimizerConfigurationWorkModeAutoConsumptionRelays: React.FC<
  OptimizerConfigurationWorkModeAutoConsumptionRelaysProps
> = ({ channel }) => {
  const { t: tc } = useTranslation('common');
  const { submitConfiguration } = useOptimizerRelaysConsumption(channel);
  const { selectedWorkMode } = useOptimizerConfigurationContext();
  const form = useForm<RelaysConsumptionFormBody>({
    defaultValues: {
      relayConfig1: { ...initialRelay, index: 1 },
      relayConfig2: { ...initialRelay, index: 2 },
      relayConfig3: { ...initialRelay, index: 3 },
      relayConfig4: { ...initialRelay, index: 4 },
      from: '00:00',
      to: '00:00',
    },
  });
  const { setOptimizerData } = useOptimizerSetData(form.setValue, 'selfConsumptionWithRelays');

  useEffect(() => {
    if (channel) setOptimizerData(channel);
  }, [channel]);

  const onSubmit = form.handleSubmit((values) => {
    submitConfiguration(selectedWorkMode, values);
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <div className="optimizer-configuration-form__item">
          <WorkingHours />
        </div>

        <hr className="m-t-24 m-b-24" />

        <div className="optimizer-configuration-form__item">
          <OptimizerConfigurationRelays thresholdPercent={false} sumExists />
        </div>

        <div className="button-container">
          <button className="button m-t-48 max-width-desktop" type="submit">
            {tc('buttons.save')}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};
