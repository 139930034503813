import React from 'react';

type IconGatewayProps = {
  position?: number; // In percents from 0 to 100
};

export const IconGateway: React.FC<IconGatewayProps> = ({ position = 100 }) => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-cover icon-gateway"
  >
    {position < 100 ? (
      <>
        <path
          d="M8.00024 6.00001L29.0002 3.5L29.0002 34.5L8.00024 31.5769L8.00024 6.00001Z"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.3916 6L14.3916 32"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M21.6958 5V33" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M5 6.33301L5 31.6663"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M34 6.33301V31.6663" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M26.3141 18.5H24" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M7.8976 10.5005H5.5835"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M7.8976 27H5.5835" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </>
    ) : (
      <>
        <path
          d="M7.89771 6.08984L22.9394 6.08983L22.9394 31.6668L7.89771 31.6668L7.89771 6.08984Z"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0835 6.3335L15.0835 31.0578"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31 6.08984L23.0001 6.08983L23.0001 31.6668L31 31.6668L31 6.08984Z"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 6.33301L5 31.6663"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M34 6.33301V31.6663" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M28.3141 18.5H26" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M7.8976 10.5005H5.5835"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M7.8976 27H5.5835" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </>
    )}
  </svg>
);
