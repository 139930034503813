import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IconDeviceListProps = {
  colorAccent?: boolean;
};

export const IconDeviceList: React.FC<IconDeviceListProps> = ({ colorAccent }) => (
  <svg
    className={classNames('icon-device-list', { accent: colorAccent })}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.8"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      d="M20.0001 6.16895H4.001C2.89649 6.16895 2.00111 7.16381 2.00111 8.39104V20.6126C2.00111 21.8398 2.89649 22.8347 4.001 22.8347H20.0001C21.1046 22.8347 22 21.8398 22 20.6126V8.39104C22 7.16381 21.1046 6.16895 20.0001 6.16895Z"
      stroke="#15141D"
      vectorEffect="non-scaling-stroke"
    />
    <path d="M16.8609 1.30811L12.0022 6.16949L7.13918 1.30811" stroke="#15141D" vectorEffect="non-scaling-stroke" />
  </svg>
);
