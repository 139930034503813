import { Dispatch, SetStateAction } from 'react';
import {
  ChannelTypeInternal,
  OptimizerConfigValidation,
  UserChannel,
  UserDevice,
} from '../data-access/gql-types/graphql';
import { ChannelType, ChannelStateType } from './channel';
import { DeviceType } from './devices';

export type DevicesAndChannelsState = {
  deviceList: DeviceType[];
  setDeviceList: Dispatch<SetStateAction<DeviceType[]>>;
  channelList: ChannelType[];
  setChannelList: Dispatch<SetStateAction<ChannelType[]>>;
  channel: ChannelType | undefined;
  setChannelDetails: (userChannel: UserChannel) => void;
  optimizerConfigValidation: OptimizerConfigValidation | undefined;
  setOptimizerConfigValidation: Dispatch<SetStateAction<OptimizerConfigValidation | undefined>>;
  updateChannelDetails: Dispatch<SetStateAction<ChannelType | undefined>>;
  clearChannelDetails: () => void;
  device: DeviceType | undefined;
  setDeviceDetails: (userDevice: UserDevice) => void;
  updateDeviceDetails: Dispatch<SetStateAction<DeviceType | undefined>>;
  clearDeviceDetails: () => void;
};

export type ChannelsState = {
  channelState: Record<string, ChannelStateType>;
  setChannelState: Dispatch<SetStateAction<Record<string, ChannelStateType>>>;
};

export enum Visibility {
  All = 'ALL',
  Visible = 'VISIBLE',
  InVisible = 'INVISIBLE',
}

export type ChannelGroupItem = {
  label: string;
  type: ChannelTypeInternal;
  channels: ChannelType[];
};

export type UseDevicesAndChannels = DevicesAndChannelsState & {
  channel: ChannelType | undefined;
  device: DeviceType | undefined;
  deviceChannels: ChannelType[];
  deviceChannelsById: (id: string) => ChannelType[];
  channelGroups: ChannelGroupItem[];
  channelTypesList: string[];
  editDeviceName: (editDeviceId: string, editDeviceName: string) => void;
};

export enum RGBType {
  RGB = 'RGB',
  RGBW = 'RGBW',
  WHITE = 'White',
  WW = 'WW',
}
