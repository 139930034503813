import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useMutation } from '@apollo/client';
import { RunTriggerMutation, RunTriggerMutationVariables, Trigger } from '../../../data-access/gql-types/graphql';
import { RUN_TRIGGER } from '../../../data-access/mutations/trigger';
import { useInstallation } from '../../../hooks';
import { useDevicesAndChannelsContext } from '../../../hooks/devices-and-channels/provider';
import { ROUTES } from '../../../routes';
import { ChannelType } from '../../../types';
import { IconWrapper } from '../../icon-wrapper';
import { IconAutomatic, IconPlay, IconErrorSmall } from '../../icons';
import { EventList } from '../event-list';

type TriggerBoxProps = {
  triggerId: string;
  triggerData: Trigger;
};

export const TriggerBox: React.FC<TriggerBoxProps> = ({ triggerId, triggerData }) => {
  const history = useHistory();
  const { channelList } = useDevicesAndChannelsContext();
  const { selectedInstallationId } = useInstallation();

  if (!triggerData) return null;

  const actionChannels = useMemo(() => {
    const actionChannelApiIdList = triggerData?.actions.map((element) => element.id);
    return channelList.filter(
      (channel) => channel && actionChannelApiIdList?.includes(channel.id || ''),
    ) as ChannelType[];
  }, [channelList]);

  const [runTrigger] = useMutation<RunTriggerMutation, RunTriggerMutationVariables>(RUN_TRIGGER, {
    variables: {
      input: {
        triggerId,
        installationId: selectedInstallationId,
        ignoreStateConditions: true,
      },
    },
  });

  return (
    <div
      className={classNames('action-box action-box--advanced action-box--list-item', {
        'action-box--disabled': !triggerData.isActive,
      })}
    >
      <div className="action-box__content">
        <div className="action-box__content-control m-b-0 p-b-8 p-t-8">
          <div
            onClick={() => history.push(ROUTES.TriggerDetails(triggerId))}
            className={classNames('action-box__head action-box__head-wide')}
          >
            <div className="action-box__wrapper">
              <IconWrapper className="icon-wrapper--size-32 icon-wrapper--relative">
                <IconAutomatic size={20} className="icon-automatic--accent" />
                {!triggerData.isValid && <IconErrorSmall />}
              </IconWrapper>
            </div>
            <h4 className="action-box__head-action-name text-ellipsis">{triggerData?.name}</h4>
          </div>
          <div className="action-control">
            <button
              disabled={!triggerData.isValid}
              onClick={() => runTrigger()}
              className={classNames('action-control__advanced', {
                'action-control__advanced--disabled': !triggerData.isValid,
              })}
            >
              <IconPlay size={28} />
            </button>
          </div>
        </div>
        {actionChannels.length ? <EventList trigger={triggerData} channels={actionChannels} /> : null}
      </div>
    </div>
  );
};
