import React from 'react';
import './index.scss';

type IconSignalProps = {
  signal: number;
};

export const IconSignal: React.FC<IconSignalProps> = ({ signal }) => (
  <div className="icon-signal">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_24543_259169)">
        <path
          d="M3 6.90051C4.75171 5.11164 7.17406 4 9.84754 4C12.521 4 14.9434 5.11093 16.6951 6.90051"
          className={signal > 2 ? 'active' : 'inactive'}
          stroke="#CBCBD1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.9502 8.9023C6.20311 7.61975 7.93534 6.82715 9.84637 6.82715C11.7574 6.82715 13.4854 7.61975 14.7391 8.9023"
          className={signal > 1 ? 'active' : 'inactive'}
          stroke="#CBCBD1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.90625 10.8996C7.66036 10.1319 8.69831 9.65332 9.84757 9.65332C10.9968 9.65332 12.0341 10.1312 12.7889 10.8996"
          className={signal > 0 ? 'active' : 'inactive'}
          stroke="#CBCBD1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.4502 13.9805C11.4502 14.8074 10.7771 15.4805 9.9502 15.4805C9.12325 15.4805 8.4502 14.8074 8.4502 13.9805C8.4502 13.5689 8.61753 13.1924 8.89168 12.922C9.16135 12.6478 9.53859 12.4805 9.9502 12.4805C10.3618 12.4805 10.7383 12.6478 11.0087 12.922C11.2829 13.1916 11.4502 13.5689 11.4502 13.9805Z"
          className="active dot"
          stroke="#CBCBD1"
          fill="#CBCBD1"
          strokeWidth="0.259487"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_24543_259169">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
);
