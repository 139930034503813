import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  BatteryState,
  BatteryStateEnum,
  BlindRemoteButtonState,
  BlindRemoteButtonStateEnum,
  RemoteButtonState,
  RemoteButtonStateEnum,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { IconArrowLink, IconChevron, IconPause } from '../../../../../components';
import { Dialog } from '../../../../../components/dialog/base';
import { IconSignalState } from '../../../../../components/icons';
import { IconToastWarning2 } from '../../../../../components/icons/icon-toast-warning-2';
import { DeviceChannelsGrouped } from '../../../pages/channel-list/types';
import { getChannelType } from '../../../utils';
import { ChannelBox } from '../box';
import './index.scss';

type DeviceChannelsBoxProps = {
  deviceChannelsGrouped: DeviceChannelsGrouped;
  isListItem?: boolean;
  handleExalusChannelItemClick?: (channel: IDeviceChannel) => void;
  isDraggable?: boolean;
  allCollapsedOpen?: boolean;
  isChannelNumber?: boolean;
  hideControls?: boolean;
};

export const DeviceChannelsBox: React.FC<DeviceChannelsBoxProps> = ({
  deviceChannelsGrouped,
  isListItem,
  handleExalusChannelItemClick,
  isDraggable,
  allCollapsedOpen = false,
  isChannelNumber = true,
  hideControls,
}) => {
  const { t: tc } = useTranslation('common');
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => setExpanded(allCollapsedOpen), [allCollapsedOpen]);

  const remoteButtonStates = useMemo(() => {
    const allStates = deviceChannelsGrouped.channels.map((ch) => (ch as IDeviceChannel).States).flat();

    return allStates.filter(
      (state) =>
        state.TypeAsEnum === ChannelResponseType.BlindRemoteButtonState ||
        state.TypeAsEnum === ChannelResponseType.FacadeRemoteButtonState ||
        state.TypeAsEnum === ChannelResponseType.RemoteButtonState,
    );
  }, [deviceChannelsGrouped.channels.flat()]);

  const isBatteryError = useMemo(() => {
    const batteryStates = deviceChannelsGrouped.channels
      .map((ch) => (ch as IDeviceChannel).States)
      .flat()
      .filter((state) => state.TypeAsEnum === ChannelResponseType.BatteryState);

    return batteryStates.find((x) =>
      [BatteryStateEnum.Low, BatteryStateEnum.Empty].includes((x.Data as BatteryState).State),
    );
  }, [deviceChannelsGrouped.channels.flat()]);

  const renderGroupIcon = () => {
    const opened = remoteButtonStates.find(
      (state) =>
        (state.TypeAsEnum === ChannelResponseType.BlindRemoteButtonState &&
          (state.Data as BlindRemoteButtonState).State === BlindRemoteButtonStateEnum.OpenPressed) ||
        (state.TypeAsEnum === ChannelResponseType.FacadeRemoteButtonState &&
          (state.Data as BlindRemoteButtonState).State === BlindRemoteButtonStateEnum.OpenPressed),
    );

    if (opened) {
      return <IconChevron withCircle direction="up" size={80} channelNumber={opened.Data.Channel} />;
    }

    const closed = remoteButtonStates.find(
      (state) =>
        (state.TypeAsEnum === ChannelResponseType.BlindRemoteButtonState &&
          (state.Data as BlindRemoteButtonState).State === BlindRemoteButtonStateEnum.ClosePressed) ||
        (state.TypeAsEnum === ChannelResponseType.FacadeRemoteButtonState &&
          (state.Data as BlindRemoteButtonState).State === BlindRemoteButtonStateEnum.ClosePressed),
    );
    if (closed) {
      return <IconChevron withCircle direction="down" size={80} channelNumber={closed.Data.Channel} />;
    }

    const stopped = remoteButtonStates.find(
      (state) =>
        (state.TypeAsEnum === ChannelResponseType.BlindRemoteButtonState &&
          (state.Data as BlindRemoteButtonState).State === BlindRemoteButtonStateEnum.StopPressed) ||
        (state.TypeAsEnum === ChannelResponseType.FacadeRemoteButtonState &&
          (state.Data as BlindRemoteButtonState).State === BlindRemoteButtonStateEnum.StopPressed),
    );

    if (stopped) {
      return <IconPause size={80} channelNumber={stopped.Data.Channel} />;
    }

    const pressed = remoteButtonStates.find(
      (state) =>
        state.TypeAsEnum === ChannelResponseType.RemoteButtonState &&
        (state.Data as RemoteButtonState).State === RemoteButtonStateEnum.Pressed,
    );

    if (pressed) {
      return <IconSignalState signal={100} channelNumber={pressed.Data.Channel} />;
    }

    return <IconSignalState signal={20} />;
  };

  const handleExpand = useCallback(() => setExpanded(!expanded), [expanded]);

  const title = useMemo(() => {
    if (deviceChannelsGrouped.channels.length > 0) {
      return `${tc(`typesPlural.exalus.${getChannelType(deviceChannelsGrouped.channels[0] as IDeviceChannel)}`)} ${
        deviceChannelsGrouped.deviceName
      }`;
    }
  }, [deviceChannelsGrouped]);

  return (
    <div className="device-box--exalus--remote" onClick={handleExpand}>
      <div
        className={classNames('device-box device-box--exalus', {
          'device-box--list-item': isListItem,
        })}
      >
        <div className="device-box__head">
          <div className="device-box__head-icon">
            {renderGroupIcon()}
            {isBatteryError && <IconToastWarning2 colorError size={16} />}
          </div>
          <div className="device-box__head-wrapper">
            <h4 className="device-box__name text-ellipsis">{deviceChannelsGrouped.deviceName}</h4>
          </div>
        </div>
        {!isDraggable && (
          <div className="device-box__controls">
            <div className="arrow">
              {isListItem ? <IconChevron direction={!expanded ? 'down' : 'up'} /> : <IconArrowLink />}
            </div>
          </div>
        )}
      </div>

      {isListItem ? (
        <ul className={classNames('remote-channels', { expanded: expanded })}>
          {(deviceChannelsGrouped.channels as IDeviceChannel[]).map((channel: IDeviceChannel) => (
            <li key={channel.ChannelId} className="items-list__item">
              <ChannelBox
                channel={channel}
                isListItem
                isChannelNumber={isChannelNumber}
                handleExalusChannelItemClick={handleExalusChannelItemClick}
                hideControls={hideControls}
              />
            </li>
          ))}
        </ul>
      ) : (
        <Dialog className="exalus-dialog" show={expanded} setShow={setExpanded} title={title}>
          <ul className={classNames('remote-channels', { expanded: expanded })}>
            {(deviceChannelsGrouped.channels as IDeviceChannel[]).map((channel: IDeviceChannel) => (
              <li key={channel.ChannelId} className="items-list__item">
                <ChannelBox
                  channel={channel}
                  isListItem
                  isChannelNumber={isChannelNumber}
                  hideControls={hideControls}
                />
              </li>
            ))}
          </ul>
          <button className="close-button" onClick={() => setExpanded(false)}>
            {tc('buttons.close')}
          </button>
        </Dialog>
      )}
    </div>
  );
};
