import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogConfirmation } from '../../../../components';
import { environment } from '../../../../environment';
import { useAboutSettings } from '../../hooks/use-about-settings';

export const AboutSettings: React.FC = () => {
  const { t } = useTranslation('profile');
  const { t: tc } = useTranslation('common');
  const { handleClearAppData, showAppDataClearDialogConfirmation, setShowAppDataClearDialogConfirmation } =
    useAboutSettings();

  return (
    <>
      <span className="caption">{t('application-data')}</span>
      <div className="grid-list-24">
        <button className="button button--secondary" onClick={() => setShowAppDataClearDialogConfirmation(true)}>
          {t('clear-app-data.button')}
        </button>
      </div>
      <h3 className="m-t-24 m-b-16">{t('application-version')}</h3>
      <button className="p-l-0 p-r-0 m-b-0">{environment.VERSION}</button>
      <DialogConfirmation
        show={showAppDataClearDialogConfirmation}
        setShow={setShowAppDataClearDialogConfirmation}
        header={t('clear-app-data.header')}
        content={t('clear-app-data.content')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnText={t('clear-app-data.confirm')}
        secondaryBtnAction={() => setShowAppDataClearDialogConfirmation(false)}
        primaryBtnAction={handleClearAppData}
        direction="column"
      />
    </>
  );
};
