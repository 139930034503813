import React, { useMemo } from 'react';
import {
  ChannelTypeInternal,
  GateFeatureTypeInternal,
  GateModeInternal,
} from '../../../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../../../hooks';
import { useGate } from '../../../../../hooks/use-gate';
import { useGateSupportedFeatures } from '../../../../../modules/channel-details/gate/hooks/use-gate-supported';
import { ChannelGateType, ChannelGateStateType, ChannelType } from '../../../../../types';
import { IconCirclePadlockClose, IconCirclePadlockOpen, IconCirclePadlockOpenFull } from '../../../../icons';
import { InitiateButton } from '../../../../initiate-button';
import { RollerControls } from '../../../../roller-controls';

type GateChannelControlBoxProps = {
  channel: ChannelType;
  isListItem?: boolean;
};

const stepByStepIcon = {
  1: <IconCirclePadlockClose />,
  2: <IconCirclePadlockOpen />,
  3: <IconCirclePadlockOpenFull />,
};

export const GateChannelControlBox: React.FC<GateChannelControlBoxProps> = ({ isListItem, channel }) => {
  const { handleAction, handleStepByStep } = useGate(channel);
  const { channelState } = useChannelsState();
  const { lockReportingModeSupported } = useGateSupportedFeatures(channel);
  const state = channelState[channel.id] as ChannelGateStateType | undefined;

  const handleGateAction = (type: string) => handleAction(type);

  const gateMode = useMemo(() => {
    const supported = (channel.data as ChannelGateType).supportedGateFeatures;

    if (supported.includes(GateFeatureTypeInternal.StepByStep) && supported.includes(GateFeatureTypeInternal.SetDir)) {
      return GateModeInternal.RollUp;
    }

    return (channel.data as ChannelGateType).gateMode;
  }, [(channel.data as ChannelGateType).gateMode, (channel.data as ChannelGateType).supportedGateFeatures]);

  const isStepByStep = useMemo(() => {
    return (channel.data as ChannelGateType).gateMode === GateModeInternal.StepByStep;
  }, [(channel.data as ChannelGateType).gateMode]);

  const isQuickControl = useMemo(() => {
    return (channel.data as ChannelGateType).isQuickControl;
  }, [(channel.data as ChannelGateType).isQuickControl]);

  const gateStep = useMemo(() => {
    if (lockReportingModeSupported) return state?.lockStatus ? 1 : 2;
    else {
      if (!state?.position) return 3;
      return state?.position === 100 ? 1 : 2;
    }
  }, [state?.position, state?.lockStatus, lockReportingModeSupported]);

  if (gateMode === GateModeInternal.Unknown) return null;

  if (gateMode === GateModeInternal.RollUp) {
    return <RollerControls handleAction={handleGateAction} isListItem={isListItem} type={ChannelTypeInternal.Gate} />;
  }

  if (isStepByStep && isQuickControl) {
    return (
      <InitiateButton isOn={false} onClickControl={handleStepByStep} isSmall isChannelItem checkStatus>
        {stepByStepIcon[gateStep]}
      </InitiateButton>
    );
  }

  return null;
};
