import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import omitDeep from 'omit-deep';
import * as uuid from 'uuid';
import { useMutation } from '@apollo/client';
import { SelectOptionType } from '../../../components';
import {
  CreatePriceAndTimeTriggerInput,
  CreatePriceAndTimeTriggerMutation,
  CreatePriceAndTimeTriggerMutationVariables,
  CreateStateTriggerMutation,
  CreateStateTriggerMutationVariables,
  CreateTimeTriggerInput,
  CreateTimeTriggerMutation,
  CreateTimeTriggerMutationVariables,
  EditPriceAndTimeTriggerMutation,
  EditPriceAndTimeTriggerMutationVariables,
  EditStateTriggerMutation,
  EditStateTriggerMutationVariables,
  EditTimeTriggerMutation,
  EditTimeTriggerMutationVariables,
  TriggerOnFailureMode,
  TriggerPriceAndTimeConditionRequestInput,
  TriggerTimeConditionRequestInput,
  UpdateTriggerNameMutation,
  UpdateTriggerNameMutationVariables,
} from '../../../data-access/gql-types/graphql';
import {
  CREATE_PRICE_TRIGGER,
  CREATE_STATE_TRIGGER,
  CREATE_TIME_TRIGGER,
  EDIT_PRICE_TRIGGER,
  EDIT_STATE_TRIGGER,
  EDIT_TIME_TRIGGER,
  UPDATE_TRIGGER_NAME,
} from '../../../data-access/mutations/trigger';
import { useInstallation } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { toastError } from '../../../utils/toast';
import { useTriggerFormContext } from '../context';
import { RedirectToDetailsType } from '../enums';
import { UseTriggerHookType } from '../types';

export const useTrigger = (): UseTriggerHookType => {
  const history = useHistory();
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { triggerId } = useParams<{ triggerId: string }>();
  const { selectedInstallationId } = useInstallation();
  const { name, actions, stateConditions, timeCondition, initialName, onFailureMode, priceCondition } =
    useTriggerFormContext();

  const redirectToDetails = useCallback(
    (id, type: RedirectToDetailsType) => {
      history.push({
        pathname: ROUTES.TriggerDetails(id),
        state: { back: type },
      });
    },
    [triggerId],
  );

  const [createTimeTrigger, { loading: createTimeTriggerLoading }] = useMutation<
    CreateTimeTriggerMutation,
    CreateTimeTriggerMutationVariables
  >(CREATE_TIME_TRIGGER, {
    onCompleted: (response) => {
      if (!response || !response.createTimeTrigger) {
        toastError({ content: tc('errors.somethingWentWrong') });
        return;
      }

      if (response.createTimeTrigger.errors === null) {
        redirectToDetails(response.createTimeTrigger?.idResponse?.id, RedirectToDetailsType.CREATE);
      }
    },
  });

  const [createStateTrigger, { loading: createStateTriggerLoading }] = useMutation<
    CreateStateTriggerMutation,
    CreateStateTriggerMutationVariables
  >(CREATE_STATE_TRIGGER, {
    onCompleted: (response) => {
      if (!response || !response.createStateTrigger) {
        toastError({ content: tc('errors.somethingWentWrong') });
        return;
      }

      if (response.createStateTrigger.idResponse?.id) {
        redirectToDetails(response.createStateTrigger?.idResponse?.id, RedirectToDetailsType.CREATE);
      }
    },
  });

  const [createPriceTrigger, { loading: createPriceTriggerLoading }] = useMutation<
    CreatePriceAndTimeTriggerMutation,
    CreatePriceAndTimeTriggerMutationVariables
  >(CREATE_PRICE_TRIGGER, {
    onCompleted: (response) => {
      if (response.createPriceAndTimeTrigger.errors === null) {
        redirectToDetails(response.createPriceAndTimeTrigger?.idResponse?.id, RedirectToDetailsType.CREATE);
      }
    },
  });

  const [editTimeTrigger, { loading: editTimeTriggerLoading }] = useMutation<
    EditTimeTriggerMutation,
    EditTimeTriggerMutationVariables
  >(EDIT_TIME_TRIGGER);
  const [editStateTrigger, { loading: editStateTriggerLoading }] = useMutation<
    EditStateTriggerMutation,
    EditStateTriggerMutationVariables
  >(EDIT_STATE_TRIGGER);
  const [editPriceTrigger, { loading: editPriceTriggerLoading }] = useMutation<
    EditPriceAndTimeTriggerMutation,
    EditPriceAndTimeTriggerMutationVariables
  >(EDIT_PRICE_TRIGGER);
  const [updateTriggerName, { loading: updateTriggerNameLoading }] = useMutation<
    UpdateTriggerNameMutation,
    UpdateTriggerNameMutationVariables
  >(UPDATE_TRIGGER_NAME);

  const isLoading = useMemo(() => {
    return (
      createTimeTriggerLoading ||
      createStateTriggerLoading ||
      createPriceTriggerLoading ||
      editTimeTriggerLoading ||
      editStateTriggerLoading ||
      editPriceTriggerLoading ||
      updateTriggerNameLoading
    );
  }, [
    createTimeTriggerLoading,
    createStateTriggerLoading,
    createPriceTriggerLoading,
    editTimeTriggerLoading,
    editStateTriggerLoading,
    editPriceTriggerLoading,
    updateTriggerNameLoading,
  ]);

  const handleSaveTrigger = (formName?: string) => {
    const triggerName = formName ?? name;

    if (Object.keys(priceCondition).length) {
      const variables: CreatePriceAndTimeTriggerInput = {
        name: triggerName,
        installationId: selectedInstallationId,
        trigger: {
          triggerId: triggerId || uuid.v4(),
          actions: actions.map((x) => ({ ...x })),
          priceAndTimeCondition: {
            ...(priceCondition as TriggerPriceAndTimeConditionRequestInput),
            daysOfWeek: ((priceCondition as TriggerPriceAndTimeConditionRequestInput).daysOfWeek || []).map((x) => x),
          },
          stateConditions: stateConditions.map((x) => ({ ...x })),
          onFailureMode,
        },
      };

      if (!triggerId) {
        createPriceTrigger({ variables: { input: omitDeep(cloneDeep(variables), ['__typename']) } });
      } else {
        editPriceTrigger({
          variables: {
            input: {
              trigger: omitDeep(cloneDeep(variables.trigger), [
                '__typename',
                'nextFireTime',
                'currentExecutionCount',
                'targetExecutionCount',
              ]),
              installationId: variables.installationId,
            },
          },
          onCompleted: async (data) => {
            if (!data || !data.editPriceAndTimeTrigger) {
              toastError({ content: tc('errors.somethingWentWrong') });
              return;
            }

            if (data.editPriceAndTimeTrigger.idResponse?.id) {
              if (triggerName !== initialName) {
                await updateTriggerName({
                  variables: {
                    input: {
                      triggerId,
                      installationId: variables.installationId,
                      name: triggerName,
                    },
                  },
                  onCompleted: (response) => {
                    if (response.updateTriggerName) {
                      redirectToDetails(data.editPriceAndTimeTrigger?.idResponse?.id, RedirectToDetailsType.EDIT);
                    }
                  },
                });
              } else {
                await redirectToDetails(data.editPriceAndTimeTrigger?.idResponse?.id, RedirectToDetailsType.EDIT);
              }
            }
          },
        });
      }
    } else {
      const variables: CreateTimeTriggerInput = {
        name: triggerName,
        installationId: selectedInstallationId,
        trigger: {
          triggerId: triggerId || uuid.v4(),
          actions: actions.map((x) => ({ ...x })),
          timeCondition: {
            ...(timeCondition as TriggerTimeConditionRequestInput),
            daysOfWeek: ((timeCondition as TriggerTimeConditionRequestInput).daysOfWeek || []).map((x) => x),
          },
          stateConditions: stateConditions.map((x) => ({ ...x })),
          onFailureMode,
        },
      };

      if (triggerId) {
        if (Object.keys(timeCondition).length) {
          editTimeTrigger({
            variables: {
              input: {
                trigger: omitDeep(cloneDeep(variables.trigger), ['__typename', 'nextFireTime']),
                installationId: variables.installationId,
              },
            },
            onCompleted: async (data) => {
              if (data.editTimeTrigger?.idResponse?.id) {
                if (triggerName !== initialName) {
                  await updateTriggerName({
                    variables: {
                      input: {
                        triggerId,
                        installationId: variables.installationId,
                        name: triggerName,
                      },
                    },
                    onCompleted: (response) => {
                      if (response.updateTriggerName) {
                        redirectToDetails(data.editTimeTrigger?.idResponse?.id, RedirectToDetailsType.EDIT);
                      }
                    },
                  });
                } else {
                  await redirectToDetails(data.editTimeTrigger?.idResponse?.id, RedirectToDetailsType.EDIT);
                }
              }
            },
          });
        } else {
          editStateTrigger({
            variables: {
              input: {
                trigger: omitDeep(cloneDeep(variables.trigger), ['__typename', 'timeCondition']),
                installationId: variables.installationId,
              },
            },
            onCompleted: async (data) => {
              if (data.editStateTrigger?.idResponse?.id) {
                if (triggerName !== initialName) {
                  await updateTriggerName({
                    variables: {
                      input: {
                        triggerId,
                        installationId: variables.installationId,
                        name: triggerName,
                      },
                    },
                    onCompleted: (response) => {
                      if (response.updateTriggerName) {
                        redirectToDetails(data.editStateTrigger?.idResponse?.id, RedirectToDetailsType.EDIT);
                      }
                    },
                  });
                } else {
                  await redirectToDetails(data.editStateTrigger?.idResponse.id, RedirectToDetailsType.EDIT);
                }
              }
            },
          });
        }
      } else {
        if (Object.keys(timeCondition).length) {
          createTimeTrigger({ variables: { input: omitDeep(cloneDeep(variables), ['__typename', 'nextFireTime']) } });
        } else {
          createStateTrigger({ variables: { input: omitDeep(cloneDeep(variables), ['__typename', 'timeCondition']) } });
        }
      }
    }
  };

  const failureModes: SelectOptionType<TriggerOnFailureMode>[] = [
    { label: t('trigger.failureMode.CONTINUE'), value: TriggerOnFailureMode.Continue },
    { label: t('trigger.failureMode.STOP_EXECUTION'), value: TriggerOnFailureMode.StopExecution },
  ];

  return {
    handleSaveTrigger,
    isLoading,
    failureModes,
  };
};
