import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, NavHeader, Page } from '../../../../components';

type ActionTasksTypesProps = {
  children: ReactNode[];
};

const ActionTasksTypes: React.FC<ActionTasksTypesProps> = ({ children }) => {
  const { t } = useTranslation('action');

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t('action.create.tasks.task')} />
        </>
      }
    >
      <div className="grid-list-24">{children}</div>
    </Page>
  );
};

export default ActionTasksTypes;
