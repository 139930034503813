import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BlindRemoteButtonStateEnum,
  EnergyMeasurementParameter,
  FloodSensorStates,
  GatePositionTypeEnum,
  GatewayPositionTypeEnum,
  MovementStateEnum,
  RemoteButtonStateEnum,
  WindThresholdStateEnum,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { DeviceStateComparisonMethod, SunPosition } from 'lavva.exalushome/build/js/Services/Scenes/LeftArgumentTypes';
import { ConditionsTypes } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { SelectOptionType } from '../../../../../components';
import { BrightnessMethod, DaysOfWeekOption, TriggerMethodType } from '../types';

export const useActionParameterOptions = (astronomical?: boolean) => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('channel-details');

  const triggerMethodsOptions: SelectOptionType<TriggerMethodType>[] = useMemo(
    () => [
      { label: t('action.create.conditions.sources.schedule.ifIsIt'), value: TriggerMethodType.IfIsIt },
      { label: t('action.create.conditions.sources.schedule.whenChangeOn'), value: TriggerMethodType.WhenChangeOn },
    ],
    [],
  );

  const brightnessMethodOptions: SelectOptionType<BrightnessMethod>[] = useMemo(
    () => [
      { label: t('action.create.conditions.brightnessMethod.percent'), value: BrightnessMethod.Percent },
      { label: t('action.create.conditions.brightnessMethod.number'), value: BrightnessMethod.Number },
      {
        label: t('action.create.conditions.brightnessMethod.state'),
        value: BrightnessMethod.State,
      },
    ],
    [],
  );

  const comparisonMethodsOptions: SelectOptionType<DeviceStateComparisonMethod>[] = useMemo(
    () => [
      { label: t('action.create.conditions.comparisonMethod.default'), value: DeviceStateComparisonMethod.Default },
      { label: t('action.create.conditions.comparisonMethod.delta'), value: DeviceStateComparisonMethod.Delta },
    ],
    [],
  );

  const conditionsTypes: SelectOptionType<ConditionsTypes>[] = useMemo(
    () => [
      {
        label: t(`action.create.conditions.conditionTypes.${!astronomical ? 'biggerThan' : 'ifAfter'}`),
        value: ConditionsTypes.BiggerThan,
      },
      {
        label: t(`action.create.conditions.conditionTypes.${!astronomical ? 'biggerThanOrEqual' : 'ifAfterOrEqual'}`),
        value: ConditionsTypes.BiggerThanOrEqual,
      },
      { label: t(`action.create.conditions.conditionTypes.bothAreTrue`), value: ConditionsTypes.BothAreTrue },
      {
        label: t(`action.create.conditions.conditionTypes.${!astronomical ? 'equal' : 'ifEqual'}`),
        value: ConditionsTypes.Equal,
      },
      {
        label: t(`action.create.conditions.conditionTypes.${!astronomical ? 'notEqual' : 'ifNotEqual'}`),
        value: ConditionsTypes.NotEqueal,
      },
      { label: t(`action.create.conditions.conditionTypes.oneOfBothIsTrue`), value: ConditionsTypes.OneOfBothIsTrue },
      {
        label: t(`action.create.conditions.conditionTypes.${!astronomical ? 'smallerThan' : 'ifBefore'}`),
        value: ConditionsTypes.SmallerThan,
      },
      {
        label: t(`action.create.conditions.conditionTypes.${!astronomical ? 'smallerThanOrEqual' : 'ifBeforeOrEqual'}`),
        value: ConditionsTypes.SmallerThanOrEqual,
      },
    ],
    [],
  );

  const stateDeviceTriggerOptions: SelectOptionType<RemoteButtonStateEnum>[] = useMemo(
    () => [
      {
        label: t('action.create.conditions.sources.device.releasingButton'),
        value: RemoteButtonStateEnum.Relased,
      },
      {
        label: t('action.create.conditions.sources.device.pressingButton'),
        value: RemoteButtonStateEnum.Pressed,
      },
    ],
    [],
  );

  const triggerButtonMethodOptions: SelectOptionType<BlindRemoteButtonStateEnum>[] = useMemo(
    () => [
      {
        label: t('action.create.conditions.sources.device.releasingButtonStop'),
        value: BlindRemoteButtonStateEnum.StopReleased,
      },
      {
        label: t('action.create.conditions.sources.device.pressingButtonClose'),
        value: BlindRemoteButtonStateEnum.ClosePressed,
      },
      {
        label: t('action.create.conditions.sources.device.pressingButtonOpen'),
        value: BlindRemoteButtonStateEnum.OpenPressed,
      },
      {
        label: t('action.create.conditions.sources.device.releasingButtonOpen'),
        value: BlindRemoteButtonStateEnum.OpenReleased,
      },
      {
        label: t('action.create.conditions.sources.device.pressingButtonStop'),
        value: BlindRemoteButtonStateEnum.StopPressed,
      },
      {
        label: t('action.create.conditions.sources.device.releasingButtonClose'),
        value: BlindRemoteButtonStateEnum.CloseReleased,
      },
    ],
    [],
  );

  const sunPositions: SelectOptionType<SunPosition>[] = useMemo(
    () => [
      { label: t('action.create.conditions.sources.schedule.sunrise'), value: SunPosition.SunRise },
      { label: t('action.create.conditions.sources.schedule.sunset'), value: SunPosition.SunSet },
    ],
    [],
  );

  const daysOfWeek: DaysOfWeekOption[] = useMemo(
    () => [
      {
        label: t('trigger.daysOfWeek.monday'),
        value: 1,
        checked: false,
      },
      {
        label: t('trigger.daysOfWeek.tuesday'),
        value: 2,
        checked: false,
      },
      {
        label: t('trigger.daysOfWeek.wednesday'),
        value: 3,
        checked: false,
      },
      {
        label: t('trigger.daysOfWeek.thursday'),
        value: 4,
        checked: false,
      },
      {
        label: t('trigger.daysOfWeek.friday'),
        value: 5,
        checked: false,
      },
      {
        label: t('trigger.daysOfWeek.saturday'),
        value: 6,
        checked: false,
      },
      {
        label: t('trigger.daysOfWeek.sunday'),
        value: 7,
        checked: false,
      },
    ],
    [],
  );

  const floodOptions: SelectOptionType<FloodSensorStates>[] = useMemo(
    () => [
      {
        label: t('action.create.conditions.sources.device.flooded'),
        value: FloodSensorStates.FloodDetected,
      },
      {
        label: t('action.create.conditions.sources.device.notFlooded'),
        value: FloodSensorStates.NoFloodDetected,
      },
    ],
    [],
  );

  const reedOptions: SelectOptionType<number>[] = useMemo(
    () => [
      { label: t('action.create.conditions.sources.device.close'), value: 1 },
      { label: t('action.create.conditions.sources.device.open'), value: 0 },
    ],
    [],
  );

  const windOptions: SelectOptionType<WindThresholdStateEnum>[] = useMemo(
    () => [
      { label: t('action.create.conditions.sources.device.wind.below'), value: WindThresholdStateEnum.Below },
      { label: t('action.create.conditions.sources.device.wind.exceeded'), value: WindThresholdStateEnum.Exceeded },
      { label: t('action.create.conditions.sources.device.wind.inactive'), value: WindThresholdStateEnum.Inactive },
    ],
    [],
  );

  const gateOptions: SelectOptionType<GatePositionTypeEnum>[] = useMemo(
    () => [
      { label: t('action.create.conditions.sources.device.open'), value: GatePositionTypeEnum.Open },
      { label: t('action.create.conditions.sources.device.close'), value: GatePositionTypeEnum.Close },
    ],
    [],
  );

  const gatewayOptions: SelectOptionType<GatewayPositionTypeEnum>[] = useMemo(
    () => [
      { label: t('action.create.conditions.sources.device.open'), value: GatewayPositionTypeEnum.Open },
      { label: t('action.create.conditions.sources.device.close'), value: GatewayPositionTypeEnum.Close },
    ],
    [],
  );

  const movementOptions: SelectOptionType<MovementStateEnum>[] = useMemo(
    () => [
      { label: t('action.create.conditions.sources.device.notMovement'), value: MovementStateEnum.No },
      { label: t('action.create.conditions.sources.device.movement'), value: MovementStateEnum.Yes },
    ],
    [],
  );

  const energyOptions: SelectOptionType<EnergyMeasurementParameter>[] = useMemo(
    () => [
      {
        label: tc('exalus.energyMeasurements.types.Voltage'),
        value: EnergyMeasurementParameter.Voltage,
      },
      {
        label: tc('exalus.energyMeasurements.types.Current'),
        value: EnergyMeasurementParameter.Current,
      },
      {
        label: tc('exalus.energyMeasurements.types.PhaseShift'),
        value: EnergyMeasurementParameter.PhaseShift,
      },
      {
        label: tc('exalus.energyMeasurements.types.ActivePower'),
        value: EnergyMeasurementParameter.ActivePower,
      },
      {
        label: tc('exalus.energyMeasurements.types.ReactivePower'),
        value: EnergyMeasurementParameter.ReactivePower,
      },
      {
        label: tc('exalus.energyMeasurements.types.ApparentPower'),
        value: EnergyMeasurementParameter.ApparentPower,
      },
      {
        label: tc('exalus.energyMeasurements.types.PowerFactor'),
        value: EnergyMeasurementParameter.PowerFactor,
      },
      {
        label: tc('exalus.energyMeasurements.types.ActiveForwardEnergy'),
        value: EnergyMeasurementParameter.ActiveForwardEnergy,
      },
      {
        label: tc('exalus.energyMeasurements.types.ActiveReverseEnergy'),
        value: EnergyMeasurementParameter.ActiveReverseEnergy,
      },
      {
        label: tc('exalus.energyMeasurements.types.ApparentEnergy'),
        value: EnergyMeasurementParameter.ApparentEnergy,
      },
      {
        label: tc('exalus.energyMeasurements.types.ReactiveForwardEnergy'),
        value: EnergyMeasurementParameter.ReactiveForwardEnergy,
      },
      {
        label: tc('exalus.energyMeasurements.types.ReactiveReverseEnergy'),
        value: EnergyMeasurementParameter.ReactiveReverseEnergy,
      },
      {
        label: tc('exalus.energyMeasurements.types.Frequency'),
        value: EnergyMeasurementParameter.Frequency,
      },
      {
        label: tc('exalus.energyMeasurements.types.SPIErrorRate'),
        value: EnergyMeasurementParameter.SPIErrorRate,
      },
      {
        label: tc('exalus.energyMeasurements.types.CRCErrorRate'),
        value: EnergyMeasurementParameter.CRCErrorRate,
      },
      {
        label: tc('exalus.energyMeasurements.types.Timestamp'),
        value: EnergyMeasurementParameter.Timestamp,
      },
      {
        label: tc('exalus.energyMeasurements.types.FailedMeasurementsRate'),
        value: EnergyMeasurementParameter.FailedMeasurementsRate,
      },
      {
        label: tc('exalus.energyMeasurements.types.FundamentalActivePower'),
        value: EnergyMeasurementParameter.FundamentalActivePower,
      },
      {
        label: tc('exalus.energyMeasurements.types.HarmonicActivePower'),
        value: EnergyMeasurementParameter.HarmonicActivePower,
      },
    ],
    [],
  );

  return {
    triggerButtonMethodOptions,
    triggerMethodsOptions,
    stateDeviceTriggerOptions,
    sunPositions,
    daysOfWeek,
    floodOptions,
    reedOptions,
    movementOptions,
    gateOptions,
    gatewayOptions,
    conditionsTypes,
    comparisonMethodsOptions,
    windOptions,
    brightnessMethodOptions,
    energyOptions,
  };
};
