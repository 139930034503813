import React from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { SwitchControlButtons } from '../../../../control-buttons/switch';
import { useCentralActionSwitchControl } from '../../../hooks/use-central-action-switch-control';

type CentralActionSwitchControlBoxProps = {
  channels: IDeviceChannel[];
  isListItem?: boolean;
};

export const CentralActionSwitchControlBox: React.FC<CentralActionSwitchControlBoxProps> = ({
  channels,
  isListItem,
}) => {
  const { turnOn, turnOff } = useCentralActionSwitchControl(channels);

  return <SwitchControlButtons turnOn={turnOn} turnOff={turnOff} isListItem={isListItem} />;
};
