import React, { createContext, useContext } from 'react';
import { useProfile } from '../user';

type UserPermissionsProviderState = {
  userPermissions: Record<string, boolean>;
  refetchUserMe: () => void;
};

const initialState: UserPermissionsProviderState = {
  userPermissions: {},
  refetchUserMe: () => null,
};

export const UserPermissionsContext = createContext(initialState);
export const useUserPermissionsContext = (): UserPermissionsProviderState => useContext(UserPermissionsContext);

const UserPermissionsProvider: React.FC = ({ children }) => {
  const { permissions, refetchUserMe } = useProfile();

  const values: UserPermissionsProviderState = {
    userPermissions: permissions,
    refetchUserMe,
  };

  return <UserPermissionsContext.Provider value={values}>{children}</UserPermissionsContext.Provider>;
};

export default UserPermissionsProvider;
