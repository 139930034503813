import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Slider } from '@mui/material';
import { hexToRgb } from '../../utils/helpers';
import { IconSun } from '../icons';
import './index.scss';

type LightSliderProps = {
  value: number;
  onChange: (val: number) => void;
  onPointerUp?: (val: number) => void;
  gradientColor?: string;
  isBasic?: boolean;
  showTooltip?: boolean;
  active?: boolean;
  whiteLight?: boolean;
  label?: string;
};

export const LightSlider: React.FC<LightSliderProps> = ({
  value,
  onChange,
  onPointerUp,
  isBasic,
  gradientColor,
  showTooltip = true,
  active,
  whiteLight,
  label,
}) => {
  const handleOnPointerUp = () => onPointerUp?.(value);

  const handleChange = (_: Event, newValue: number | number[]) => {
    onChange(newValue as number);
  };

  const color = useMemo(
    () => (gradientColor?.includes('#') ? hexToRgb(gradientColor) : gradientColor),
    [gradientColor],
  );

  const style = useMemo(
    () =>
      ({
        '--slider-position': `${value}%`,
        '--slider-gradient-color': color,
      } as React.CSSProperties),
    [value, color],
  );

  return (
    <>
      <h3>{label}</h3>
      <div
        className={classNames('light-slider', {
          'light-slider--gradient': color,
          'light-slider--basic': isBasic,
          'light-slider--white': whiteLight,
          'light-slider--active': active,
        })}
      >
        <div
          className={classNames('light-slider__icon light-slider__icon--off', {
            'light-slider__icon--accent': value < 10,
          })}
        >
          <IconSun isOn={false} />
        </div>
        <div className="light-slider__slider-element">
          <Slider
            style={style}
            className="light-slider__input"
            min={0}
            max={100}
            defaultValue={0}
            value={value}
            onChange={handleChange}
            onPointerUp={handleOnPointerUp}
            track={false}
            classes={{ rail: 'rail', thumb: 'thumb' }}
          />
        </div>
        {showTooltip && (
          <div style={style} className="light-slider__value">
            <h2 className="light-slider__value-text">{value.toFixed()}%</h2>
            <svg
              className="light-slider__value-handle"
              width="19"
              height="6"
              viewBox="0 0 19 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 3H13" />
              <circle cx="16" cy="3" r="2.5" />
            </svg>
          </div>
        )}
        <div
          className={classNames('light-slider__icon light-slider__icon--on', {
            'light-slider__icon--accent': value < 95,
          })}
        >
          <IconSun isOn />
        </div>
      </div>
    </>
  );
};
