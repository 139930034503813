import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelTypeInternal, ScalarParameterTypeInternal } from '../../../../data-access/gql-types/graphql';
import { useApi, useChannelsState } from '../../../../hooks';
import { ChannelCoverStateType, ChannelGateStateType, ChannelMeterType, ChannelType } from '../../../../types';
import { isDevelopment } from '../../../../utils/helpers';
import { EcoRatio } from '../../eco-ratio';

type ChannelBoxPostNameProps = {
  channel: ChannelType;
};

export const ChannelBoxPostName: React.FC<ChannelBoxPostNameProps> = <
  T extends ChannelCoverStateType | ChannelGateStateType,
>({
  channel,
}) => {
  const { t: tc } = useTranslation('common');
  const { convertMeasurementToNumber } = useApi();
  const { channelState } = useChannelsState();
  const ecoIndicatorNumberType = convertMeasurementToNumber(ChannelTypeInternal.Meter)(
    'scalars',
    ScalarParameterTypeInternal.EcoIndicator,
  ) as number;

  const postName = useMemo(() => {
    switch (channel.data.type) {
      case ChannelTypeInternal.Meter: {
        if (!isDevelopment) return null;
        const ecoIndicatorSupported = (channel?.data as ChannelMeterType)?.supportedScalarParameters.find(
          (x) => x === ecoIndicatorNumberType,
        );

        return ecoIndicatorSupported ? (
          <EcoRatio channel={channel} ecoIndicatorNumberType={ecoIndicatorNumberType} />
        ) : null;
      }
      case ChannelTypeInternal.Blind: {
        const calibrateState = (channelState[channel.id] as T)?.calibrateState;

        if (calibrateState) {
          if (calibrateState.progress) {
            return <span className="calibration">{`${tc('status.calibrate')} ${calibrateState.progress}%`}</span>;
          } else if (!(calibrateState.openingMilliseconds && calibrateState.closingMilliseconds)) {
            return <span className="no-calibration">{tc('status.no-calibrate')}</span>;
          }
        }

        return null;
      }
      default:
        return null;
    }
  }, [channel.data.type, (channelState[channel.id] as T)?.calibrateState, tc, ecoIndicatorNumberType]);

  return <>{postName}</>;
};
