import React from 'react';
import { ChannelTypeInternal, TriggerActionRequestInput } from '../../../../../data-access/gql-types/graphql';
import { ChannelType } from '../../../../../types';
import CoverSetup from '../cover';
import GateSetup from '../gate';
import LightSetup from '../light';
import SwitchSetup from '../switch';

type ChannelControlsProps = {
  channel: ChannelType;
  defaultParameters: TriggerActionRequestInput;
  setAction: (value: TriggerActionRequestInput) => void;
};

const ChannelControls: React.FC<ChannelControlsProps> = ({ channel, defaultParameters, setAction }) => {
  switch (channel.data.type) {
    case ChannelTypeInternal.Blind:
      return <CoverSetup defaultParameters={defaultParameters} channel={channel} setAction={setAction} />;
    case ChannelTypeInternal.Switch:
      return <SwitchSetup defaultParameters={defaultParameters} channel={channel} setAction={setAction} />;
    case ChannelTypeInternal.Light:
      return <LightSetup defaultParameters={defaultParameters} channel={channel} setAction={setAction} />;
    case ChannelTypeInternal.Gate:
      return <GateSetup defaultParameters={defaultParameters} channel={channel} setAction={setAction} />;
    default:
      return null;
  }
};

export default ChannelControls;
