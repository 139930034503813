import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DialogTimePicker, IconEdit, Input } from '../../../../../components';

type SetOnTimeActionProps = {
  value: string;
  bindingIndex: number;
  bindingListIndex: number;
  listName: string;
};

export const SetOnTimeAction: React.FC<SetOnTimeActionProps> = ({
  value,
  bindingIndex,
  bindingListIndex,
  listName,
}) => {
  const { t } = useTranslation('device-settings');
  const { setValue } = useFormContext();
  const [showTimePicker, setShowTimePicker] = useState<boolean>(false);

  const handleSetTime = (time: string) => {
    setValue(`bindings.${bindingIndex}.${listName}.${bindingListIndex}.setOnTimeValue`, time);
  };

  return (
    <>
      <Input
        defaultValue={value}
        value={value}
        required
        readOnly
        label={t('bindings.time')}
        onClickInput={() => setShowTimePicker(true)}
        reverse
        endIcon={<IconEdit />}
      />
      <DialogTimePicker
        header={t('bindings.time')}
        open={showTimePicker}
        setOpen={setShowTimePicker}
        onSave={(value) => handleSetTime(value)}
        time={value}
        showSeconds
        disableHours
      />
    </>
  );
};
