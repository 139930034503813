import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  DeviceTaskPairExecutionResult as ChannelTaskPairExecutionResult,
  DeviceTaskPairExecutionResult,
} from 'lavva.exalushome/build/js/Services/Devices/IDevicesService';
import { BlindActionEnum } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { ControlWrapper, CoverButton, IconChevron, IconFavorite, IconVent, Slider } from '../../../../../../components';
import { defaultFavouritePositionList } from '../../../../../../const';

type BlindDetailsPositionControlButtonsProps = {
  close: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  stop: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  open: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  handleAction: (action, position?: number) => Promise<ChannelTaskPairExecutionResult[]>;
  handleSliderEvent: (event: React.PointerEvent<HTMLInputElement>) => void;
  handleMicroventilation: (position: number) => Promise<DeviceTaskPairExecutionResult[]>;
};

export const BlindDetailsPositionControlButtons: React.FC<BlindDetailsPositionControlButtonsProps> = ({
  close,
  stop,
  open,
  handleAction,
  handleSliderEvent,
  handleMicroventilation,
}) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const [sliderValue, setSliderValue] = useState(50);
  const isOpen = false;
  const isClosed = false;

  const onChange = (value: number) => {
    setSliderValue(value);
    handleAction(BlindActionEnum.Percentage, value);
  };

  return (
    <>
      <div className="cover-detail-view">
        <ControlWrapper>
          <Slider
            value={sliderValue}
            onPointerUp={handleSliderEvent}
            onChange={(e) => setSliderValue(Number(e.target.value))}
            showValue={false}
          />
        </ControlWrapper>
        <div className="cover-detail-view__controls">
          <div
            className={classNames('cover-detail-view__controls-down', {
              'cover-detail-view__controls-down--disabled': isClosed,
            })}
            onClick={() => !isClosed && close()}
          >
            <IconChevron withCircle disabled={isClosed} direction="down" />
          </div>
          <button className="cover-detail-view__controls-stop" onClick={stop}>
            {tc('status.stop')}
          </button>
          <div
            className={classNames('cover-detail-view__controls-up', { 'cover__controls-up--disabled': isOpen })}
            onClick={() => !isOpen && open()}
          >
            <IconChevron withCircle disabled={isOpen} direction="up" />
          </div>
        </div>
      </div>
      <div className="favourite">
        <div className="favourite__buttons favourite__buttons--cover">
          {[0, 1, 2].map((index) => (
            <CoverButton
              key={index}
              position={defaultFavouritePositionList[index]}
              onClick={() => onChange(defaultFavouritePositionList[index])}
            >
              <IconFavorite />
              <span>{defaultFavouritePositionList[index]}%</span>
            </CoverButton>
          ))}
          <CoverButton position={91} onClick={() => handleMicroventilation(91)}>
            <IconVent />
            <span>{t('microventilation')}</span>
          </CoverButton>
        </div>
      </div>
    </>
  );
};
