import React, { useMemo } from 'react';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ControlWrapper } from '../../../../../../../components';
import LavvaDetailsWrapper from '../../../../../../../components/details-wrapper';
import { canChannelReturnAny } from '../../../../../utils';
import { DimmerControls } from '../../dimmer';
import { MeterDetailsControls } from '../../meter/details';
import { OnOffControls } from '../components/on-off-controls';

type SwitchDetailsControlsProps = {
  channel: IDeviceChannel;
  active: boolean;
};

export const SwitchDetailsControls: React.FC<SwitchDetailsControlsProps> = ({ channel, active }) => {
  const isEnergy = useMemo(() => canChannelReturnAny(channel, [ChannelResponseType.MeasuredEnergy]), [channel]);

  return (
    <>
      {!isEnergy ? (
        <>
          <LavvaDetailsWrapper>
            <ControlWrapper>
              <OnOffControls channel={channel} active={active} />
            </ControlWrapper>
          </LavvaDetailsWrapper>
          <DimmerControls channel={channel} active={active} />
        </>
      ) : (
        <MeterDetailsControls channel={channel}>
          <OnOffControls channel={channel} active={active} />
        </MeterDetailsControls>
      )}
    </>
  );
};
