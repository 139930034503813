import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IWszfBidiConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/WindSensor/IWszfBidiConfigService';
import { WindThresholdStep as WindThresholdStepType } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/WindSensor/WszfBidiConfigParams';
import { WszfBidiConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/WindSensor/WszfBidiConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { InputSelect, SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../hooks';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { WindThresholdParams } from '../../../device-details/types';

type WindThresholdFormProps = {
  device: IDevice;
  channel: IDeviceChannel;
  windThreshold: WindThresholdStepType | null;
};

const WindThresholdForm: React.FC<WindThresholdFormProps> = ({ device, channel, windThreshold }) => {
  const { t } = useTranslation('device-info');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse } = useConfigurationService();
  const form = useForm<WindThresholdParams>();

  const { WindThreshold } = form.watch();

  useEffect(() => {
    if (windThreshold !== null) form.setValue('WindThreshold', windThreshold);
  }, [windThreshold]);

  const onSubmit = form.handleSubmit(async (values) => {
    turnOnBackdrop();
    const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IWszfBidiConfigService>(
      WszfBidiConfigService.ServiceName,
    );

    const result = await configService.SetWindThresholdAsync(device, channel.Number, values.WindThreshold);
    handleSubmitResponse(result);
    turnOffBackdrop();
  });

  const onChangeWindThreshold = (value) => form.setValue('WindThreshold', value);

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="grid-list-24">
        <InputSelect
          onChange={onChangeWindThreshold}
          value={WindThreshold}
          options={[
            {
              label: t('exalus.params.WszfBidiParams.windThresholdSteps.Disabled'),
              value: WindThresholdStepType.Disabled,
            },
            {
              label: t('exalus.params.WszfBidiParams.windThresholdSteps.Speed15km_h'),
              value: WindThresholdStepType.Speed15km_h,
            },
            {
              label: t('exalus.params.WszfBidiParams.windThresholdSteps.Speed21km_h'),
              value: WindThresholdStepType.Speed21km_h,
            },
            {
              label: t('exalus.params.WszfBidiParams.windThresholdSteps.Speed28km_h'),
              value: WindThresholdStepType.Speed28km_h,
            },
            {
              label: t('exalus.params.WszfBidiParams.windThresholdSteps.Speed36km_h'),
              value: WindThresholdStepType.Speed36km_h,
            },
            {
              label: t('exalus.params.WszfBidiParams.windThresholdSteps.Speed45km_h'),
              value: WindThresholdStepType.Speed45km_h,
            },
            {
              label: t('exalus.params.WszfBidiParams.windThresholdSteps.Speed55km_h'),
              value: WindThresholdStepType.Speed55km_h,
            },
            {
              label: t('exalus.params.WszfBidiParams.windThresholdSteps.Speed66km_h'),
              value: WindThresholdStepType.Speed66km_h,
            },
            {
              label: t('exalus.params.WszfBidiParams.windThresholdSteps.Speed78km_h'),
              value: WindThresholdStepType.Speed78km_h,
            },
            {
              label: t('exalus.params.WszfBidiParams.windThresholdSteps.Speed91km_h'),
              value: WindThresholdStepType.Speed91km_h,
            },
          ]}
          label={t('exalus.params.WszfBidiParams.WindThreshold')}
        />
        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default WindThresholdForm;
