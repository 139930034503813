import React from 'react';
import classNames from 'classnames';
import { IconEdit, IconRadio, SelectOptionType } from '../../../../../../components';
import { Dialog } from '../../../../../../components/dialog/base';
import { TimeEndType } from '../../enums';
import '../../index.scss';

type TimeEndDialogProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  title: string;
  options: SelectOptionType<string>[];
  selectedOptionIndex: number;
  onChange: (selectedOptionIndex: number) => void;
  count: number;
  date: string;
};

export const TimeEndDialog: React.FC<TimeEndDialogProps> = ({
  show,
  setShow,
  title,
  options,
  selectedOptionIndex,
  onChange,
  count,
  date,
}) => {
  return (
    <Dialog show={show} setShow={setShow}>
      <div className="dialog-select">
        <h2 className="dialog-select__header">{title}</h2>
        <ul>
          {options.map((option, optionIndex) => {
            const isSelected = optionIndex === selectedOptionIndex;

            return (
              <li
                key={option.label}
                className={classNames('dialog-select__option-item', {
                  'dialog-select__option-item--selected': isSelected,
                })}
                onClick={() => onChange(optionIndex)}
              >
                <IconRadio className="dialog-select__option-item-icon" selected={isSelected} />
                <span className="dialog-select__option-item-label">
                  <span>
                    {option.label}
                    {isSelected && option.value === TimeEndType.COUNT ? (
                      <span>: {count}</span>
                    ) : isSelected && option.value === TimeEndType.DATE ? (
                      <span>: {date}</span>
                    ) : null}
                  </span>
                </span>
                {isSelected && (option.value === TimeEndType.COUNT || option.value === TimeEndType.DATE) ? (
                  <IconEdit />
                ) : null}
              </li>
            );
          })}
        </ul>
      </div>
    </Dialog>
  );
};
