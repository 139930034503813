import React from 'react';
import './index.scss';

type IconTipProps = {
  pulse?: boolean;
};

export const IconTip: React.FC<IconTipProps> = ({ pulse }) => (
  <div className="icon-tip">
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8" clipPath="url(#clip0_21814_43522)">
        <g clipPath="url(#clip1_21814_43522)">
          <path
            d="M18 22.48V15C18 12.24 20.24 10 23 10C25.76 10 28 12.24 28 15V22.48C30.41 20.87 32 18.12 32 15C32 10.03 27.97 6 23 6C18.03 6 14 10.03 14 15C14 18.12 15.59 20.87 18 22.48ZM37.67 31.74L28.59 27.22C28.25 27.08 27.89 27 27.5 27H26V15C26 13.34 24.66 12 23 12C21.34 12 20 13.34 20 15V36.47L13.15 35.04C13 35.01 12.84 34.99 12.68 34.99C12.06 34.99 11.5 35.25 11.09 35.65L9.51001 37.25L19.39 47.13C19.94 47.67 20.69 48.01 21.51 48.01H35.1C36.61 48.01 37.76 46.9 37.97 45.45L39.48 34.91C39.5 34.77 39.51 34.64 39.51 34.5C39.5 33.26 38.74 32.19 37.67 31.74Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_21814_43522">
          <rect width="48" height="48" fill="white" />
        </clipPath>
        <clipPath id="clip1_21814_43522">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
    {pulse && <div className="pulsating-circle" />}
  </div>
);
