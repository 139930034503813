import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IRob21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/IRob21ConfigService';
import { Rob21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/Rob21ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../hooks';
import ConfigurationInput from '../../../device-details/components/configuration-input';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { GateTimeParams } from '../../../device-details/types';

type PulseTimeFormProps = {
  device: IDevice;
  pulseTime: number | null;
  channel: IDeviceChannel;
};

const PulseTimeForm: React.FC<PulseTimeFormProps> = ({ device, pulseTime, channel }) => {
  const { t } = useTranslation('device-info');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse } = useConfigurationService();
  const form = useForm<GateTimeParams>();

  useEffect(() => {
    if (pulseTime !== null) form.setValue('time', pulseTime.toString());
  }, [pulseTime]);

  const onSubmit = form.handleSubmit(async (values) => {
    turnOnBackdrop();
    const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRob21ConfigService>(
      Rob21ConfigService.ServiceName,
    );

    const result = await configService.SetPulseTimeAsync(device, channel.Number, parseFloat(values.time));
    handleSubmitResponse(result);
    turnOffBackdrop();
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="grid-list-24">
        <ConfigurationInput
          name="time"
          label={t('exalus.params.Rob21Params.PulseTime')}
          placeholder={t('exalus.params.Rob21Params.PulseTime')}
          inputType="number"
          step="0.1"
          min={0.1}
          max={300}
        />
        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default PulseTimeForm;
