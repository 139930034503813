import React, { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Header, IconAdmin, IconUser, NavHeader, Page, SelectOptionType, SubmitButton } from '../../../components';
import RadiosGroup from '../../../components/radios-group';
import { InstallationAccessType, ShareInfoModificationType } from '../../../data-access/gql-types/graphql';
import { ROUTES } from '../../../routes';
import { SharingType } from '../../../types';
import { useInstallationShare } from '../hooks/use-installation-share';
import { useMember } from '../hooks/use-member';

const ShareRole: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const {
    state,
    methods: { handleSetSharePermission, handleSetShareAccess },
  } = useInstallationShare();
  const { changeRole } = useMember();

  const permissions: SelectOptionType<InstallationAccessType>[] = useMemo(
    () => [
      {
        value: InstallationAccessType.Admin,
        label: t('share_path.role_admin'),
        subLabel: t('share_path.admin_info'),
        icon: <IconAdmin />,
      },
      {
        value: InstallationAccessType.User,
        label: t('share_path.role_user'),
        subLabel: t('share_path.user_info'),
        icon: <IconUser />,
      },
    ],
    [],
  );

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    handleSetSharePermission(target.value as InstallationAccessType);
  };

  const handleNext = () => {
    if (state.shareInfoModificationType === ShareInfoModificationType.Undefined) {
      if (!state.userId) {
        // new share
        if (state.permission === InstallationAccessType.Admin) {
          handleSetShareAccess(SharingType.Permanent);
          history.push(ROUTES.InstallationSummaryShare());
        } else {
          history.push(ROUTES.InstallationShareRestriction());
        }
      } else {
        // change only role
        changeRole();
      }
    } else {
      // edit path
      if (state.permission === InstallationAccessType.Admin) {
        handleSetShareAccess(SharingType.Permanent);
        history.push(ROUTES.InstallationSummaryShare());
      } else {
        history.push(ROUTES.InstallationShareRestriction());
      }
    }
  };

  return (
    <Page
      className="share-role"
      header={
        <>
          <NavHeader />
          <Header title={t('share_path.role_title')} isUnderline />
        </>
      }
    >
      <RadiosGroup
        options={permissions}
        value={state.permission}
        onChange={handleChange}
        withImage
        subLabelFontSize={12}
      />
      <SubmitButton type="button" onClick={handleNext} disabled={state.permission === InstallationAccessType.Undefined}>
        {!state.userId ? tc('buttons.next') : tc('buttons.save')}
      </SubmitButton>
    </Page>
  );
};

export default ShareRole;
