import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  ChannelTypeInternal,
  DeviceGetBindingsQuery,
  DeviceGetBindingsQueryVariables,
  GenericInputChannelStateResponse,
  UserChannel,
} from '../../../../data-access/gql-types/graphql';
import { DEVICE_GET_BINDINGS } from '../../../../data-access/queries/devices';
import { useInstallation } from '../../../../hooks';
import { BindingsItem, DeviceBindingsForm } from '../types';
import { mapBindingData } from '../utils';

export const useDeviceBindings = () => {
  const { deviceId } = useParams<{ deviceId: string }>();
  const { selectedInstallationId } = useInstallation();
  const [inputs, setInputs] = useState<UserChannel[]>([]);
  const [channels, setChannels] = useState<UserChannel[]>([]);
  const form = useForm<DeviceBindingsForm>({
    defaultValues: {
      bindings: [],
    },
  });
  const { data, loading } = useQuery<DeviceGetBindingsQuery, DeviceGetBindingsQueryVariables>(DEVICE_GET_BINDINGS, {
    variables: { installationId: selectedInstallationId, deviceId },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (data?.channelBindings) {
      const filteredChannels = (data?.channelBindings || []).filter((x) =>
        [
          ChannelTypeInternal.Switch,
          ChannelTypeInternal.Light,
          ChannelTypeInternal.Blind,
          ChannelTypeInternal.Gate,
        ].includes(x.channelType),
      ) as UserChannel[];
      setChannels(
        filteredChannels.map((ch) => ({
          ...ch,
          ...(ch.payload && {
            payload: {
              ...ch.payload,
              ...('switchSupportedSlots' in ch.payload && { supportedSlots: ch.payload.switchSupportedSlots }),
              ...('blindSupportedSlots' in ch.payload && { supportedSlots: ch.payload.blindSupportedSlots }),
              ...('lightSupportedSlots' in ch.payload && { supportedSlots: ch.payload.lightSupportedSlots }),
              ...('gateSupportedSlots' in ch.payload && { supportedSlots: ch.payload.gateSupportedSlots }),
            },
          }),
        })),
      );

      setInputs(
        (data?.channelBindings || []).filter(
          (x) => x.channelType === ChannelTypeInternal.GenericInput,
        ) as UserChannel[],
      );

      const bindings: BindingsItem[] = [];

      const channelBindings = data.channelBindings.map((channelBinding) => {
        if (channelBinding.payload?.__typename === 'GenericInputChannelStateResponse') {
          return {
            ...channelBinding,
            payload: {
              ...channelBinding.payload,
              actionBindings: channelBinding.payload.actionBindings.map((actionBinding) => {
                return {
                  ...actionBinding,
                  ...('switchSlot' in actionBinding && { slot: actionBinding.switchSlot }),
                  ...('blindSlot' in actionBinding && { slot: actionBinding.blindSlot }),
                  ...('lightSlot' in actionBinding && { slot: actionBinding.lightSlot }),
                  ...('gateSlot' in actionBinding && { slot: actionBinding.gateSlot }),
                };
              }),
            },
          };
        }

        return {
          ...channelBinding,
        };
      }) as UserChannel[];

      channelBindings
        .filter((x) => x.channelType === ChannelTypeInternal.GenericInput)
        .map((inputChannel) => {
          if ((inputChannel.payload as GenericInputChannelStateResponse)?.actionBindings) {
            bindings.push(mapBindingData(inputChannel, filteredChannels));
          } else {
            bindings.push({
              inputId: inputChannel.id,
              single: [],
              double: [],
            });
          }
        });

      form.setValue(
        'bindings',
        !bindings.length
          ? inputs.map((input) => ({
              inputId: input.id,
              single: [],
              double: [],
            }))
          : bindings,
      );
    }
  }, [data?.channelBindings]);

  return {
    form,
    loading,
    inputs,
    channels,
  };
};
