import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { useMutation } from '@apollo/client';
import {
  ChangeChannelsVisibilityMutation,
  ChangeChannelsVisibilityMutationVariables,
} from '../../../data-access/gql-types/graphql';
import { CHANNEL_VISIBILITY } from '../../../data-access/mutations/channels';
import { useDevicesAndChannels } from '../../../hooks';
import { useMutationErrors } from '../../../hooks/backend-errors/use-mutation-errors';
import { ChannelType, MutationErrorType, Visibility } from '../../../types';
import { toastError } from '../../../utils/toast';
import { VisibilityChannel } from '../types';

export const useChannelsVisibility = () => {
  const { t: tc } = useTranslation('common');
  const { handleErrors } = useMutationErrors(MutationErrorType.Channel);
  const { channelList, channelGroups, setChannelList } = useDevicesAndChannels({ visibility: Visibility.All });
  const [visibilityList, updateVisibilityList] = useState<VisibilityChannel[]>([]);
  const [changeChannelsVisibility, { loading }] = useMutation<
    ChangeChannelsVisibilityMutation,
    ChangeChannelsVisibilityMutationVariables
  >(CHANNEL_VISIBILITY);

  useEffect(() => {
    const list = channelList.map((d) => {
      if (d) {
        return {
          id: d.id,
          currentVisibility: d.isVisible,
          newVisibility: d.isVisible,
        };
      }
    }) as VisibilityChannel[];

    updateVisibilityList(list);
  }, [channelList]);

  const onChannelToggle = useCallback(
    (id: string, value: boolean) => {
      const list = [...visibilityList];

      const channelIndex = list.findIndex((item) => item && item.id === id);
      if (channelIndex !== -1) {
        list[channelIndex].newVisibility = value;
        updateVisibilityList(list);
      }
    },
    [visibilityList],
  );

  const saveVisibility = () => {
    const forUpdate = visibilityList.filter((channel) => channel.currentVisibility !== channel.newVisibility);

    changeChannelsVisibility({
      variables: {
        input: {
          channels: forUpdate.map((item) => ({ channelId: item.id, isVisible: item.newVisibility })),
        },
      },
      onCompleted: (data) => {
        if (!data || !data.changeChannelsVisibility) {
          toastError({ content: tc('errors.somethingWentWrong') });
          return;
        }

        if (data.changeChannelsVisibility.result?.ok) {
          setChannelList((prev) => {
            const tempList = cloneDeep(prev);

            for (const item of forUpdate) {
              const channelIndex = tempList.findIndex((channel) => channel.id === item.id);

              if (channelIndex !== -1) {
                const newChannel = tempList[channelIndex] as ChannelType;
                newChannel.isVisible = item.newVisibility;
              }
            }

            return tempList;
          });
        } else {
          handleErrors(data.changeChannelsVisibility.errors || []);
        }
      },
    });
  };

  return {
    visibilityList,
    channelGroups,
    loading,
    saveVisibility,
    onChannelToggle,
  };
};
