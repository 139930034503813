import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from '@mui/material';
import { Input } from '../../../../components';
import './index.scss';

type SearchBarProps = {
  searchVisible: boolean;
  setSearchVisible: Dispatch<SetStateAction<boolean>>;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
};

export const SearchBar: React.FC<SearchBarProps> = ({ searchText, setSearchText, searchVisible, setSearchVisible }) => {
  const { t } = useTranslation('common');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <Popover
      open={searchVisible}
      onClose={() => setSearchVisible(false)}
      anchorReference="anchorPosition"
      anchorPosition={{ top: 0, left: window.innerWidth / 2 }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      classes={{ root: 'installation-list-search', paper: 'installation-list-search--paper' }}
    >
      <div className="installation-list-search--content">
        <Input
          defaultValue={''}
          value={searchText}
          onChange={handleChange}
          placeholder={t('search')}
          reverse
          noBottomMargin
          autoFocus
        />
      </div>
    </Popover>
  );
};
