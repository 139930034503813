import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { CoordinatesWithSourceInfo } from 'lavva.exalushome/build/js/Services/Controller/IGeolocationService';
import { useQuery } from '@apollo/client';
import { CustomBackdrop, Header, NavHeader, Page, PopUpNav } from '../../components';
import { IconDelete, IconShare } from '../../components/popup-nav/icons';
import {
  InstallationAccessType,
  InstallationGetSettingsQuery,
  InstallationGetSettingsQueryVariables,
  IntegrationType,
  UserInstallation,
  UserPermissionType,
} from '../../data-access/gql-types/graphql';
import { INSTALLATION_GET_SETTINGS } from '../../data-access/queries/installations';
import { useInstallation } from '../../hooks';
import { usePermissionsContext } from '../../hooks/user-permissions';
import { ROUTES } from '../../routes';
import { toastError } from '../../utils/toast';
import { useExalusGeolocation } from '../exalus/hooks';
import { CreateInstallationControlForm } from '../installation-create/types';
import { useShareRedirect } from '../installation-share/hooks/use-share-redirect';
import './index.scss';
import InstallationForm from './installation';

type InstallationSettingsProps = {
  id?: string;
};

const InstallationSettings: React.FC<InstallationSettingsProps> = ({ id = '' }) => {
  const history = useHistory();
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { installationId = id } = useParams<{ installationId: string }>();
  const { setSelectedInstallationId, integrationType } = useInstallation();
  const { data: location, isLoading: locationLoading } = useExalusGeolocation();
  const { shareInstallation } = useShareRedirect();
  const { permissions } = usePermissionsContext(installationId);
  const { data, loading } = useQuery<InstallationGetSettingsQuery, InstallationGetSettingsQueryVariables>(
    INSTALLATION_GET_SETTINGS,
    {
      variables: { id: installationId },
      fetchPolicy: 'no-cache', // In case of settings we always want to have fresh data
      onError: () => toastError({ content: tc('errors.downloadData') }),
    },
  );
  const editForm = useForm({
    defaultValues: {
      installation_name: '',
      icon_name: '',
      hex_color: '',
      time_zone: '',
      location: '',
    } as CreateInstallationControlForm,
  });

  const installationData = useMemo(() => {
    if (data) return data.allUserInstallations[0] as UserInstallation;
  }, [data, installationId]);

  useEffect(() => {
    if (installationData) {
      setSelectedInstallationId(installationId);
      editForm.setValue('installation_name', installationData.name || '');
      editForm.setValue('icon_name', installationData.iconName || '');
      editForm.setValue('hex_color', installationData.hexColor || '');
      editForm.setValue('time_zone', installationData.payload?.timeZone || '');

      if (integrationType !== IntegrationType.Exalus) {
        editForm.setValue(
          'location',
          installationData.payload?.coordinates
            ? `${installationData.payload.coordinates.latitude}, ${installationData.payload.coordinates.longitude}`
            : '',
        );
      } else {
        editForm.setValue(
          'location',
          (location as CoordinatesWithSourceInfo)?.Latitude && (location as CoordinatesWithSourceInfo)?.Longitude
            ? `${(location as CoordinatesWithSourceInfo).Latitude}, ${
                (location as CoordinatesWithSourceInfo).Longitude
              }`
            : '',
        );
      }
    }
  }, [installationData, location, integrationType]);

  const goToRemovePage = () => {
    const { installation_name: name } = editForm.getValues();
    history.push(ROUTES.InstallationRemove(installationId, name));
  };

  return (
    <Page
      className={classNames('installation-settings', { hide: id })}
      isStickyHeader={!id}
      {...(!id
        ? {
            header: (
              <>
                <NavHeader>
                  <PopUpNav
                    links={[
                      ...(permissions[UserPermissionType.ShareInstallation] &&
                      integrationType !== IntegrationType.Exalus
                        ? [
                            {
                              label: t('share'),
                              onClick: () => shareInstallation(installationId),
                              hasLineBelow: installationData?.accessType === InstallationAccessType.Owner,
                              icon: <IconShare />,
                            },
                          ]
                        : []),
                      ...(permissions[UserPermissionType.DeleteInstallation]
                        ? [
                            {
                              label: t('remove_installation'),
                              onClick: goToRemovePage,
                              icon: <IconDelete />,
                            },
                          ]
                        : []),
                    ]}
                  />
                </NavHeader>
                <Header title={t('settingsTitle')} />
              </>
            ),
          }
        : {})}
    >
      <InstallationForm editForm={editForm} />
      <CustomBackdrop loading={loading || locationLoading} />
    </Page>
  );
};

export default InstallationSettings;
