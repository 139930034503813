import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import {
  GlobalTimeParameters,
  StateOfTurnOnSwitchDeviceParameters,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/OnOffSwitchConfigurations/OnOffSwitchParameters';
import { IRog21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/PowerPlugSwitch/IRog21ConfigService';
import { Rog21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/PowerPlugSwitch/Rog21ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../hooks';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import GlobalTimeForm from '../on-off/global-time';
import TurnOnBehaviourForm from '../on-off/turn-behaviour';
import { initialConfig, Rog21ConfigParams, Rog21ParamsEnum } from './types';

type Rog21ConfigFormProps = {
  device: IDevice;
  channel?: IDeviceChannel;
};

const Rog21ConfigForm: React.FC<Rog21ConfigFormProps> = ({ device, channel }) => {
  const { t } = useTranslation('device-info');
  const [config, setConfig] = useState<Rog21ConfigParams>(initialConfig);
  const [open, setOpen] = useState<boolean>(false);
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const {
    handleGetSwitchParamsResponse,
    RemotesButton,
    DiagnosticButton,
    DiagnosticResultPopup,
    firmwareService,
    UserManualButton,
  } = useConfigurationService(device, channel);
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);

  const getDefaultParams = async (panel: Rog21ParamsEnum) => {
    turnOnBackdrop();
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRog21ConfigService>(
      Rog21ConfigService.ServiceName,
    );

    switch (panel) {
      case Rog21ParamsEnum.TURN_ON_BEHAVIOUR: {
        if (channel) {
          const data = await configService.GetTurnOnBehaviourAsync(device, channel.Number);

          handleGetSwitchParamsResponse<StateOfTurnOnSwitchDeviceParameters>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as StateOfTurnOnSwitchDeviceParameters });
            setOpen(true);
          });
        }
        break;
      }
      case Rog21ParamsEnum.GLOBAL_TIME: {
        if (channel) {
          const data = await configService.GetGlobalTimeSettingsAsync(device, channel.Number);

          handleGetSwitchParamsResponse<GlobalTimeParameters>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as GlobalTimeParameters });
            setOpen(true);
          });
        }
        break;
      }
      default:
        break;
    }

    turnOffBackdrop();
  };

  const handleOpen = (panel: Rog21ParamsEnum) => getDefaultParams(panel);

  const handleBack = () => {
    setOpen(false);
    setConfig({ ...initialConfig });
  };

  const activeConfigName = useMemo(() => Object.keys(config).find((key) => config[key] !== null) || null, [config]);

  const renderCorrentForm = () => {
    if (!activeConfigName) return null;

    switch (activeConfigName) {
      case Rog21ParamsEnum.TURN_ON_BEHAVIOUR: {
        return <TurnOnBehaviourForm device={device} turnOnBehaviour={config[activeConfigName]} />;
      }
      case Rog21ParamsEnum.GLOBAL_TIME: {
        return <GlobalTimeForm device={device} timeParams={config[activeConfigName]} />;
      }
      default:
        return null;
    }
  };

  return (
    <>
      {open ? (
        <ConfigCustomPage handleBack={handleBack}>{renderCorrentForm()}</ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              <ArrowButton
                title={t('exalus.params.OnOffParams.TurnOnBehaviourParams')}
                onClick={() => handleOpen(Rog21ParamsEnum.TURN_ON_BEHAVIOUR)}
                className="m-b-24"
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.OnOffParams.GlobalTimeParams')}
                onClick={() => handleOpen(Rog21ParamsEnum.GLOBAL_TIME)}
              />
              {RemotesButton}
            </>
          ) : (
            <>
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
              {DiagnosticButton}
              {UserManualButton}
            </>
          )}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default Rog21ConfigForm;
