import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { IconToastWarning2 } from '../../../../../../../components/icons/icon-toast-warning-2';
import { ChannelBox } from '../../../box';
import { ChannelBoxIcon } from '../../../box-icon';
import { useMovementControl } from '../../../hooks/use-movement-control';
import { PowerState } from '../../../power';
import SensorDetailsWrapper from '../../../sensor-details-wrapper';
import MovementControl from '../controls';

type MovementDetailsControlsProps = {
  channel: IDeviceChannel;
  active: boolean;
};

export const MovementDetailsControls: React.FC<MovementDetailsControlsProps> = ({ channel, active }) => {
  const { t } = useTranslation('channel-details');
  const { time, isTamperProtection } = useMovementControl(channel);

  return (
    <SensorDetailsWrapper channel={channel} active={active}>
      <div className="details-controls grid-list-16">
        <div>
          <ChannelBox channel={channel} isListItem className="device-box--details">
            <div className="device-box__head">
              <ChannelBoxIcon channel={channel} />
              <MovementControl channel={channel} isListItem details />
            </div>
          </ChannelBox>
          {isTamperProtection && (
            <div className="row-container">
              <IconToastWarning2 colorError size={16} />
              <span className="sensor-control-box__error-text">{t('exalus.movement.tamperAlarm')}</span>
            </div>
          )}
          {time && <div className="details-controls__detection-time">{`${t('exalus.checked')} ${time}`}</div>}
        </div>
        <PowerState channel={channel} />
      </div>
    </SensorDetailsWrapper>
  );
};
