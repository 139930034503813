import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Api } from 'lavva.exalushome';
import {
  DeviceDiagnosticsStatus,
  DiagnosticResult,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/DiagnosticService/DiagnosticParams';
import { DiagnosticService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/DiagnosticService/DiagnosticService';
import { IDiagnosticService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/DiagnosticService/IDiagnosticService';
import { FirmwareService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FirmwareService/FirmwareService';
import { IFirmwareService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FirmwareService/IFirmwareService';
import { IRemotesService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RemoteService/IRemotesService';
import { RemotesService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RemoteService/RemotesService';
import { ExtaLifeService } from 'lavva.exalushome.extalife/build/js/ExtaLife';
import { PortosService } from 'lavva.exalushome.portos';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { DialogConfirmation } from '../../../../../components';
import ArrowButton from '../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../hooks';
import { useSupportedDeviceById } from '../../../../../hooks/supported-devices/supported-devices';
import { ROUTES } from '../../../../../routes';
import { toastSuccess } from '../../../../../utils/toast';
import { useExalusContext } from '../../../context';
import { deviceConfigServices } from '../utils';
import { useHandleConfigurationErrors } from './use-handle-configuration-errors';

export const useConfigurationService = (device?: IDevice, channel?: IDeviceChannel) => {
  const history = useHistory();
  const { t } = useTranslation('device-info');
  const { t: tc } = useTranslation('common');
  const { t: ts } = useTranslation('store');
  const { turnOnBackdrop, turnOffBackdrop, turnOnBackdropPopup, turnOffBackdropPopup } = useBackdropContext();
  const { connected, synchronized, inited } = useExalusContext();
  const { data: supportedDevice } = useSupportedDeviceById(device?.ModelGuid || '');
  const [configService, setConfigService] = useState<string>('');
  const [remoteService, setRemoteService] = useState<IRemotesService | undefined>(undefined);
  const [firmwareService, setFirmwareService] = useState<IFirmwareService | undefined>(undefined);
  const [diagnosticService, setDiagnosticService] = useState<IDiagnosticService | undefined>(undefined);
  const [diagnosticResult, setDiagnosticResult] = useState<DiagnosticResult | null>(null);
  const [showDiagnosticPopup, setShowDiagnosticPopup] = useState<boolean>(false);
  const {
    handleSubmitResponse,
    handleGetFacadeParamsResponse,
    handleDiagnosticResponse,
    handleGetParamsResponse,
    handleGetBlindParamsResponse,
    handleGetSwitchParamsResponse,
    showGetFacadeParamsError,
  } = useHandleConfigurationErrors();

  useEffect(() => {
    if (connected && synchronized && inited && device) getAllParams();
  }, [device, connected, synchronized, inited]);

  const getAllParams = useCallback(async () => {
    if (device) {
      const portosService = Api.Get<PortosService>(PortosService.ServiceName);
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);

      const configServices = await extaLifeService.GetAllDeviceServicesAsync(device);
      const portosServices = portosService.GetAllDeviceServices(device);

      if (portosServices.length > 0) setConfigService(portosServices[0].GetServiceName());
      else if (configServices.length > 0) {
        for (const service of configServices) {
          const found = deviceConfigServices.find((x) => x.ServiceName === service.GetServiceName());

          if (found) {
            setConfigService(found.ServiceName);
          }
        }

        const remote = configServices.find((x) => x.GetServiceName() === RemotesService.ServiceName);
        const diagnostic = configServices.find((x) => x.GetServiceName() === DiagnosticService.ServiceName);
        const firmware = configServices.find((x) => x.GetServiceName() === FirmwareService.ServiceName);

        if (firmware) setFirmwareService(firmware as IFirmwareService);
        if (remote) setRemoteService(remote as IRemotesService);
        if (diagnostic) setDiagnosticService(diagnostic as IDiagnosticService);
      }
    }
  }, [device]);

  const goToAssignedRemotes = useCallback(() => {
    if (channel) history.push(ROUTES.DeviceRemotes(channel.ChannelId));
  }, [channel]);

  const runDiagnostic = useCallback(async () => {
    if (diagnosticService && device) {
      turnOnBackdropPopup({
        header: t('exalus.params.DiagnosticParams.Diagnostic'),
        message: t('exalus.diagnosticResult.diagnosticsProgress'),
        loader: true,
      });
      const result = await diagnosticService.RunDiagnosticAsync(device);

      handleDiagnosticResponse(result, () => {
        turnOffBackdropPopup();
        setDiagnosticResult(result as DiagnosticResult);
        setShowDiagnosticPopup(true);
      });
    }
  }, [device, diagnosticService]);

  const repairOrHide = useCallback(async () => {
    if (diagnosticService && diagnosticResult) {
      if (diagnosticResult.CanBeFixed) {
        turnOnBackdrop();
        const result = await diagnosticService.RepairDeviceAsync(diagnosticResult);

        handleDiagnosticResponse(result, () => {
          toastSuccess({ content: t('exalus.diagnosticResult.repaired') });
          turnOffBackdrop();
          setShowDiagnosticPopup(false);
        });
      } else {
        setShowDiagnosticPopup(false);
      }
    }
  }, [diagnosticResult, diagnosticService]);

  const RemotesButton = useMemo(() => {
    return remoteService ? (
      <ArrowButton
        className="m-b-24"
        title={t('exalus.params.RemotesParams.AssignedTransmitters')}
        onClick={goToAssignedRemotes}
      />
    ) : null;
  }, [remoteService]);

  const DiagnosticButton = useMemo(() => {
    return diagnosticService ? (
      <ArrowButton className="m-t-24" title={t('exalus.params.DiagnosticParams.Diagnostic')} onClick={runDiagnostic} />
    ) : null;
  }, [diagnosticService]);

  const UserManualButton = useMemo(() => {
    return supportedDevice ? (
      <ArrowButton
        className="m-t-24"
        title={ts('userManual')}
        onClick={() => history.push(ROUTES.UserManual(device?.ModelGuid || ''))}
      />
    ) : null;
  }, [supportedDevice]);

  const DiagnosticResultPopup = useMemo(() => {
    if (diagnosticResult) {
      return (
        <DialogConfirmation
          show={showDiagnosticPopup}
          setShow={setShowDiagnosticPopup}
          header={t('exalus.params.DiagnosticParams.Diagnostic')}
          content={t(`exalus.diagnosticResult.${DeviceDiagnosticsStatus[diagnosticResult.DeviceDiagnosticsStatus]}`)}
          primaryBtnText={diagnosticResult.CanBeFixed ? t('exalus.diagnosticResult.repair') : tc('buttons.close')}
          {...(diagnosticResult.CanBeFixed ? { secondaryBtnText: tc('buttons.cancel') } : {})}
          primaryBtnAction={repairOrHide}
          secondaryBtnAction={() => setShowDiagnosticPopup(false)}
        />
      );
    }

    return null;
  }, [showDiagnosticPopup, diagnosticResult]);

  return {
    configService,
    RemotesButton,
    DiagnosticButton,
    UserManualButton,
    DiagnosticResultPopup,
    firmwareService,
    showGetFacadeParamsError,
    handleGetBlindParamsResponse,
    handleSubmitResponse,
    handleGetParamsResponse,
    handleGetSwitchParamsResponse,
    handleGetFacadeParamsResponse,
  };
};
