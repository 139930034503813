import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DeviceType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IconChevron } from '../../../../../../../components';
import { ChannelBox } from '../../../../../components/channel/box';
import { DeviceIcon } from '../../../../../components/device/icon';
import { DeviceChannelsCheck } from '../../../types';
import './index.scss';

type DeviceChannelsGroupedProps = {
  deviceChannels: DeviceChannelsCheck;
  groupLabel: string;
  handleChannelOnChange: (id: string, groupLabel: string) => void;
  visibility?: boolean;
};

export const DeviceChannelsGrouped: React.FC<DeviceChannelsGroupedProps> = ({
  deviceChannels,
  groupLabel,
  handleChannelOnChange,
  visibility,
}) => {
  const { t } = useTranslation('common');
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleExpand = useCallback(() => setExpanded(!expanded), [expanded]);

  const onChannelToggle = (id: string) => {
    handleChannelOnChange(id, groupLabel);
  };

  return (
    <div className="grouped-device-channels">
      <div className="driver m-b-0" onClick={handleExpand}>
        <div className="driver__link">
          <div className="driver__header-icon">
            <DeviceIcon iconType={deviceChannels.device.IconType} />
          </div>
          <h4 className="driver__header">
            <div className="driver__header-title">
              <div className="driver__header-title-name text-ellipsis">{deviceChannels.device.Name}</div>
              <div className="driver__header-title-type">
                {`${t(`types.exalus.${DeviceType[deviceChannels.device.DeviceType]}`)} ${
                  deviceChannels.device.Model || ''
                }`}
              </div>
            </div>
          </h4>
        </div>
        <div className="arrow">
          <IconChevron direction={!expanded ? 'down' : 'up'} />
        </div>
      </div>
      {expanded && (
        <ul className={classNames('grouped-channels', { expanded: expanded })}>
          {deviceChannels.channels.map((checkChannel) => (
            <li key={checkChannel.channel.ChannelId}>
              <ChannelBox
                channel={checkChannel.channel}
                isListItem
                isCheckbox
                onChannelToggle={onChannelToggle}
                checked={checkChannel.checked}
                disableRedirect
                showIsHidden={checkChannel.channel.IsHidden && !visibility}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
