import React from 'react';
import * as dndCore from '@dnd-kit/core';
import { DragAndDrop, dragOverlayStyle, InstallationItem, InstallationItemSortable } from '../../../../components';
import { ChangeInstallationsOrderItemInput } from '../../../../data-access/gql-types/graphql';
import { InstallationSortable } from '../../../../types';
import { useListSortable } from '../../hooks/use-list-sortable';

export type InstallationListSortableProps = {
  sortedInstallationList: InstallationSortable[];
  setSortedInstallationList: (data: InstallationSortable[]) => void;
  setChangeInstallationVariables: (value: ChangeInstallationsOrderItemInput[]) => void;
  setIsValid: (value: boolean) => void;
};

export const InstallationListSortable: React.FC<InstallationListSortableProps> = (props) => {
  const { onDragStart, onDragEnd, dragOverlayInstallation } = useListSortable(props);

  return (
    <DragAndDrop onDragStart={onDragStart} onDragEnd={onDragEnd} items={props.sortedInstallationList}>
      {props.sortedInstallationList.map((userInstallation: InstallationSortable) => (
        <InstallationItemSortable key={userInstallation.id} installation={userInstallation.installation} />
      ))}
      <dndCore.DragOverlay>
        {dragOverlayInstallation && (
          <InstallationItem isDraggable style={dragOverlayStyle} installation={dragOverlayInstallation.installation} />
        )}
      </dndCore.DragOverlay>
    </DragAndDrop>
  );
};
