import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Header, IconSortHandle, NavHeader } from '../../../../components';
import { IconSearch } from '../../../../components/icons/icon-search';
import { useInstallationListHeader } from '../../hooks/use-installation-list-header';
import { SearchBar } from '../search-bar';
import './index.scss';

type InstallationListHeaderProps = {
  toggleSort: () => void;
  sortMode: boolean;
  searchVisible: boolean;
  setSearchVisible: Dispatch<SetStateAction<boolean>>;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
};

export const InstallationListHeader: React.FC<InstallationListHeaderProps> = ({
  sortMode,
  toggleSort,
  setSearchVisible,
  searchText,
  searchVisible,
  setSearchText,
}) => {
  const { t } = useTranslation('installation');
  const { sortButton, goToDashboard } = useInstallationListHeader();

  const openSearchBar = () => {
    setSearchText('');
    setSearchVisible(true);
  };

  return (
    <>
      <NavHeader isIconClose onClick={goToDashboard}>
        <button className="installation-list__btn-handle" onClick={openSearchBar}>
          <IconSearch />
        </button>
        {sortButton && (
          <button className="installation-list__btn-handle" onClick={toggleSort}>
            <IconSortHandle className={classNames({ 'icon-sort-handle--active': sortMode })} />
          </button>
        )}
      </NavHeader>
      <Header title={t('installationList')} />
      <SearchBar
        searchVisible={searchVisible}
        setSearchVisible={setSearchVisible}
        searchText={searchText}
        setSearchText={setSearchText}
      />
    </>
  );
};
