import { Dispatch, SetStateAction } from 'react';
import { NativeBluetoothDeviceInfo } from 'lavva.webbluetooth';
import { SelectOptionType } from '../../../components';
import { FindWebBluetoothDevicesView } from '../utils/FindWebBluetoothDevicesView';

export type AddDeviceHook = {
  addDevice: () => void;
};

export type BluetoothProviderState = {
  deviceNameBT: string;
  bluetoothData: BluetoothData;
  deviceConnected: boolean;
  identifing: boolean;
  wifiList: SelectOptionType<string>[];
  typeOptions: SelectOptionType<string>[];
  wifiLoading: boolean;
  isAlreadyAdded: boolean;
  checkList: string[];
  registerErrorMessage: string;
  redoRegisteringDisabled: boolean;
  updateBluetoothData: (obj: Record<string, string | string[] | boolean | DiagnosticPayload | null>) => void;
  checkIfConnected: () => void;
  searchBluetooth: (custom: boolean) => void;
  getWifiNetworks: () => void;
  getDeviceSettings: () => void;
  identifyDevice: () => void;
  resetBluetoothData: () => void;
  disconnect: () => void;
  releaseLock: () => void;
  setRedoRegisteringDisabled: Dispatch<SetStateAction<boolean>>;
  registerDevice: (ssid: string, password: string) => void;
  getKnowsWifiNetworks: () => void;
  updateByWifi: () => void;
  setWifi: (ssid: string, password: string) => void;
  removeWifi: (ssid: string) => void;
  updatePayload: UpdateData;
  percentage: number;
  isCustomBluetooth: boolean;
  foundDevices: NativeBluetoothDeviceInfo[];
  dialogScanning: boolean;
  scanning: boolean;
  setDialogScanning: Dispatch<SetStateAction<boolean>>;
  handleDeviceConnect: (device: NativeBluetoothDeviceInfo) => void;
  WebBluetooth: FindWebBluetoothDevicesView;
  updateWifiData: (ssid: string, password: string) => void;
  returnToStartPage: () => void;
  errorCode: string;
  advancedSettings: AdvancedSettings | null;
};

export type BluetoothData = {
  ssid: string;
  password: string;
  name: string;
  deviceName: string;
  mac: string;
  type: string;
  identifyIsAvailable: boolean;
  isAlreadyAdded: boolean;
  diagnostic: DiagnosticPayload | null;
  subTypes: string[];
};

export enum BluetoothMethodRequest {
  Identify = 'Identify',
  GetSettings = 'GetSettings',
  SendSuccessConfirmation = 'SendSuccessConfirmation',
  GetWiFiNetworks = 'GetWiFiNetworks',
  GetKnownWifis = 'GetKnownWifis',
  RegisterLavva = 'RegisterLavva',
  UpdateByWifi = 'UpdateByWifi',
  SetWifi = 'SetWifi',
  RemoveWifi = 'RemoveWifi',
}

export enum BluetoothMethodResponse {
  Settings = 'Settings',
  WiFiNetworks = 'WiFiNetworks',
  KnownWifis = 'KnownWifis',
  RegisterLavvaResponse = 'RegisterLavvaResponse',
  RegisterLavvaProgress = 'RegisterLavvaProgress',
  UpdateByWifiResponse = 'UpdateByWifiResponse',
  SetWifiResponse = 'SetWifiResponse',
  RemoveWifiResponse = 'RemoveWifiResponse',
  AddingDeviceEnable = 'AddingDeviceEnable',
}

export type SettingsData = {
  method: BluetoothMethodResponse.Settings;
  payload: SettingsPayload;
};

export type WiFiNetworksData = {
  method: BluetoothMethodResponse.WiFiNetworks;
  payload: WiFiNetworksPayload;
};

export type RegisterLavvaResponseData = {
  method: BluetoothMethodResponse.RegisterLavvaResponse;
  payload: RegisterLavvaResponsePayload;
};

export type DiagnosticPayload = {
  wifiIsConnected: boolean;
  defaultSsid: string;
  ip: string;
  internetConnected: boolean;
  lavvaCloudConnected: boolean;
};

type SubGateTypeInfo = {
  availableSubTypes: string[];
  maxSubTypes: number;
};

export type AdvancedSettings = {
  currentSubTypes: string[];
  subTypesInfo: Record<string, SubGateTypeInfo>;
};

export type SettingsPayload = {
  name: string;
  currentType: string;
  mac: string;
  types: string[];
  isAlreadyAdded?: boolean;
  identifyIsAvailable?: boolean;
  diagnostic?: DiagnosticPayload;
  advancedSettings?: AdvancedSettings;
};

export type WifiData = {
  RSSI: string;
  SSID: string;
  supportedAuth?: boolean;
};

export type WiFiNetworksPayload = {
  wifiList: WifiData[];
};

export type WiFiKnowsNetworksPayload = {
  wifiList: string[];
};

export type RegisterLavvaResponsePayload = {
  status: boolean;
  deviceId: string;
  errorCode?: string;
};

export type RegisterLavvaProgressPayload = {
  step: RegisterLavvaProgressEnum;
};

export type UpdateByWifiErrorPayload = {
  status: boolean;
  errorCode: string;
};

export type UpdatePayload = {
  version: string;
  progress: number;
};

export type UpdateData = UpdatePayload & {
  status: boolean | null;
  version: string;
  progress: number;
  errorCode?: string;
};

export type UpdateByWifiOkPayload = {
  update: UpdatePayload;
};

export type UpdateByWifiSuccessPayload = {
  status: boolean;
};

export type RemoveWifiPayload = {
  status: boolean;
};

export type AddingDeviceEnablePayload = {
  status: boolean;
};

export type SetWifiPayload = {
  status: boolean;
};

export type IdentifyFormValues = {
  name: string;
  type: string;
  subTypes: string[];
};

export type NetworkFormValues = {
  ssid: string;
  password: string;
};

export type RegisterLavvaRequestBody = {
  method: BluetoothMethodRequest;
  payload: RegisterLavvaRequestBodyPayload;
};

export type RegisterLavvaRequestBodyPayload = {
  wifi: {
    ssid: string;
    password: string;
  };
  name: string;
  type: string;
  installationId: string;
  discoveryUrl: string;
  settings?: {
    subTypes: string[];
  };
};

export enum RegisterLavvaProgressEnum {
  CONNECTING_TO_WIFI = 'CONNECTING_TO_WIFI',
  GENERATING_CERT = 'GENERATING_CERT',
  REGISTRATION_IN_CLOUD = 'REGISTRATION_IN_CLOUD',
  SAVING_SETTINGS = 'SAVING_SETTINGS',
}

export enum DriveFlowType {
  Remote = 'remote',
  Drive = 'drive',
}


export enum OSSystem {
  Desktop = 'DESKTOP',
  Ios = 'IOS',
  Android = 'ANDROID',
}

export enum GateTypePrefix {
  SmartAWSC = 'smartAWSC',
  SmartAwGate = 'smartAW-Gate',
}
