import React from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ControlWrapper } from '../../../../../../components';
import { IconPhone } from '../../../../../../components/icons/scenario';
import '../components/details-list/index.scss';
import ListSection from '../components/list-section';

type EntryPhoneChartProps = {
  channel: IDeviceChannel;
};

const EntryPhoneChart: React.FC<EntryPhoneChartProps> = () => (
  <ControlWrapper className="control-wrapper--full-space no-center">
    <div className="list-details m-t-24">
      <ListSection
        section={{
          date: 'Piątek, 27.10',
          items: [
            { icon: <IconPhone />, state: 'Odebrane', period: '27.10.23, 10:05' },
            { icon: <IconPhone />, state: 'Nieodebrane', period: '27.10.23, 12:31' },
            { icon: <IconPhone />, state: 'Odebrane', period: '27.10.23, 17:12' },
          ],
        }}
      />
      <ListSection
        section={{
          date: 'Sobota, 28.10',
          items: [
            { icon: <IconPhone />, state: 'Nieodebrane', period: '28.10.23, 09:01' },
            { icon: <IconPhone />, state: 'Nieodebrane', period: '28.10.23, 11:58' },
            { icon: <IconPhone />, state: 'Odebrane', period: '28.10.23, 22:21' },
          ],
        }}
      />
    </div>
  </ControlWrapper>
);

export default EntryPhoneChart;
