import React from 'react';
import './index.scss';

type IconCirclePadlockCloseProps = {
  big?: boolean;
};

export const IconCirclePadlockClose: React.FC<IconCirclePadlockCloseProps> = ({ big }) => (
  <>
    {!big ? (
      <svg
        className="icon-circle-padlock-close"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.75" y="0.75" width="30.5" height="30.5" rx="15.25" stroke="#15141D" strokeWidth="1.5" />
        <rect
          x="10.9998"
          y="13.1763"
          width="11.0002"
          height="8.41162"
          rx="4"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.941 11.5589C12.941 9.59337 14.5344 8 16.4999 8V8C18.4654 8 20.0588 9.59337 20.0588 11.5589V13.1764H12.941V11.5589Z"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.4998 15.7646V18.9999"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ) : (
      <svg
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon-circle-padlock-close"
      >
        <rect x="1.5" y="1.5" width="38" height="38" rx="19" stroke="#15141D" strokeWidth="2" />
        <rect
          x="13"
          y="16.8574"
          width="14.572"
          height="11.143"
          rx="4"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5719 14.7145C15.5719 12.1107 17.6826 10 20.2864 10V10C22.8901 10 25.0009 12.1107 25.0009 14.7145V16.8572H15.5719V14.7145Z"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.2859 20.2856V24.5714"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
  </>
);
