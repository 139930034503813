import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogConfirmation } from '../../../../components';

type LocationPermissionDialogProps = {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  handleAction: () => void;
};

export const LocationPermissionDialog: React.FC<LocationPermissionDialogProps> = ({
  show,
  setShow,
  handleAction,
}) => {
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');

  return (
    <DialogConfirmation
      show={show}
      setShow={setShow}
      header={t('locationPermissions.header')}
      content={t('locationPermissions.content')}
      primaryBtnText={tc('buttons.allow')}
      secondaryBtnText={tc('buttons.refuse')}
      primaryBtnAction={handleAction}
      secondaryBtnAction={() => setShow(false)}
    />
  );
};
