import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputPin } from '../../../../../../components';

type PinProps = {
  pin: string;
};

export const Pin: React.FC<PinProps> = ({ pin }) => {
  const { control } = useFormContext();
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');

  return (
    <Controller
      name="pin"
      control={control}
      rules={{
        required: {
          value: true,
          message: tc('isRequired'),
        },
        minLength: {
          value: 4,
          message: tc('isRequired'),
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <InputPin
          defaultValue={pin}
          value={pin}
          label={t('exalus.pin')}
          onChange={field.onChange}
          name="pin"
          hasError={!!error}
        />
      )}
    />
  );
};
