import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import LavvaDetailsWrapper from '../../../components/details-wrapper';
import { AggregateVectorParameterTypeInternal, MeasurementChannelKind } from '../../../data-access/gql-types/graphql';
import { useApi, useBackdropContext } from '../../../hooks';
import { ChannelType, ChannelMeterType } from '../../../types';
import { MeterAnalysis } from './analysis';
import { MeterCurrent } from './current';
import { MeterMeasurements } from './measurements';
import { analizeAvailableMeasurementParameters } from './utils';

type ChannelDetailsMeterProps = {
  channel: ChannelType;
};

const ChannelDetailsMeter: React.FC<ChannelDetailsMeterProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { carouselDisabled } = useBackdropContext();
  const { convertNumberToMeasurement } = useApi();

  const analizeTabShouldDisplay = useMemo(() => {
    const supportedMappedParams = (channel.data as ChannelMeterType).supportedAggregateVectorParameters.map(
      (param) =>
        convertNumberToMeasurement(MeasurementChannelKind.Meter)(
          'aggregates',
          param,
        ) as AggregateVectorParameterTypeInternal,
    );

    return supportedMappedParams.some((param) => analizeAvailableMeasurementParameters.includes(param));
  }, [channel]);

  return (
    <LavvaDetailsWrapper
      preventSwipeEvent={carouselDisabled}
      tabs={[
        { label: t('currently') },
        { label: t('measurement') },
        ...(analizeTabShouldDisplay ? [{ label: t('analysis') }] : []),
      ]}
      additionalTabs={
        analizeTabShouldDisplay
          ? [<MeterMeasurements key="2" channel={channel} />, <MeterAnalysis key="3" channel={channel} />]
          : [<MeterMeasurements key="2" channel={channel} />]
      }
    >
      <MeterCurrent channel={channel} />
    </LavvaDetailsWrapper>
  );
};

export default ChannelDetailsMeter;
