import { UserInstallation } from '../../../data-access/gql-types/graphql';

export type ThemeUnion = 'light' | 'dark' | 'device';

export enum DisplayedMessagesEnum {
  CHANNEL_COVER_FAVORITE_BUTTON_TIP = 'DEVICE_COVER_FAVORITE_BUTTON_TIP',
}

export type LocalData = {
  installationList: UserInstallation[];
};

export type StorageType = {
  displayedMessagesList: DisplayedMessagesEnum[];
  theme: ThemeUnion | null;
  wasIntroduced: boolean;
  isListView: boolean;
  meterAdvancedParameters: boolean;
  isChannelsListView: boolean;
  lastActiveGroupId: string | null;
  lastActiveTabInChannelList: number | null;
  lastActiveTabInDeviceList: string | null;
  lastActiveActionTab: string | null;
  bluetoothNetwork: string | null;
  vibration: boolean;
  remindNative: Date | null;
  remindCreateAccount: Date | null;
  updateDevicesMemory: Record<string, Date>;
  localData: LocalData;
};

// It should never have happened
if (!window) {
  console.error('Window Local Storage Missing.  No data will be stored locally.');
}

const KEY = 'ZAMEL_APP_DATA_STORAGE';

const emptyData: StorageType = {
  displayedMessagesList: [],
  theme: null,
  wasIntroduced: false,
  isListView: false,
  meterAdvancedParameters: false,
  isChannelsListView: false,
  lastActiveGroupId: null,
  lastActiveTabInChannelList: null,
  lastActiveTabInDeviceList: null,
  lastActiveActionTab: null,
  bluetoothNetwork: null,
  vibration: true,
  remindNative: null,
  remindCreateAccount: null,
  updateDevicesMemory: {},
  localData: {
    installationList: [],
  },
};

let data: StorageType = { ...emptyData };

const dataString = window.localStorage.getItem(KEY);

if (dataString) {
  try {
    const parsedData: StorageType = JSON.parse(dataString);
    if (parsedData) {
      data = parsedData;
    }
  } catch (e) {
    console.error(`LocalStorage value of key: "${KEY}" is not a valid JSON.`);
    // Temporary option of clearing cache on error
    window.localStorage.removeItem(KEY);
    data = emptyData;
  }
}

export const get = (): StorageType => {
  return data;
};

export const set = (newData: StorageType): void => {
  data = newData;
  window.localStorage.setItem(KEY, JSON.stringify(data));
};

export const getItem = <Key extends keyof StorageType>(key: Key): StorageType[Key] => {
  return data[key];
};

export const setItem = <Key extends keyof StorageType>(key: Key, value: StorageType[Key]): void => {
  data[key] = value;
  window.localStorage.setItem(KEY, JSON.stringify(data));
};

export const clear = (): void => {
  window.localStorage.removeItem(KEY);
  data = emptyData;
};

export const setLocalItem = <Key extends keyof LocalData>(key: Key, value: LocalData[Key]): void => {
  const oldData = window.localStorage.getItem(KEY);

  if (oldData) {
    const oldDataParsed: StorageType = JSON.parse(oldData);
    if (!oldDataParsed.localData) {
      oldDataParsed.localData = emptyData.localData;
    } else {
      oldDataParsed.localData = {
        ...oldDataParsed.localData,
        [key]: value,
        [key]: value,
      };
    }
    window.localStorage.setItem(KEY, JSON.stringify(oldDataParsed));
  }
};

export const getLocalItem = <Key extends keyof LocalData>(key: Key): LocalData[Key] | null => {
  const data = window.localStorage.getItem(KEY);
  if (data) {
    const dataParsed: StorageType = JSON.parse(data);
    if (dataParsed.localData) return dataParsed.localData[key];
  }

  return null;
};
