import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ManualCalibration,
  TimeCalibration,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/BlindConfigurations/BlindConfigParameters';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { CustomBackdrop, Header, NavHeader, Page, SubmitButton } from '../../../../../../../components';
import { usePreventBack } from '../../../../../../../hooks/navigation-guard/use-navigation-guard';
import { ROUTES } from '../../../../../../../routes';
import { toastError, toastInfo } from '../../../../../../../utils/toast';
import { ChannelBoxIcon } from '../../../../../components/channel/box-icon';
import { useHandleDataFrameErrors } from '../../../../../hooks';
import { useConfigurationService } from '../../../../device-details/hooks/use-configuration-service';
import { useCalibration } from '../../common/hooks/use-calibration';
import './index.scss';

type Srp22CalibrationStep2_3Props = {
  setStep: Dispatch<SetStateAction<number>>;
  step: number;
  manualService?: ManualCalibration;
};

const Srp22CalibrationStep2_3: React.FC<Srp22CalibrationStep2_3Props> = ({ setStep, step, manualService }) => {
  const { t: tc } = useTranslation('common');
  const { channel, history, backdropOpen, t, turnOnBackdrop, turnOffBackdrop } = useCalibration();
  const { handleGetBlindParamsResponse } = useConfigurationService();
  const [calibrationService, setCalibrationService] = useState<TimeCalibration | undefined>(undefined);
  const { handleError } = useHandleDataFrameErrors();
  const viewType = step === 2 ? 'B' : 'C';
  usePreventBack(backdropOpen ? t('exalus.params.ZfBidiParams.calibrationFlow.noBackMessage') : '');

  useEffect(() => {
    const getCalibrationService = async () => {
      if (manualService) {
        turnOnBackdrop();
        const result =
          step === 2
            ? await manualService.StepBCalibrateOpeningTime()
            : await manualService.StepCCalibrateClosingTime();

        handleGetBlindParamsResponse<TimeCalibration>(result, () => {
          setCalibrationService(result as unknown as TimeCalibration);
          turnOffBackdrop();
        });
      }
    };

    getCalibrationService();
  }, [manualService]);

  useEffect(() => {
    if (!manualService) history.replace(ROUTES.Srp22Calibration(channel?.ChannelId));
  }, [manualService]);

  const handleClick = useCallback(async () => {
    if (channel && calibrationService) {
      turnOnBackdrop();
      const result = await calibrationService.StepBConfirmBlindPositionAsync();

      if (result === Status.OK) {
        if (step === 3) {
          if (manualService) {
            const endResult: Status = await manualService?.StepDSaveCalibrationAsync();

            if (endResult === Status.OK) {
              setStep(4);
              history.push(`${ROUTES.Srp22Calibration(channel.ChannelId)}?step=4`);
            } else handleError(endResult);
          }
        } else {
          setStep(3);
          history.push(`${ROUTES.Srp22Calibration(channel.ChannelId)}?step=3`);
        }
      } else {
        handleError(result);
      }

      turnOffBackdrop();
    } else {
      toastError({ content: tc('errors.somethingWentWrong') });
    }
  }, [channel, step]);

  const handleAction = useCallback(async () => {
    if (calibrationService) {
      const result = await calibrationService.StepAInitBlindMoveAsync();

      if (result === Status.OK) {
        toastInfo({ content: t(`exalus.params.SrpParams.calibrationFlow.step${viewType}Executed`) });
      } else {
        handleError(result);
      }

      turnOffBackdrop();
    }
  }, [calibrationService, viewType]);

  return (
    <Page
      className="calibration-flow"
      isStickyHeader
      whiteContent
      header={
        <>
          <NavHeader />
          <Header title={t('exalus.calibration')} />
        </>
      }
    >
      {channel && <ChannelBoxIcon channel={channel} className="blind-icon" />}
      <p className="info m-t-40">{t(`exalus.params.SrpParams.calibrationFlow.step${viewType}Info`)}</p>
      <button className="button button--secondary m-t-24 m-b-48" onClick={handleAction}>
        {t(`exalus.params.SrpParams.calibrationFlow.step${viewType}Action`)}
      </button>
      <p className="info--sub tiny m-b-24">
        {t(`exalus.params.SrpParams.calibrationFlow.step${viewType}Confirmation`)}
      </p>
      <SubmitButton type="button" onClick={handleClick} tranparent>
        {t('exalus.params.SrpParams.calibrationFlow.next')}
      </SubmitButton>
      {!channel && <CustomBackdrop loading />}
    </Page>
  );
};

export default Srp22CalibrationStep2_3;
