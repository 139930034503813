import React from 'react';
import { Button } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useBackdropContext } from '../../hooks';
import './index.scss';

type CustomBackdropProps = {
  loading?: boolean;
  btnTitle?: string;
  onClick?: () => void;
};

export const CustomBackdrop: React.FC<CustomBackdropProps> = ({ loading, btnTitle, onClick }) => {
  const { backdropOpen } = useBackdropContext();

  return (
    <Backdrop classes={{ root: 'backdrop' }} open={backdropOpen || !!loading}>
      <div className="backdrop-content">
        <CircularProgress color="inherit" size={!(btnTitle && onClick) ? 70 : 90} />
        {btnTitle && onClick && (
          <Button className="circle-button" onClick={onClick}>
            {btnTitle}
          </Button>
        )}
      </div>
    </Backdrop>
  );
};
