import { useEffect, useMemo, useState } from 'react';
import { groupBy } from 'lodash';
import { useSubscription } from '@apollo/client';
import {
  MeasurementChannelKind,
  MeasurementSubscriptionResponse,
  OnMeasurementSubscription,
  OnMeasurementSubscriptionVariables,
  OptimizerModeInternal,
  OptimizerScalarParameterTypeInternal,
  OptimizerVectorParameterTypeInternal,
} from '../../../../data-access/gql-types/graphql';
import { ON_MEASUREMENT } from '../../../../data-access/subscriptions/on-measurement';
import { useApi, useInstallation } from '../../../../hooks';
import { ChannelOptimizerType } from '../../../../types/channel/optimizer';
import { CurrentHookParams } from '../../meter/current/types';
import { useOptimizerModes } from '../configuration/hooks/use-optimizer-modes';
import { OptimizerCurrentDataMap } from '../current/types';

const kind = MeasurementChannelKind.Optimizer;

export const useOptimizerCurrent = ({ channel }: CurrentHookParams) => {
  const { convertNumberToMeasurement } = useApi();
  const { workModeOptions } = useOptimizerModes();
  const { skipLavvaFetch } = useInstallation();
  const [selectedWorkMode, setSelectedWorkMode] = useState<OptimizerModeInternal>(OptimizerModeInternal.TestMode);
  const { data } = useSubscription<OnMeasurementSubscription, OnMeasurementSubscriptionVariables>(ON_MEASUREMENT, {
    variables: {
      input: {
        channelId: channel?.id,
        kind,
        scalarParameterTypes: (channel?.data as ChannelOptimizerType)?.supportedScalarParameters,
        vectorParameterTypes: (channel?.data as ChannelOptimizerType)?.supportedVectorParameters,
        indices: [],
      },
    },
    skip: !channel || skipLavvaFetch,
  });

  useEffect(() => {
    if ((channel?.data as ChannelOptimizerType)?.payload) {
      setSelectedWorkMode((channel?.data as ChannelOptimizerType).payload?.mode || OptimizerModeInternal.TestMode);
    }
  }, [channel?.data]);

  const optimizerData = useMemo(() => {
    if ((data?.onMeasurement.data as MeasurementSubscriptionResponse)?.vectorMeasurements) {
      const mapData = (data?.onMeasurement.data as MeasurementSubscriptionResponse).vectorMeasurements.map((x) => ({
        index: x.index,
        value: x.value,
        type: convertNumberToMeasurement(MeasurementChannelKind.Optimizer)('vectors', x.type),
      }));

      return groupBy(mapData, (x) => x.type) as Record<OptimizerVectorParameterTypeInternal, OptimizerCurrentDataMap[]>;
    }
  }, [data]);

  const temperature = useMemo(() => {
    return ((data?.onMeasurement.data as MeasurementSubscriptionResponse)?.scalarMeasurements || []).find(
      (x) =>
        convertNumberToMeasurement(MeasurementChannelKind.Optimizer)('scalars', x.type) ===
        OptimizerScalarParameterTypeInternal.Temperature,
    )?.value;
  }, [data]);

  const workMode = useMemo(() => {
    return workModeOptions.find((x) => x.value === selectedWorkMode)?.label || '';
  }, [selectedWorkMode]);

  const measurementFullData = useMemo(
    () => data?.onMeasurement.data as MeasurementSubscriptionResponse | undefined,
    [data],
  );

  return {
    optimizerData,
    measurementFullData,
    workMode,
    temperature,
  };
};
