import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ChannelTypeInternal,
  DeviceConnectionState,
  VersionedFeatureType,
} from '../../../data-access/gql-types/graphql';
import { useChannelsState, useDevicesAndChannels } from '../../../hooks';
import { useVersionMap } from '../../../hooks/api/version-map';
import { useDevicesAndChannelsContext } from '../../../hooks/devices-and-channels/provider';

export const useDeviceSettings = () => {
  const { t } = useTranslation('channel-list');
  const { t: ts } = useTranslation('device-settings');
  const { checkSupport, versionMapData } = useVersionMap();
  const { deviceId } = useParams<{ deviceId: string; channelId: string }>();
  const { device, channelList } = useDevicesAndChannelsContext();
  const { deviceChannels } = useDevicesAndChannels({ deviceId });
  const { channelState } = useChannelsState();

  const inputs = useMemo(() => {
    if (deviceId) {
      const channels = channelList.filter(
        (x) => x.deviceId === deviceId && x.data.type === ChannelTypeInternal.GenericInput,
      );

      return channels;
    }

    return [];
  }, [deviceId, channelList]);

  const isDeviceInRecovery = useMemo(
    () =>
      deviceChannels.some(
        (channel) => channelState[channel.id]?.deviceConnectionState === DeviceConnectionState.Recovery,
      ),
    [deviceChannels, channelState],
  );

  const bindingsEnabled = useMemo(
    () =>
      checkSupport({
        feature: VersionedFeatureType.DeviceBindingsV2,
        supportKey: 'Core',
        currentVersion: device?.payload.currentFirmwareVersion,
      }),
    [device?.payload.currentFirmwareVersion, inputs.length, versionMapData],
  );

  const rssiSupported = useMemo(
    () =>
      checkSupport({
        feature: VersionedFeatureType.GetRssi,
        supportKey: 'Core',
        currentVersion: device?.payload.currentFirmwareVersion,
      }),
    [device?.payload.currentFirmwareVersion, versionMapData],
  );

  const tabs = [
    { label: t('configuration') },
    ...(!isDeviceInRecovery ? [{ label: t('channels') }] : []),
    ...(inputs.length && !isDeviceInRecovery ? [{ label: t('inputs') }] : []),
    ...(inputs.length && bindingsEnabled && !isDeviceInRecovery ? [{ label: ts('bindings.bindings') }] : []),
  ];

  return {
    inputs,
    bindingsEnabled,
    rssiSupported,
    tabs,
  };
};
