import { rgbToHex } from '@mui/material';
import {
  DeviceConnectionState,
  LightChannelColorStateResponse,
  LightStateChangedPayload,
} from '../../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../../hooks';
import { ChannelLightStateType } from '../../../../types';

export const useLavvaDeviceLightUpdate = () => {
  const { setChannelState } = useChannelsState();

  const updateLavvaLightState = ({
    channelId,
    toggleValue,
    brightness,
    temperature,
    color,
  }: LightStateChangedPayload) => {
    setChannelState((oldState) => {
      const newState = { ...oldState };
      newState[channelId] = {
        ...newState[channelId],
        deviceConnectionState: DeviceConnectionState.Connected,
        toggleValue,
        brightness,
        temperature,
        color: color
          ? ({
              ...color,
              hex: rgbToHex(`rgb(${color.r}, ${color.g}, ${color.b})`),
            } as LightChannelColorStateResponse)
          : null,
      } as ChannelLightStateType;

      return newState;
    });
  };

  return {
    updateLavvaLightState,
  };
};
