import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import {
  Notification,
  NotificationCode,
  NotificationGetAllQueryVariables,
  NotificationMarkAsDisplayedMutation,
  NotificationMarkAsDisplayedMutationVariables,
  NotificationMarkAsHiddenMutation,
  NotificationMarkAsHiddenMutationVariables,
  Query,
} from '../../../data-access/gql-types/graphql';
import {
  NOTIFICATION_MARK_AS_DISPLAYED,
  NOTIFICATION_MARK_AS_HIDDEN,
} from '../../../data-access/mutations/notification';
import { NOTIFICATION_GET_ALL } from '../../../data-access/queries/notification';
import { ON_NOTIFIED } from '../../../data-access/subscriptions/on-notified';
import { NotificationType, NotificationCallback } from '../../../types';
import { isDev, isLocalhost } from '../../../utils/helpers/environment';
import { isLocalApp } from '../../../utils/helpers/local-app';
import { toastError } from '../../../utils/toast';
import { useDevicesAndChannelsContext } from '../../devices-and-channels/provider';
import { useInstallation } from '../../installation';
import { useRefetchData } from '../../refresh-data';
import { useBackdropContext } from '../../use-backdrop';
import { useUserPermissionsContext } from '../../user-permissions/provider';
import { addedOrRemovedOrExpired, mapNotification } from './utils';

export const useNotification = () => {
  const { t } = useTranslation('notifications');
  const { t: tc } = useTranslation('common');
  const { setSelectedInstallationId, selectedInstallationId } = useInstallation();
  const [notificationList, setNotificationList] = useState<NotificationType[]>([]);
  const { refetchAll } = useRefetchData();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { refetchUserMe } = useUserPermissionsContext();
  const { channelList } = useDevicesAndChannelsContext();
  const { data } = useSubscription(ON_NOTIFIED);
  const { data: notificationsData, loading } = useQuery<Query, NotificationGetAllQueryVariables>(NOTIFICATION_GET_ALL, {
    fetchPolicy: 'no-cache',
    skip: isLocalApp,
    // onError: () => toastError({ content: tc('errors.downloadData') }),
  });
  const [notificationMarkAsDisplayed] = useMutation<
    NotificationMarkAsDisplayedMutation,
    NotificationMarkAsDisplayedMutationVariables
  >(NOTIFICATION_MARK_AS_DISPLAYED);
  const [notificationMarkAsHidden] = useMutation<
    NotificationMarkAsHiddenMutation,
    NotificationMarkAsHiddenMutationVariables
  >(NOTIFICATION_MARK_AS_HIDDEN);

  const markAsDisplayed = (ids: string[], callback?: NotificationCallback) => {
    turnOnBackdrop();
    notificationMarkAsDisplayed({
      variables: { correlationIds: ids },
      onCompleted: (data) => {
        if (data.markAsDisplayed) {
          const notifications = Array.from(notificationList).map((notification) => {
            if (ids.contains(notification.id)) {
              return {
                ...notification,
                wasDisplayed: true,
              };
            }

            return { ...notification };
          });

          setNotificationList(notifications);
          if (callback?.onSuccess) callback.onSuccess();
        } else {
          if (callback?.onError) callback.onError();
        }
      },
      onError: () => {
        toastError({ content: tc('errors.somethingWentWrong') });
        turnOffBackdrop();
      },
    });
  };

  const markAsHidden = (ids: string[], callback?: NotificationCallback) => {
    turnOnBackdrop();
    notificationMarkAsHidden({
      variables: { correlationIds: ids },
      onCompleted: (data) => {
        if (data.markAsHidden) {
          const notifications = Array.from(notificationList).map((notification) => {
            if (ids.contains(notification.id)) {
              return {
                ...notification,
                isHidden: true,
              };
            }

            return { ...notification };
          });

          setNotificationList(notifications);
          if (callback?.onSuccess) callback.onSuccess();
        } else {
          turnOffBackdrop();
          if (callback?.onError) callback.onError();
        }
      },
      onError: () => turnOffBackdrop(),
    });
  };

  useEffect(() => {
    if (data?.onNotified) {
      const notification = data.onNotified as Notification;
      const newNotification = mapNotification(notification, channelList, t);
      if (newNotification) setNotificationList((prev) => [newNotification, ...prev]);

      if (
        [NotificationCode.RemovedFromInstallation, NotificationCode.UserInstallationExpired].includes(notification.code)
      ) {
        const foundInstallationId = notification.params.find((x) => x.name === 'InstallationId');
        if (foundInstallationId?.value === selectedInstallationId) {
          setSelectedInstallationId(undefined);
        }
      }

      if (notification.code === NotificationCode.GateAlert) {
        const alertType = notification.params.find((x) => x.name === 'AlertType')?.value;
        const channelId = notification.params.find((x) => x.name === 'ChannelId')?.value;

        if (alertType && alertType !== 'Unknown') {
          const alias = channelList.find((x) => x.id === channelId)?.alias;

          toastError({
            content: t(`${notification.code}.gateAlerts.${alertType}`, {
              alias: alias ? ` ${alias}` : '',
            }),
          });
        }
      }

      if (isLocalhost || isDev) console.log('NOTIFICATION', notification);

      if (addedOrRemovedOrExpired.find((x) => x === notification.code)) {
        refetchAll();
        refetchUserMe();
      }
    }
  }, [data]);

  useEffect(() => {
    if (!notificationsData) return;
    const newNotifications: NotificationType[] = [];

    for (const receivedNotification of notificationsData.allNotifications.filter((x) => !x.isHidden)) {
      const notification = mapNotification(receivedNotification, channelList, t);
      if (notification) newNotifications.push(notification);
    }

    setNotificationList(uniqBy([...newNotifications, ...notificationList], 'id'));
  }, [notificationsData, channelList, t]);

  return {
    notificationList,
    notificationsLoading: loading,
    setNotificationList,
    markAsDisplayed,
    markAsHidden,
  };
};
