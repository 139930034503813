import { useQuery } from '@apollo/client';
import { VersionedFeatureType, VersionMapQuery, VersionMapQueryVariables } from '../../data-access/gql-types/graphql';
import { VERSION_MAP } from '../../data-access/queries/devices';
import { isLocalApp } from '../../utils/helpers/local-app';
import { compareVersions } from '../../utils/helpers/versions';
import { useInstallation } from '../installation';

type SupportPayload = {
  feature: VersionedFeatureType;
  supportKey: 'Core' | 'Gate' | 'Optimizer';
  currentVersion?: string | null;
};

export const useVersionMap = () => {
  const { skipLavvaFetch } = useInstallation();
  const { data } = useQuery<VersionMapQuery, VersionMapQueryVariables>(VERSION_MAP, {
    fetchPolicy: 'cache-and-network',
    skip: skipLavvaFetch || isLocalApp,
  });

  const checkSupport = ({ feature, supportKey, currentVersion }: SupportPayload) => {
    if (!currentVersion) return false;

    const core = data?.versionMap.find((x) => x.key === supportKey);
    if (core) {
      const foundFeature = core.versionedFeatures.find((x) => x.feature === feature);

      if (foundFeature) {
        const { major, minor, build } = foundFeature.minVersion;

        return compareVersions(currentVersion, `${major}.${minor}.${build}`) >= 0;
      }
    }

    return false;
  };

  return {
    versionMapData: data?.versionMap || [],
    checkSupport,
  };
};
