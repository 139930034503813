import React, { useMemo } from 'react';
import { DeviceTaskType as ChannelTaskType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { canChannelDo } from '../../../../../utils';
import GateCommonControls from '../gate-types/common/controls';
import GatePreciseControls from '../gate-types/precise/controls';

type GateControlsProps = {
  channel: IDeviceChannel;
};

const GateControls: React.FC<GateControlsProps> = ({ channel }) => {
  const isPreciseGate = useMemo(() => canChannelDo(channel, [ChannelTaskType.PreciseGateControl]), [channel]);

  return <>{!isPreciseGate ? <GateCommonControls channel={channel} /> : <GatePreciseControls channel={channel} />}</>;
};

export default GateControls;
