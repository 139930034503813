import { useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getToken, onMessage, isSupported, getMessaging } from 'firebase/messaging';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import {
  DeviceTokenMetadata,
  NotificationClientRegistration,
  NotificationsServiceErrorCode,
} from 'lavva.exalushome/build/js/Services/Notifications/IControllerNotificationsService';
import { NativeContext } from 'lavva.webview/build/js/Services/Common/ICommonService';
import { useLazyQuery, useMutation } from '@apollo/client';
import { IconLavva } from '../../components';
import {
  IntegrationType,
  PushReceiversRegistrationsQuery,
  PushReceiversRegistrationsQueryVariables,
  PushReceiverType,
  RegisterPushNotificationReceiverMutation,
  RegisterPushNotificationReceiverMutationVariables,
} from '../../data-access/gql-types/graphql';
import { REGISTER_PUSH_NOTIFICATION_RECEIVER } from '../../data-access/mutations/notification';
import { PUSH_REGISTRATIONS } from '../../data-access/queries/register';
import { environment } from '../../environment';
import { useExalusContext } from '../../modules/exalus/context';
import { useExalusNotifications } from '../../modules/exalus/hooks/notifications.hooks';
import { firebaseConfig } from '../../utils/firebase';
import { isLocalhost } from '../../utils/helpers/environment';
import { getDeviceName } from '../../utils/push-notifications';
import { toastInfo } from '../../utils/toast';
import { useInstallation } from '../installation';
import { useNativeFunctionsContext } from '../native/native-functions';
import { useProfile } from '../user';

const pushReceiverByNativeCtx = {
  [NativeContext.None]: PushReceiverType.Web,
  [NativeContext.AndroidGecko]: PushReceiverType.Android,
  [NativeContext.IosWebkit]: PushReceiverType.Ios,
};

export const usePushNotifications = () => {
  const { user } = useProfile();
  const { getNotificationToken, nativeCtx, nativeInit, notificationApiSupported } = useNativeFunctionsContext();
  const { getAllControllerNotificationTokens, registerNotificationClient } = useExalusNotifications();
  const { connected } = useExalusContext();
  const { integrationType } = useInstallation();
  const [getPushRegistrations] = useLazyQuery<
    PushReceiversRegistrationsQuery,
    PushReceiversRegistrationsQueryVariables
  >(PUSH_REGISTRATIONS, {
    fetchPolicy: 'no-cache',
  });
  const [registerPushNotificationReceiver] = useMutation<
    RegisterPushNotificationReceiverMutation,
    RegisterPushNotificationReceiverMutationVariables
  >(REGISTER_PUSH_NOTIFICATION_RECEIVER);

  const initPushNotifications = async () => {
    if (nativeCtx === null) return;

    switch (nativeCtx) {
      case NativeContext.None: {
        const isFirebaseSupported = await isSupported();
        if (!notificationApiSupported || !isFirebaseSupported) return;

        if (Notification.permission === 'default') {
          console.log({ permission: Notification.permission });

          Notification.requestPermission()
            .then((permission) => {
              if (permission === 'granted') registerTokenForWeb();
            })
            .catch((error) => console.log('Notification error:', error));
        }

        if (Notification.permission === 'granted') registerTokenForWeb();
        break;
      }
      case NativeContext.AndroidGecko:
      case NativeContext.IosWebkit: {
        registerTokenForIosOrAndroid();
        break;
      }
    }
  };

  useEffect(() => {
    if (!nativeInit || nativeCtx === null || !user.profile.email || integrationType === null) return;

    switch (integrationType) {
      case IntegrationType.Exalus: {
        if (connected) initPushNotifications();
        break;
      }
      default: {
        initPushNotifications();
        break;
      }
    }
  }, [nativeCtx, user.profile.email, nativeInit, integrationType, connected]);

  const addPushNotificationListener = async () => {
    if (isLocalhost) return;
    const isFirebaseSupported = await isSupported();
    if (!isFirebaseSupported) return;

    const app = initializeApp(firebaseConfig);

    onMessage(getMessaging(app), (payload) => {
      console.log('WEB_PUSH', payload);
      const notificationTitle = payload.notification?.title || 'Lavva';
      const notificationOptions = {
        body: payload.notification?.body,
        icon: payload.notification?.image,
      };

      if (notificationApiSupported) {
        new Notification(notificationTitle, notificationOptions);
      } else {
        toastInfo({ content: notificationOptions.body || '', icon: <IconLavva /> });
      }
    });
  };

  const registerExalusNotificationToken = async (token: string, clientName: string, lavvaUserName: string) => {
    if (integrationType !== IntegrationType.Exalus) return;
    const controlerTokens = await getAllControllerNotificationTokens();

    if ((controlerTokens as ResponseResult<NotificationsServiceErrorCode>).Type) return;

    const found = (controlerTokens as DeviceTokenMetadata[]).find((x) => x.Token === token);

    if (!found) {
      const exalusClientRegistration = new NotificationClientRegistration();
      exalusClientRegistration.DeviceToken = token;
      exalusClientRegistration.ClientName = clientName;
      exalusClientRegistration.LavvaUserName = lavvaUserName;

      registerNotificationClient(exalusClientRegistration);
    }
  };

  const registerPushReceiverRegistration = (firebaseToken: string, deviceName: string) => {
    if (nativeCtx === null) return;
    const pushReceiverType = pushReceiverByNativeCtx[nativeCtx];

    registerPushNotificationReceiver({
      variables: {
        input: {
          token: firebaseToken,
          pushReceiverName: deviceName,
          pushReceiverType,
        },
      },
    });
  };

  const registerTokenForWeb = async () => {
    if (isLocalhost || !notificationApiSupported) return;
    const isFirebaseSupported = await isSupported();
    if (!isFirebaseSupported) return;

    try {
      const app = initializeApp(firebaseConfig);
      const firebaseToken = await getToken(getMessaging(app), {
        vapidKey: environment.VAPID_KEY,
      });
      console.log({ firebaseToken });
      if (!firebaseToken) return;

      const pushRegistrations = await getPushRegistrations();
      console.log({ pushRegistrations });

      addPushNotificationListener();

      const found = (pushRegistrations.data?.allPushReceiversRegistrations || []).find(
        (registration) => registration.token === firebaseToken,
      );

      const deviceName = getDeviceName();
      registerExalusNotificationToken(firebaseToken, deviceName, user.profile.email);

      if (found) return;

      registerPushReceiverRegistration(firebaseToken, deviceName);
    } catch (err) {
      console.log('An error occurred when requesting to receive the token.', err);
    }
  };

  const registerTokenForIosOrAndroid = async () => {
    if (isLocalhost) return;

    try {
      const firebaseToken = await getNotificationToken();
      console.log({ firebaseToken });
      if (!firebaseToken) return;
      const pushRegistrations = await getPushRegistrations();
      console.log({ pushRegistrations });

      const found = (pushRegistrations.data?.allPushReceiversRegistrations || []).find(
        (registration) => registration.token === firebaseToken,
      );

      const deviceName = getDeviceName();
      registerExalusNotificationToken(firebaseToken, deviceName, user.profile.email);

      if (found) return;

      registerPushReceiverRegistration(firebaseToken, deviceName);
    } catch (err) {
      console.log('An error occurred when requesting to receive the token.', err);
    }
  };

  return {
    registerTokenForWeb,
  };
};
