import React from 'react';
import c from 'classnames';
import { IconMarkerFill } from '../../../../../../components/icons/icon-marker-fill';
import './index.scss';

type MarkerProps = {
  lat: number;
  lng: number;
  type?: 'custom' | 'current_location';
};

const Marker: React.FC<MarkerProps> = ({ type = 'custom' }) => (
  <div className={c('marker', { current_loc: type === 'current_location' })} style={{ cursor: 'pointer' }}>
    {type === 'custom' && <IconMarkerFill />}
  </div>
);

export default Marker;
