import React, { useMemo } from 'react';
import { sumBy } from 'lodash';
import { measurementParametersOptimizer } from '../../../../modules/channel-details/measurements/utils';
import { useOptimizerCurrent } from '../../../../modules/channel-details/optimizer/hooks/use-optimizer-current';
import { PhaseState, getOptimizerCurrentState } from '../../../../modules/channel-details/optimizer/utils';
import { ChannelType } from '../../../../types';
import { IconArrowLink, IconEco, IconWarning } from '../../../icons';
import './index.scss';

type OptimizerControlProps = {
  channel: ChannelType;
};

export const OptimizerControl: React.FC<OptimizerControlProps> = ({ channel }) => {
  const { optimizerData } = useOptimizerCurrent({ channel });
  const value = optimizerData ? sumBy(optimizerData?.ACTIVE_POWER, 'value') : undefined;

  const currentState = useMemo(() => {
    if (value && optimizerData) {
      const over = optimizerData.VOLTAGE.find((x) => getOptimizerCurrentState(x.value).label === PhaseState.Over);
      if (over) return <IconWarning fillRed />;
      else {
        const warning = optimizerData.VOLTAGE.find(
          (x) => getOptimizerCurrentState(x.value).label === PhaseState.Warning,
        );
        if (warning) return <IconWarning fillYellow />;
        else return value < 0 ? <IconEco /> : null;
      }
    }
  }, [value, optimizerData]);

  return (
    <div className="optimizer-control">
      {value !== undefined ? (
        <div className="optimizer-control__value-wrapper">
          <div className="current-icon">{currentState}</div>
          <span className="optimizer-control__value">
            {(Math.abs(value) / 1000).toFixed(measurementParametersOptimizer.ACTIVE_POWER.fixed)}
          </span>
          <span className="optimizer-control__unit">{measurementParametersOptimizer.ACTIVE_POWER.unit}</span>
          {value ? <IconArrowLink size={22} direction={value < 0 ? 'up' : 'down'} /> : null}
        </div>
      ) : null}
    </div>
  );
};
