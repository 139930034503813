import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { IconButton } from '@mui/material';
import { IconChevron, IconPause, IconPlay } from '../../../../../../../components';
import ArrowButton from '../../../../../../../components/arrow-button';
import LavvaDetailsWrapper from '../../../../../../../components/details-wrapper';
import { IconPip } from '../../../../../../../components/icons/icon-pip';
import { useExalusCameraStream } from '../../../../../hooks/camera.hooks';
import { useHistoryState } from '../../../hooks/use-history-state';
import { useVideo } from '../../../hooks/use-video';
import SensorHistory from '../../../sensor-history';
import './index.scss';

type CameraDetailsControlsProps = {
  channel: IDeviceChannel;
};

export const CameraDetailsControls: React.FC<CameraDetailsControlsProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { tabs } = useHistoryState(channel);
  const { data } = useExalusCameraStream(channel);
  const { videoRef, pipSupported, handlePipClick, fullScreen, setFullScreen, toggleVideo, isPaused, buttonsVisible } =
    useVideo();

  useEffect(() => {
    if (data && videoRef.current && activeTabIndex === 0) {
      videoRef.current.srcObject = data;
    }
  }, [data, activeTabIndex]);

  return (
    <LavvaDetailsWrapper
      tabs={tabs}
      additionalTabs={[<SensorHistory key={2} channel={channel} />]}
      overwriteTabIndex={activeTabIndex}
      setOverwriteActiveTabIndex={setActiveTabIndex}
    >
      <div className="p-l-24 p-r-24">
        <div className={classNames('camera-details', { 'camera-details--full-screen': fullScreen })}>
          {fullScreen && buttonsVisible && (
            <IconButton className="button-control full-screen" onClick={() => setFullScreen(false)}>
              <IconChevron />
            </IconButton>
          )}
          <video ref={videoRef} autoPlay playsInline muted style={{ width: '100vw' }} />
          {data && buttonsVisible && (
            <div className="video-controls">
              <IconButton className="button-control" onClick={toggleVideo}>
                {!isPaused ? <IconPause /> : <IconPlay />}
              </IconButton>
            </div>
          )}
          {isPaused && (
            <div className="stopped-view">
              <span>{t('exalus.camera.stopped')}</span>
            </div>
          )}
          {data && pipSupported && buttonsVisible && (
            <div className="pip-btn">
              <IconButton type="button" onClick={handlePipClick}>
                <IconPip />
              </IconButton>
            </div>
          )}
        </div>
        {data && (
          <ArrowButton title={t('exalus.camera.fullScreen')} onClick={() => setFullScreen(true)} className="m-t-48" />
        )}
      </div>
    </LavvaDetailsWrapper>
  );
};
