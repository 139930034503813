import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useRgb } from '../../../modules/channel-details/light/hooks/use-rgb';
import { ChannelType, RGBType } from '../../../types';
import { InitiateButton } from '../../initiate-button';
import { useChannel } from '../hooks/use-channel';
import { ChannelIcon } from '../icon';

type ChannelDetailsHeaderProps = {
  channel: ChannelType;
  onClickInitiateButton?: (channelId: string, turnOn: boolean) => void;
  className?: string;
  lightControlDisabled?: boolean;
};

export const ChannelDetailsHeader: React.FC<ChannelDetailsHeaderProps> = ({
  channel,
  className,
  lightControlDisabled,
}) => {
  const { t: tc } = useTranslation('common');
  const { rgbType, isOn, handleControlOn } = useRgb(channel);
  const { additionalInfo } = useChannel({ channel, bigIcons: true, details: true });

  return (
    <div className={classNames('details-header', { [className as string]: className })}>
      <div className="details-header--left">
        <div className="details-header__icon">
          <ChannelIcon channel={channel} />
          {additionalInfo}
        </div>
        <div className="details-header__name">
          <h2 className="text-ellipsis">{channel.alias}</h2>
          <div className="details-header__type">{tc(`types.${channel.data.type}`)}</div>
        </div>
      </div>
      {(rgbType === RGBType.RGB || rgbType === RGBType.RGBW) && !lightControlDisabled && (
        <InitiateButton isOn={isOn} isSmall onClickControl={handleControlOn} headerButton />
      )}
    </div>
  );
};
