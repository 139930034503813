import React from 'react';
import { GateStepIcon } from '../../../modules/channel-details/gate/components/gate-step-icon';
import './index.scss';

type IconCirclePadlockOpenFullProps = {
  big?: boolean;
};

export const IconCirclePadlockOpenFull: React.FC<IconCirclePadlockOpenFullProps> = ({ big }) => (
  <>
    {!big ? (
      <svg
        className="icon-circle-padlock-open-full"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.75" y="0.75" width="30.5" height="30.5" rx="15.25" stroke="#15141D" strokeWidth="1.5" />
        <g clipPath="url(#clip0_26712_34782)">
          <rect
            x="10"
            y="13"
            width="11.3333"
            height="8.66667"
            rx="4"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.3333 17.3335L13.9999 17.3335"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 13L18.7696 12.5421C18.4937 8.59904 20.3693 8.17476 25.5 10V10"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_26712_34782">
            <rect width="16" height="16" fill="white" transform="translate(8 7)" />
          </clipPath>
        </defs>
      </svg>
    ) : (
      <GateStepIcon step={3} />
    )}
  </>
);
