import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CustomBackdrop, Header, NavHeader, Page, Tabs } from '../../components';
import { useDevicesAndChannelsContext } from '../../hooks/devices-and-channels/provider';
import { useRouter } from '../../hooks/router/use-router';
import { ROUTES } from '../../routes';
import { pathnameWithParameters } from '../../utils/location';
import ConfigurationDetails from './configuration-details';
import DeviceBindings from './device-bindings';
import DeviceChannels from './device-channels';
import DeviceInfo from './device-info';
import DeviceInputs from './device-inputs';
import { useGetDeviceDetails } from './hooks/use-get-device-details';
import './index.scss';
import { useDeviceSettings } from './hooks/use-device-settings';

const DeviceSettings: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { t } = useTranslation('channel-list');
  const { isBackAvailable, handleReplaceRedirect } = useRouter();
  const { deviceId } = useParams<{ deviceId: string; channelId: string }>();
  const { device, setDeviceDetails, clearDeviceDetails } = useDevicesAndChannelsContext();
  const { inputs, bindingsEnabled, rssiSupported, tabs } = useDeviceSettings();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { data, loading } = useGetDeviceDetails(deviceId);

  useEffect(() => {
    if (data?.userDevice) setDeviceDetails(data.userDevice);
    return () => clearDeviceDetails();
  }, [data]);

  useEffect(() => {
    const tab = new URLSearchParams(search).get('tab');
    if (tab) setActiveTabIndex(parseInt(tab));
  }, []);

  const handleTabChange = (tabIndex: number) => {
    history.replace(pathnameWithParameters(ROUTES.DeviceSettings(deviceId), search, { key: 'tab', value: tabIndex }));
    setActiveTabIndex(tabIndex);
  };

  const renderCurrentForm = () => {
    switch (activeTabIndex) {
      case 1:
        return (
          <>
            <Header subheader title={t('channels')} />
            <DeviceChannels />
          </>
        );
      case 2:
        return <DeviceInputs inputs={inputs} bindingsEnabled={bindingsEnabled} />;
      case 3:
        return <DeviceBindings />;
      default:
        return <ConfigurationDetails rssiSupported={rssiSupported} />;
    }
  };

  return (
    <Page
      isStickyHeader
      className="driver-settings__page"
      header={
        <>
          <NavHeader {...(!isBackAvailable ? { onClick: () => handleReplaceRedirect(ROUTES.Root()) } : {})} />
          <Header>
            <div className="driver-settings__header">{device && <DeviceInfo device={device} />}</div>
          </Header>
          <Tabs
            tabList={tabs}
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={handleTabChange}
            tabsType="pills"
            isDivider
          />
        </>
      }
    >
      {device && <div className="p-b-32">{renderCurrentForm()}</div>}
      <CustomBackdrop loading={loading} />
    </Page>
  );
};

export default DeviceSettings;
