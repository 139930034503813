import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { CustomBackdrop, EmptyStatePage, Page, SubmitButton } from '../../components';
import { useInstallation } from '../../hooks';
import { StatusTypeEnum } from '../../types';
import { isLocalApp } from '../../utils/helpers/local-app';
import { InstallationListSection, InstallationListSortable, InstallationListHeader } from './components';
import { useInstallationList } from './hooks/use-installation-list';

const InstallationList: React.FC = () => {
  const { t } = useTranslation('installation');
  const { installationList, installationsLoading } = useInstallation();
  const {
    newInstallation,
    toggleSort,
    onSortSave,
    setChangeInstallationVariables,
    setIsValid,
    setSortedInstallationList,
    setSearchText,
    setSearchVisible,
    sortedInstallationList,
    sortMode,
    isValid,
    loading,
    searchText,
    searchVisible,
  } = useInstallationList();

  return (
    <Page
      isStickyHeader
      header={
        <InstallationListHeader
          toggleSort={toggleSort}
          sortMode={sortMode}
          searchVisible={searchVisible}
          setSearchVisible={setSearchVisible}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      }
    >
      <div className={classNames('installation-list m-b-24', { 'grid-list-24': installationList.length })}>
        {!installationsLoading ? (
          <>
            {installationList.length ? (
              <>
                {!sortMode ? (
                  <InstallationListSection searchText={searchText} />
                ) : (
                  <InstallationListSortable
                    sortedInstallationList={sortedInstallationList}
                    setSortedInstallationList={setSortedInstallationList}
                    setChangeInstallationVariables={setChangeInstallationVariables}
                    setIsValid={setIsValid}
                  />
                )}
              </>
            ) : (
              <EmptyStatePage type={StatusTypeEnum.NO_INSTALLATION} caption={t('emptyState.noInstallation.caption')} />
            )}
          </>
        ) : (
          <CustomBackdrop loading={installationsLoading} />
        )}
      </div>
      {!isLocalApp && (
        <>
          {sortMode ? (
            <SubmitButton onClick={onSortSave} isLoading={loading} disabled={!isValid} />
          ) : (
            <SubmitButton onClick={newInstallation}>{t('newInstallation')}</SubmitButton>
          )}
        </>
      )}
    </Page>
  );
};

export default InstallationList;
