import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toastSuccess } from '../../../utils/toast';

export const useAboutSettings = () => {
  const { t } = useTranslation('profile');
  const [showAppDataClearDialogConfirmation, setShowAppDataClearDialogConfirmation] = useState<boolean>(false);

  const handleClearAppData = () => {
    localStorage.clear();
    toastSuccess({ content: t('clear-app-data.toast') });
    setShowAppDataClearDialogConfirmation(false);
  };

  return {
    handleClearAppData,
    showAppDataClearDialogConfirmation,
    setShowAppDataClearDialogConfirmation,
  };
};
