import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useActionBatch } from '../../api/modules/action/action.hooks';
import {
  SwitchLightOnOffBatchChannel,
  SwitchLightSetOnTimeBatchChannel,
  TypeOfActionType,
} from '../../api/modules/action/action.types';
import { SwitchStateInternal } from '../../data-access/gql-types/graphql';
import { ChannelType } from '../../types';
import { toastInfo } from '../../utils/toast';

export const useSwitchAction = (channels: ChannelType[]) => {
  const { t } = useTranslation('action');
  const actionBatch = useActionBatch();

  const handleControlsOn = useCallback(
    (value: string) => {
      if (!channels.length) {
        toastInfo({ content: t('trigger.validationErrors.deviceNotFound') });
        return;
      }

      actionBatch.mutate({
        controls: channels.map((channel) => ({
          deviceId: channel.deviceId,
          channelId: channel.id,
          value: value === SwitchStateInternal.On,
          $type: TypeOfActionType.BatchSwitchToggleOnOffRequest,
        })) as SwitchLightOnOffBatchChannel[],
      });
    },
    [channels],
  );

  const handleSetOnTime = useCallback(
    (value: number) => {
      if (!channels.length) {
        toastInfo({ content: t('trigger.validationErrors.deviceNotFound') });
        return;
      }

      actionBatch.mutate({
        controls: channels.map((channel) => ({
          deviceId: channel.deviceId,
          channelId: channel.id,
          timeInMilliseconds: value,
          $type: TypeOfActionType.BatchSwitchSetOnTimeRequest,
        })) as SwitchLightSetOnTimeBatchChannel[],
      });
    },
    [channels],
  );

  return {
    handleControlsOn,
    handleSetOnTime,
  };
};
