import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { isNumber } from 'lodash';
import { NetworkStatus, useQuery } from '@apollo/client';
import { useGetDeviceStatus } from '../../../api/modules/recovery/recovery.hooks';
import {
  ChannelTypeInternal,
  DeviceConnectionState,
  RecoveryDeviceStatus,
  RssiQuery,
  RssiQueryVariables,
} from '../../../data-access/gql-types/graphql';
import { RSSI } from '../../../data-access/queries/devices';
import { useChannelsState, useDevicesAndChannels, useInstallation } from '../../../hooks';
import { useDevicesAndChannelsContext } from '../../../hooks/devices-and-channels/provider';
import { convertDateToFormat } from '../../../utils/helpers';
import { getSignalStrength } from '../../../utils/helpers/network';

export const useConfigurationDetails = () => {
  const { t } = useTranslation('device-info');
  const { t: tc } = useTranslation('common');
  const { deviceId } = useParams<{ channelType: ChannelTypeInternal; deviceId: string }>();
  const { device } = useDevicesAndChannelsContext();
  const { deviceChannels } = useDevicesAndChannels({ deviceId });
  const { channelState } = useChannelsState();
  const { mutate } = useGetDeviceStatus();
  const { selectedInstallationId, skipLavvaFetch } = useInstallation();
  const { data, networkStatus, refetch } = useQuery<RssiQuery, RssiQueryVariables>(RSSI, {
    variables: { installationId: selectedInstallationId, deviceId: deviceId },
    fetchPolicy: 'network-only',
    skip: skipLavvaFetch,
    notifyOnNetworkStatusChange: true,
  });

  const status = device?.recoveryMode?.status;
  const progress = device?.recoveryMode?.progress;

  const isDeviceInRecovery = useMemo(
    () =>
      deviceChannels.some(
        (channel) => channelState[channel.id]?.deviceConnectionState === DeviceConnectionState.Recovery,
      ),
    [deviceChannels, channelState],
  );

  useEffect(() => {
    if (isDeviceInRecovery && device) mutate({ deviceId: device.id });
  }, [isDeviceInRecovery]);

  const registeredAt = useMemo(
    () =>
      device?.payload.registeredAtUtc
        ? convertDateToFormat(new Date(device.payload.registeredAtUtc), 'dd.MM.yy, HH:mm:ss')
        : '-',
    [device?.payload.registeredAtUtc],
  );

  const isDeviceConnected = useMemo(
    () =>
      deviceChannels.some(
        (channel) => channelState[channel.id]?.deviceConnectionState === DeviceConnectionState.Connected,
      ),
    [deviceChannels, channelState],
  );

  const isDeviceDisconnected = useMemo(
    () =>
      deviceChannels.some(
        (channel) => channelState[channel.id]?.deviceConnectionState === DeviceConnectionState.Disconnected,
      ),
    [deviceChannels, channelState],
  );

  const lastConnectedAt = useMemo(
    () =>
      device?.payload.lastConnectedAtUtc
        ? convertDateToFormat(new Date(device.payload.lastConnectedAtUtc), 'dd.MM.yy, HH:mm:ss')
        : '-',
    [device?.payload.lastConnectedAtUtc],
  );

  const rssi = useMemo(() => {
    if (data?.rssi && isNumber(data.rssi.rssi)) return getSignalStrength(data.rssi.rssi, true);
    return null;
  }, [data?.rssi]);

  const buttonContent = useMemo(
    () => (status === RecoveryDeviceStatus.DownloadingFirmware ? tc('buttons.loading') : t('updateSoftware.update')),
    [status],
  );

  return {
    isDeviceInRecovery,
    registeredAt,
    status,
    buttonContent,
    progress,
    isDeviceConnected,
    isDeviceDisconnected,
    lastConnectedAt,
    rssi,
    rssiLoading: networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.refetch,
    refetchRssi: refetch,
  };
};
