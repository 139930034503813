import React, { useMemo, useState } from 'react';
import { ControlWrapper, LightSlider, OnOffControl } from '../../../components';
import {
  ChannelTypeInternal,
  LightModeTypeInternal,
  LightToggleValueInternal,
} from '../../../data-access/gql-types/graphql';
import { useLightAction } from '../../../hooks';
import { ChannelType, ChannelLightType } from '../../../types';
import { ActionTimesActivations } from '../components/action-times-activation';

type LightActionProps = {
  channels: ChannelType[];
};

const LightAction: React.FC<LightActionProps> = ({ channels }) => {
  const [brightness, setBrightness] = useState<number>(100);
  const { handleControlsOn, handleSetBrightness } = useLightAction(channels);

  const hasBrightness = useMemo(
    () =>
      channels.any(
        (channel) =>
          channel.data.type === ChannelTypeInternal.Light &&
          ((channel.data as ChannelLightType)?.supportedModes || []).includes(LightModeTypeInternal.Brightness),
      ),
    [channels],
  );

  const handleChange = (value: number) => setBrightness(value);

  const handleBrightness = (value: number) => handleSetBrightness(value);

  return (
    <>
      <ControlWrapper>
        <OnOffControl
          actionOn={() => handleControlsOn(LightToggleValueInternal.On)}
          actionOff={() => handleControlsOn(LightToggleValueInternal.Off)}
        />
      </ControlWrapper>
      {hasBrightness && (
        <div className="m-t-24 m-b-48 p-l-24 p-r-24">
          <LightSlider value={brightness} onChange={handleChange} onPointerUp={handleBrightness} />
        </div>
      )}
      <ActionTimesActivations channels={channels} />
    </>
  );
};

export default LightAction;
