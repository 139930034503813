import React, { useMemo } from 'react';
import { ChannelTypeInternal, GateModeInternal } from '../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../hooks';
import { useRgb } from '../../../modules/channel-details/light/hooks/use-rgb';
import { ChannelCoverStateType, ChannelType, ChannelLightStateType } from '../../../types';
import { ChannelGateType, ChannelGateStateType } from '../../../types/channel/gate';
import { useChannel } from '../hooks/use-channel';
import { ChannelIcon } from '../icon';
import { ChannelBoxPostName } from './post-name';
import { ChannelBoxPreName } from './pre-name';

type HeadContentProps = {
  channel: ChannelType;
};

export const HeadContent: React.FC<HeadContentProps> = ({ channel }) => {
  const { channelState } = useChannelsState();
  const { hasBrightness, hasRGB } = useRgb(channel);
  const { additionalInfo } = useChannel({ channel, details: true });

  const percentageBadge = useMemo(() => {
    switch (channel.data.type) {
      case ChannelTypeInternal.Blind:
        return (channelState[channel.id] as ChannelCoverStateType)?.lavvaStatePosition
          ? `${(channelState[channel.id] as ChannelCoverStateType)?.lavvaStatePosition}%`
          : '0%';
      case ChannelTypeInternal.Gate: {
        if ((channel.data as ChannelGateType).gateMode === GateModeInternal.RollUp) {
          return (channelState[channel.id] as ChannelGateStateType)?.position
            ? `${(channelState[channel.id] as ChannelGateStateType)?.position}%`
            : `0%`;
        }
        return null;
      }
      case ChannelTypeInternal.Light:
        return hasBrightness && (channelState[channel.id] as ChannelLightStateType)?.brightness !== null
          ? `${(channelState[channel.id] as ChannelLightStateType)?.brightness}%`
          : null;
      default:
        return null;
    }
  }, [
    channel.data.type,
    (channelState[channel.id] as ChannelCoverStateType)?.lavvaStatePosition,
    (channelState[channel.id] as ChannelGateStateType)?.position,
    (channelState[channel.id] as ChannelLightStateType)?.brightness,
    hasBrightness,
  ]);

  return (
    <>
      <div className="device-box__head-icon">
        <ChannelIcon channel={channel} />
        {additionalInfo}
        {percentageBadge !== null && <div className="channel-value-badge">{percentageBadge}</div>}
      </div>
      <div className="device-box__head-wrapper">
        {hasRGB && <ChannelBoxPreName channel={channel} />}
        <div className="device-box__head-title text-ellipsis">
          <span className="text-ellipsis">{channel.alias}</span>
          <ChannelBoxPostName channel={channel} />
        </div>
      </div>
    </>
  );
};
