import React from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ControlWrapper } from '../../../../../../components';

type CameraChartProps = {
  channel: IDeviceChannel;
};

const CameraChart: React.FC<CameraChartProps> = () => (
  <ControlWrapper className="control-wrapper--full-space no-center">COMING SOON</ControlWrapper>
);

export default CameraChart;
