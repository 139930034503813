import React from 'react';
import { QrReader } from 'react-qr-reader';
import { ViewFinder } from './view-finder';

type QRCodeReaderProps = {
  onHandleScan: (data: string) => void;
};

export const QRCodeReader: React.FC<QRCodeReaderProps> = ({ onHandleScan }) => {
  const handleScan = (data: string | null) => {
    if (data) onHandleScan(data);
  };

  return (
    <QrReader
      constraints={{
        facingMode: 'environment',
      }}
      ViewFinder={ViewFinder}
      scanDelay={300}
      videoContainerStyle={{ width: '100%', height: 'calc(100vh - 160px)', paddingTop: '0' }}
      videoStyle={{ width: '100%', height: 'calc(100vh - 160px)' }}
      onResult={(result) => {
        if (result) handleScan(result.toString());
      }}
    />
  );
};
