import { useEffect } from 'react';
import { useSubscription } from '@apollo/client';
import {
  OnPartialConfigChangedSubscription,
  OnPartialConfigChangedSubscriptionVariables,
} from '../../../../../data-access/gql-types/graphql';
import { ON_PARTIAL_CONFIG_CHANGED } from '../../../../../data-access/subscriptions/lavva-devices';
import { useChannelsState, useInstallation } from '../../../../../hooks';
import { ChannelGateStateType, ChannelType } from '../../../../../types';
import { DailyMode } from '../daily-mode';
import { PartialMode } from '../partial-mode';

type AdditionalModesProps = {
  channel: ChannelType;
};

export const AdditionalModes: React.FC<AdditionalModesProps> = ({ channel }) => {
  const { skipLavvaFetch } = useInstallation();
  const { setChannelState } = useChannelsState();
  const { data: configurationData } = useSubscription<
    OnPartialConfigChangedSubscription,
    OnPartialConfigChangedSubscriptionVariables
  >(ON_PARTIAL_CONFIG_CHANGED, {
    variables: { channelId: channel.id },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (configurationData?.onPartialConfigChanged) {
      const { numericalProperties, channelId } = configurationData.onPartialConfigChanged;
      console.log('CONFIGURATION DATA', { numericalProperties });

      const partialMode = numericalProperties.find((x) => x.property === 'PartialControlModeStatus');
      const dailyMode = numericalProperties.find((x) => x.property === 'DailyModeStatus');
      const lockStatus = numericalProperties.find((x) => x.property === 'LockStatus');

      setChannelState((oldState) => {
        const newState = { ...oldState };
        newState[channelId] = {
          ...newState[channelId],
          ...(partialMode && { partialControlModeStatus: partialMode.value === 'True' }),
          ...(dailyMode && { dailyModeStatus: dailyMode.value === 'True' }),
          ...(lockStatus && { lockStatus: lockStatus.value === 'True' }),
        } as ChannelGateStateType;

        return newState;
      });
    }
  }, [configurationData]);

  return (
    <>
      <PartialMode channel={channel} />
      <DailyMode channel={channel} />
    </>
  );
};
