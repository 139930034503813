import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FacadeType } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { DeviceTaskPairExecutionResult as ChannelTaskPairExecutionResult } from 'lavva.exalushome/build/js/Services/Devices/IDevicesService';
import { BlindActionEnum } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { ControlWrapper, CoverButton, IconChevron, IconFavorite, Slider } from '../../../../../../components';
import { defaultFavouritePositionList } from '../../../../../../const';
import { SlatsSection } from '../../../channel/controls/facade/details/components/slats-section';

type FacadeDetailsSpecificControlButtonsProps = {
  close: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  stop: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  open: () => Promise<ChannelTaskPairExecutionResult[]> | Promise<void>;
  handleAction: (action, position?: number) => Promise<ChannelTaskPairExecutionResult[]>;
  handleSliderEvent: (event: React.PointerEvent<HTMLInputElement>) => void;
  handleTiltEvent: (value: number) => void;
  facadeType: FacadeType;
};

export const FacadeDetailsSpecificControlButtons: React.FC<FacadeDetailsSpecificControlButtonsProps> = ({
  close,
  stop,
  open,
  handleAction,
  handleSliderEvent,
  handleTiltEvent,
  facadeType,
}) => {
  const { t: tc } = useTranslation('common');
  const [sliderValue, setSliderValue] = useState(50);
  const [tiltValue, setTiltValue] = useState(50);
  const isOpen = false;
  const isClosed = false;

  const onChange = (value: number) => {
    setSliderValue(value);
    handleAction(BlindActionEnum.Percentage, value);
  };

  return (
    <div className="cover-detail-view facade">
      <ControlWrapper className="control-wrapper--space-evenly control-wrapper--full-space">
        <div className="slider-section">
          <Slider
            value={sliderValue}
            onPointerUp={handleSliderEvent}
            onChange={(e) => setSliderValue(Number(e.target.value))}
            showValue={false}
            className="slider--width-auto"
          />
        </div>
        <SlatsSection
          tiltValue={tiltValue}
          fullRange={facadeType === FacadeType.Facade180}
          defaultValue={100 - tiltValue}
          value={100 - tiltValue}
          onChange={(_, value) => {
            setTiltValue(100 - (value as number));
          }}
          onChangeCommitted={() => {
            setTiltValue(tiltValue);
            handleTiltEvent(tiltValue);
          }}
        />
      </ControlWrapper>
      <div className="cover-detail-view__controls">
        <div
          className={classNames('cover-detail-view__controls-down', {
            'cover-detail-view__controls-down--disabled': isClosed,
          })}
          onClick={() => !isClosed && close()}
        >
          <IconChevron withCircle disabled={isClosed} direction="down" />
        </div>
        <button className="cover-detail-view__controls-stop" onClick={stop}>
          {tc('status.stop')}
        </button>
        <div
          className={classNames('cover-detail-view__controls-up', { 'cover__controls-up--disabled': isOpen })}
          onClick={() => !isOpen && open()}
        >
          <IconChevron withCircle disabled={isOpen} direction="up" />
        </div>
      </div>
      <div className="favourite">
        <div className="favourite__buttons">
          {[0, 1, 2].map((index) => (
            <CoverButton
              key={index}
              position={defaultFavouritePositionList[index]}
              onClick={() => onChange(defaultFavouritePositionList[index])}
            >
              <IconFavorite />
              <span>{defaultFavouritePositionList[index]}%</span>
            </CoverButton>
          ))}
        </div>
      </div>
    </div>
  );
};
