import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DeviceResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { StateHistoryErrorCode } from 'lavva.exalushome/build/js/Services/StatesHistory/IStatesHistoryService';
import { FloodStateHistory, StateDataResponse } from 'lavva.exalushome/build/js/Services/StatesHistory/StatesHistory';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { ControlWrapper, DatePicker, TimeRanges } from '../../../../../../components';
import { IconLeak } from '../../../../../../components/icons';
import { useBackdropContext, useInstallation } from '../../../../../../hooks';
import { useExalusServicesContext } from '../../../../context/services';
import { useHistoryState } from '../../hooks/use-history-state';
import ChartPagination, { defaultLimit } from '../components/chart-pagination';
import CustomizedXTick from '../components/customized-x-tick';
import { useHistoryCharts } from '../hooks/use-history-charts';
import { stateInterval } from '../types';

type FloodChartProps = {
  channel: IDeviceChannel;
  active?: boolean;
};

const FloodChart: React.FC<FloodChartProps> = ({ channel, active }) => {
  const { t } = useTranslation('channel-details');
  const [state, setState] = useState<StateDataResponse<FloodStateHistory> | null>(null);
  const { turnOnBackdrop, turnOffBackdrop, backdropOpen } = useBackdropContext();
  const { historyStatesApi } = useExalusServicesContext();
  const { handleHistoryError } = useHistoryState(channel);
  const { selectedInstallation } = useInstallation();
  const { offSet, setOffSet, activeRange, onClickActiveMeasurementRange, formatDate } = useHistoryCharts();

  useEffect(() => {
    const getStates = async () => {
      turnOnBackdrop();
      const result = await historyStatesApi.GetStatesByIntervalAsync<FloodStateHistory>(
        channel.GetDevice(),
        channel.Number,
        DeviceResponseType.FloodSensorState,
        stateInterval[activeRange],
        defaultLimit,
        offSet,
        true,
      );

      if ((result as ResponseResult<StateHistoryErrorCode>).Type) {
        handleHistoryError(result as ResponseResult<StateHistoryErrorCode>);
      } else {
        setState(result as StateDataResponse<FloodStateHistory>);
        turnOffBackdrop();
      }
    };

    getStates();
  }, [activeRange, offSet]);

  const data = useMemo(() => {
    return state?.Data.reverse().map((x) => ({
      date: formatDate(x.Time),
      flood: x.Values?.State,
    }));
  }, [state]);

  return (
    <>
      <ControlWrapper className={classNames('control-wrapper--full-space no-center', { active: active })}>
        <div className="exalus-chart-container">
          <TimeRanges
            activeMeasurementRange={activeRange}
            setActiveMeasurementRange={onClickActiveMeasurementRange}
            disableMonth
            disableYear
          />
          <DatePicker
            showDateButtons={false}
            activeDay={new Date()}
            activeMeasurementRange={activeRange}
            setActiveDay={() => null}
          />
          {data && data.length > 0 ? (
            <div className="binary-chart">
              <ResponsiveContainer width="100%" height={150}>
                <BarChart
                  width={500}
                  height={150}
                  data={data}
                  margin={{
                    top: 15,
                    right: 20,
                    left: 30,
                    bottom: 0,
                  }}
                >
                  <Bar dataKey="flood" fill={selectedInstallation?.hexColor} />
                  <XAxis
                    dataKey="date"
                    tick={<CustomizedXTick />}
                    height={44}
                    interval="preserveStartEnd"
                    allowDuplicatedCategory={false}
                  />
                  <YAxis max={1} min={0} hide />
                </BarChart>
              </ResponsiveContainer>
              <div className="axis-icon">
                <IconLeak isOn={true} />
                <span>{t('exalus.floodDetected.heading')}</span>
              </div>
              <div className="axis-icon">
                <IconLeak isOn={false} />
                <span>{t('exalus.none')}</span>
              </div>
              <ChartPagination offSet={offSet} setOffSet={setOffSet} isNextAvailable={!!state?.IsNextPageAvailable} />
            </div>
          ) : (
            <>{!backdropOpen && <p className="empty-states">{t('chartEmptyState.header')}</p>}</>
          )}
        </div>
      </ControlWrapper>
    </>
  );
};

export default FloodChart;
