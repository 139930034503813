import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { ActionType, ChannelAction, DeviceConnectionState, UserAction } from '../../../data-access/gql-types/graphql';
import { useApi, useChannelsState } from '../../../hooks';
import { useDevicesAndChannelsContext } from '../../../hooks/devices-and-channels/provider';
import { ROUTES } from '../../../routes';
import { ActionAdvancedIconTypeEnum, ChannelType } from '../../../types';
import { IconWrapper } from '../../icon-wrapper';
import { IconConnectionError, IconErrorSmall, IconRecovery, IconSortHandle } from '../../icons';
import { ActionAdvancedIcon } from '../advanced-icon';
import { ActionControl } from '../control';
import { EventList } from '../event-list';
import { ActionIcon } from '../icon';
import './index.scss';

type ActionBoxProps = {
  actionId: string;
  actionData?: UserAction;
  isListItem?: boolean;
  showControl?: boolean;
  isDraggable?: boolean;
  style?: React.CSSProperties;
  attributes?: Record<string, unknown>;
  setNodeRef?: (node: HTMLElement | null) => void;
  listeners?: DraggableSyntheticListeners;
  showEvents?: boolean;
};

export const ActionBox: React.FC<ActionBoxProps> = ({
  isDraggable = false,
  setNodeRef,
  attributes,
  listeners,
  style,
  isListItem = false,
  showControl = true,
  actionId,
  actionData,
  showEvents = false,
}) => {
  const history = useHistory();
  const { t } = useTranslation('action');
  const { channelList } = useDevicesAndChannelsContext();
  const { groups } = useApi();
  const { channelState } = useChannelsState();
  const [userAction, setUserAction] = useState<UserAction | undefined>(undefined);

  const getUserAction = () => {
    let newAction: UserAction | undefined;

    for (const dashboardGroup of groups) {
      for (const dashboardItem of dashboardGroup.group.groupItems) {
        if (dashboardItem.id === actionId) {
          newAction = dashboardItem.payload as UserAction;
          break;
        }
      }

      if (newAction) return newAction;
    }
    if (!newAction && actionData) return actionData;
  };

  useEffect(() => setUserAction(actionData || getUserAction()), [groups, actionId, actionData]);

  const actionChannels = useMemo(() => {
    const actionChannelIdList = userAction?.action.channelActions.map((element: ChannelAction) => element.channelId);
    return channelList.filter((channel) => channel && actionChannelIdList?.includes(channel.id)) as ChannelType[];
  }, [channelList, userAction]);

  const renderIcon = () =>
    !isDraggable && userAction ? (
      <ActionControl channels={actionChannels} userAction={userAction} isListItem={isListItem} />
    ) : (
      <div className="action-box__draggable">
        <div className="action-box__draggable-handle" {...attributes} {...listeners}>
          <IconSortHandle />
        </div>
      </div>
    );

  const isAdvanced = useMemo(() => {
    return actionData
      ? actionData?.action.actionType === ActionType.Advanced
      : userAction?.action.actionType === ActionType.Advanced;
  }, [actionData, userAction]);

  const actionIcon = useMemo(() => {
    return actionData ? actionData?.iconName : userAction?.iconName;
  }, [actionData, userAction]);

  const isInvalid = useMemo(() => {
    return actionData ? !actionData?.action.channelActions.length : !userAction?.action.channelActions.length;
  }, [actionData, userAction]);

  const isRecoveryStatus = useMemo(() => {
    return (
      actionChannels?.length &&
      actionChannels.every(
        (actionChannel) => channelState[actionChannel.id]?.deviceConnectionState === DeviceConnectionState.Recovery,
      )
    );
  }, [actionChannels, channelList, userAction, channelState]);

  const isDisconnected = useMemo(() => {
    return (
      actionChannels?.length &&
      actionChannels.every(
        (actionChannel) => channelState[actionChannel.id]?.deviceConnectionState === DeviceConnectionState.Disconnected,
      )
    );
  }, [actionChannels, channelList, userAction, channelState]);

  const name = useMemo(
    () => (userAction?.action.name !== 'Favourites' ? userAction?.action.name : t('favourites')),
    [userAction, t],
  );

  if (!userAction) return null;

  return (
    <div
      className={classNames('action-box', {
        'action-box--list-item': isListItem,
        'action-box--advanced': isAdvanced,
        'action-box--invalid': isInvalid,
      })}
      style={style}
      ref={setNodeRef}
    >
      <div className="action-box__content">
        {isAdvanced ? (
          <>
            <div
              className={classNames('action-box__content-control m-b-0', {
                'p-b-8 p-t-8': showEvents,
                'p-b-4 p-t-4': !showEvents && isListItem,
              })}
            >
              <div
                onClick={() => history.push(ROUTES.ActionDetails(userAction.action.id))}
                className={classNames('action-box__head action-box__head-wide', {
                  'action-box__head--box': !isListItem,
                })}
              >
                <div className="action-box__wrapper">
                  <IconWrapper className="icon-wrapper--size-32 icon-wrapper--relative">
                    <ActionAdvancedIcon iconName={actionIcon || ActionAdvancedIconTypeEnum.DEFAULT} />
                    {showControl &&
                      (isInvalid ? (
                        <IconErrorSmall />
                      ) : isDisconnected ? (
                        <IconConnectionError size={11} details />
                      ) : isRecoveryStatus ? (
                        <IconRecovery details />
                      ) : null)}
                  </IconWrapper>
                </div>
                <h4 className="action-box__head-action-name text-ellipsis">{name}</h4>
              </div>
              {showEvents && showControl && renderIcon()}
            </div>
            {showEvents && !isInvalid && <EventList channels={actionChannels} userAction={userAction} />}
          </>
        ) : (
          <div
            onClick={() => history.push(ROUTES.ActionDetails(userAction.action.id))}
            className={classNames('action-box__head', { 'action-box__head--box': !isListItem })}
          >
            <div className="action-box__relative-wrapper">
              <ActionIcon action={userAction.action} />
              {showControl &&
                (isInvalid ? (
                  <IconErrorSmall />
                ) : isDisconnected ? (
                  <IconConnectionError size={11} details />
                ) : isRecoveryStatus ? (
                  <IconRecovery details />
                ) : null)}
            </div>
            <h4 className="action-box__head-action-name text-ellipsis">{name}</h4>
          </div>
        )}
      </div>
      {!showEvents && showControl && renderIcon()}
    </div>
  );
};
