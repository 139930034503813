import React, { useEffect, useState } from 'react';
import * as uuid from 'uuid';
import { DimStateTask } from '../../../../../exalus/pages/action-create/types';
import { DimChannelSettings } from '../../../../components/channel-settings/dim';
import { useLavvaCreateActionContext } from '../../../../context';
import { useTaskEdit } from '../../../../hooks/use-task-edit';
import { DeviceTaskParams, SceneTaskType, Task } from '../../../../types';

export const DimSettings: React.FC = () => {
  const { channels, taskVariant } = useLavvaCreateActionContext();
  const [brightness, setBrightness] = useState<number>(0);
  const { task, finalizeDeviceTaskSubmit } = useTaskEdit();

  useEffect(() => {
    if (task) {
      setBrightness(((task.taskParams as DeviceTaskParams).state as DimStateTask).brightness);
    }
  }, [task]);

  const handleChangeBrightness = (value: number) => setBrightness(value);

  const handleSubmit = () => {
    for (let i = 0; i < channels.length; i++) {
      const task: Task = {
        id: uuid.v4(),
        taskType: SceneTaskType.ChannelState,
        taskParams: {
          state: {
            brightness,
          } as DimStateTask,
          channel: channels[i],
          variant: taskVariant,
        } as DeviceTaskParams,
      };

      finalizeDeviceTaskSubmit(task, i === channels.length - 1);
    }
  };

  return (
    <DimChannelSettings
      handleSubmit={handleSubmit}
      handleChangeBrightness={handleChangeBrightness}
      brightness={brightness}
    />
  );
};
