import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { UserGroup } from '../../../data-access/gql-types/graphql';
import { useInstallation } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { ChannelType, SharedItemType } from '../../../types';
import { toastError } from '../../../utils/toast';
import { initialState, StateShareInstallationType } from '../context';

export const useShareRedirect = () => {
  const history = useHistory();
  const { t: tc } = useTranslation('common');
  const { selectedInstallationId } = useInstallation();

  const shareInstallation = (installationId?: string) => {
    if (installationId) {
      const state: StateShareInstallationType = {
        ...initialState,
        installationId,
      };

      history.push(`${ROUTES.InstallationShared(installationId)}?state=${JSON.stringify(state)}`);
    } else {
      toastError({ content: tc('errors.somethingWentWrong') });
    }
  };

  const shareChannel = (channel?: ChannelType) => {
    if (channel) {
      const state: StateShareInstallationType = {
        ...initialState,
        installationId: selectedInstallationId || '',
        shareItem: { itemId: channel.id, name: channel.alias },
        channels: [{ id: channel.id, name: channel.alias }],
        subject: SharedItemType.Channel,
      };

      history.push(`${ROUTES.InstallationShared(selectedInstallationId)}?state=${JSON.stringify(state)}`);
    } else {
      toastError({ content: tc('errors.somethingWentWrong') });
    }
  };

  const shareGroup = (userGroup?: UserGroup) => {
    if (userGroup) {
      const state: StateShareInstallationType = {
        ...initialState,
        installationId: selectedInstallationId || '',
        shareItem: { itemId: userGroup.id, name: userGroup.group.name },
        channels: [{ id: userGroup.id, name: userGroup.group.name }],
        subject: SharedItemType.Group,
      };

      history.push(`${ROUTES.InstallationShared(selectedInstallationId)}?state=${JSON.stringify(state)}`);
    } else {
      toastError({ content: tc('errors.somethingWentWrong') });
    }
  };

  return {
    shareInstallation,
    shareChannel,
    shareGroup,
  };
};
