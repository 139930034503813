import { useEffect, useRef, useState } from 'react';
import c from 'classnames';
import { useBackdropContext } from '../../../../../hooks';
import { SelectedPhase } from '../../types';

type PhasesProps = {
  onClickPhase: (val: number) => void;
  mappedPhases?: SelectedPhase[];
  selectedPhases?: number[];
  className?: string;
};

export const Phases: React.FC<PhasesProps> = ({ onClickPhase, mappedPhases, className, selectedPhases }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { preventCarouselSwipe } = useBackdropContext();

  useEffect(() => {
    ref.current?.addEventListener('scroll', preventCarouselSwipe);
    return () => ref.current?.removeEventListener('scroll', preventCarouselSwipe);
  }, []);

  useEffect(() => {
    if (ref && ref.current) {
      const activeElement = ref?.current.children[activeTabIndex];
      if (activeElement) {
        activeElement.scrollIntoView({ block: 'nearest', inline: 'center' });
      }
    }
  }, [ref?.current?.scrollWidth, activeTabIndex]);

  const handlePhaseClick = (val: number) => {
    setActiveTabIndex(val);
    onClickPhase(val);
  };

  return (
    <div ref={ref} className={c('phases-button-group', { [className as string]: className })}>
      {mappedPhases?.map((option, index) => (
        <button
          key={index}
          className={c('phases-button-group__option', {
            'phases-button-group__option--active': selectedPhases?.includes(option.value),
          })}
          onClick={() => handlePhaseClick(option.value)}
        >
          <span className="phases-button-group__color" style={{ background: option.color }} />
          {option.label}
        </button>
      ))}
    </div>
  );
};
