import React from 'react';
import CheckContentWrapper, { PageType } from '../../common/check-content-wrapper';
import { DashboardCarousel } from './carousel';

type DashboardBodyProps = {
  activeTabIndex: number;
  setActiveTabIndex: (value: number) => void;
  isListView: boolean;
};

export const DashboardBody: React.FC<DashboardBodyProps> = ({ activeTabIndex, setActiveTabIndex, isListView }) => (
  <CheckContentWrapper page={PageType.Dashboard}>
    <DashboardCarousel activeTabIndex={activeTabIndex} setActiveTabIndex={setActiveTabIndex} isListView={isListView} />
  </CheckContentWrapper>
);
