import React, { useMemo } from 'react';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { IconSortHandle, AdvancedActionChannelParameters, ChannelItem } from '../../../components';
import { SortChannelAction } from '../../../types';

type ItemProps = {
  list: SortChannelAction[];
  isDraggable?: boolean;
  setNodeRef?: (node: HTMLElement | null) => void;
  style?: React.CSSProperties;
  attributes?: Record<string, unknown>;
  listeners?: DraggableSyntheticListeners;
  id: string;
};

const Item: React.FC<ItemProps> = ({
  list,
  isDraggable = false,
  listeners,
  attributes,
  setNodeRef,
  style,
  id,
}) => {
  const item = useMemo(() => list.find((item: SortChannelAction) => item.id === id), [id]);

  if (!item) return null;

  return (
    <div className="action-item" style={style} ref={setNodeRef}>
      <ChannelItem
        id={item.id}
        isOn={isDraggable}
        details={'parameter' in item ? <AdvancedActionChannelParameters parameter={item.parameter} /> : null}
      />
      {isDraggable && (
        <div className="action-item__draggable-handle" {...attributes} {...listeners}>
          <IconSortHandle />
        </div>
      )}
    </div>
  );
};
export default Item;
