import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelBox } from '../../../components';
import { useDevicesAndChannelsContext } from '../../../hooks/devices-and-channels/provider';
import Info from '../components/info';
import { InputsType } from '../components/inputs-type';
import ReturnBehaviorSelect from '../components/return-behavior-select';
import SwapIn from '../components/swap-in';
import { InfoPageType } from '../types';

type LightConfigurationProps = {
  setInfoPage: Dispatch<SetStateAction<InfoPageType>>;
};

const LightConfiguration: React.FC<LightConfigurationProps> = ({ setInfoPage }) => {
  const { t } = useTranslation('configuration');
  const { channel } = useDevicesAndChannelsContext();

  if (!channel) return null;

  return (
    <>
      <Info title={t('channelTest')} onClick={() => setInfoPage(InfoPageType.CHANNEL)} />
      <ChannelBox channel={channel} isListItem />
      <hr />

      <Info title={t('inService')} onClick={() => setInfoPage(InfoPageType.INSERVICE)} />
      <SwapIn channel={channel} />
      <hr />
      <InputsType setInfoPage={setInfoPage} isUnderlineDown />
      <ReturnBehaviorSelect channel={channel} />
    </>
  );
};

export default LightConfiguration;
