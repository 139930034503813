import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { WindThresholdStateEnum } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { IconToastWarning2 } from '../../../../../../../components/icons/icon-toast-warning-2';
import { useWindControl } from '../../../hooks/use-wind-control';
import { SensorControlBox } from '../../../sensor-control-box';

type WindControlProps = {
  channel: IDeviceChannel;
  isListItem?: boolean;
  details?: boolean;
};

const WindControl: React.FC<WindControlProps> = ({ channel, isListItem, details }) => {
  const { t } = useTranslation('channel-details');
  const { windSpeed, windThreshold } = useWindControl(channel);

  if (details || windThreshold !== WindThresholdStateEnum.Exceeded) {
    return <SensorControlBox isListItem={isListItem} value={windSpeed} unit={'m/s'} />;
  }

  return (
    <div
      className={classNames('list-controls', {
        'list-controls--list-item': isListItem,
      })}
    >
      <SensorControlBox isListItem={isListItem} value={windSpeed} unit={'m/s'} />
      <div className="device-box__warning device-box__warning--no-padding">
        <IconToastWarning2 size={16} colorError />
        <span className={'m-l-8'}>{t('exalus.windThresholdExceeded.heading')}</span>
      </div>
    </div>
  );
};

export default WindControl;
