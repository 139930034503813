import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { ChannelBox } from '../../../box';
import { ChannelBoxIcon } from '../../../box-icon';
import { useTemperatureControl } from '../../../hooks/use-temperature-control';
import { PowerState } from '../../../power';
import SensorDetailsWrapper from '../../../sensor-details-wrapper';
import TemperatureControl from '../controls';

type TemperatureDetailsControlsProps = {
  channel: IDeviceChannel;
  active: boolean;
};

export const TemperatureDetailsControls: React.FC<TemperatureDetailsControlsProps> = ({ channel, active }) => {
  const { t } = useTranslation('channel-details');
  const { time } = useTemperatureControl(channel);

  return (
    <SensorDetailsWrapper channel={channel} active={active}>
      <div className="details-controls grid-list-16">
        <div>
          <ChannelBox channel={channel} isListItem className="device-box--details">
            <div className="device-box__head">
              <ChannelBoxIcon channel={channel} />
              <TemperatureControl channel={channel} isListItem />
            </div>
          </ChannelBox>
          {time && <div className="details-controls__detection-time">{`${t('exalus.checked')} ${time}`}</div>}
        </div>
        <PowerState channel={channel} />
      </div>
    </SensorDetailsWrapper>
  );
};
