import { SelectOptionType } from '../../../../components';
import {
  BlindSupportedSlotsInternal,
  ChannelTypeInternal,
  GateSupportedSlotsInternal,
  GenericInputChannelStateResponse,
  InputTriggerInternal,
  LightSupportedSlotsInternal,
  SwitchSupportedSlotsInternal,
  UserChannel,
} from '../../../../data-access/gql-types/graphql';
import { getEnumKeyByValue, millisecondsToTime } from '../../../../utils/helpers';
import { ActionBindingType, ActionConfigResponseMap, BindingListItem, BindingsItem } from '../types';

export const actionOptions: Record<string, SelectOptionType<ActionBindingType>[]> = {
  [ChannelTypeInternal.Switch]: [
    {
      label: 'bindings.purposeOptions.default',
      value: ActionBindingType.Default,
      data: SwitchSupportedSlotsInternal.Toggle,
    },
    {
      label: 'bindings.purposeOptions.advanced',
      value: ActionBindingType.Switch,
      data: SwitchSupportedSlotsInternal.Advanced,
    },
  ],
  [ChannelTypeInternal.Light]: [
    {
      label: 'bindings.purposeOptions.default',
      value: ActionBindingType.Default,
      data: LightSupportedSlotsInternal.Toggle,
    },
    {
      label: 'bindings.purposeOptions.advanced',
      value: ActionBindingType.Light,
      data: LightSupportedSlotsInternal.Advanced,
    },
  ],
  [ChannelTypeInternal.Blind]: [
    {
      label: 'bindings.purposeOptions.blindOpen',
      value: ActionBindingType.BlindDefault1,
      data: BlindSupportedSlotsInternal.Open,
    },
    {
      label: 'bindings.purposeOptions.blindClose',
      value: ActionBindingType.BlindDefault2,
      data: BlindSupportedSlotsInternal.Close,
    },
    {
      label: 'bindings.purposeOptions.advanced',
      value: ActionBindingType.Blind,
      data: BlindSupportedSlotsInternal.Advanced,
    },
  ],
  [ChannelTypeInternal.Gate]: [
    {
      label: 'bindings.purposeOptions.limitSwitchOpen',
      value: ActionBindingType.GateDefault1,
      data: GateSupportedSlotsInternal.LimitOpen,
    },
    {
      label: 'bindings.purposeOptions.limitSwitchClose',
      value: ActionBindingType.GateDefault2,
      data: GateSupportedSlotsInternal.LimitClose,
    },
    {
      label: 'bindings.purposeOptions.opticalBarrier',
      value: ActionBindingType.GateDefault3,
      data: GateSupportedSlotsInternal.OpticBarrier,
    },
    {
      label: 'bindings.purposeOptions.button',
      value: ActionBindingType.GateDefault4,
      data: GateSupportedSlotsInternal.Button,
    },
    {
      label: 'bindings.purposeOptions.multiLimitSwitch',
      value: ActionBindingType.GateDefault5,
      data: GateSupportedSlotsInternal.MultiLimitSwitch,
    },
    {
      label: 'bindings.purposeOptions.doorDailyFuncButton',
      value: ActionBindingType.GateDefault6,
      data: GateSupportedSlotsInternal.DoorDailyFuncButton,
      disabled: true,
    },
    {
      label: 'bindings.purposeOptions.lockSensor',
      value: ActionBindingType.GateDefault7,
      data: GateSupportedSlotsInternal.LockSensor,
      disabled: true,
    },
    {
      label: 'bindings.purposeOptions.advanced',
      value: ActionBindingType.Gate,
      data: GateSupportedSlotsInternal.Advanced,
    },
  ],
};

export const actionBindingTypeByChannelType = (
  channelType: ChannelTypeInternal | 'Cloud' | undefined,
  binding: ActionConfigResponseMap,
) => {
  switch (channelType) {
    case ChannelTypeInternal.Switch: {
      return binding.isDefaultAction ? ActionBindingType.Default : ActionBindingType.Switch;
    }
    case ChannelTypeInternal.Light: {
      return binding.isDefaultAction ? ActionBindingType.Default : ActionBindingType.Light;
    }
    case ChannelTypeInternal.Blind: {
      switch (binding.slot) {
        case BlindSupportedSlotsInternal.Open:
          return ActionBindingType.BlindDefault1;
        case BlindSupportedSlotsInternal.Close:
          return ActionBindingType.BlindDefault2;
        case BlindSupportedSlotsInternal.Advanced:
          return ActionBindingType.Blind;
        default:
          return null;
      }
    }
    case ChannelTypeInternal.Gate: {
      switch (binding.slot) {
        case GateSupportedSlotsInternal.LimitOpen:
          return ActionBindingType.GateDefault1;
        case GateSupportedSlotsInternal.LimitClose:
          return ActionBindingType.GateDefault2;
        case GateSupportedSlotsInternal.OpticBarrier:
          return ActionBindingType.GateDefault3;
        case GateSupportedSlotsInternal.Button:
          return ActionBindingType.GateDefault4;
        case GateSupportedSlotsInternal.MultiLimitSwitch:
          return ActionBindingType.GateDefault5;
        case GateSupportedSlotsInternal.DoorDailyFuncButton:
          return ActionBindingType.GateDefault6;
        case GateSupportedSlotsInternal.LockSensor:
          return ActionBindingType.GateDefault7;
        case GateSupportedSlotsInternal.Advanced:
          return ActionBindingType.Gate;
        default:
          return null;
      }
    }
    case 'Cloud': {
      return ActionBindingType.Cloud;
    }
    default: {
      return ActionBindingType.Default;
    }
  }
};

const mapActionBindings = (binding: ActionConfigResponseMap, channels: UserChannel[]) => {
  let channelType: ChannelTypeInternal | 'Cloud' | undefined = undefined;

  if (binding.destination) {
    channelType = channels.find((x) => x.id === binding.destination?.channelId)?.channelType;
  } else {
    if ('onSignalActionId' in binding || 'offSignalActionId' in binding) {
      channelType = 'Cloud';
    }
  }

  return {
    inputTrigger: binding.inputTrigger,
    slot: binding.slot,
    channelType,
    actionType: actionBindingTypeByChannelType(channelType, binding),
    ...(binding.destination ? { channelId: binding.destination.channelId } : {}),
    ...('onSignalActionId' in binding || 'offSignalActionId' in binding ? { channelId: 'cloud' } : {}),
    ...('setOnTimeValue' in binding && binding.setOnTimeValue !== null
      ? { setOnTimeValue: millisecondsToTime(binding.setOnTimeValue as number) }
      : {}),
    ...('position' in binding && binding.position !== null ? { position: binding.position } : {}),
    ...('dir' in binding && binding.dir !== null ? { dir: binding.dir } : {}),
    ...('brightness' in binding && binding.brightness !== null ? { brightness: binding.brightness } : {}),
    ...('temperature' in binding && binding.temperature !== null ? { temperature: binding.temperature } : {}),
    ...('r' in binding && binding.r !== null ? { r: binding.r } : {}),
    ...('g' in binding && binding.g !== null ? { g: binding.g } : {}),
    ...('b' in binding && binding.b !== null ? { b: binding.b } : {}),
    ...('onSignalActionId' in binding && binding.onSignalActionId !== null
      ? { onSignalActionId: binding.onSignalActionId }
      : {}),
    ...('offSignalActionId' in binding && binding.offSignalActionId !== null
      ? { offSignalActionId: binding.offSignalActionId }
      : {}),
  } as BindingListItem;
};

export const mapBindingData = (inputChannel: UserChannel, channels: UserChannel[]) => {
  const list = ((inputChannel.payload as GenericInputChannelStateResponse).actionBindings || []).map((binding) =>
    mapActionBindings(binding as ActionConfigResponseMap, channels),
  );

  return {
    inputId: inputChannel.id,
    single: list.filter((binding) => binding.inputTrigger === InputTriggerInternal.SingleClick),
    double: list.filter((binding) => binding.inputTrigger === InputTriggerInternal.DoubleClick),
  } as BindingsItem;
};

export const getEnumKeySlot = (channelType: ChannelTypeInternal | 'Cloud', slot: string | null) => {
  if (!slot) return '';

  switch (channelType) {
    case ChannelTypeInternal.Switch: {
      return getEnumKeyByValue(SwitchSupportedSlotsInternal, slot);
    }
    case ChannelTypeInternal.Light: {
      return getEnumKeyByValue(LightSupportedSlotsInternal, slot);
    }
    case ChannelTypeInternal.Blind: {
      return getEnumKeyByValue(BlindSupportedSlotsInternal, slot);
    }
    case ChannelTypeInternal.Gate: {
      return getEnumKeyByValue(GateSupportedSlotsInternal, slot);
    }
    default: {
      return '';
    }
  }
};
