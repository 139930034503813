import React from 'react';
import c from 'classnames';
import './index.scss';

type IconRecoveryProps = {
  normal?: boolean;
  big?: boolean;
  details?: boolean;
  iconToastError?: boolean;
};

export const IconRecovery: React.FC<IconRecoveryProps> = ({ normal, big, details, iconToastError }) => (
  <div className={c('icon-recovery', { normal: normal, big: big, details: details, 'icon-toast': iconToastError })}>
    !
  </div>
);
