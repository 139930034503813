import React from 'react';

type IconVerticalGateProps = {
  position?: number; // In percents from 0 to 100
};

export const IconVerticalGate: React.FC<IconVerticalGateProps> = ({ position = 100 }) => (
  <svg
    className="icon-cover icon-vertical-gate"
    width="38"
    height="33"
    viewBox="0 0 38 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.2 31.1893L37.2473 0.85771L1.0 0.85771L1 31.1893"
      stroke="#15141D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    {position > 10 && (
      <path
        d="M36.25 8.15384L0.999992 8.15384"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    )}
    {position > 30 && (
      <path
        d="M36.25 15.7L0.999992 15.7"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    )}
    {position > 69 && (
      <path
        d="M36.25 23.5462L0.999992 23.5462"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    )}
    {position > 99 && (
      <path
        d="M36.25 31.1662L0.999992 31.1662"
        stroke="#15141D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    )}
  </svg>
);
