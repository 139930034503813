import { gql } from '@apollo/client';

export const CHANNELS_GET_ALL = gql`
  query ChannelsGetAll($installationId: UUID!) {
    allUserChannels(installationId: $installationId, order: { deviceId: ASC, alias: ASC }) {
      installationId
      usesCounter
      grantedUses
      alias
      userId
      iconName
      isVisible
      id
      deviceId
      channelType
      currentFirmwareVersion
      personalization {
        __typename
        type
        ... on NoPersonalization {
          type
          __typename
        }
        ... on GatePersonalization {
          isQuickControl
          type
          __typename
        }
      }
      payload {
        id: channelId
        channelId
        deviceConnectionState
        deviceId
        ... on SwitchChannelStateResponse {
          state
          measuredBy {
            meterChannelId
            phase
          }
        }
        ... on BlindChannelStateResponse {
          position
          measuredBy {
            meterChannelId
            phase
          }
          direction
          calibrateState {
            closingMilliseconds
            openingMilliseconds
            progress
            started
            calibrateBlindError: error
          }
        }
        ... on MeterChannelStateResponse {
          meterType
          supportedVectorParameters
          supportedScalarParameters
          supportedAggregateVectorParameters
          payload {
            ... on InternalMeasurementPayload {
              id: channelId
              channelId
            }
            ... on DefaultMeterTypeMeasurementPayload {
              id: channelId
              channelId
            }
            ... on StandaloneMeasurementPayload {
              id: channelId
              channelId
              ... @defer {
                activeEnergy
                maxActivePower
                averageActivePower
                sumOfActivePower
              }
            }
          }
        }
        ... on LightChannelStateResponse {
          supportedModes
          toggleValue
          brightness
          temperature
          color {
            r
            g
            b
          }
        }
        ... on OptimizerChannelStateResponse {
          __typename
          mode
          supportedVectorParameters
          supportedScalarParameters
          supportedAggregateVectorParameters
        }
        ... on GateChannelStateResponse {
          gateKind
          position
          electricStrikeStatus
          partialControlModeStatus
          dailyModeStatus
          lockStatus
          gateDirection: direction
          gateMode
          supportedGateFeatures
          dependantChannels {
            channelId
            channelType
            index
          }
          calibrateState {
            closingMilliseconds
            openingMilliseconds
            progress
            started
            calibrateGateError: error
          }
        }
        ... on GenericInputChannelStateResponse {
          genericInputType
          bistableMode
          monostableMode
        }
      }
    }
  }
`;

export const CHANNEL_DETAILS = gql`
  fragment IntValidationFragment on IntValidation {
    min
    max
    step
  }

  fragment FloatValidationFragment on FloatValidation {
    min
    max
    step
  }

  fragment RelayConfigFragment on RelayConfig {
    enabled
    index
    thresholdValue
    timeOff
    timeOn
  }

  fragment VoltageThresholdFragment on VoltageThreshold {
    index
    value
  }

  fragment WorkingHoursFragment on WorkingHours {
    from
    to
  }

  fragment TariffFragment on Tariff {
    hoursRanges {
      from
      to
      timeZoneKind
    }
    wholeWeekendCheaper
    wholeHolidaysCheaper
    ignoreSummerTimeChanges
    kind
  }

  query ChannelsDetails($installationId: UUID!, $channelId: UUID!) {
    userChannel(installationId: $installationId, id: $channelId) {
      installationId
      usesCounter
      grantedUses
      alias
      userId
      iconName
      isVisible
      id
      deviceId
      channelType
      currentFirmwareVersion
      favouritePositions {
        ... on PositionFavourites {
          value
        }
        ... on LightsFavourites {
          value
          brightness
          color {
            red
            green
            blue
          }
        }
        ... on SetOnTimeFavourites {
          value
        }
      }
      personalization {
        __typename
        type
        ... on NoPersonalization {
          type
          __typename
        }
        ... on GatePersonalization {
          isQuickControl
          isPartialControlModeVisible
          isDailyModeVisible
          type
          __typename
        }
      }
      payload {
        channelId
        id: channelId
        deviceConnectionState
        deviceId
        ... on SwitchChannelStateResponse {
          state
          supplyReturnBehavior
          measuredBy {
            meterChannelId
            phase
          }
        }
        ... on BlindChannelStateResponse {
          position
          measuredBy {
            meterChannelId
            phase
          }
          direction
          calibrateState {
            closingMilliseconds
            openingMilliseconds
            progress
            started
            calibrateBlindError: error
          }
        }
        ... on MeterChannelStateResponse {
          meterType
          ampTransformers
          meterPurpose
          tariff {
            kind
            wholeWeekendCheaper
            wholeHolidaysCheaper
            ignoreSummerTimeChanges
            hoursRanges {
              from
              to
              timeZoneKind
              pricingTier
            }
          }
          payload {
            ... on InternalMeasurementPayload {
              id: channelId
              channelId
            }
            ... on DefaultMeterTypeMeasurementPayload {
              id: channelId
              channelId
            }
            ... on StandaloneMeasurementPayload {
              id: channelId
              channelId
              ... @defer {
                activeEnergy
                maxActivePower
                averageActivePower
                sumOfActivePower
              }
            }
          }
          supportedVectorParameters
          supportedScalarParameters
          supportedAggregateVectorParameters
        }
        ... on LightChannelStateResponse {
          supportedModes
          supplyReturnBehavior
          toggleValue
          brightness
          temperature
          color {
            r
            g
            b
          }
        }
        ... on OptimizerChannelStateResponse {
          __typename
          mode
          supportedVectorParameters
          supportedScalarParameters
          supportedAggregateVectorParameters

          selfConsumptionWithPowerLimit {
            tempMin
            heaterPowerLimit
            tempMax
            tempMinEnabled
            tempSelfConsumption
            powerLimit
            workingHours {
              ...WorkingHoursFragment
            }
            voltageThreshold {
              ...VoltageThresholdFragment
            }
            relaysConfig {
              ...RelayConfigFragment
            }
            tariff {
              ...TariffFragment
            }
          }

          selfConsumptionWithRelays {
            workingHours {
              ...WorkingHoursFragment
            }
            relaysConfig {
              ...RelayConfigFragment
            }
          }

          fullSelfConsumption {
            tempMin
            heaterPowerLimit
            tempMax
            tempMinEnabled
            tempSelfConsumption
            workingHours {
              ...WorkingHoursFragment
            }
            voltageThreshold {
              ...VoltageThresholdFragment
            }
            relaysConfig {
              ...RelayConfigFragment
            }
            tariff {
              ...TariffFragment
            }
          }
          comfort {
            tempMax
            heaterPowerLimit
            tempMin
            voltageThreshold {
              ...VoltageThresholdFragment
            }
            relaysConfig {
              ...RelayConfigFragment
            }
            tariff {
              ...TariffFragment
            }
          }
          eco {
            tempMax
            voltageThreshold {
              ...VoltageThresholdFragment
            }
            relaysConfig {
              ...RelayConfigFragment
            }
          }
          configValidation {
            relay {
              thresholdPercent {
                ...FloatValidationFragment
              }
              thresholdPower {
                ...FloatValidationFragment
              }
              timeOn {
                ...IntValidationFragment
              }
              timeOff {
                ...IntValidationFragment
              }
              __typename
            }
            voltageThreshold {
              ...FloatValidationFragment
            }
            tempMax {
              ...FloatValidationFragment
            }
            tempMin {
              ...FloatValidationFragment
            }
            tempSelfConsumptionMin {
              ...FloatValidationFragment
            }
            heaterPowerLimit {
              ...FloatValidationFragment
            }
            powerLimit {
              ...FloatValidationFragment
            }
            __typename
          }
        }
        ... on GateChannelStateResponse {
          gateKind
          position
          electricStrikeStatus
          partialControlModeStatus
          dailyModeStatus
          lockStatus
          alertConfig {
            barrierAlertEnabled
            misdirectionAlertEnabled
            externalSourceTriggeredAlertEnabled
            openedIntervalToNotifyInMs
          }
          gateDirection: direction
          channelId
          gateMode
          sbsSignalTimeInMs
          supportedGateFeatures
          dependantChannels {
            channelId
            channelType
            index
          }
          calibrateState {
            closingMilliseconds
            openingMilliseconds
            progress
            started
            calibrateGateError: error
          }
        }
        ... on GenericInputChannelStateResponse {
          genericInputType
          bistableMode
          monostableMode
        }
      }
      sharedChannelSettings {
        id
        ... on MeterSharedChannelSettings {
          tariffConfigurations {
            tariff
            prices {
              pricingTier
              price
            }
          }
        }
      }
    }
  }
`;

export const GATE_HISTORY = gql`
  query GateHistory($installationId: UUID!, $channelId: UUID!, $count: NonNegativeInt!, $offset: NonNegativeInt!) {
    gateUserChannel: userChannel(installationId: $installationId, id: $channelId) {
      installationId
      id
      deviceId
      channelType
      payload {
        id: channelId
        ... on GateChannelStateResponse {
          ... @defer {
            history(count: $count, offset: $offset) {
              error {
                errorCode
                description
              }
              data {
                totalCount
                entries {
                  id
                  eventType
                  value
                  occuredAt
                  controlledBy {
                    sourceId
                    id
                    controlSource
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DYNAMIC_MARKET_PRICES = gql`
  query DynamicMarketPrices {
    dynamicMarketPrices {
      day
      records {
        time
        priceInMWh
        priceInkWh
        startingHour
      }
    }
  }
`;