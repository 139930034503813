import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import {
  ButtonTypeBlind as ButtonTypeBlindType,
  InputModeBlind as InputModeBlindType,
  InputOutputBlindParameters,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/BlindConfigurations/BlindConfigParameters';
import { ISrp22ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/ISrp22ConfigService';
import { ISrp30ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/SrpConfigService/ISrp30ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { InputSelect, SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../hooks';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';

type InputsModeProps = {
  device: IDevice;
  inputs: InputOutputBlindParameters | null;
};

const InputsMode: React.FC<InputsModeProps> = ({ device, inputs }) => {
  const { t } = useTranslation('device-info');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { configService: serviceName, handleSubmitResponse } = useConfigurationService(device);
  const form = useForm<InputOutputBlindParameters>();

  const { ButtonTypeBlind, InputModeBlind } = form.watch();

  useEffect(() => {
    if (inputs !== null) {
      form.setValue('InputModeBlind', inputs.InputModeBlind);
      form.setValue('ButtonTypeBlind', inputs.ButtonTypeBlind);
    }
  }, [inputs]);

  const onSubmit = form.handleSubmit(async (values) => {
    if (inputs) {
      turnOnBackdrop();
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<
        ISrp30ConfigService | ISrp22ConfigService
      >(serviceName);

      const result = await configService.SetBlindInputOutputParamAsync(device, {
        InputModeBlind: values.InputModeBlind,
        ButtonTypeBlind: values.ButtonTypeBlind,
        Channel: inputs.Channel,
      });
      handleSubmitResponse(result);
      turnOffBackdrop();
    }
  });

  const onChangeInputModeBlind = (value: InputModeBlindType) => {
    form.setValue('InputModeBlind', value);
  };

  const onChangeButtonTypeBlind = (value: ButtonTypeBlindType) => {
    form.setValue('ButtonTypeBlind', value);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="grid-list-24">
        <InputSelect
          onChange={onChangeInputModeBlind}
          value={InputModeBlind}
          options={[
            { label: t('exalus.params.SrpParams.InputModeSteps.Local'), value: InputModeBlindType.Local },
            { label: t('exalus.params.SrpParams.InputModeSteps.Central'), value: InputModeBlindType.Central },
          ]}
          label={t('exalus.params.SrpParams.InputMode')}
        />
        <InputSelect
          onChange={onChangeButtonTypeBlind}
          value={ButtonTypeBlind}
          options={[
            { label: t('exalus.params.SrpParams.ButtonTypeSteps.Single'), value: ButtonTypeBlindType.SingleButton },
            { label: t('exalus.params.SrpParams.ButtonTypeSteps.Double'), value: ButtonTypeBlindType.DoubleButton },
          ]}
          label={t('exalus.params.SrpParams.ButtonType')}
        />
        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default InputsMode;
