import {
  BlindChannelStateResponse,
  ChannelTypeInternal,
  PositionFavourites,
  UserChannel,
} from '../../../data-access/gql-types/graphql';
import { ChannelIconTypeEnum, ChannelType, ChannelStateType } from '../../../types';

export const parseCoverChannel = (userChannel: UserChannel): ChannelType => ({
  id: userChannel.id,
  installationId: userChannel.installationId,
  isVisible: userChannel.isVisible,
  alias: userChannel.alias,
  iconName: userChannel.iconName || ChannelIconTypeEnum.COVER,
  deviceId: userChannel.deviceId,
  currentFirmwareVersion: userChannel.currentFirmwareVersion || null,
  data: {
    type: ChannelTypeInternal.Blind,
    favouritePositionList: (userChannel.favouritePositions as PositionFavourites[])?.map((position) =>
      position.value ? Math.round(position.value * 100) : 0,
    ) as [number, number, number],
    measuredBy: (userChannel.payload as BlindChannelStateResponse)?.measuredBy || undefined,
  },
});

export const parseCoverChannelState = (userChannel: UserChannel): ChannelStateType => ({
  lavvaStateDirection: (userChannel.payload as BlindChannelStateResponse)?.direction,
  lavvaStatePosition: (userChannel.payload as BlindChannelStateResponse)?.position,
  calibrateState: (userChannel.payload as BlindChannelStateResponse)?.calibrateState || undefined,
  movingParams: {
    predictedTimeInMs: null,
    targetPosition: null,
    slatsPredictedTimeInMs: null,
  },
  deviceConnectionState: userChannel.payload?.deviceConnectionState,
  usesCounter: userChannel.usesCounter,
  grantedUses: userChannel.grantedUses || undefined,
});
