import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IRcr21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MovementSensor/IRcr21ConfigService';
import {
  MovementDetectorParams,
  PirSensorParams,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MovementSensor/Rcr21ConfigParams';
import { Rcr21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/MovementSensor/Rcr21ConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../hooks';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import DetectorForm from './detector';
import RipForm from './rip';
import { initialConfig, Rcr21ConfigParams, Rcr21ParamsEnum } from './types';

type Rcr21ConfigFormProps = {
  device: IDevice;
  channel?: IDeviceChannel;
};

const Rcr21ConfigForm: React.FC<Rcr21ConfigFormProps> = ({ device, channel }) => {
  const { t } = useTranslation('device-info');
  const [config, setConfig] = useState<Rcr21ConfigParams>(initialConfig);
  const [open, setOpen] = useState<boolean>(false);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const {
    handleGetParamsResponse,
    handleSubmitResponse,
    DiagnosticButton,
    DiagnosticResultPopup,
    firmwareService,
    UserManualButton,
  } = useConfigurationService(device);
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);

  const getDefaultParams = async (panel: Rcr21ParamsEnum) => {
    if (channel) {
      turnOnBackdrop();
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRcr21ConfigService>(
        Rcr21ConfigService.ServiceName,
      );

      switch (panel) {
        case Rcr21ParamsEnum.RIP_SENSOR: {
          const data = await configService.GetPirSensorParamsAsync(device, channel.Number);

          handleGetParamsResponse<PirSensorParams>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as PirSensorParams });
            setOpen(true);
          });
          break;
        }
        case Rcr21ParamsEnum.MOVEMENT_DETECTOR: {
          const data = await configService.GetMovementDetectorParamsAsync(device, channel.Number);

          handleGetParamsResponse<MovementDetectorParams>(data, () => {
            setConfig({ ...initialConfig, [panel]: data as MovementDetectorParams });
            setOpen(true);
          });
          break;
        }
        default:
          break;
      }

      turnOffBackdrop();
    }
  };

  const resetAlarm = useCallback(async () => {
    if (channel) {
      turnOnBackdrop();
      const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRcr21ConfigService>(
        Rcr21ConfigService.ServiceName,
      );

      const result = await configService.ClearTamperAlarmAsync(device, channel.Number);

      handleSubmitResponse(result, 'alarmResetSuccessfully');

      turnOffBackdrop();
    }
  }, [channel]);

  const handleOpen = (panel: Rcr21ParamsEnum) => getDefaultParams(panel);

  const handleBack = useCallback(() => {
    setOpen(false);
    setConfig({ ...initialConfig });
  }, []);

  const activeConfigName = useMemo(() => Object.keys(config).find((key) => config[key] !== null) || null, [config]);

  const renderCorrentForm = () => {
    if (!activeConfigName) return null;

    switch (activeConfigName) {
      case Rcr21ParamsEnum.RIP_SENSOR: {
        return channel ? <RipForm device={device} channel={channel} params={config[activeConfigName]} /> : null;
      }
      case Rcr21ParamsEnum.MOVEMENT_DETECTOR: {
        return channel ? <DetectorForm device={device} channel={channel} params={config[activeConfigName]} /> : null;
      }
      default:
        return null;
    }
  };

  return (
    <>
      {open ? (
        <ConfigCustomPage handleBack={handleBack}>{renderCorrentForm()}</ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.Rcr21Params.RipSensorParams')}
                onClick={() => handleOpen(Rcr21ParamsEnum.RIP_SENSOR)}
              />
              <ArrowButton
                className="m-b-24"
                title={t('exalus.params.Rcr21Params.MovementDetectorParams')}
                onClick={() => handleOpen(Rcr21ParamsEnum.MOVEMENT_DETECTOR)}
              />
              <ArrowButton className="m-b-24" title={t('exalus.params.Rcr21Params.ResetAlarm')} onClick={resetAlarm} />
            </>
          ) : (
            <>
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
              {DiagnosticButton}
              {UserManualButton}
            </>
          )}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default Rcr21ConfigForm;
