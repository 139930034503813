import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PopUpNavLink } from '../../../../../components';
import { availableGroupIcons } from '../../../../../components/action/edit-icon/available-icons';
import {
  IconChannelsOrder,
  IconEdit,
  IconGridView,
  IconGroups,
  IconListView,
  IconOrder,
  IconAdd,
} from '../../../../../components/popup-nav/icons';
import { useInstallation } from '../../../../../hooks';
import { useRefetchData } from '../../../../../hooks/refresh-data';
import { ROUTES } from '../../../../../routes';
import { diffHours } from '../../../../../utils/helpers';
import * as storage from '../../../../../utils/storage/lavva';
import { useExalusContext } from '../../../context';
import { useExalusServicesContext } from '../../../context/services';
import { useExalusStorageContext } from '../../../context/storage';
import { useLoggedUser } from '../../../hooks/common/logged-user';
import { getIcon } from '../../../utils/icons';

export const useDashboard = () => {
  const history = useHistory();
  const { t } = useTranslation('dashboard');
  const { t: tg } = useTranslation('groups');
  const { activeGroup, allGroups } = useExalusContext();
  const { installationList } = useInstallation();
  const { isDashboardGridView, toggleGridView } = useExalusStorageContext();
  const { groupsApi } = useExalusServicesContext();
  const { canAddEditGroup } = useLoggedUser();
  const { refetchAll } = useRefetchData();

  useEffect(() => {
    if (localStorage.getItem('local-app')) {
      const remindCreateAccount = storage.getItem('remindCreateAccount');
      if (!remindCreateAccount) history.push(ROUTES.CreateAccountOffer());
      else if (diffHours(new Date(remindCreateAccount), 72)) history.push(ROUTES.CreateAccountOffer());
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!installationList.length) refetchAll();
    }, 300);

    return () => clearTimeout(timeout);
  }, [installationList.length]);

  const popUpNavLinks = useMemo((): PopUpNavLink[] => {
    return [
      ...(canAddEditGroup
        ? [
            {
              onClick: () => history.push(ROUTES.GroupEdit(activeGroup?.Guid)),
              label: t('groupEdit', { groupName: activeGroup?.Name }),
              hasLineBelow: true,
              icon: <IconEdit />,
            },
            { onClick: () => history.push(ROUTES.GroupCreate()), label: t('newGroup'), icon: <IconAdd /> },
            {
              onClick: () => history.push(ROUTES.Group()),
              label: tg('allGroups'),
              hasLineBelow: true,
              icon: <IconGroups />,
            },
          ]
        : []),
      ...(groupsApi.IsGroupsAndObjectsOrderingAvailable() && canAddEditGroup
        ? [
            {
              onClick: () => history.push(ROUTES.GroupSortChannels(!isDashboardGridView)),
              label: t('channelsOrder'),
              icon: <IconChannelsOrder />,
            },
            {
              onClick: () => history.push(ROUTES.GroupSort()),
              label: t('groupsOrder'),
              hasLineBelow: true,
              icon: <IconOrder />,
            },
          ]
        : []),
      {
        onClick: toggleGridView,
        label: !isDashboardGridView ? t('gridView') : t('listView'),
        icon: !isDashboardGridView ? <IconGridView /> : <IconListView />,
      },
    ];
  }, [activeGroup, isDashboardGridView, canAddEditGroup, t, tg]);

  const tabs = useMemo(() => {
    const items = allGroups.map((group) => {
      const Icon = getIcon(availableGroupIcons, group.IconName);
      return { label: group.Name, ...(Icon ? { icon: Icon.component } : {}) };
    });

    return [...items, { label: '+', onClick: () => history.push(ROUTES.GroupCreate()), addNew: true }];
  }, [allGroups]);

  return {
    popUpNavLinks,
    tabs,
  };
};
