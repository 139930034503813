import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, DialogConfirmation } from '../../../../components';
import { GroupItem } from '../../types';

type GroupsModifyPopupProps = {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  handleSave: () => void;
  toggleGroup: (id: string) => void;
  groupList: GroupItem[];
};

export const GroupsModifyPopup: React.FC<GroupsModifyPopupProps> = ({
  show,
  setShow,
  handleSave,
  toggleGroup,
  groupList,
}) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');

  return (
    <DialogConfirmation
      show={show}
      setShow={setShow}
      header={t('groupAddRemove')}
      primaryBtnText={tc('buttons.save')}
      secondaryBtnText={tc('buttons.cancel')}
      primaryBtnAction={handleSave}
      secondaryBtnAction={() => setShow(false)}
    >
      {groupList.map((group) => (
        <Checkbox key={group.id} checked={group.checked} onChange={() => toggleGroup(group.id)} reverse>
          {group.name}
        </Checkbox>
      ))}
    </DialogConfirmation>
  );
};
