import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Header, NavHeader, Page, SubmitButton } from '../../../../../components';
import { ROUTES } from '../../../../../routes';
import { pathnameWithParameters } from '../../../../../utils/location';
import { useBluetoothContext } from '../../../context';
import { DriveFlowType } from '../../../types';
import './index.scss';

const WisniowskiDriveAddTutorialStep0: React.FC = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { type } = useParams<{ type: DriveFlowType }>();
  const { t } = useTranslation('drive-add');
  const { t: tc } = useTranslation('common');
  const [learnMore, setLearnMore] = useState<boolean>(false);
  const { checkIfConnected } = useBluetoothContext();

  useEffect(() => {
    checkIfConnected();
  }, []);

  useEffect(() => {
    const learn = new URLSearchParams(search).get('learn');
    if (learn) setLearnMore(true);
    else setLearnMore(false);
  }, [search]);

  const handleLearnMore = () => {
    history.push(
      pathnameWithParameters(pathname, search, {
        key: 'learn',
        value: true,
      }),
    );
  };

  const handleNext = () => {
    switch (type) {
      case DriveFlowType.Remote: {
        history.push(ROUTES.DeviceAddBluetoothWisniowskiTutorialStep2(DriveFlowType.Remote));
        break;
      }
      case DriveFlowType.Drive: {
        history.push(ROUTES.DeviceAddBluetoothWisniowskiTutorialStep1());
        break;
      }
      default: {
        history.push(ROUTES.DeviceAddBluetoothNetwork());
        break;
      }
    }
  };

  return (
    <>
      {!learnMore ? (
        <Page
          contentClassName="drive-add tutorial"
          containerClassName="p-t-0"
          isStickyHeader
          header={<NavHeader title={tc('buttons.return')} />}
        >
          <div className="tutorial--content">
            <Header title={t('tutorial.photocellConfirmation')} center />
            <img src="/images/photocell.png" alt="drive-case" />
            <button className="learn-more m-t-24" onClick={handleLearnMore}>
              {t('tutorial.learnMore')}
            </button>
            <SubmitButton onClick={handleNext}>{t('tutorial.confirm')}</SubmitButton>
          </div>
        </Page>
      ) : (
        <Page contentClassName="drive-add" kind="above" header={<NavHeader title={tc('buttons.return')} />}>
          <div className="tutorial--content">
            <Header title={t('tutorial.learnMore')} />
            <p>{t('tutorial.learnMoreInfo.message')}</p>
            <div className="statement-container">
              <p className="question-mark">?</p>
              <p className="statement">{t('tutorial.learnMoreInfo.statement')}</p>
            </div>
          </div>
        </Page>
      )}
    </>
  );
};

export default WisniowskiDriveAddTutorialStep0;
