import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { FloodSensorStates } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { IconToastSuccess } from '../../../../../../../components';
import { IconToastWarning2 } from '../../../../../../../components/icons/icon-toast-warning-2';
import { useFloodControl } from '../../../hooks/use-flood-control';
import { SensorControlBox } from '../../../sensor-control-box';

type FloodControlProps = {
  channel: IDeviceChannel;
  isListItem?: boolean;
  details?: boolean;
};

const FloodControl: React.FC<FloodControlProps> = ({ channel, isListItem, details }) => {
  const { t } = useTranslation('channel-details');
  const { flood } = useFloodControl(channel);

  return (
    <SensorControlBox isListItem={isListItem} details={details}>
      <span className="sensor-control-box__icon">
        {flood === null ? (
          details ? (
            <span className="sensor-control-box__status">{t('exalus.sensor.noData')}</span>
          ) : null
        ) : flood === FloodSensorStates.FloodDetected ? (
          <>
            <IconToastWarning2 colorError size={16} />
            <span className="sensor-control-box__error-text">{t('exalus.floodDetected.heading')}</span>
          </>
        ) : (
          <IconToastSuccess />
        )}
      </span>
    </SensorControlBox>
  );
};

export default FloodControl;
