import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { addDays, format, isSameDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import * as duration from 'duration-fns';
import { useLazyQuery } from '@apollo/client';
import {
  DialogSelect,
  DialogTimePicker,
  IconBullet,
  IconEdit,
  IconLocation2,
  IconRepeat,
  IconTime,
  IconWrapper,
  Input,
  SelectOptionType,
  SubmitButton,
  SwitcherOptionType,
  IconDayTime,
  IconError,
  IconSunDown,
  IconSunUp,
} from '../../../../components';
import Calendar from '../../../../components/calendar';
import { DEFAULT_TIME_ZONE } from '../../../../const';
import {
  CalculateNextTriggerFireTimeQueryVariables,
  DayOfWeekInternal,
  Query,
} from '../../../../data-access/gql-types/graphql';
import { CALCULATE_NEXT_TRIGGER_FIRE_TIME } from '../../../../data-access/queries/triggers';
import { useInstallation } from '../../../../hooks';
import { TriggerTimeConditionType } from '../../../../types';
import { getFormatInTimeZone, leadingZero, sortedDays } from '../../../../utils/helpers';
import { convertMinutesToHours } from '../../../../utils/helpers';
import { convertToTimeSpanFormat } from '../../../../utils/helpers/timespan-format';
import { useTriggerFormContext } from '../../context';
import { StepComponentProps } from '../../steps';
import {
  AdvanceDelayDialog,
  InstallationLocationDialog,
  RepeatCountDialog,
  TimeEndDialog,
  SelectedDaysDialog,
} from './components';
import { TimeEndType, TimeType, SunAction, SunActionModification, RepeatType } from './enums';
import './index.scss';

export const TimeCondition: React.FC<StepComponentProps> = ({ goToChannels, goToSummary }) => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { setTimeCondition, timeConditionConfig, setTimeConditionConfig, actions } = useTriggerFormContext();
  const [internalTimeCondition, setInternalTimeCondition] = useState<TriggerTimeConditionType>({});
  const { handleSubmit } = useForm();
  const { selectedInstallation } = useInstallation();

  const now = new Date();
  const [timeType, setTimeType] = useState<TimeType>(timeConditionConfig.timeType);
  const [timeRepeat, setTimeRepeat] = useState(timeConditionConfig.timeRepeat);
  const [sunTimeValue, setSunTimeValue] = useState<number>(timeConditionConfig.sunTimeValue);
  const [selectedDays, setSelectedDays] = useState<DayOfWeekInternal[]>(timeConditionConfig.selectedDays);
  const [repeatCount, setRepeatCount] = useState(timeConditionConfig.repeatCount);

  const [startDate, setStartDate] = useState(timeConditionConfig.startDate ?? new Date());
  const [endDate, setEndDate] = useState(timeConditionConfig.endDate ?? new Date());
  const [isEndDateOpen, setIsEndDateOpen] = useState<boolean>(false);
  const [nextFireTime, setNextFireTime] = useState<string | null>(null);
  const [isValid, setIsValid] = useState<boolean>(false);

  const [showInstallationLocationDialog, setShowInstallationLocationDialog] = useState<boolean>(false);
  const [showTimePicker, setShowTimePicker] = useState<boolean>(false);
  const [showSunActionPicker, setShowSunActionPicker] = useState<boolean>(false);
  const [showSunActionModificationPicker, setShowSunActionModificationPicker] = useState<boolean>(false);
  const [showTimeEndPicker, setShowTimeEndPicker] = useState<boolean>(false);
  const [showRepeatPicker, setShowRepeatPicker] = useState<boolean>(false);
  const [showAdvanceDelayPicker, setShowAdvanceDelayPicker] = useState<boolean>(false);
  const [showWeekDaysRepeatPicker, setShowWeekDaysRepeatPicker] = useState<boolean>(false);
  const [showRepeatCountPicker, setShowRepeatCountPicker] = useState<boolean>(false);

  const [calculateNextTriggerFireTime, { loading: calculateNextFireLoading }] = useLazyQuery<
    Query,
    CalculateNextTriggerFireTimeQueryVariables
  >(CALCULATE_NEXT_TRIGGER_FIRE_TIME, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.calculateNextTriggerFireTime?.nextFireTime) {
        const time = duration.parse(data?.calculateNextTriggerFireTime?.nextFireTime?.time);

        if (time) {
          const parsedTime = `${leadingZero(time.hours)}:${leadingZero(time.minutes)}`;
          const fireTime = formatInTimeZone(
            `${data?.calculateNextTriggerFireTime?.nextFireTime?.date}T${parsedTime}Z`,
            selectedInstallation?.payload?.timeZone || DEFAULT_TIME_ZONE,
            'dd.MM.yyyy, HH:mm',
          );
          setIsValid(true);
          setNextFireTime(fireTime);
        }
      } else {
        setIsValid(false);
        setNextFireTime(null);
      }
    },
    onError: () => {
      setIsValid(false);
      setNextFireTime(null);
    },
  });

  const shortDayOfWeek = (day: DayOfWeekInternal) => {
    return t(`trigger.shortenedDaysOfWeek.${day.toLowerCase()}`);
  };

  const timeTypeOptions: SwitcherOptionType[] = [
    {
      value: TimeType.HOUR,
      label: t('trigger.conditions.time.types.hour'),
      icon: <IconTime size={28} />,
    },
    {
      value: TimeType.DAYTIME,
      label: t('trigger.conditions.time.types.daytime'),
      icon: <IconDayTime />,
    },
  ];
  const onTimeTypeChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value === TimeType.DAYTIME && !selectedInstallation?.payload?.coordinates) {
      setShowInstallationLocationDialog(true);
      return;
    }
    setTimeType(target.value as TimeType);
  };

  const sunActionOptions = [
    {
      value: SunAction.SUNRISE,
      label: t('trigger.sunActionOptions.sunrise'),
      icon: <IconSunUp />,
      shortLabel: t('trigger.sunActionOptionsShort.sunrise'),
    },
    {
      value: SunAction.SUNSET,
      label: t('trigger.sunActionOptions.sunset'),
      icon: <IconSunDown />,
      shortLabel: t('trigger.sunActionOptionsShort.sunset'),
    },
  ];
  const sunActionOnChange = useCallback(
    (value) => {
      setSunAction(sunActionOptions.find((option) => option.value === value) ?? sunActionOptions[0]);
      setShowSunActionPicker(false);
    },
    [setShowSunActionPicker],
  );
  const [sunAction, setSunAction] = useState(
    sunActionOptions.find((option) => option.value === timeConditionConfig.sunAction) ?? sunActionOptions[0],
  );

  const sunActionModificationOptions: SelectOptionType<SunActionModification>[] = [
    { value: SunActionModification.NONE, label: t('trigger.sunActionModificationOptions.none') },
    { value: SunActionModification.DELAY, label: t('trigger.sunActionModificationOptions.delay') },
    { value: SunActionModification.ADVANCE, label: t('trigger.sunActionModificationOptions.advance') },
  ];
  const sunActionModificationOnChange = useCallback(
    (value) => {
      setSunActionModification(
        sunActionModificationOptions.find((option) => option.value === value) ?? sunActionModificationOptions[0],
      );
      setShowSunActionModificationPicker(false);
    },
    [setShowSunActionModificationPicker],
  );
  const [sunActionModification, setSunActionModification] = useState(
    sunActionModificationOptions.find((option) => option.value === timeConditionConfig.sunActionModification) ??
      sunActionModificationOptions[0],
  );

  const selectedDaysLabel = useMemo(() => {
    const days = [...selectedDays]
      .sort((a, b) => sortedDays[a] - sortedDays[b])
      .map((day) => shortDayOfWeek(day))
      .join(', ');

    return selectedDays.length ? `: ${days}` : '';
  }, [selectedDays, showWeekDaysRepeatPicker]);
  const repeatOptions: SelectOptionType<RepeatType>[] = useMemo(
    () => [
      { value: RepeatType.ONCE, label: t('trigger.conditions.time.repeat.once') },
      { value: RepeatType.DAILY, label: t('trigger.conditions.time.repeat.daily') },
      {
        value: RepeatType.SELECTED,
        label: `${t('trigger.conditions.time.repeat.selected')}${selectedDaysLabel}`,
      },
      { value: RepeatType.MONTHLY, label: t('trigger.conditions.time.repeat.monthly') },
      { value: RepeatType.YEARLY, label: t('trigger.conditions.time.repeat.yearly') },
    ],
    [selectedDays, selectedDaysLabel, showWeekDaysRepeatPicker],
  );
  const [repeatSelected, setRepeatSelected] = useState(
    repeatOptions.find((option) => option.value === timeConditionConfig.repeatType) ?? repeatOptions[1],
  );
  const repeatOnChange = useCallback(
    (value) => {
      setRepeatSelected(repeatOptions.find((option) => option.value === value) ?? repeatOptions[0]);
      setShowRepeatPicker(false);
      switch (value) {
        case RepeatType.ONCE: {
          setTimeEndType(timeEndOptions[2]);
          setRepeatCount(1);
          break;
        }
        case RepeatType.DAILY: {
          setTimeEndType(timeEndOptions[0]);
          break;
        }
        case RepeatType.SELECTED: {
          setShowWeekDaysRepeatPicker(true);
          break;
        }
        default: {
          break;
        }
      }
      if (value !== RepeatType.SELECTED) {
        setSelectedDays([]);
      }
    },
    [setShowRepeatPicker, showRepeatPicker, showWeekDaysRepeatPicker],
  );
  useEffect(() => {
    const closeRepeatPicker = !showRepeatPicker && !showWeekDaysRepeatPicker;
    if (closeRepeatPicker) {
      if (!selectedDays.length && repeatSelected.value === RepeatType.SELECTED) {
        setRepeatSelected(repeatOptions.find((option) => option.value === RepeatType.DAILY) ?? repeatOptions[0]);
      }
    }
  }, [showRepeatPicker]);

  const timeEndOptions: SelectOptionType<TimeEndType>[] = [
    { value: TimeEndType.NEVER, label: t('trigger.timeEndOptions.never') },
    { value: TimeEndType.DATE, label: t('trigger.timeEndOptions.date') },
    { value: TimeEndType.COUNT, label: t('trigger.timeEndOptions.count') },
  ];
  const timeEndOnChange = useCallback(
    (optionIndex) => {
      setTimeEndType(timeEndOptions[optionIndex]);
      setShowTimeEndPicker(false);
      if (optionIndex === 1) {
        setIsEndDateOpen(true);
      }
      if (optionIndex === 2) {
        setEndDate(new Date());
        setShowRepeatCountPicker(true);
      }
    },
    [setShowTimeEndPicker, showTimeEndPicker],
  );
  const [timeEndType, setTimeEndType] = useState(
    timeEndOptions.find((option) => option.value === timeConditionConfig.timeEndType) ?? timeEndOptions[0],
  );

  useEffect(() => {
    setIsValid(false);
    const isToday = isSameDay(Date.now(), startDate);
    const dateEnd = format(endDate, 'yyyy-MM-dd');
    const { convertedHours, convertedMinutes } = convertMinutesToHours(sunTimeValue);
    const advanceDelayValue = convertToTimeSpanFormat(
      `${leadingZero(convertedHours)}:${leadingZero(convertedMinutes)}`,
    );
    const isSunrise = (timeType === TimeType.DAYTIME && sunAction.value === 'sunrise') || null;
    const isSunset = (timeType === TimeType.DAYTIME && sunAction.value === 'sunset') || null;
    const sunriseMode = {
      sunriseAdvance:
        isSunrise && sunActionModification.value === SunActionModification.ADVANCE ? advanceDelayValue : null,
      sunriseDelay: isSunrise && sunActionModification.value === SunActionModification.DELAY ? advanceDelayValue : null,
    };
    const sunsetMode = {
      sunsetAdvance:
        isSunset && sunActionModification.value === SunActionModification.ADVANCE ? advanceDelayValue : null,
      sunsetDelay: isSunset && sunActionModification.value === SunActionModification.DELAY ? advanceDelayValue : null,
    };
    const timeRepeatAsDate = new Date();
    timeRepeatAsDate.setHours(Number(timeRepeat.split(':')[0]));
    timeRepeatAsDate.setMinutes(Number(timeRepeat.split(':')[1]));

    const variables = {
      atSunrise: isSunrise,
      ...(isSunrise && sunriseMode),
      ...(isSunset && sunsetMode),
      atSunset: isSunset,
      startAt: {
        date: getFormatInTimeZone(startDate, 'UTC', 'yyyy-MM-dd'),
        ...(isToday
          ? { time: getFormatInTimeZone(new Date(), 'UTC', "'PT'HH'H'mm'M'") }
          : { time: getFormatInTimeZone(timeRepeatAsDate, 'UTC', "'PT'HH'H'mm'M'") }),
      },
      ...(timeEndType.value === TimeEndType.DATE && {
        endAt: {
          date: dateEnd,
          time: getFormatInTimeZone(new Date(), 'UTC', "'PT'HH'H'mm'M'"),
        },
      }),
      ...((timeEndType.value === TimeEndType.COUNT || repeatSelected.value === RepeatType.ONCE) && {
        targetExecutionCount: repeatCount,
      }),
      ...(timeType === TimeType.HOUR && { timeOfDay: getFormatInTimeZone(timeRepeatAsDate, 'UTC', "'PT'HH'H'mm'M'") }),
      daysOfWeek: selectedDays,
      everyMonth: repeatSelected.value === RepeatType.MONTHLY,
      everyYear: repeatSelected.value === RepeatType.YEARLY,
    };

    setInternalTimeCondition(variables);

    calculateNextTriggerFireTime({
      variables: {
        trigger: {
          ...variables,
        },
        installationId: selectedInstallation?.installationId,
      },
    });
  }, [
    repeatCount,
    timeType,
    timeRepeat,
    selectedDays,
    startDate.getDate(),
    endDate.getDate(),
    sunAction,
    sunTimeValue,
    sunActionModification,
  ]);

  const onSubmit = useCallback(() => {
    setTimeConditionConfig(() => ({
      timeType,
      sunAction: sunAction.value as SunAction,
      sunActionModification: sunActionModification.value as SunActionModification,
      repeatType: repeatSelected.value as RepeatType,
      sunTimeValue,
      timeRepeat,
      timeEndType: timeEndType.value as TimeEndType,
      repeatCount,
      selectedDays,
      startDate,
      endDate,
    }));

    setTimeCondition(internalTimeCondition);

    if (goToChannels && goToSummary) {
      if (!actions.length) {
        goToChannels();
      } else {
        goToSummary();
      }
    }
  }, [internalTimeCondition, timeConditionConfig]);

  return (
    <div className="condition-section--container">
      <form onSubmit={handleSubmit(onSubmit)} className="max-width-desktop">
        <div className="condition-section condition-section--type">
          <div className="radios-with-icons">
            {timeTypeOptions.map((option) => (
              <label
                key={option.value}
                className={c('radios_group__label-weight', {
                  checked: timeType === option.value,
                })}
              >
                <div className="icon-label">
                  <IconWrapper className="m-r-16">{option.icon}</IconWrapper>
                  {option.label}
                </div>
                <div>
                  <input
                    type="radio"
                    value={option.value}
                    checked={timeType === option.value}
                    onChange={onTimeTypeChange}
                  />
                  <span></span>
                </div>
              </label>
            ))}
          </div>
        </div>

        <hr className="m-b-24" />

        <div>
          {timeType === TimeType.DAYTIME ? (
            <div className="condition-section condition-section--badges">
              <p className="condition-section__label">{t('trigger.conditions.time.types.daytime')}</p>
              <div className="condition-badges">
                <div className="condition-badge" onClick={() => setShowSunActionPicker(true)}>
                  {sunAction.icon}
                  <span>{sunAction.shortLabel}</span>
                </div>
                <div className="condition-badge" onClick={() => setShowSunActionModificationPicker(true)}>
                  {sunActionModification.label}
                </div>
                {sunActionModification.value !== SunActionModification.NONE ? (
                  <div className="condition-badge" onClick={() => setShowAdvanceDelayPicker(true)}>
                    {sunTimeValue}min
                  </div>
                ) : null}
              </div>

              <div className="condition-section__location">
                <IconLocation2 /> <span>{t('trigger.installationLocation.label')}</span>
              </div>
            </div>
          ) : (
            <Input
              defaultValue={timeRepeat}
              value={timeRepeat}
              required
              readOnly
              onClickInput={() => setShowTimePicker(true)}
              endIcon={<IconEdit />}
              label={t('trigger.conditions.time.types.hour')}
            />
          )}
        </div>

        <hr className="m-t-24 m-b-24" />

        <div className="condition-section condition-section--repeat ">
          <div className="condition-repeat" onClick={() => setShowRepeatPicker(true)}>
            <div>
              <IconWrapper className="m-r-16">
                <IconRepeat />
              </IconWrapper>
              <p>
                {repeatSelected.value !== RepeatType.SELECTED ? repeatSelected.label : selectedDaysLabel.split(': ')[1]}
              </p>
            </div>
            <IconEdit />
          </div>
          <div className="condition-repeat-box condition-repeat-box--relative">
            <Calendar
              covered
              date={startDate}
              label={''}
              onChange={(date) => {
                date.setHours(Number(timeRepeat.split(':')[0]));
                date.setMinutes(Number(timeRepeat.split(':')[1]));
                setStartDate(date);
              }}
            />
            <span className="condition-repeat-box__heading">{t('trigger.conditions.start')}</span>
            <span className="condition-repeat-box__date">
              {isSameDay(now, startDate) ? `${t('trigger.conditions.today')}, ` : null}
              {format(startDate, 'dd.MM.yyyy')}
            </span>
          </div>
          <div
            className={c('condition-repeat-box', {
              'condition-repeat-box--disabled': repeatSelected.value === RepeatType.ONCE,
            })}
            onClick={() => setShowTimeEndPicker(true)}
          >
            <span className="condition-repeat-box__heading">{t('trigger.conditions.end')}</span>
            <span className="condition-repeat-box__date">
              {timeEndType.value === TimeEndType.NEVER ? (
                t('trigger.timeEndOptions.never')
              ) : timeEndType.value === TimeEndType.COUNT ? (
                `${t('trigger.timeEndOptions.count')}: ${repeatCount}`
              ) : (
                <>
                  {isSameDay(now, endDate) ? `${t('trigger.conditions.today')}, ` : null}
                  {format(endDate, 'dd.MM.yyyy')}
                </>
              )}
            </span>
          </div>
          {calculateNextFireLoading ? (
            <div className="condition-repeat__next-execution">
              <IconBullet />
              <p>{t('trigger.nextFireTimeLoading')}</p>
            </div>
          ) : isValid ? (
            <div className="condition-repeat__next-execution">
              <IconBullet />
              <p>
                {t('trigger.nextFireTime')} {nextFireTime}
              </p>
            </div>
          ) : (
            <div className="condition-repeat__next-execution condition-repeat__next-execution--error">
              <IconError />
              <p>{t('trigger.nextFireTimeError')}</p>
            </div>
          )}
        </div>

        <DialogTimePicker
          header={t('hour')}
          open={showTimePicker}
          setOpen={setShowTimePicker}
          onSave={(value) => setTimeRepeat(value)}
          time={timeRepeat}
        />

        <Calendar
          hidden
          date={endDate}
          label={''}
          onChange={(date) => {
            setIsEndDateOpen(false);
            setEndDate(date);
          }}
          open={isEndDateOpen}
          onOpen={() => setIsEndDateOpen(true)}
          onClose={() => setIsEndDateOpen(false)}
          minDate={addDays(startDate, 1)}
        />

        <DialogSelect
          show={showRepeatPicker}
          setShow={setShowRepeatPicker}
          title={t('trigger.conditions.time.repeating')}
          options={repeatOptions}
          value={repeatOptions.find((option) => option.value === repeatSelected.value)?.value}
          onChange={repeatOnChange}
        />

        <SelectedDaysDialog
          show={showWeekDaysRepeatPicker}
          setShow={setShowWeekDaysRepeatPicker}
          selected={selectedDays}
          onSave={(values) => setSelectedDays(values)}
          setShowRepeatPicker={setShowRepeatPicker}
        />

        <DialogSelect
          show={showSunActionPicker}
          setShow={setShowSunActionPicker}
          title={t('trigger.astronomical')}
          options={sunActionOptions}
          value={sunActionOptions.find((option) => option.value === sunAction.value)?.value}
          onChange={sunActionOnChange}
        />

        <DialogSelect
          show={showSunActionModificationPicker}
          setShow={setShowSunActionModificationPicker}
          title={t('trigger.startWith')}
          options={sunActionModificationOptions}
          value={sunActionModificationOptions.find((option) => option.value === sunActionModification.value)?.value}
          onChange={sunActionModificationOnChange}
        />

        <AdvanceDelayDialog
          open={showAdvanceDelayPicker}
          setOpen={setShowAdvanceDelayPicker}
          title={sunActionModification.label}
          onSave={(value) => setSunTimeValue(value)}
          time={sunTimeValue}
        />

        <TimeEndDialog
          show={showTimeEndPicker}
          setShow={setShowTimeEndPicker}
          title={t('trigger.conditions.end')}
          options={timeEndOptions}
          selectedOptionIndex={timeEndOptions.findIndex((option) => option.value === timeEndType.value)}
          onChange={(index) => timeEndOnChange(index)}
          count={repeatCount}
          date={format(endDate, 'dd.MM.yyyy')}
        />

        <RepeatCountDialog
          open={showRepeatCountPicker}
          setOpen={setShowRepeatCountPicker}
          title={t('trigger.timeEndOptions.count')}
          onSave={(value) => setRepeatCount(value)}
          count={repeatCount}
        />

        <InstallationLocationDialog open={showInstallationLocationDialog} setOpen={setShowInstallationLocationDialog} />

        <SubmitButton disabled={!isValid}>{tc('buttons.next')}</SubmitButton>
      </form>
    </div>
  );
};
