import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, IconLocation, LoaderDots, NavHeader, Page } from '../../../../components';
import { IconMarker } from '../../../../components/icons/icon-marker';
import { useLocationCoords } from '../../hooks/use-location-coords';
import { LocationViews } from '../../types';
import { LocationPermissionDialog } from '../location-permission-dialog';
import { useLocationContext } from '../location/context/location.context';
import './index.scss';

const LocationsList: React.FC = () => {
  const { t } = useTranslation('installation');
  const [loader, setLoader] = useState<boolean>(false);
  const { setCoords, setCurrentLocation, setCenter, setZoom, goTo, goBack, zoom } = useLocationContext();
  const { getCoords, permissionDialog, setPermissionDialog, getCoordsIfPermissionsAvailable } =
    useLocationCoords(setLoader);

  const goToMap = () => {
    goTo(LocationViews.MAP);
    setCoords({ latitude: null, longitude: null });
    setCurrentLocation({ latitude: null, longitude: null });
    if (zoom > 6) setZoom(6);
    setCenter({ lat: 52.113701, lng: 19.331115 });
  };

  const goLearn = () => goTo(LocationViews.LEARN_NEED_LOCATION);

  return (
    <Page
      kind="above"
      header={
        <>
          <NavHeader onClick={goBack} />
          <Header title={t('installationLocation')} isUnderline />
        </>
      }
    >
      <div className="location_select_page">
        <div className="need_info">
          {t('needLocationInfo')} <span onClick={goLearn}>{t('learnMore')}.</span>
        </div>
        <button className="location_btn" onClick={getCoordsIfPermissionsAvailable} type="button">
          <IconLocation />
          {t('currentLocation')}
        </button>
        <button className="location_btn" onClick={goToMap} type="button">
          <IconMarker />
          {t('setLocation')}
        </button>
        {loader && <LoaderDots />}
      </div>
      <LocationPermissionDialog show={permissionDialog} setShow={setPermissionDialog} handleAction={getCoords} />
    </Page>
  );
};

export default LocationsList;
