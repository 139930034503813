import { DayOfWeekInternal } from '../../../data-access/gql-types/graphql';

export type OptimizerScheduleBody = {
  from: string;
  to: string;
  action: ScheduleAction;
  actionValue: number;
  selectedDays: DayOfWeekInternal[];
};

export type OptimizerSchedule = OptimizerScheduleBody & {
  id: string;
};

export enum ScheduleAction {
  None = 'NONE',
  Teperature = 'TEMPERATURE',
}

export type ActionValueParameters = {
  min: number | undefined;
  max: number | undefined;
  default: number | undefined;
  step: number | undefined;
  unit: string;
  icon: React.ReactNode | null;
};
