import { useTranslation } from 'react-i18next';
import { Switch } from '../../../../../../components';
import { useChannelsState } from '../../../../../../hooks';
import { ChannelGateStateType } from '../../../../../../types';
import './index.scss';

type ElectricalStrikeProps = {
  channelId: string;
  handleChange: () => void;
};

export const ElectricalStrike: React.FC<ElectricalStrikeProps> = ({ channelId, handleChange }) => {
  const { t } = useTranslation('channel-details');
  const { channelState } = useChannelsState();
  const state = channelState[channelId] as ChannelGateStateType | undefined;

  return (
    <div className="electrical-strike">
      <Switch checked={!!state?.electricStrikeStatus} onChange={handleChange} />
      <p>{t('electricalStrike')}</p>
    </div>
  );
};
