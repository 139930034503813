import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
  LearningScenarios,
  MarkLearningScenarioAsViewedMutation,
  MarkLearningScenarioAsViewedMutationVariables,
} from '../../data-access/gql-types/graphql';
import { MARK_LEARNING_SCENARION_AS_VIEWED } from '../../data-access/mutations/user';
import { useBackdropContext, useProfile } from '../../hooks';
import { removeUnderscoreAndCapitalize } from '../../utils/helpers/string-converter';
import { IconTip } from '../icons/icon-tip';
import './index.scss';

type TipProps = {
  tip: string;
  learningKey: LearningScenarios;
  point: ReactNode;
  top?: number;
  right?: number;
};

export const Tip: React.FC<TipProps> = ({ tip, learningKey, point, top, right }) => {
  const { t } = useTranslation('common');
  const { tips, refetchUserProfile } = useProfile();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const [open, setOpen] = useState<boolean>(false);
  const [markLearningScenarioAsViewed] = useMutation<
    MarkLearningScenarioAsViewedMutation,
    MarkLearningScenarioAsViewedMutationVariables
  >(MARK_LEARNING_SCENARION_AS_VIEWED);

  useEffect(() => {
    if (tips) {
      if (!tips.includes(removeUnderscoreAndCapitalize(learningKey))) setOpen(true);
    }
  }, [tips, learningKey]);

  const handleClose = () => {
    turnOnBackdrop();

    markLearningScenarioAsViewed({
      variables: {
        input: {
          learningScenario: learningKey,
        },
      },
      onCompleted: () => {
        refetchUserProfile();
        turnOffBackdrop();
        setOpen(false);
      },
      onError: () => {
        turnOffBackdrop();
      },
    });
  };

  return (
    <>
      {open ? (
        <div className="tip-view" onClick={handleClose}>
          <div className="top-right" style={{ top: top || 'unset', right: right || 'unset' }}>
            {point}
            <IconTip />
          </div>
          <span className="tip-view--title">{t('tip')}</span>
          <span className="tip-view--content">{tip}</span>
          <button className="close-btn">{t('buttons.close')}</button>
        </div>
      ) : null}
    </>
  );
};
