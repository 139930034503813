import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { addDays, format, isSameDay } from 'date-fns';
import { uniq } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import {
  DialogSelect,
  DialogTimePicker,
  IconBullet,
  IconEdit,
  IconRepeat,
  IconWrapper,
  SelectOptionType,
  SubmitButton,
  IconError,
  IconAdd,
  IconDelete,
} from '../../../../components';
import Calendar from '../../../../components/calendar';
import { DayOfWeekInternal, Query, QueryCalculateFutureFireTimesArgs } from '../../../../data-access/gql-types/graphql';
import { CALCULATE_FUTURE_FIRE_TIMES } from '../../../../data-access/queries/triggers';
import { useInstallation } from '../../../../hooks';
import { TriggerPriceConditionType } from '../../../../types';
import { getFormatInTimeZone, sortedDays } from '../../../../utils/helpers';
import { prepareExecution } from '../../../trigger-details/utils';
import { useTriggerFormContext } from '../../context';
import { StepComponentProps } from '../../steps';
import { RepeatCountDialog as CountDialog, TimeEndDialog, SelectedDaysDialog } from '../time-condition/components';
import { TimeEndType, RepeatType } from '../time-condition/enums';
import { PriceConditionDialog } from './components/price-condition';
import { PriceDialog } from './components/price-dialog';
import { PriceConditionType } from './enums';
import './index.scss';

export const PriceCondition: React.FC<StepComponentProps> = ({ goToChannels, goToSummary }) => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { setPriceCondition, setPriceConditionConfig, priceConditionConfig, actions } = useTriggerFormContext();
  const [internalPriceCondition, setInternalPriceCondition] = useState<TriggerPriceConditionType>({});
  const { handleSubmit } = useForm();
  const { selectedInstallation } = useInstallation();

  const now = new Date();
  const [timeRepeat, setTimeRepeat] = useState(priceConditionConfig.timeRepeat);
  const [selectedDays, setSelectedDays] = useState<DayOfWeekInternal[]>(priceConditionConfig.selectedDays);
  const [repeatCount, setRepeatCount] = useState(priceConditionConfig.repeatCount);

  const [startDate, setStartDate] = useState(priceConditionConfig.startDate ?? new Date());
  const [endDate, setEndDate] = useState(priceConditionConfig.endDate ?? new Date());
  const [isEndDateOpen, setIsEndDateOpen] = useState<boolean>(false);
  const [futureFireTimes, setFutureFireTimes] = useState<string[]>([]);
  const [isValid, setIsValid] = useState<boolean>(false);

  const [showTimePicker, setShowTimePicker] = useState<boolean>(false);
  const [showTimeEndPicker, setShowTimeEndPicker] = useState<boolean>(false);
  const [showRepeatPicker, setShowRepeatPicker] = useState<boolean>(false);
  const [showWeekDaysRepeatPicker, setShowWeekDaysRepeatPicker] = useState<boolean>(false);
  const [showRepeatCountPicker, setShowRepeatCountPicker] = useState<boolean>(false);

  const [showPriceTypeConditionPicker, setShowPriceTypeConditionPicker] = useState<PriceConditionType | true | null>(
    null,
  );
  const [cheapestCountPicker, setCheapestCountPicker] = useState<boolean>(false);
  const [fromHourPicker, setFromHourPicker] = useState<boolean>(false);
  const [toHourPicker, setToHourPicker] = useState<boolean>(false);
  const [greaterPicker, setGreaterPicker] = useState<boolean>(false);
  const [lesserPicker, setLesserPicker] = useState<boolean>(false);

  const [betweenHoursFromInclusive, setBetweenHoursFromInclusive] = useState(
    priceConditionConfig.betweenHoursFromInclusive,
  );
  const [betweenHoursToInclusive, setBetweenHoursToInclusive] = useState(priceConditionConfig.betweenHoursToInclusive);
  const [numberOfCheapestPeriodsInDay, setNumberOfCheapestPeriodsInDay] = useState(
    priceConditionConfig.numberOfCheapestPeriodsInDay,
  );
  const [priceGreaterThanOrEqual, setPriceGreaterThanOrEqual] = useState(priceConditionConfig.priceGreaterThanOrEqual);
  const [priceLesserThanOrEqual, setPriceLesserThanOrEqual] = useState(priceConditionConfig.priceLesserThanOrEqual);

  const [calculateFutureFireTimes, { loading: calculateFutureFireLoading }] = useLazyQuery<
    Query,
    QueryCalculateFutureFireTimesArgs
  >(CALCULATE_FUTURE_FIRE_TIMES, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.calculateFutureFireTimes?.futureFires) {
        const futureExecutions = data.calculateFutureFireTimes.futureFires.map((x) =>
          prepareExecution(x, selectedInstallation?.payload?.timeZone || ''),
        );

        setIsValid(true);
        setFutureFireTimes(uniq(futureExecutions));
      } else {
        setIsValid(false);
        setFutureFireTimes([]);
      }
    },
    onError: () => {
      setIsValid(false);
      setFutureFireTimes([]);
    },
  });

  const shortDayOfWeek = (day: DayOfWeekInternal) => t(`trigger.shortenedDaysOfWeek.${day.toLowerCase()}`);

  const selectedDaysLabel = useMemo(() => {
    const days = [...selectedDays]
      .sort((a, b) => sortedDays[a] - sortedDays[b])
      .map((day) => shortDayOfWeek(day))
      .join(', ');

    return selectedDays.length ? `: ${days}` : '';
  }, [selectedDays]);

  const repeatOptions: SelectOptionType<RepeatType>[] = useMemo(
    () => [
      { value: RepeatType.DAILY, label: t('trigger.conditions.time.repeat.daily') },
      {
        value: RepeatType.SELECTED,
        label: `${t('trigger.conditions.time.repeat.selected')}${selectedDaysLabel}`,
      },
    ],
    [selectedDaysLabel],
  );

  const [repeatSelected, setRepeatSelected] = useState(
    repeatOptions.find((option) => option.value === priceConditionConfig.repeatType) ?? repeatOptions[1],
  );

  const repeatOnChange = useCallback(
    (value) => {
      setRepeatSelected(repeatOptions.find((option) => option.value === value) ?? repeatOptions[0]);
      setShowRepeatPicker(false);

      switch (value) {
        case RepeatType.DAILY: {
          setTimeEndType(timeEndOptions[0]);
          break;
        }
        case RepeatType.SELECTED: {
          setShowWeekDaysRepeatPicker(true);
          break;
        }
        default: {
          break;
        }
      }

      if (value !== RepeatType.SELECTED) setSelectedDays([]);
    },
    [setShowRepeatPicker, showRepeatPicker, showWeekDaysRepeatPicker],
  );

  useEffect(() => {
    const closeRepeatPicker = !showRepeatPicker && !showWeekDaysRepeatPicker;

    if (closeRepeatPicker) {
      if (!selectedDays.length && repeatSelected.value === RepeatType.SELECTED) {
        setRepeatSelected(repeatOptions.find((option) => option.value === RepeatType.DAILY) ?? repeatOptions[0]);
      }
    }
  }, [showRepeatPicker]);

  const timeEndOptions: SelectOptionType<TimeEndType>[] = [
    { value: TimeEndType.NEVER, label: t('trigger.timeEndOptions.never') },
    { value: TimeEndType.DATE, label: t('trigger.timeEndOptions.date') },
    { value: TimeEndType.COUNT, label: t('trigger.timeEndOptions.count') },
  ];

  const priceConditionOptions: SelectOptionType<PriceConditionType>[] = [
    { value: PriceConditionType.HOURS_FROM_TO, label: t('trigger.priceConditionOptions.hours') },
    { value: PriceConditionType.NUMBER_OF_CHEAPEST_PERIODS, label: t('trigger.priceConditionOptions.cheapestPeriods') },
    { value: PriceConditionType.PRICE_GREATER_OR_EQUAL, label: t('trigger.priceConditionOptions.priceGreater') },
    { value: PriceConditionType.PRICE_LESSER_OR_EQUAL, label: t('trigger.priceConditionOptions.priceLesser') },
  ];

  const timeEndOnChange = (optionIndex: number) => {
    setTimeEndType(timeEndOptions[optionIndex]);
    setShowTimeEndPicker(false);

    if (optionIndex === 1) setIsEndDateOpen(true);
    if (optionIndex === 2) {
      setEndDate(new Date());
      setShowRepeatCountPicker(true);
    }
  };

  const [timeEndType, setTimeEndType] = useState(
    timeEndOptions.find((option) => option.value === priceConditionConfig.timeEndType) ?? timeEndOptions[0],
  );

  const [priceConditionTypes, setPriceConditionTypes] = useState<PriceConditionType[]>(
    priceConditionConfig.priceConditionTypes,
  );

  const priceConditionTypeChange = (condition: PriceConditionType) => {
    const foundCondition = priceConditionTypes.find((x) => x === condition);
    if (!foundCondition) setPriceConditionTypes((prev) => [...prev, condition]);

    setShowPriceTypeConditionPicker(null);

    switch (condition) {
      case PriceConditionType.NUMBER_OF_CHEAPEST_PERIODS: {
        setCheapestCountPicker(true);
        break;
      }
      case PriceConditionType.PRICE_GREATER_OR_EQUAL: {
        setGreaterPicker(true);
        break;
      }
      case PriceConditionType.PRICE_LESSER_OR_EQUAL: {
        setLesserPicker(true);
        break;
      }
      default: {
        setFromHourPicker(true);
      }
    }
  };

  const handleConditionDelete = (condition: PriceConditionType) => {
    const conditionIndex = priceConditionTypes.findIndex((x) => x === condition);
    if (conditionIndex !== -1) {
      setPriceConditionTypes((prevItems) => prevItems.filter((_, index) => index !== conditionIndex));
    }
  };

  useEffect(() => {
    setIsValid(false);
    const isToday = isSameDay(Date.now(), startDate);
    const dateEnd = format(endDate, 'yyyy-MM-dd');
    const timeRepeatAsDate = new Date();
    timeRepeatAsDate.setHours(Number(timeRepeat.split(':')[0]));
    timeRepeatAsDate.setMinutes(Number(timeRepeat.split(':')[1]));

    const variables = {
      startAt: {
        date: getFormatInTimeZone(startDate, 'UTC', 'yyyy-MM-dd'),
        ...(isToday
          ? { time: getFormatInTimeZone(new Date(), 'UTC', "'PT'HH'H'mm'M'") }
          : { time: getFormatInTimeZone(timeRepeatAsDate, 'UTC', "'PT'HH'H'mm'M'") }),
      },
      ...(timeEndType.value === TimeEndType.DATE && {
        endAt: {
          date: dateEnd,
          time: getFormatInTimeZone(new Date(), 'UTC', "'PT'HH'H'mm'M'"),
        },
      }),
      ...((timeEndType.value === TimeEndType.COUNT || repeatSelected.value === RepeatType.ONCE) && {
        targetExecutionCount: repeatCount,
      }),
      daysOfWeek: selectedDays,
      ...(priceConditionTypes.find((x) => x === PriceConditionType.HOURS_FROM_TO)
        ? {
            betweenHoursFromInclusive: parseInt(betweenHoursFromInclusive.split(':')[0]),
            betweenHoursToInclusive: parseInt(betweenHoursToInclusive.split(':')[0]),
          }
        : {}),
      ...(priceConditionTypes.find((x) => x === PriceConditionType.NUMBER_OF_CHEAPEST_PERIODS)
        ? { numberOfCheapestPeriodsInDay }
        : {}),
      ...(priceConditionTypes.find((x) => x === PriceConditionType.PRICE_GREATER_OR_EQUAL)
        ? { priceGreaterThanOrEqual }
        : {}),
      ...(priceConditionTypes.find((x) => x === PriceConditionType.PRICE_LESSER_OR_EQUAL)
        ? { priceLesserThanOrEqual }
        : {}),
    };

    setInternalPriceCondition(variables);

    calculateFutureFireTimes({
      variables: {
        trigger: {
          ...variables,
        },
        installationId: selectedInstallation?.installationId,
      },
    });
  }, [
    repeatCount,
    timeRepeat,
    selectedDays,
    startDate.getDate(),
    endDate.getDate(),
    priceConditionTypes,
    betweenHoursFromInclusive,
    betweenHoursToInclusive,
    numberOfCheapestPeriodsInDay,
    priceGreaterThanOrEqual,
    priceLesserThanOrEqual,
  ]);

  const renderOptionDetails = (val: PriceConditionType) => {
    switch (val) {
      case PriceConditionType.HOURS_FROM_TO:
        return betweenHoursFromInclusive && betweenHoursToInclusive ? (
          <span>: {`${betweenHoursFromInclusive}-${betweenHoursToInclusive}`}</span>
        ) : null;
      case PriceConditionType.NUMBER_OF_CHEAPEST_PERIODS: {
        return numberOfCheapestPeriodsInDay ? <span>: {numberOfCheapestPeriodsInDay}</span> : null;
      }
      case PriceConditionType.PRICE_GREATER_OR_EQUAL:
        return priceGreaterThanOrEqual ? <span>: {priceGreaterThanOrEqual} zł/kWh</span> : null;
      case PriceConditionType.PRICE_LESSER_OR_EQUAL:
        return priceLesserThanOrEqual ? <span>: {priceLesserThanOrEqual} zł/kWh</span> : null;
      default:
        return null;
    }
  };

  const onSubmit = useCallback(() => {
    setPriceConditionConfig({
      repeatType: repeatSelected.value as RepeatType,
      timeRepeat,
      timeEndType: timeEndType.value as TimeEndType,
      repeatCount,
      selectedDays,
      startDate,
      endDate,
      betweenHoursFromInclusive: betweenHoursFromInclusive,
      betweenHoursToInclusive: betweenHoursToInclusive,
      numberOfCheapestPeriodsInDay: numberOfCheapestPeriodsInDay,
      priceGreaterThanOrEqual: priceGreaterThanOrEqual,
      priceLesserThanOrEqual: priceLesserThanOrEqual,
      priceConditionTypes: priceConditionTypes,
    });

    setPriceCondition(internalPriceCondition);

    if (goToChannels && goToSummary) {
      if (!actions.length) goToChannels();
      else goToSummary();
    }
  }, [internalPriceCondition, priceConditionConfig]);

  const selectedPriceConditionsOptions = useMemo(() => {
    return priceConditionOptions.filter((x) => priceConditionTypes.find((c) => x.value === c));
  }, [priceConditionOptions, priceConditionTypes]);

  return (
    <div className="condition-section--container">
      <form onSubmit={handleSubmit(onSubmit)} className="max-width-desktop">
        <div className="condition-section condition-section--repeat ">
          <div className="condition-repeat" onClick={() => setShowRepeatPicker(true)}>
            <div>
              <IconWrapper className="m-r-16">
                <IconRepeat />
              </IconWrapper>
              <p>
                {repeatSelected.value !== RepeatType.SELECTED ? repeatSelected.label : selectedDaysLabel.split(': ')[1]}
              </p>
            </div>
            <IconEdit />
          </div>
          <div className="condition-repeat-box condition-repeat-box--relative">
            <Calendar
              covered
              date={startDate}
              label={''}
              onChange={(date) => {
                date.setHours(Number(timeRepeat.split(':')[0]));
                date.setMinutes(Number(timeRepeat.split(':')[1]));
                setStartDate(date);
              }}
            />
            <span className="condition-repeat-box__heading">{t('trigger.conditions.start')}</span>
            <span className="condition-repeat-box__date">
              {isSameDay(now, startDate) ? `${t('trigger.conditions.today')}, ` : null}
              {format(startDate, 'dd.MM.yyyy')}
            </span>
          </div>
          <div
            className={c('condition-repeat-box', {
              'condition-repeat-box--disabled': repeatSelected.value === RepeatType.ONCE,
            })}
            onClick={() => setShowTimeEndPicker(true)}
          >
            <span className="condition-repeat-box__heading">{t('trigger.conditions.end')}</span>
            <span className="condition-repeat-box__date">
              {timeEndType.value === TimeEndType.NEVER ? (
                t('trigger.timeEndOptions.never')
              ) : timeEndType.value === TimeEndType.COUNT ? (
                `${t('trigger.timeEndOptions.count')}: ${repeatCount}`
              ) : (
                <>
                  {isSameDay(now, endDate) ? `${t('trigger.conditions.today')}, ` : null}
                  {format(endDate, 'dd.MM.yyyy')}
                </>
              )}
            </span>
          </div>
          {selectedPriceConditionsOptions.map((condition) => (
            <div key={condition.value} className="condition-repeat-box-container">
              <div className={c('condition-repeat-box')} onClick={() => priceConditionTypeChange(condition.value)}>
                <span className="condition-repeat-box__heading">{t('trigger.conditions.priceCondition')}</span>
                <span className="condition-repeat-box__date">
                  {condition.label}
                  {renderOptionDetails(condition.value)}
                </span>
              </div>
              <button className="btn-delete" type="button" onClick={() => handleConditionDelete(condition.value)}>
                <IconDelete />
              </button>
            </div>
          ))}
          {priceConditionTypes.length < 4 ? (
            <button className="condition-add" onClick={() => setShowPriceTypeConditionPicker(true)} type="button">
              <IconAdd />
              <p>Dodaj warunek</p>
            </button>
          ) : null}
          {calculateFutureFireLoading ? (
            <div className="condition-repeat__next-execution">
              <IconBullet />
              <p>{t('trigger.nextFireTimeLoading')}</p>
            </div>
          ) : isValid ? (
            <>
              <div className="condition-repeat__next-execution">
                <IconBullet />
                <p>{`${t('trigger.nextFireTime')}: ${!futureFireTimes.length ? t('trigger.nextFireTimeNull') : ''}`}</p>
              </div>
              {futureFireTimes.map((x) => (
                <div key={x} className="condition-repeat__next-execution p-l-24">
                  <p>{x}</p>
                </div>
              ))}
            </>
          ) : (
            <div className="condition-repeat__next-execution condition-repeat__next-execution--error">
              <IconError />
              <p>{t('trigger.nextFireTimeError')}</p>
            </div>
          )}
        </div>

        <DialogTimePicker
          header={t('hour')}
          open={showTimePicker}
          setOpen={setShowTimePicker}
          onSave={(value) => setTimeRepeat(value)}
          time={timeRepeat}
        />

        <Calendar
          hidden
          date={endDate}
          label={''}
          onChange={(date) => {
            setIsEndDateOpen(false);
            setEndDate(date);
          }}
          open={isEndDateOpen}
          onOpen={() => setIsEndDateOpen(true)}
          onClose={() => setIsEndDateOpen(false)}
          minDate={addDays(startDate, 1)}
        />

        <DialogSelect
          show={showRepeatPicker}
          setShow={setShowRepeatPicker}
          title={t('trigger.conditions.time.repeating')}
          options={repeatOptions}
          value={repeatOptions.find((option) => option.value === repeatSelected.value)?.value}
          onChange={repeatOnChange}
        />

        <SelectedDaysDialog
          show={showWeekDaysRepeatPicker}
          setShow={setShowWeekDaysRepeatPicker}
          selected={selectedDays}
          onSave={(values) => setSelectedDays(values)}
          setShowRepeatPicker={setShowRepeatPicker}
        />

        <TimeEndDialog
          show={showTimeEndPicker}
          setShow={setShowTimeEndPicker}
          title={t('trigger.conditions.end')}
          options={timeEndOptions}
          selectedOptionIndex={timeEndOptions.findIndex((option) => option.value === timeEndType.value)}
          onChange={(index) => timeEndOnChange(index)}
          count={repeatCount}
          date={format(endDate, 'dd.MM.yyyy')}
        />

        <CountDialog
          open={showRepeatCountPicker}
          setOpen={setShowRepeatCountPicker}
          title={t('trigger.timeEndOptions.count')}
          onSave={(value) => setRepeatCount(value)}
          count={repeatCount}
        />

        <PriceConditionDialog
          showPriceTypeConditionPicker={showPriceTypeConditionPicker}
          handleClose={() => setShowPriceTypeConditionPicker(null)}
          title={t('trigger.conditions.priceCondition')}
          options={priceConditionOptions}
          selectedOptionIndex={priceConditionOptions.findIndex(
            (option) => option.value === showPriceTypeConditionPicker,
          )}
          onChange={(condition) => priceConditionTypeChange(condition)}
          renderOptionDetails={renderOptionDetails}
          priceConditionTypes={priceConditionTypes}
        />

        <CountDialog
          open={cheapestCountPicker}
          setOpen={setCheapestCountPicker}
          title={t('trigger.priceConditionOptions.cheapestPeriods')}
          onSave={(value) => setNumberOfCheapestPeriodsInDay(value)}
          count={numberOfCheapestPeriodsInDay}
          maxCount={3}
        />

        <DialogTimePicker
          header={t('trigger.priceConditionOptions.from')}
          open={fromHourPicker}
          setOpen={setFromHourPicker}
          onSave={(value) => {
            setBetweenHoursFromInclusive(value);
            setFromHourPicker(false);
            setToHourPicker(true);
          }}
          time={betweenHoursFromInclusive}
          maxHours={22}
          maxMinutes={0}
        />

        <DialogTimePicker
          header={t('trigger.priceConditionOptions.to')}
          open={toHourPicker}
          setOpen={setToHourPicker}
          onSave={(value) => {
            setBetweenHoursToInclusive(value);
            setToHourPicker(false);
          }}
          time={betweenHoursToInclusive}
          maxMinutes={0}
          minHours={Number(betweenHoursFromInclusive.split(':')[0]) + 1}
          maxHours={23}
        />

        <PriceDialog
          show={greaterPicker}
          setShow={setGreaterPicker}
          price={priceGreaterThanOrEqual}
          onSave={(value) => setPriceGreaterThanOrEqual(value)}
          label={`${t('trigger.priceConditionOptions.priceGreater')} (zł/kWh)`}
        />

        <PriceDialog
          show={lesserPicker}
          setShow={setLesserPicker}
          price={priceLesserThanOrEqual}
          onSave={(value) => setPriceLesserThanOrEqual(value)}
          label={`${t('trigger.priceConditionOptions.priceLesser')} (zł/kWh)`}
        />

        <SubmitButton disabled={!isValid}>{tc('buttons.next')}</SubmitButton>
      </form>
    </div>
  );
};
