import React, { ChangeEvent, PointerEvent } from 'react';
import { ControlWrapper, LightSlider, Slider } from '../../../../../../components';

type TemperatureComponentProps = {
  temperature: number;
  onChangeTemperature: (event: ChangeEvent<HTMLInputElement>) => void;
  handleTemperature: (event: PointerEvent<HTMLInputElement>) => void;
  brightness?: number;
  handleChange?: (val: number) => void;
  handleBrightness?: (val: number) => void;
};

const TemperatureComponent: React.FC<TemperatureComponentProps> = ({
  temperature,
  brightness,
  handleChange,
  handleBrightness,
  handleTemperature,
  onChangeTemperature,
}) => {
  return (
    <>
      <ControlWrapper className="control-wrapper--medium">
        <Slider value={temperature} onChange={onChangeTemperature} onPointerUp={handleTemperature} whiteLight />
      </ControlWrapper>
      {brightness !== undefined && handleBrightness && handleChange && (
        <div className="details-page__controls-slider p-l-24 p-r-24">
          <LightSlider value={brightness} onChange={handleChange} onPointerUp={handleBrightness} whiteLight />
        </div>
      )}
    </>
  );
};

export default TemperatureComponent;
