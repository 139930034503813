import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { meanBy, sumBy } from 'lodash';
import { CustomBackdrop, EmptyStateBox, IconWarning, InputSelect, Switch } from '../../../../components';
import { ScalarParameterTypeInternal, VectorParameterTypeInternal } from '../../../../data-access/gql-types/graphql';
import { useApi } from '../../../../hooks';
import { ChannelType } from '../../../../types';
import { MeasurementItem, MeasurementGroup } from '../../measurements/components/measurement-item';
import { PhasesButtonGroup } from '../../measurements/components/phases-button-group';
import '../../measurements/index.scss';
import {
  averageSummaryMeasurements,
  filteredPhasesSumMeasurements,
  filteredSummaryMeasurements,
  measurementParameters,
} from '../../measurements/utils';
import { useMeterMeasurements } from '../hooks/use-meter-measurements';
import { measurementNameParse, sortedSummary } from '../utils';

type MeterMeasurementsProps = {
  channel: ChannelType;
};

export const MeterMeasurements: React.FC<MeterMeasurementsProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { convertNumberToMeasurement } = useApi();
  const {
    timeRanges,
    activePeriod,
    loading,
    data,
    setActivePeriod,
    isStandalone,
    advancedParameters,
    kind,
    phaseTabs,
    phaseMeasurementsGroupedByPhase,
    phaseMeasurementsGroupedByType,
    handleControl,
    getMeasurementSubText,
    getMeasurementSubValue,
  } = useMeterMeasurements(channel);

  const noData = useMemo(() => {
    return (
      !data?.meterCommonMeasurements.data?.scalarMeasurements.length &&
      !data?.meterCommonMeasurements.data?.vectorMeasurements.length &&
      !loading
    );
  }, [data, loading]);

  return (
    <div className="list-measurement p-l-24 p-r-24">
      {!noData && phaseTabs.length ? (
        <div className="list-measurement__tab-container">
          <PhasesButtonGroup
            onSelectedPhases={(phases) => setActiveTabIndex(phases[0]?.value)}
            defaultPhases={[0, ...(phaseTabs || [])]}
            className="m-t-0 m-b-16 phases-button-group--background-element"
            singleChoice
            {...(!isStandalone ? { itemPrefix: t('outputShort') } : {})}
          />
        </div>
      ) : null}
      <div className="filters m-t-16 m-b-16">
        <div className="row-container">
          <Switch checked={advancedParameters} onChange={handleControl} />
          <p>{t('advancedParameters')}</p>
        </div>
        {!advancedParameters && isStandalone && (
          <InputSelect options={timeRanges} value={activePeriod} onChange={setActivePeriod} label={''} />
        )}
      </div>
      {!noData ? (
        <div className="list-measurement__wrapper grid-list-16">
          {activeTabIndex === 0 ? (
            <>
              {sortedSummary(phaseMeasurementsGroupedByType)
                ?.filter((phaseMeasurementGroupType) =>
                  filteredPhasesSumMeasurements.includes(
                    convertNumberToMeasurement(kind)(
                      'vectors',
                      phaseMeasurementGroupType[0],
                    ) as VectorParameterTypeInternal,
                  ),
                )
                .map((phaseMeasurementGroupType) => {
                  const type = convertNumberToMeasurement(kind)(
                    'vectors',
                    phaseMeasurementGroupType[0],
                  ) as VectorParameterTypeInternal;
                  const value = averageSummaryMeasurements.includes(type)
                    ? meanBy(phaseMeasurementGroupType[1], 'value')
                    : sumBy(phaseMeasurementGroupType[1], 'value');

                  if (measurementParameters[type])
                    return (
                      <MeasurementItem
                        key={type}
                        channelId={channel.id}
                        measurement={type}
                        measurementGroup={MeasurementGroup.SinglePhase}
                        text={t(`energyMeasurements.types.summary.${measurementNameParse(type)}`)}
                        value={value}
                        parameters={measurementParameters[type]}
                        kind={kind}
                        subText={getMeasurementSubText(type)}
                        subValue={getMeasurementSubValue(type, value)}
                      />
                    );
                })}
              {data?.meterCommonMeasurements.data?.scalarMeasurements
                ?.filter((absoluteMeasurement) =>
                  filteredSummaryMeasurements.includes(
                    convertNumberToMeasurement(kind)(
                      'scalars',
                      absoluteMeasurement.type,
                    ) as ScalarParameterTypeInternal,
                  ),
                )
                .map((absoluteMeasurement) => {
                  const type = convertNumberToMeasurement(kind)(
                    'scalars',
                    absoluteMeasurement.type,
                  ) as ScalarParameterTypeInternal;
                  if (measurementParameters[type]) {
                    return (
                      <MeasurementItem
                        key={type}
                        channelId={channel.id}
                        measurement={type}
                        measurementGroup={MeasurementGroup.Absolute}
                        text={t(`energyMeasurements.types.summary.${measurementNameParse(type)}`)}
                        value={absoluteMeasurement.value}
                        parameters={measurementParameters[type]}
                        kind={kind}
                      />
                    );
                  }
                })}
            </>
          ) : (
            phaseMeasurementsGroupedByPhase[activeTabIndex]?.map((phaseMeasurement) => {
              const type = convertNumberToMeasurement(kind)('vectors', phaseMeasurement.type);

              if (measurementParameters[type]) {
                return (
                  <MeasurementItem
                    key={phaseMeasurement.type}
                    channelId={channel.id}
                    measurement={type}
                    measurementGroup={MeasurementGroup.SinglePhase}
                    text={t(`energyMeasurements.types.phase.${measurementNameParse(type)}`)}
                    value={phaseMeasurement.value}
                    parameters={measurementParameters[type]}
                    phase={activeTabIndex}
                    kind={kind}
                    subText={getMeasurementSubText(type)}
                    subValue={getMeasurementSubValue(type, phaseMeasurement.value)}
                  />
                );
              }
            })
          )}
        </div>
      ) : (
        <div className="p-t-16">
          <EmptyStateBox content={t('noDataToDisplay')} icon={<IconWarning />} />
        </div>
      )}

      <CustomBackdrop loading={loading} />
    </div>
  );
};
