import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IRemotesService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RemoteService/IRemotesService';
import { IRemoteDevice } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RemoteService/RemoteParams';
import {
  RemoteServiceErrorCode,
  RemotesService,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RemoteService/RemotesService';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { SelectOptionType } from '../../../../../../../components';
import { useBackdropContext } from '../../../../../../../hooks';
import { getParameterByName } from '../../../../../../../utils/location';
import { toastError, toastSuccess } from '../../../../../../../utils/toast';
import { useExalusContext } from '../../../../../context';
import { useExalusChannel, useHandleDataFrameErrors } from '../../../../../hooks';

export enum RemotePage {
  Assigned = 'assigned',
  Available = 'available',
  ConfigEdit = 'config_edit',
  ConfigAdd = 'config_add',
}

export const useRemotes = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('device-info');
  const { channelId } = useParams<{ channelId?: string }>();
  const { connected, synchronized } = useExalusContext();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { data: channel } = useExalusChannel((channelId || '').split('_')[0], channelId || '');
  const [remotes, setRemotes] = useState<IRemoteDevice[]>([]);
  const [selectedRemote, setSelectedRemote] = useState<IRemoteDevice | undefined>(undefined);
  const [remotesFetched, setRemotesFetched] = useState<boolean>(false);
  const [page, setPage] = useState<RemotePage>(RemotePage.Assigned);
  const { handleError } = useHandleDataFrameErrors();
  const form = useForm();

  useEffect(() => {
    if (connected && synchronized) {
      const urlPage = getParameterByName('page');
      setPage((urlPage as RemotePage) || RemotePage.Assigned);

      switch (urlPage) {
        case RemotePage.Available: {
          getAvailableRemotesForDevice();
          break;
        }
        case RemotePage.ConfigAdd:
        case RemotePage.ConfigEdit: {
          break;
        }
        default: {
          getAssignedRemotes();
          break;
        }
      }
    }
  }, [location.search, connected, synchronized]);

  const handleGetRemotesResponse = <T,>(
    data: T | ResponseResult<RemoteServiceErrorCode>,
    onSuccess: () => void,
    onError?: () => void,
  ) => {
    if ((data as ResponseResult<RemoteServiceErrorCode>).Type) {
      showGetRemotesError(data as ResponseResult<RemoteServiceErrorCode>);
      onError?.();
    } else {
      onSuccess();
    }
  };

  const showGetRemotesError = (data: ResponseResult<RemoteServiceErrorCode>) => {
    turnOffBackdrop();

    switch (data.Type) {
      case RemoteServiceErrorCode.OtherError: {
        toastError({ content: t('exalus.getParams.otherError') });
        break;
      }
      case RemoteServiceErrorCode.InvalidChannelNumber: {
        toastError({ content: t('exalus.getParams.invalidChannelNumber') });
        break;
      }
      case RemoteServiceErrorCode.NoData: {
        toastError({ content: t('exalus.getParams.noData') });
        break;
      }
      case RemoteServiceErrorCode.IllegalButtonNumber: {
        toastError({ content: t('exalus.getParams.illegalButtonNumber') });
        break;
      }
      case RemoteServiceErrorCode.ButtonAlreadyAssigned: {
        toastError({ content: t('exalus.getParams.buttonAlreadyAssigned') });
        break;
      }
      case RemoteServiceErrorCode.IllegalTimeValue: {
        toastError({ content: t('exalus.getParams.illegalTimeValue') });
        break;
      }
      case RemoteServiceErrorCode.NoPairedRemotes: {
        toastError({ content: t('exalus.getParams.noPairedRemotes') });
        break;
      }
      default:
        break;
    }
  };

  const getRemotesService = useCallback(async () => {
    const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
    return await extaLifeService.GetDeviceServiceByServiceTypeAsync<IRemotesService>(RemotesService.ServiceName);
  }, []);

  const getAssignedRemotes = useCallback(async () => {
    setRemotesFetched(false);
    turnOnBackdrop();
    setRemotes([]);
    const remoteService = await getRemotesService();

    if (channel) {
      const data = await remoteService.GetPairedRemotesAsync(channel.GetDevice(), channel.Number);

      handleGetRemotesResponse<IRemoteDevice[]>(data, () => {
        setRemotes(data as IRemoteDevice[]);
        setRemotesFetched(true);
        turnOffBackdrop();
      });
    }
  }, [channel]);

  const getAvailableRemotesForDevice = useCallback(async () => {
    setRemotesFetched(false);
    turnOnBackdrop();
    setRemotes([]);
    const remoteService = await getRemotesService();

    if (channel) {
      const data = await remoteService.GetAvailableRemotesForDeviceAsync(channel.GetDevice(), channel.Number);

      handleGetRemotesResponse<IRemoteDevice[]>(data, () => {
        setRemotes(data as IRemoteDevice[]);
        setRemotesFetched(true);
        turnOffBackdrop();
      });
    }
  }, [channel]);

  const submitDelete = useCallback(
    async (setDeletePopup: Dispatch<SetStateAction<boolean>>) => {
      if (selectedRemote) {
        turnOnBackdrop();
        const remoteService = await getRemotesService();

        if (channel) {
          const result = await remoteService.RemoveRemoteFromDeviceAsync(selectedRemote);

          handleGetRemotesResponse<Status>(result, () => {
            if (result === Status.OK) {
              toastSuccess({ content: t('exalus.params.RemotesParams.RemoteDeleted') });
              getAssignedRemotes();
              turnOffBackdrop();
              setDeletePopup(false);
            } else {
              handleError(result as Status);
            }
          });
        }
      }
    },
    [selectedRemote],
  );

  const submitAssign = useCallback(
    async (remoteDevice: IRemoteDevice, back: number) => {
      turnOnBackdrop();
      const remoteService = await getRemotesService();

      if (channel) {
        const result = await remoteService.AssingRemoteToDeviceAsync(remoteDevice);

        handleGetRemotesResponse<Status>(result, () => {
          if (result === Status.OK) {
            toastSuccess({ content: t('exalus.params.RemotesParams.RemoteAssigned') });
            turnOffBackdrop();
            history.go(back);
          } else {
            handleError(result as Status);
          }
        });
      }
    },
    [selectedRemote],
  );

  const editRemoteSubmit = useCallback(
    async (remoteDevice: IRemoteDevice) => {
      turnOnBackdrop();
      const remoteService = await getRemotesService();

      if (channel) {
        const result = await remoteService.EditRemoteOptionsAsync(remoteDevice);

        handleGetRemotesResponse<Status>(result, () => {
          if (result === Status.OK) {
            toastSuccess({ content: t('exalus.params.RemotesParams.RemoteEdited') });
            turnOffBackdrop();
            history.goBack();
          } else {
            handleError;
          }
        });
      }
    },
    [selectedRemote],
  );

  const parameters = useMemo(() => {
    if (selectedRemote) {
      return selectedRemote.CurrentRemotePairingOptions[selectedRemote.DeviceType].ModeParams[
        selectedRemote.RemoteMode
      ];
    }
  }, [selectedRemote]);

  const availableChannels = useMemo(() => {
    if (parameters?._remoteChannels) {
      const remoteChannels: SelectOptionType<string>[] = [];

      for (let i = 1; i <= parameters._remoteChannels; i++) {
        remoteChannels.push({ label: i.toString(), value: i.toString() });
      }

      return remoteChannels;
    }
  }, [parameters]);

  useEffect(() => {
    if (parameters) {
      form.setValue('Channel', '_channel' in parameters ? parameters._channel : undefined);
      form.setValue('KeyOn', '_keyOn' in parameters ? parameters._keyOn : undefined);
      form.setValue('KeyOff', '_keyOff' in parameters ? parameters._keyOff : undefined);
      form.setValue('TimeOn', '_timeOn' in parameters ? parameters._timeOn : undefined);
      form.setValue('KeyBrightening', '_keyBrightening' in parameters ? parameters._keyBrightening : undefined);
      form.setValue('KeyDimmer', '_keyDimmer' in parameters ? parameters._keyDimmer : undefined);
    }
  }, [parameters]);

  const finishConfiguration = form.handleSubmit(async (values) => {
    if (selectedRemote) {
      const remoteDevice = selectedRemote;
      if (values.Channel) {
        remoteDevice.CurrentRemotePairingOptions[remoteDevice.DeviceType].ModeParams[remoteDevice.RemoteMode].Channel =
          values.Channel;
      }
      if (values.KeyOn) {
        remoteDevice.CurrentRemotePairingOptions[remoteDevice.DeviceType].ModeParams[remoteDevice.RemoteMode].KeyOn =
          values.KeyOn;
      }
      if (values.KeyOff) {
        remoteDevice.CurrentRemotePairingOptions[remoteDevice.DeviceType].ModeParams[remoteDevice.RemoteMode].KeyOff =
          values.KeyOff;
      }
      if (values.TimeOn) {
        remoteDevice.CurrentRemotePairingOptions[remoteDevice.DeviceType].ModeParams[remoteDevice.RemoteMode].TimeOn =
          values.TimeOn;
      }
      if (values.KeyBrightening) {
        remoteDevice.CurrentRemotePairingOptions[remoteDevice.DeviceType].ModeParams[
          remoteDevice.RemoteMode
        ].KeyBrightening = values.KeyBrightening;
      }
      if (values.KeyDimmer) {
        remoteDevice.CurrentRemotePairingOptions[remoteDevice.DeviceType].ModeParams[
          remoteDevice.RemoteMode
        ].KeyDimmer = values.KeyDimmer;
      }

      if (page === RemotePage.ConfigAdd) {
        submitAssign(remoteDevice, -2);
      } else {
        editRemoteSubmit(remoteDevice);
      }
    }
  });

  return {
    channel,
    remotes,
    page,
    parameters,
    availableChannels,
    form,
    selectedRemote,
    remotesFetched,
    t,
    finishConfiguration,
    getAssignedRemotes,
    setSelectedRemote,
    submitDelete,
    submitAssign,
    getAvailableRemotesForDevice,
  };
};
