import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as uuid from 'uuid';
import { NavHeader, Page, SubmitButton } from '../../../../components';
import { RgbSceneIcon } from '../../../../components/rgb-scene';
import { hsbToRgb } from '../../../../utils/helpers';
import RgbPicker from '../../../channel-details/light/components/rgb/picker';
import { useRgbSceneFormContext } from '../../context';
import { RgbSceneFormView } from '../../enums';
import { RgbSceneFormSetColorTime } from './components/set-color-time';

export const RgbSceneFormSetColor: React.FC = () => {
  const { t } = useTranslation('channel-details');
  const history = useHistory();
  const { colors, addColor, setSelectedView, icon, name, activeColorId, setActiveColorId, updateColor } =
    useRgbSceneFormContext();
  const activeColor = colors.find((color) => color.id === activeColorId);
  const [colorTimeValue, setColorTimeValue] = useState<number>(15);
  const [sliderColor, setSliderColor] = useState<string>('');
  const [rgbValue, setRgbValue] = useState<string>('255,255,255');
  const [brightness, setBrightness] = useState<number>(100);
  const [hue, setHue] = useState<number>(0);
  const [saturation, setSaturation] = useState<number>(0);

  useEffect(() => {
    if (activeColor) {
      setSliderColor(`${activeColor.r},${activeColor.g},${activeColor.b}`);
      setRgbValue(`${activeColor.r},${activeColor.g},${activeColor.b}`);
      if (activeColor.brightness) {
        setBrightness(activeColor.brightness);
      }
      if (activeColor.swap) {
        setColorTimeValue(activeColor.swap);
      }
    }
  }, [activeColor]);

  const handleChange = useCallback(
    (value: number) => {
      setBrightness(value);
    },
    [setBrightness],
  );

  const handleBrightness = useCallback(
    (value: number) => {
      const brightnessVal = value || 100;
      const rgb = hsbToRgb([hue, saturation, 1]).join(',');
      setRgbValue(rgb);
      setBrightness(brightnessVal);
      setHue(hue);
      setSaturation(saturation);
    },
    [hue, saturation, brightness],
  );

  const handleColorPicked = useCallback(
    (hue, saturation) => {
      const brightnessVal = brightness || 100;
      const rgb = hsbToRgb([hue, saturation, 1]).join(',');
      setRgbValue(rgb);
      setBrightness(brightnessVal);
      setSliderColor(hsbToRgb([hue, saturation, 1]).join(','));
      setHue(hue);
      setSaturation(saturation);
    },
    [brightness, hue, saturation],
  );

  const onSubmit = useCallback(() => {
    const colors = rgbValue.split(',').map((color) => Number(color));

    if (activeColor) {
      updateColor({
        r: colors[0],
        g: colors[1],
        b: colors[2],
        id: activeColor.id,
        brightness: brightness,
        ...(colorTimeValue ? { swap: colorTimeValue } : {}),
      });
      setActiveColorId(null);
    } else {
      addColor({
        r: colors[0],
        g: colors[1],
        b: colors[2],
        id: uuid.v4(),
        brightness,
        ...(colorTimeValue ? { swap: colorTimeValue } : {}),
      });
    }

    setSelectedView(RgbSceneFormView.LIST);
  }, [rgbValue, colorTimeValue]);

  return (
    <Page
      header={
        <NavHeader
          onClick={() => {
            if (colors.length === 0) {
              history.goBack();
            } else {
              setActiveColorId(null);
              setSelectedView(RgbSceneFormView.LIST);
            }
          }}
        />
      }
      containerClassName={'p-t-0 p-r-0 p-b-0 p-l-0'}
      headerClassName={'p-t-24 p-r-24 p-b-0 p-l-24'}
      contentClassName={'p-r-24 p-b-24 p-l-24 page__content--scrollable'}
    >
      <div className="details-header">
        <div className="details-header--left details-header--left-flex">
          <div className="details-header__icon details-header__icon--color-primary">
            <RgbSceneIcon iconName={icon} />
          </div>
          <div className="details-header__name">
            <h2 className="text-ellipsis">{name}</h2>
            <div className="details-header__type">
              {t('color')} {activeColorId && activeColor ? colors.indexOf(activeColor, 0) + 1 : colors.length + 1}
            </div>
          </div>
        </div>
      </div>

      <RgbPicker
        rgbValue={rgbValue}
        handleChange={handleChange}
        handleColorPicked={handleColorPicked}
        brightness={brightness}
        handleBrightness={handleBrightness}
        sliderColor={sliderColor}
      />

      <RgbSceneFormSetColorTime colorTimeValue={colorTimeValue} setColorTimeValue={setColorTimeValue} />

      <SubmitButton type="button" onClick={onSubmit} disabled={!rgbValue} />
    </Page>
  );
};
