import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { pathnameWithParameters } from '../../utils/location';
import { Carousel, CarouselItem } from '../carousel';
import { TabType, Tabs } from '../tabs';

type LavvaDetailsWrapperProps = {
  children: ReactNode;
  tabs?: TabType[];
  preventSwipeEvent?: boolean;
  additionalTabs?: ReactNode[];
  underTabs?: { component: ReactNode; index: number };
  overwriteTabIndex?: number;
  setOverwriteActiveTabIndex?: Dispatch<SetStateAction<number>>;
};

const LavvaDetailsWrapper: React.FC<LavvaDetailsWrapperProps> = ({
  preventSwipeEvent = false,
  children,
  tabs = [],
  additionalTabs,
  underTabs,
  overwriteTabIndex,
  setOverwriteActiveTabIndex,
}) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(-1);
  const currentTabIndex = overwriteTabIndex !== undefined ? overwriteTabIndex : activeTabIndex;

  useEffect(() => {
    const tab = new URLSearchParams(search).get('tab');
    if (tab) {
      if (setOverwriteActiveTabIndex) setOverwriteActiveTabIndex(parseInt(tab));
      else setActiveTabIndex(parseInt(tab));
    } else {
      if (setOverwriteActiveTabIndex) setOverwriteActiveTabIndex(0);
      else setActiveTabIndex(0);
    }
  }, []);

  const changeActiveTab = (index: number) => {
    if (setOverwriteActiveTabIndex) setOverwriteActiveTabIndex(index);
    else setActiveTabIndex(index);

    history.replace(
      pathnameWithParameters(pathname, search, {
        key: 'tab',
        value: index,
      }),
    );
  };

  return (
    <>
      {additionalTabs?.length ? (
        <>
          <Tabs
            tabList={tabs}
            activeTabIndex={currentTabIndex}
            setActiveTabIndex={changeActiveTab}
            tabsType="pills"
            isDivider={false}
            fullWidth
          />
          {underTabs?.index === currentTabIndex && underTabs.component}
          <div className="details-page">
            <Carousel
              preventSwipeEvent={preventSwipeEvent}
              setActiveIndex={changeActiveTab}
              activeIndex={currentTabIndex}
            >
              <CarouselItem key={1}>{children}</CarouselItem>
              {additionalTabs.map((Component: ReactNode, index: number) => (
                <CarouselItem key={index}>{Component}</CarouselItem>
              ))}
            </Carousel>
          </div>
        </>
      ) : (
        <div className="details-page">{children}</div>
      )}
    </>
  );
};

export default LavvaDetailsWrapper;
