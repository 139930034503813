import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResetAggregateMeasurements } from '../../../api/modules/meter/meter.hooks';
import { DialogConfirmation } from '../../../components';
import ArrowButton from '../../../components/arrow-button';
import { ChannelTypeInternal, MeasurementChannelKind, MeterTypeInternal } from '../../../data-access/gql-types/graphql';
import { useBackdropContext, useDevicesAndChannels } from '../../../hooks';
import { useRefetchData } from '../../../hooks/refresh-data';
import { useErrors } from '../../../hooks/use-errors';
import { getEnumKeyByValue } from '../../../utils/helpers';
import { toastError, toastSuccess } from '../../../utils/toast';

type MeasurementsConfigurationProps = {
  meterChannelId: string;
};

const MeasurementsConfiguration: React.FC<MeasurementsConfigurationProps> = ({ meterChannelId }) => {
  const { t } = useTranslation('configuration');
  const { t: tc } = useTranslation('common');
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { handleLavvaResolve } = useErrors();
  const { mutate } = useResetAggregateMeasurements();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { refetchChannelDetails } = useRefetchData();
  const { channel } = useDevicesAndChannels({ channelId: meterChannelId });

  const isStandalone = useMemo(() => {
    return channel?.data.type === ChannelTypeInternal.Meter && channel.data.meterType === MeterTypeInternal.Standalone;
  }, [channel]);

  const handleReset = () => {
    if (meterChannelId) {
      turnOnBackdrop();

      mutate(
        { channelId: meterChannelId, kind: getEnumKeyByValue(MeasurementChannelKind, MeasurementChannelKind.Meter) },
        {
          onSuccess: ({ data }) => {
            turnOffBackdrop();

            setShowDialog(false);
            handleLavvaResolve({
              status: data.publishStatus,
              deviceId: channel?.deviceId,
              onSuccess: async () => {
                await refetchChannelDetails();
                toastSuccess({ content: t('resetMeasurements.success') });
              },
            });
          },
        },
      );
    } else {
      toastError({ content: tc('errors.somethingWentWrong') });
    }
  };

  return (
    <>
      <ArrowButton title={t('resetMeasurements.button')} onClick={() => setShowDialog(true)} className="m-t-24" small />
      <DialogConfirmation
        show={showDialog}
        setShow={setShowDialog}
        header={t('resetMeasurements.popupHeader')}
        content={t(`resetMeasurements.popupContent${isStandalone ? 'Long' : ''}`)}
        primaryBtnText={tc('buttons.continue')}
        secondaryBtnText={tc('buttons.cancel')}
        primaryBtnAction={handleReset}
        secondaryBtnAction={() => setShowDialog(false)}
        timer
      />
    </>
  );
};

export default MeasurementsConfiguration;
