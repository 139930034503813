import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Hours, Minutes, Seconds } from 'lavva.exalushome/build/js/Helpers';
import { DeviceResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import {
  DeviceStateArgument,
  DeviceStateComparisonMethod,
  DeviceStateComparisonParams,
  NumberParam,
  TimeSpanParam,
} from 'lavva.exalushome/build/js/Services/Scenes/LeftArgumentTypes';
import { ConditionsTypes, ISequence } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { AddOrEditArgument, SupportedArgumentTypes } from 'lavva.exalushome/build/js/Services/Scenes/ScenesBuilder';
import { useBackdropContext } from '../../../../../hooks';
import { toastError } from '../../../../../utils/toast';
import { useExalusCreateActionContext } from '../context';
import { useExalusComparisonContext } from '../context/comparison';
import { TriggerMethodType } from '../types';
import { getTimeParts } from '../utils/time';

type Params = {
  supportedParam: SupportedArgumentTypes;
  scheduleCondition?: boolean;
};

export const useConditionEdit = ({ supportedParam, scheduleCondition }: Params) => {
  const history = useHistory();
  const { t: tc } = useTranslation('common');
  const { conditionList, builder, allowedArguments, addConditionToList, editCondition, setAllowedArguments } =
    useExalusCreateActionContext();
  const { comparisonMethod, days, timespan, paramValue, setComparisonMethod, setDays, setTimeSpan, setParamValue } =
    useExalusComparisonContext();
  const [seq, setSeq] = useState<ISequence | null>(null);
  const [onlyParametersEdit, setOnlyParametersEdit] = useState<boolean>(false);
  const [triggerMethod, setTriggerMethod] = useState<TriggerMethodType>(TriggerMethodType.IfIsIt);
  const [conditionType, setConditionType] = useState<ConditionsTypes>(ConditionsTypes.Equal);
  const [timeSec, setTimeSec] = useState<string>('5');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  useEffect(() => {
    const setEntryParams = async () => {
      const params = new URLSearchParams(window.location.search);
      const id = params.get('id');
      const parameters = params.get('parameters');
      if (parameters) setOnlyParametersEdit(true);

      if (id) {
        const index = conditionList.findIndex((x) => x.Guid === id);

        if (index !== -1) {
          setSeq(conditionList[index]);
          setTimeSec(conditionList[index].ConditionTimeout.toString());
          setConditionType(conditionList[index].ConditionType);

          const deviceArgument = conditionList[index].LeftArgument.Argument?.ArgumentAsDeviceState;
          if (deviceArgument) {
            setComparisonMethod(deviceArgument.ComparisonMethod);

            if (deviceArgument.ComparisonMethod === DeviceStateComparisonMethod.Delta) {
              const comparisonParams = deviceArgument.GetComparisonParams();
              const timeSpan: string = comparisonParams.get(
                DeviceStateComparisonParams.DeltaCalculationTimeRange,
              )?.Value;
              const numberParam: number = comparisonParams.get(DeviceStateComparisonParams.DeltaValue)?.Value;

              if (timeSpan) {
                if (timeSpan.includes('.')) {
                  const timeSpanParts = timeSpan.split('.');
                  setDays(timeSpanParts[0]);
                  setTimeSpan(timeSpanParts[1]);
                } else {
                  setDays('0');
                  setTimeSpan(timeSpan);
                }
              }

              if (numberParam !== undefined) {
                if (deviceArgument.Type !== DeviceResponseType.MeasuredBrightness) {
                  setParamValue(numberParam.toString());
                } else {
                  setParamValue(Math.round(numberParam * 100).toString());
                }
              }
            }
          }

          if (index !== 0) {
            if (conditionList[index].AtMeetCondition) setTriggerMethod(TriggerMethodType.WhenChangeOn);
          } else {
            setTriggerMethod(TriggerMethodType.WhenChangeOn);
          }
        }
      } else {
        const allowedArgument = allowedArguments.find((x) => x.ArgumentType === supportedParam);

        if (allowedArgument) {
          if (allowedArgument.InitialAtMeetConditionValue) setTriggerMethod(TriggerMethodType.WhenChangeOn);
        } else {
          if (scheduleCondition) {
            setTriggerMethod(TriggerMethodType.WhenChangeOn);
          } else {
            if (!conditionList.length) setTriggerMethod(TriggerMethodType.WhenChangeOn);
          }
        }
      }
    };

    setEntryParams();
  }, []);

  const changeableAtMeetCondition = useMemo(() => {
    if (!allowedArguments.length) return true;
    return !!allowedArguments.find((x) => x.ArgumentType === supportedParam)?.ChangableAtMeetCondition;
  }, [supportedParam, allowedArguments]);

  const saveConditionParams = (deviceArgument: DeviceStateArgument) => {
    try {
      turnOnBackdrop();
      const argument = builder?.ArgumentAsDeviceState(
        deviceArgument,
        triggerMethod === TriggerMethodType.WhenChangeOn,
        triggerMethod === TriggerMethodType.WhenChangeOn && changeableAtMeetCondition ? parseInt(timeSec) : 0,
        conditionType,
      );

      finalizeSequenceSubmit(argument);
    } catch (error) {
      toastError({ content: tc('errors.somethingWentWrong') });
      console.log(error);
      turnOffBackdrop();
    }
  };

  const finalizeSequenceSubmit = useCallback(
    (argument?: AddOrEditArgument, back?: number) => {
      if (!seq) {
        const addSequence = argument?.AddArgument();

        if (addSequence) {
          setAllowedArguments(addSequence.GetAllowedArguments());
          const sequence = addSequence.SequenceData;

          if (sequence) {
            addConditionToList(sequence);
            turnOffBackdrop();

            if (!onlyParametersEdit) history.go(back || -4);
            else history.goBack();
          }
        }
      } else {
        const editSequence = argument?.EditArgument(seq.Guid);

        if (editSequence) {
          setAllowedArguments([]);
          const sequence = editSequence.SequenceData;

          if (sequence) {
            editCondition(sequence);
            turnOffBackdrop();

            if (!onlyParametersEdit) history.go(back || -4);
            else history.goBack();
          }
        }
      }
    },
    [onlyParametersEdit, seq],
  );

  const createDeviceArgumentWithComparison = (modifiedParamValue?: number) => {
    const deviceArgument = new DeviceStateArgument();

    if (comparisonMethod === DeviceStateComparisonMethod.Delta) {
      deviceArgument.ComparisonMethod = DeviceStateComparisonMethod.Delta;

      const timeParts = getTimeParts(timespan);
      const hours = timeParts.hours as Hours;
      const minutes = timeParts.minutes as Minutes;
      const seconds = timeParts.seconds as Seconds;
      const daysQuantity = parseInt(days);

      deviceArgument.SetComparisonParam<TimeSpanParam>(
        DeviceStateComparisonParams.DeltaCalculationTimeRange,
        new TimeSpanParam(seconds, minutes, hours, daysQuantity),
      );
      deviceArgument.SetComparisonParam<NumberParam>(
        DeviceStateComparisonParams.DeltaValue,
        new NumberParam(modifiedParamValue || parseFloat(paramValue)),
      );
    } else {
      deviceArgument.ComparisonMethod = DeviceStateComparisonMethod.Default;
    }

    return deviceArgument;
  };

  return {
    triggerMethod,
    seq,
    timeSec,
    conditionType,
    onlyParametersEdit,
    changeableAtMeetCondition,
    setConditionType,
    setTriggerMethod,
    finalizeSequenceSubmit,
    setTimeSec,
    saveConditionParams,
    createDeviceArgumentWithComparison,
  };
};
