import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomBackdrop, EmptyStateBox, IconWarning } from '../../../components';
import { useApi, useDevicesAndChannels, useInstallation } from '../../../hooks';
import { ROUTES } from '../../../routes';
import { useAddDevice } from '../../device-add/hooks/use-add-device';

export enum PageType {
  Channels = 'Channels',
  Dashboard = 'Dashboard',
  Devices = 'Devices',
}

type CheckContentWrapperProps = {
  children: ReactNode;
  page?: PageType;
};

const CheckContentWrapper: React.FC<CheckContentWrapperProps> = ({ children, page }) => {
  const { t } = useTranslation('installation');
  const { t: tch } = useTranslation('channel-list');
  const { t: td } = useTranslation('device-list');
  const { selectedInstallationId, installationList, installationsLoading } = useInstallation();
  const { channelsLoading, devicesLoading, dashboardLoading } = useApi();
  const { addDevice } = useAddDevice();
  const { channelGroups, deviceList, channelList } = useDevicesAndChannels();

  const content = useMemo(() => {
    if (installationList.length === 0 && !installationsLoading)
      return (
        <EmptyStateBox
          header={t('installation')}
          content={t('noInstallations.message')}
          btnText={t('noInstallations.button')}
          icon={<IconWarning />}
          linkTo={ROUTES.InstallationCreate()}
          showAllways
        />
      );

    if (!selectedInstallationId && !installationsLoading)
      return (
        <EmptyStateBox
          header={t('installation')}
          content={t('noSelectedInstallation.message')}
          btnText={t('noSelectedInstallation.button')}
          icon={<IconWarning />}
          linkTo={ROUTES.InstallationCreate()}
          showAllways
        />
      );

    if (page === PageType.Channels && !channelsLoading && channelGroups.length === 0)
      return (
        <EmptyStateBox
          header={tch('devicesEmptyState.header')}
          btnText={tch('devicesEmptyState.missingDevice.btnText')}
          content={tch('devicesEmptyState.missingDevice.content')}
          icon={<IconWarning />}
          onClick={addDevice}
        />
      );

    if (page === PageType.Dashboard && !dashboardLoading && !channelsLoading && channelList.length === 0)
      return (
        <EmptyStateBox
          header={tch('devicesEmptyState.header')}
          btnText={tch('devicesEmptyState.missingDevice.btnText')}
          content={tch('devicesEmptyState.missingDevice.content')}
          icon={<IconWarning />}
          onClick={addDevice}
        />
      );

    if (page === PageType.Devices && !devicesLoading && deviceList.length === 0)
      return (
        <EmptyStateBox
          header={td('devicesEmptyState.header')}
          content={td('devicesEmptyState.content')}
          icon={<IconWarning />}
        />
      );

    return children;
  }, [
    selectedInstallationId,
    installationList.length,
    channelsLoading,
    devicesLoading,
    dashboardLoading,
    channelGroups,
    deviceList,
    children,
    page,
  ]);

  const isLoading = useMemo(
    () => installationsLoading || channelsLoading || devicesLoading || dashboardLoading,
    [installationsLoading, channelsLoading, devicesLoading, dashboardLoading],
  );

  return (
    <>
      {content}
      <CustomBackdrop loading={isLoading} />
    </>
  );
};

export default CheckContentWrapper;
