import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useInstallation } from '../installation';
import { useUserPermissionsContext } from './provider';

export const usePermissionsContext = (installationId?: string) => {
  const { userPermissions } = useUserPermissionsContext();
  const { selectedInstallationId } = useInstallation();

  const permissions = useMemo(() => {
    if (isEmpty(userPermissions)) return {};
    const id = installationId || selectedInstallationId || '';

    return id ? userPermissions[id] || {} : {};
  }, [selectedInstallationId, installationId, userPermissions]);

  return {
    permissions,
  };
};
