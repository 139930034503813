import { useEffect, useRef } from 'react';

export const useHorizontalScroll = <T extends HTMLElement>() => {
  const scrollableRef = useRef<T | null>(null);

  useEffect(() => {
    const element = scrollableRef.current;
    if (!element) return;

    const handleScroll = (event: WheelEvent) => {
      if (event.deltaY !== 0) {
        event.preventDefault();
        element.scrollLeft += event.deltaY;
      }
    };

    element.addEventListener('wheel', handleScroll, { passive: false });

    return () => {
      element.removeEventListener('wheel', handleScroll);
    };
  }, []);

  return { scrollableRef };
};
