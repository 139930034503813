import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWakeLock } from 'react-screen-wake-lock';
import {
  LamelaManualCalibration,
  LamelaTiltCalibration,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/ZfBidiConfigParams';
import { FacadeConfigurationErrorCode } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/ZfBidiConfigService';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { ResponseResult } from 'lavva.exalushome/build/js/Services/FieldChangeResult';
import { CustomBackdrop, Header, NavHeader, Page, SubmitButton } from '../../../../../../../components';
import { usePreventBack } from '../../../../../../../hooks/navigation-guard/use-navigation-guard';
import { ROUTES } from '../../../../../../../routes';
import { ChannelBoxIcon } from '../../../../../components/channel/box-icon';
import { useFacadeControl } from '../../../../../components/channel/hooks/use-facade-control';
import { useHandleDataFrameErrors } from '../../../../../hooks';
import { useConfigurationService } from '../../../../device-details/hooks/use-configuration-service';
import { useCalibration } from '../../common/hooks/use-calibration';
import './index.scss';

type ZfBidiCalibrationStep3Props = {
  setStep: Dispatch<SetStateAction<number>>;
  setMovementService: Dispatch<SetStateAction<LamelaManualCalibration | undefined>>;
  setTiltService: Dispatch<SetStateAction<LamelaTiltCalibration | undefined>>;
};

const ZfBidiCalibrationStep3: React.FC<ZfBidiCalibrationStep3Props> = ({
  setStep,
  setMovementService,
  setTiltService,
}) => {
  const { t: td } = useTranslation('device-add');
  const { channel, history, t, getZfBidiConfigService } = useCalibration();
  const { stop } = useFacadeControl(channel);
  const { handleGetFacadeParamsResponse } = useConfigurationService();
  const { handleError } = useHandleDataFrameErrors();
  const { request, release } = useWakeLock();
  const [loading, setLoading] = useState<boolean>(false);
  usePreventBack(loading ? t('exalus.params.ZfBidiParams.calibrationFlow.noBackMessage') : '');

  useEffect(() => {
    request();

    return () => {
      release();
    };
  }, []);

  const handleStart = useCallback(async () => {
    if (channel) {
      setLoading(true);
      const configService = await getZfBidiConfigService();
      const result = await configService.LamelaTiltCalibration(channel?.GetDevice(), channel.Number);

      handleGetFacadeParamsResponse<LamelaTiltCalibration>(result, async () => {
        setTiltService(result as LamelaTiltCalibration);
        const initResult: Status | ResponseResult<FacadeConfigurationErrorCode> = await (
          result as LamelaTiltCalibration
        ).InitCalibrationAsync();

        handleGetFacadeParamsResponse<Status>(initResult, () => {
          if (initResult === Status.OK) {
            setMovementService((result as LamelaTiltCalibration).LamelaManualCalibration);
            setLoading(false);

            setStep(4);
            history.push(`${ROUTES.FacadeCalibration(channel.ChannelId)}?step=4`);
          } else {
            handleError(initResult as Status);
          }
        });
      });
    }
  }, [channel]);

  const handleStop = useCallback(() => {
    stop();
    setLoading(false);
  }, [stop]);

  const handleNext = useCallback(() => {
    setStep(6);
    history.push(`${ROUTES.FacadeCalibration(channel?.ChannelId)}?step=6`, { back: 5 });
  }, [channel]);

  return (
    <Page
      className="calibration-flow"
      isStickyHeader
      whiteContent
      header={
        <>
          <NavHeader />
          <Header title={t('exalus.calibration')} />
        </>
      }
    >
      {channel && <ChannelBoxIcon channel={channel} className="facade-icon" />}
      <p className="info m-t-40 m-b-24">{t('exalus.params.ZfBidiParams.calibrationFlow.setLamelsDefault')}</p>
      <p className="info--sub m-b-24 tiny">
        {t(`exalus.params.ZfBidiParams.calibrationFlow.${!loading ? 'setLamelsInfo' : 'positionSetInProgress'}`)}
      </p>
      {!loading && (
        <button className="button button--secondary m-t-24" onClick={handleStart}>
          {t('exalus.params.ZfBidiParams.calibrationFlow.start')}
        </button>
      )}
      {!loading && (
        <SubmitButton type="button" className="button" onClick={handleNext} tranparent>
          {t('exalus.params.ZfBidiParams.calibrationFlow.next')}
        </SubmitButton>
      )}
      {loading && <CustomBackdrop loading btnTitle={td('exalus.searchStop')} onClick={handleStop} />}
    </Page>
  );
};

export default ZfBidiCalibrationStep3;
