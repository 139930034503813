import { ChannelTypeInternal, UserDevice } from '../../data-access/gql-types/graphql';
import { DeviceType } from '../../types';

const getDeviceType = (userDevice: UserDevice) => {
  const types = (userDevice.payload?.channelInfos || []).map((x) => x?.channelType);

  return (
    [
      ChannelTypeInternal.Blind,
      ChannelTypeInternal.Gate,
      ChannelTypeInternal.Switch,
      ChannelTypeInternal.Light,
      ChannelTypeInternal.Meter,
      ChannelTypeInternal.Optimizer,
      ChannelTypeInternal.GenericInput,
    ].find((type) => types.includes(type)) || ChannelTypeInternal.Unknown
  );
};

export const parseDevice = (userDevice: UserDevice) => {
  const newDevice: DeviceType = {
    id: userDevice.id,
    installationId: userDevice.installationId,
    type: getDeviceType(userDevice),
    isUpdating: false,
    payload: userDevice.payload
      ? { ...userDevice.payload }
      : {
          __typename: 'DeviceStateModel',
          channelInfos: [],
          connectedToSsid: '',
          macAddress: '',
          currentFirmwareVersion: '',
          deviceId: '',
          isUpdateAvailable: false,
          model: '',
          name: '',
          newestFirmwareVersion: '',
          timeZone: '',
          lastConnectedAtUtc: '',
          registeredAtUtc: '',
        },
  };
  return newDevice;
};
