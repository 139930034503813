import React from 'react';

type IconOnePieceDoorProps = {
  position?: number; // In percents from 0 to 100
};

export const IconOnePieceDoor: React.FC<IconOnePieceDoorProps> = ({ position = 100 }) => (
  <svg
    width="42"
    height="38"
    viewBox="0 0 42 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-cover icon-one-piece-door"
  >
    {position === 100 ? (
      <>
        <path
          d="M37 34L37 3L0.999989 3L0.999989 34"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.25 10.1538L0.999992 10.1538"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.25 18.5L0.999992 18.5"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.25 26.8462L0.999992 26.8462"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M37 34H0.999999" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </>
    ) : position === 0 ? (
      <>
        <path d="M39 3L2.99999 3" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M39 34V6.5M3 34V29V26.5V6M39 4V3M3 4.5V3" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" />
        <path
          d="M37.7742 3L41 6L1 6L4.5 3L37.7742 3Z"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.0649 6L2.9359 6"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ) : (
      <>
        <path d="M39 3L2.99999 3" stroke="#15141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M39 34V24M3 34V29V26.5V24M39 11V3M3 12V5.5V3"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.7742 3L41 24L0.999999 24L4.5 3L37.7742 3Z"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.7749 9.3916L4.22652 9.3916"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.0649 16.6953L2.9359 16.6953"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}
  </svg>
);
