import React, { createContext, useState } from 'react';
import { ChannelStateType, ChannelsState } from '../../types';

const initialState: ChannelsState = {
  channelState: {},
  setChannelState: () => null,
};

export const ChannelsStateContext = createContext(initialState);

const ChannelsStateProvider: React.FC = ({ children }) => {
  const [channelState, setChannelState] = useState<Record<string, ChannelStateType>>(initialState.channelState);

  const values: ChannelsState = {
    channelState,
    setChannelState,
  };

  return <ChannelsStateContext.Provider value={values}>{children}</ChannelsStateContext.Provider>;
};

export default ChannelsStateProvider;
