import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { IconChevron, SelectOptionType } from '../../../components';
import { DayOfWeekInternal } from '../../../data-access/gql-types/graphql';
import { toastError } from '../../../utils/toast';
import { useSelectedDays } from '../../trigger-form/hooks/use-selected-days';
import { useOptimizerScheduleContext } from '../context';
import { OptimizerScheduleBody, ScheduleAction } from '../types';
import { useScheduleValues } from './use-schedule-values';

export const useAddEditSchedule = () => {
  const history = useHistory();
  const { t } = useTranslation('configuration');
  const [repeatPopup, setRepeatPopup] = useState<boolean>(false);
  const [actionValuePopup, setActionValuePopup] = useState<boolean>(false);
  const [errors, setError] = useState<{ days: boolean; action: boolean }>({ days: false, action: false });
  const [selectedDaysTemp, setSelectedDaysTemp] = useState<DayOfWeekInternal[]>([]);
  const { actionValueParameters } = useScheduleValues();
  const { daysOfWeek } = useSelectedDays();
  const { addScheduleToList, editSchedule, schedules } = useOptimizerScheduleContext();
  const { scheduleId } = useParams<{ scheduleId: string }>();
  const form = useForm<OptimizerScheduleBody>({
    defaultValues: {
      from: '00:00',
      to: '00:00',
      action: ScheduleAction.None,
      actionValue: 0,
      selectedDays: [],
    },
  });
  const { action, actionValue, selectedDays } = form.watch();

  useEffect(() => {
    if (scheduleId) {
      const foundSchedule = schedules.find((x) => x.id === scheduleId);

      if (foundSchedule) {
        form.setValue('from', foundSchedule.from);
        form.setValue('to', foundSchedule.to);
        form.setValue('action', foundSchedule.action);
        form.setValue('actionValue', foundSchedule.actionValue);
        form.setValue('selectedDays', foundSchedule.selectedDays);
      }
    }
  }, [scheduleId]);

  const selectedDaysTitle = useMemo(() => {
    return daysOfWeek
      .filter((x) => selectedDays.includes(x.value))
      .map((x) => x.short)
      .join(', ');
  }, [selectedDays]);

  const actions: SelectOptionType<ScheduleAction>[] = [
    {
      label: t('schedule.actions.none'),
      value: ScheduleAction.None,
      icon: <IconChevron direction="right" noSpace />,
      onClick: () => {
        form.setValue('actionValue', 0);
      },
    },
    {
      label: t('schedule.actions.temperature'),
      value: ScheduleAction.Teperature,
      icon: <IconChevron direction="right" noSpace />,
      onClick: () => {
        if (
          actionValueParameters[ScheduleAction.Teperature].default !== undefined &&
          (!actionValue || action !== ScheduleAction.Teperature)
        ) {
          form.setValue('actionValue', actionValueParameters[ScheduleAction.Teperature].default);
        }

        setActionValuePopup(true);
      },
    },
  ];

  const handleOpenRepeatPopup = () => {
    setSelectedDaysTemp(selectedDays);
    setRepeatPopup(true);
  };

  const handleCheckDay = (day: DayOfWeekInternal) => {
    const index = selectedDaysTemp.findIndex((x) => x === day);

    if (index !== -1) {
      setSelectedDaysTemp((prev) => {
        const temp = [...prev];
        temp.splice(index, 1);

        return [...temp];
      });
    } else {
      setSelectedDaysTemp((prev) => [...prev, day]);
    }
  };

  const handleRepeatDaysSave = () => {
    form.setValue('selectedDays', selectedDaysTemp);
    setRepeatPopup(false);
  };

  const handleScheduleSubmit = form.handleSubmit((values) => {
    const daysError = !values.selectedDays.length;
    const actionError = !values.actionValue;
    setError({ days: daysError, action: actionError });

    if (values.from >= values.to) {
      toastError({ content: t('schedule.errors.timeStartEnd') });
    }

    if (daysError || actionError || values.from >= values.to) return;

    if (!scheduleId) addScheduleToList(values);
    editSchedule(scheduleId, values);
    history.goBack();
  });

  return {
    form,
    actions,
    actionValuePopup,
    action,
    actionValue,
    repeatPopup,
    selectedDaysTemp,
    selectedDaysTitle,
    scheduleId,
    errors,
    handleOpenRepeatPopup,
    handleCheckDay,
    setRepeatPopup,
    setActionValuePopup,
    handleScheduleSubmit,
    handleRepeatDaysSave,
  };
};
