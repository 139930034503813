import { useMemo } from 'react';
import { GateFeatureTypeInternal, GateModeInternal } from '../../../data-access/gql-types/graphql';
import { ChannelGateType, ChannelType } from '../../../types';

export const useActionGatesSupportedFeatures = (channels: ChannelType[]) => {
  const ventilationSupported = useMemo(() => {
    const oldGates = channels
      .filter((x) => !(x.data as ChannelGateType).supportedGateFeatures.length)
      .every((x) => (x.data as ChannelGateType).gateMode === GateModeInternal.RollUp);

    const newGates = channels
      .filter((x) => (x.data as ChannelGateType).supportedGateFeatures.length)
      .every((x) => (x.data as ChannelGateType).supportedGateFeatures.includes(GateFeatureTypeInternal.Tilt));

    return oldGates && newGates;
  }, [channels]);

  const setDirectionSupported = useMemo(() => {
    const oldGates = channels
      .filter((x) => !(x.data as ChannelGateType).supportedGateFeatures.length)
      .every((x) => (x.data as ChannelGateType).gateMode === GateModeInternal.RollUp);

    const newGates = channels
      .filter((x) => (x.data as ChannelGateType).supportedGateFeatures.length)
      .every((x) => (x.data as ChannelGateType).supportedGateFeatures.includes(GateFeatureTypeInternal.SetDir));

    return oldGates && newGates;
  }, [channels]);

  const setPositionSupported = useMemo(() => {
    const oldGates = channels
      .filter((x) => !(x.data as ChannelGateType).supportedGateFeatures.length)
      .every((x) => (x.data as ChannelGateType).gateMode === GateModeInternal.RollUp);

    const newGates = channels
      .filter((x) => (x.data as ChannelGateType).supportedGateFeatures.length)
      .every((x) => (x.data as ChannelGateType).supportedGateFeatures.includes(GateFeatureTypeInternal.SetPos));

    return oldGates && newGates;
  }, [channels]);

  return {
    ventilationSupported,
    setDirectionSupported,
    setPositionSupported,
  };
};
