import { useMemo } from 'react';
import { VersionedFeatureType } from '../../../../../data-access/gql-types/graphql';
import { useVersionMap } from '../../../../../hooks/api/version-map';
import { ChannelType } from '../../../../../types';

export const useOptimizerSupport = (channel: ChannelType) => {
  const { checkSupport, versionMapData } = useVersionMap();

  const tariffSupported = useMemo(
    () =>
      checkSupport({
        feature: VersionedFeatureType.OptimizerTariffs,
        supportKey: 'Optimizer',
        currentVersion: channel.currentFirmwareVersion,
      }),
    [channel.currentFirmwareVersion, versionMapData],
  );

  return {
    tariffSupported,
  };
};
