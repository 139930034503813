import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DeviceResponseType as ChannelResponseType,
  DeviceTaskType as ChannelTaskType,
} from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { LightBrightnessDeviceState } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { LightSlider } from '../../../../../../components';
import { canChannelDo } from '../../../../utils';
import { useDimmerControl } from '../../hooks/use-dimmer-control';

type DimmerControlsProps = {
  channel: IDeviceChannel;
  active: boolean;
};

export const DimmerControls: React.FC<DimmerControlsProps> = ({ channel, active }) => {
  const { handleSliderEvent } = useDimmerControl(channel);
  const state = useMemo(() => {
    return channel.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.LightBrightness,
    ) as LightBrightnessDeviceState;
  }, [channel.States]);

  const brightness = useMemo(() => {
    return state?.Data?.Brightness ?? null;
  }, [channel.States]);

  const [sliderValue, setSliderValue] = useState(brightness ?? 0);
  const [tempBrightness, setTempBrightness] = useState<number | null>(null);
  const [previousValue, setPreviousValue] = useState(0);

  useEffect(() => {
    if (previousValue === tempBrightness && tempBrightness !== brightness) {
      setSliderValue(Math.round((brightness * 100) / 100));
    } else if (tempBrightness && tempBrightness === brightness) {
      setSliderValue(tempBrightness);
    }
    setPreviousValue(brightness);
  }, [brightness]);

  useEffect(() => {
    setTempBrightness(sliderValue);
  }, [sliderValue]);

  useEffect(() => {
    setSliderValue(Math.round((brightness * 100) / 100));
    setPreviousValue(sliderValue);
  }, [setSliderValue]);

  useEffect(() => {
    if (typeof brightness === 'number') {
      if (brightness === sliderValue) return;
      setSliderValue(brightness);
    }
  }, [state]);

  const handleChange = useCallback((value: number) => setSliderValue(value), [setSliderValue]);

  if (!canChannelDo(channel, [ChannelTaskType.SetLightBrightness]) || !state) {
    return null;
  }

  return (
    <div className="details-page__controls-slider">
      <LightSlider value={sliderValue} onPointerUp={handleSliderEvent} onChange={handleChange} active={active} />
    </div>
  );
};
