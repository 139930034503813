import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { NotifyTask, NotifyType } from 'lavva.exalushome/build/js/Services/Scenes/SceneTaskTypes';
import { SupportedTaskTypes } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import {
  Checkbox,
  CustomBackdrop,
  Header,
  InputSelect,
  NavHeader,
  Page,
  SubmitButton,
  TextArea,
} from '../../../../../../../components';
import { useProfile } from '../../../../../../../hooks';
import { ROUTES } from '../../../../../../../routes';
import { toastError } from '../../../../../../../utils/toast';
import { ActionType } from '../../../../../../action-create/types';
import { useExalusCreateActionContext } from '../../../context';
import { useNotifyTask } from '../../../hooks/use-notify-task';
import { useTaskEdit } from '../../../hooks/use-task-edit';
import { NotifyTaskParams } from '../../../types';
import { specialPhrasePattern } from '../../../utils/notify-helpers';
import './index.scss';
import { Tiles } from './tiles';

const NotifyView: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('action');
  const { task, finalizeNotifyTaskSubmit } = useTaskEdit();
  const { actionType, supportedTaskTypes } = useExalusCreateActionContext();
  const [notifyType, setNotifyType] = useState<NotifyType>(NotifyType.AppNotification);
  const [message, setMessage] = useState<string>('');
  const [notificationClients, setNotificationClients] = useState<string[]>([]);
  const { channelsAvailable, getMessageParsed } = useNotifyTask();
  const { user, loadingUser } = useProfile();

  useEffect(() => {
    if (actionType === ActionType.Undefined) history.replace(ROUTES.ActionList());
  }, []);

  const activeForUser = useMemo(() => {
    return notificationClients.findIndex((x) => x === user.profile.email);
  }, [notificationClients, user]);

  useEffect(() => {
    if (task) {
      const message = (task.taskParams as NotifyTaskParams).message;
      const notifyType = (task.taskParams as NotifyTaskParams).notifyType;
      const notificationClients = (task.taskParams as NotifyTaskParams).notificationClients;

      if (notifyType) setNotifyType(notifyType);
      if (message) setMessage(message);
      setNotificationClients(notificationClients);
    }
  }, [task]);

  const supportedOptions = useMemo(() => {
    return !supportedTaskTypes.length
      ? { app: true, email: false }
      : {
          app: !!supportedTaskTypes.find((x) => x === SupportedTaskTypes.NotifyTaskAppNotify),
          email: !!supportedTaskTypes.find((x) => x === SupportedTaskTypes.NotifyTaskEmailNotify),
        };
  }, [supportedTaskTypes]);

  const messageParsed = useMemo(() => getMessageParsed(message), [message]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newMessage = event.target.value;
    const specialPhrases = message.match(specialPhrasePattern) || [];
    const newSpecialPhrases: string[] = newMessage.match(specialPhrasePattern) || [];

    if (specialPhrases.every((phrase: string) => newSpecialPhrases.includes(phrase))) {
      setMessage(newMessage);
    }
  };

  const onKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Backspace') {
      const cursorPosition = event.currentTarget.selectionStart;
      const specialPhrases = message.match(specialPhrasePattern) || [];

      for (const phrase of specialPhrases) {
        const startIdx = message.indexOf(phrase);
        const endIdx = startIdx + phrase.length;

        if ((startIdx <= cursorPosition && cursorPosition <= endIdx) || cursorPosition === endIdx) {
          const updatedMessage = message.slice(0, startIdx) + message.slice(endIdx);
          setMessage(updatedMessage);

          event.preventDefault();
          return;
        }
      }
    }
  };

  const handleTileSelect = (val: string) =>
    setMessage((prev) => {
      const updatedMessage = prev.length ? `${prev} ${val}` : val;

      if (updatedMessage.length > 1000) {
        toastError({ content: t('action.create.tasks.limitCharacters') });
        return prev;
      }

      return updatedMessage;
    });

  const handelToggle = () => {
    const temp = user.profile.email;

    if (activeForUser !== -1) setNotificationClients(notificationClients.filter((x) => x !== temp));
    else setNotificationClients([...notificationClients, temp]);
  };

  const handleSubmit = () => {
    const task = new NotifyTask();
    task.Message = message;
    task.NotifyType = NotifyType.AppNotification;
    task.NotificationClients = notificationClients;

    finalizeNotifyTaskSubmit(task);
  };

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t('action.create.tasks.notify')} />
        </>
      }
    >
      <>
        <div className="grid-list-24 m-b-24">
          <InputSelect
            options={[
              {
                label: t('action.create.tasks.appNotification'),
                value: NotifyType.AppNotification,
                disabled: !supportedOptions.app,
              },
              {
                label: t('action.create.tasks.email'),
                value: NotifyType.Email,
                disabled: !supportedOptions.email,
              },
            ]}
            value={notifyType}
            onChange={setNotifyType}
            label={t('action.create.tasks.notifyType')}
            className="m-b-0"
          />
          <Checkbox checked={activeForUser !== -1} onChange={handelToggle} reverse className="m-t-16 m-b-0">
            {t('action.create.tasks.activeForUser')}
          </Checkbox>
        </div>
        <TextArea
          defaultValue={message}
          value={message}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          label={t('action.create.tasks.editor')}
          placeholder={t('action.create.tasks.message')}
          rows={4}
          maxLength={1000}
        />
        {messageParsed && (
          <div className="parsed-message">
            <p>{t('action.create.tasks.message')}</p>
            <p>{messageParsed}</p>
          </div>
        )}
        <Tiles channelsAvailable={channelsAvailable} handleTileSelect={handleTileSelect} />
        <SubmitButton onClick={handleSubmit} />
      </>
      <CustomBackdrop loading={loadingUser} />
    </Page>
  );
};

export default NotifyView;
