import React from 'react';

type IconMultiboxProps = {
  position?: number; // In percents from 0 to 100
};

export const IconMultibox: React.FC<IconMultiboxProps> = ({ position = 100 }) => (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon-cover icon-multibox"
  >
    {position < 100 ? (
      <>
        <path
          d="M29.0526 40.8634L29.0526 4.84229L15.7368 4.8423L15.7368 40.8634L19.9737 40.8635"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.9736 18.1577L29.0526 19.3682L29.0526 40.8631L19.9736 42.9735L19.9736 18.1577Z"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5789 9.68416L20.5789 8.47363"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.2104 8.47356L24.2104 9.68408"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23 29.0532L23 31.8545"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.3684 19.3687H15.7368"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.0527 15.7368L15.7369 15.7368"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ) : (
      <>
        <path
          d="M15.7368 4.84229L29.0526 4.84227L29.0526 40.8634L15.7368 40.8634L15.7368 4.84229Z"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5789 9.68416L20.5789 8.47363"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.2104 8.47356L24.2104 9.68408"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.3684 29.0527L19.3684 31.854"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.0527 19.3687H15.7369"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.0527 15.7368L15.7369 15.7368"
          stroke="#15141D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}
  </svg>
);
