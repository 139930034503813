import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { IZfBidiConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/IZfBidiConfigService';
import { FacadeDetection as FacadeDetectionType } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/ZfBidiConfigParams';
import { ZfBidiConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/ZfBidiConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { InputSelect, SubmitButton } from '../../../../../../components';
import { useBackdropContext } from '../../../../../../hooks';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import { FacadeDetectionParams } from '../../../device-details/types';

type FacadeDetencionFormProps = {
  device: IDevice;
  channel: IDeviceChannel;
  facadeDetection: FacadeDetectionType | null;
};

const FacadeDetencionForm: React.FC<FacadeDetencionFormProps> = ({ device, channel, facadeDetection }) => {
  const { t } = useTranslation('device-info');
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleSubmitResponse } = useConfigurationService();
  const form = useForm<FacadeDetectionParams>();

  const { FacadeDetection } = form.watch();

  useEffect(() => {
    if (facadeDetection !== null) form.setValue('FacadeDetection', facadeDetection);
  }, [facadeDetection]);

  const onSubmit = form.handleSubmit(async (values) => {
    turnOnBackdrop();
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IZfBidiConfigService>(
      ZfBidiConfigService.ServiceName,
    );

    const result = await configService.SetFacadeTypeDetectionAsync(device, channel.Number, values.FacadeDetection);
    handleSubmitResponse(result);
    turnOffBackdrop();
  });

  const onChangeFacadeDetection = (value: FacadeDetectionType) => {
    form.setValue('FacadeDetection', value);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className="grid-list-24">
        <InputSelect
          onChange={onChangeFacadeDetection}
          value={FacadeDetection}
          options={[
            {
              label: t('exalus.params.ZfBidiParams.facadeDetectionSteps.Automatic'),
              value: FacadeDetectionType.Automatic,
            },
            {
              label: t('exalus.params.ZfBidiParams.facadeDetectionSteps.ForceType90'),
              value: FacadeDetectionType.ForceType90,
            },
            {
              label: t('exalus.params.ZfBidiParams.facadeDetectionSteps.ForceType180'),
              value: FacadeDetectionType.ForceType180,
            },
          ]}
          label={t('exalus.params.ZfBidiParams.FacadeDetencion')}
        />
        <SubmitButton />
      </form>
    </FormProvider>
  );
};

export default FacadeDetencionForm;
