import React, { ReactNode } from 'react';
import { ApolloProvider } from '@apollo/client';
import { useGraphqlContext } from './graphql.context';

type ApolloProviderWrapperProps = {
  children: ReactNode;
};

const ApolloProviderWrapper: React.FC<ApolloProviderWrapperProps> = ({ children }) => {
  const { client } = useGraphqlContext();

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloProviderWrapper;
