import React from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { useHumidityControl } from '../../../hooks/use-humidity-control';
import { SensorControlBox } from '../../../sensor-control-box';

type HumidityControlProps = {
  channel: IDeviceChannel;
  isListItem?: boolean;
};

const HumidityControl: React.FC<HumidityControlProps> = ({ channel, isListItem }) => {
  const { humidity } = useHumidityControl(channel);

  return <SensorControlBox isListItem={isListItem} value={humidity} unit={'%'} />;
};

export default HumidityControl;
