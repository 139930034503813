import React from 'react';
import './index.scss';

type IconEyeProps = {
  hideCircle?: boolean;
};

export const IconEye: React.FC<IconEyeProps> = ({ hideCircle }) => (
  <svg className="icon-eye" width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 7.75C22 7.75 17.299 14.5 11.5 14.5C5.70101 14.5 1 7.75 1 7.75C1 7.75 5.70101 1 11.5 1C17.299 1 22 7.75 22 7.75Z"
      stroke="#15141D"
      strokeWidth="1.5"
    />
    {!hideCircle && <circle cx="11.5" cy="8" r="2" fill="#15141D" />}
  </svg>
);
