import React from 'react';
import classNames from 'classnames';
import { ApolloQueryResult } from '@apollo/client';
import { IconButton } from '@mui/material';
import { SignalStrength } from '../../../../../components';
import { IconSynchronize } from '../../../../../components/icons/icon-synchronize';
import { Exact, RssiQuery } from '../../../../../data-access/gql-types/graphql';
import './index.scss';

type RssiProps = {
  rssiSupported: boolean;
  rssi: number | null;
  rssiLoading: boolean;
  refetchRssi: (
    variables?:
      | Partial<
          Exact<{
            installationId: string;
            deviceId: string;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<RssiQuery>>;
};

const Rssi: React.FC<RssiProps> = ({ rssiSupported, rssi, rssiLoading, refetchRssi }) => {
  const refetch = () => refetchRssi();

  if (!rssiSupported || rssi === null) return null;

  return (
    <>
      <SignalStrength signal={rssi} />
      <IconButton
        disabled={rssiLoading}
        className={classNames('rssi-button', { rotating: rssiLoading })}
        onClick={refetch}
      >
        <IconSynchronize />
      </IconButton>
    </>
  );
};

export default Rssi;
