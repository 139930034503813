import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogConfirmation, IconBluetooth, SignalStrength } from '../../../../components';
import ArrowButton from '../../../../components/arrow-button';
import CircularProgressWithLabel from '../../../../components/progress-with-label';
import { useInstallation } from '../../../../hooks';
import { usePreventBack } from '../../../../hooks/navigation-guard/use-navigation-guard';
import { getSignalStrength } from '../../../../utils/helpers/network';
import { useBluetoothContext } from '../../context';
import './index.scss';

type DialogScanningProps = {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
};

export const DialogScanning: React.FC<DialogScanningProps> = ({ show, setShow }) => {
  const { t } = useTranslation('device-add');
  const { t: tc } = useTranslation('common');
  const { foundDevices, scanning, searchBluetooth, handleDeviceConnect, WebBluetooth } = useBluetoothContext();
  const { selectedInstallation } = useInstallation();
  usePreventBack(show ? t('bluetooth.noBackMessageScan') : '');

  const handleCancel = () => {
    setShow(false);
    WebBluetooth.OnSelectionCancelled.Invoke();
  };

  return (
    <DialogConfirmation
      show={show}
      setShow={setShow}
      header={t('bluetooth.bluetoothDevices')}
      secondaryBtnAction={handleCancel}
      primaryBtnAction={() => searchBluetooth(true)}
      {...(!scanning ? { primaryBtnText: ` ${t('bluetooth.scanAgain')}` } : {})}
      secondaryBtnText={tc('buttons.cancel')}
      contentClassName="scanning-bluetooth-content"
      headerIcon={
        scanning ? (
          <CircularProgressWithLabel
            color="inherit"
            size={26}
            {...(selectedInstallation ? { style: { color: selectedInstallation.hexColor } } : {})}
          />
        ) : (
          <div />
        )
      }
    >
      {foundDevices.map((device, index) => (
        <div key={device.deviceName}>
          <ArrowButton
            title={device.deviceName}
            subTitle={device.deviceAddress}
            icon={<IconBluetooth />}
            arrowIcon={<SignalStrength signal={getSignalStrength(device.rssi, true)} />}
            onClick={() => handleDeviceConnect(device)}
          />
          {foundDevices.length - 1 !== index && <hr />}
        </div>
      ))}
    </DialogConfirmation>
  );
};
