import { useMemo } from 'react';
import { GateFeatureTypeInternal, GateModeInternal } from '../../../../data-access/gql-types/graphql';
import { ChannelGateType, ChannelType } from '../../../../types';

export const useGateSupportedFeatures = (channel?: ChannelType) => {
  const gateChannel = channel?.data as ChannelGateType | undefined;

  const ventilationSupported = useMemo(() => {
    if (gateChannel?.supportedGateFeatures.length) {
      return gateChannel.supportedGateFeatures.includes(GateFeatureTypeInternal.Tilt);
    }

    return gateChannel?.gateMode === GateModeInternal.RollUp;
  }, [gateChannel?.gateMode, gateChannel?.supportedGateFeatures]);

  const setDirectionSupported = useMemo(() => {
    if (gateChannel?.supportedGateFeatures.length) {
      return gateChannel.supportedGateFeatures.includes(GateFeatureTypeInternal.SetDir);
    }

    return gateChannel?.gateMode === GateModeInternal.RollUp;
  }, [gateChannel?.gateMode, gateChannel?.supportedGateFeatures]);

  const setPositionSupported = useMemo(() => {
    if (gateChannel?.supportedGateFeatures.length) {
      return gateChannel.supportedGateFeatures.includes(GateFeatureTypeInternal.SetPos);
    }

    return gateChannel?.gateMode === GateModeInternal.RollUp;
  }, [gateChannel?.gateMode, gateChannel?.supportedGateFeatures]);

  const calibrationSupported = useMemo(() => {
    return gateChannel?.supportedGateFeatures.includes(GateFeatureTypeInternal.Calibrate);
  }, [gateChannel?.supportedGateFeatures]);

  const stepByStepSupported = useMemo(() => {
    if (gateChannel?.supportedGateFeatures.length) {
      return gateChannel.supportedGateFeatures.includes(GateFeatureTypeInternal.StepByStep);
    }

    return gateChannel?.gateMode === GateModeInternal.StepByStep;
  }, [gateChannel?.gateMode, gateChannel?.supportedGateFeatures]);

  const electricStrikeSupported = useMemo(() => {
    return gateChannel?.supportedGateFeatures.includes(GateFeatureTypeInternal.ElectricStrike);
  }, [gateChannel?.supportedGateFeatures]);

  const partialControlModeSupported = useMemo(() => {
    return gateChannel?.supportedGateFeatures.includes(GateFeatureTypeInternal.PartialControlMode);
  }, [gateChannel?.supportedGateFeatures]);

  const dailyModeModeSupported = useMemo(() => {
    return gateChannel?.supportedGateFeatures.includes(GateFeatureTypeInternal.DailyMode);
  }, [gateChannel?.supportedGateFeatures]);

  const lockReportingModeSupported = useMemo(() => {
    return gateChannel?.supportedGateFeatures.includes(GateFeatureTypeInternal.LockReporting);
  }, [gateChannel?.supportedGateFeatures]);

  return {
    ventilationSupported,
    setDirectionSupported,
    setPositionSupported,
    calibrationSupported,
    electricStrikeSupported,
    stepByStepSupported,
    partialControlModeSupported,
    dailyModeModeSupported,
    lockReportingModeSupported,
  };
};
