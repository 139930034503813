import React from 'react';
import { Api } from 'lavva.exalushome';
import { ExtaLifeService } from 'lavva.exalushome.extalife';
import { GlobalTimeParameters } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/OnOffSwitchConfigurations/OnOffSwitchParameters';
import { IRdp21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Dimmer/IRdp21ConfigService';
import { IRog21ConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/PowerPlugSwitch/IRog21ConfigService';
import { IRomConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RomSwitches/IRomConfigService';
import { IRopConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/RopSwitches/IRopConfigService';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import TimeForm from '../common/time';

type GlobalTimeFormProps = {
  device: IDevice;
  timeParams: GlobalTimeParameters | null;
};

const GlobalTimeForm: React.FC<GlobalTimeFormProps> = ({ device, timeParams }) => {
  const { configService: serviceName } = useConfigurationService(device);

  const saveChanges = async (params: GlobalTimeParameters) => {
    const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
    const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<
      IRdp21ConfigService | IRog21ConfigService | IRopConfigService | IRomConfigService
    >(serviceName);

    return await configService.SetGlobalTimeSettingsAsync(device, params);
  };

  return <TimeForm timeParams={timeParams} saveChanges={saveChanges} paramsTitle="GlobalTimeParams" />;
};

export default GlobalTimeForm;
