import {
  AmpTransformersInternal,
  ChannelTypeInternal,
  MeterChannelStateResponse,
  MeterPurposeInternal,
  MeterSharedChannelSettings,
  StandaloneMeasurementPayload,
  UserChannel,
} from '../../../data-access/gql-types/graphql';
import { ChannelIconTypeEnum, ChannelType, ChannelStateType } from '../../../types';

export const parseMeterChannel = (userChannel: UserChannel): ChannelType => ({
  id: userChannel.id,
  installationId: userChannel.installationId,
  isVisible: userChannel.isVisible,
  alias: userChannel.alias,
  iconName: userChannel.iconName || ChannelIconTypeEnum.METER,
  deviceId: userChannel.deviceId,
  currentFirmwareVersion: userChannel.currentFirmwareVersion || null,
  data: {
    type: ChannelTypeInternal.Meter,
    meterType: (userChannel.payload as MeterChannelStateResponse)?.meterType || undefined,
    ampTransformers:
      (userChannel.payload as MeterChannelStateResponse)?.ampTransformers || AmpTransformersInternal.Unknown,
    meterPurpose: (userChannel.payload as MeterChannelStateResponse)?.meterPurpose || MeterPurposeInternal.Unknown,
    payload: ((userChannel.payload as MeterChannelStateResponse)?.payload as StandaloneMeasurementPayload) || null,
    supportedVectorParameters: (userChannel.payload as MeterChannelStateResponse)?.supportedVectorParameters || [],
    supportedScalarParameters: (userChannel.payload as MeterChannelStateResponse)?.supportedScalarParameters || [],
    tariff: (userChannel.payload as MeterChannelStateResponse)?.tariff || undefined,
    supportedAggregateVectorParameters:
      (userChannel.payload as MeterChannelStateResponse)?.supportedAggregateVectorParameters || [],
    sharedChannelSettings: userChannel.sharedChannelSettings as MeterSharedChannelSettings || {},
  },
});

export const parseMeterStateChannel = (userChannel: UserChannel): ChannelStateType => ({
  deviceConnectionState: userChannel.payload?.deviceConnectionState,
  usesCounter: userChannel.usesCounter,
  grantedUses: userChannel.grantedUses || undefined,
});
