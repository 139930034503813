import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  ChannelBox,
  CustomBackdrop,
  EmptyStateBox,
  IconWarning,
  NavHeader,
  Page,
  SubmitButton,
} from '../../components';
import { ChannelGroupItem, ChannelType } from '../../types';
import { useChannelsVisibility } from './hooks/use-channels-visibility';

const ChannelsVisibility: React.FC = () => {
  const { t: tc } = useTranslation('common');
  const { t } = useTranslation('channel-list');
  const { visibilityList, channelGroups, loading, saveVisibility, onChannelToggle } = useChannelsVisibility();

  return (
    <Page
      header={
        <>
          <NavHeader />
          <h3 className="m-b-24">{t('visibilityEditing')}</h3>
        </>
      }
    >
      <>
        {channelGroups.length > 0 ? (
          <ul className="m-t-24 page-list">
            {channelGroups.map((item: ChannelGroupItem, typeIndex: number) => (
              <Accordion
                kind="custom"
                key={typeIndex}
                title={item.label}
                hideExpandedBorder
                expanded={channelGroups.length === 1}
              >
                <ul className="page-list__items-list items-list">
                  {item.channels.map((ch: ChannelType) => {
                    const checked = visibilityList.find((item) => item && item.id === ch.id)?.newVisibility;

                    return (
                      <li key={ch.id} className="items-list__item">
                        <ChannelBox
                          channel={ch}
                          isListItem
                          isCheckbox
                          checked={checked}
                          onChannelToggle={onChannelToggle}
                        />
                      </li>
                    );
                  })}
                </ul>
              </Accordion>
            ))}
            <SubmitButton
              disabled={!visibilityList.filter((device) => device.currentVisibility !== device.newVisibility).length}
              onClick={saveVisibility}
            >
              {tc(`buttons.${!loading ? 'save' : 'loading'}`)}
            </SubmitButton>
            <CustomBackdrop loading={loading} />
          </ul>
        ) : (
          <EmptyStateBox
            header={t('visibilityEditing')}
            content={t('channelsEmptyState.content')}
            icon={<IconWarning />}
          />
        )}
      </>
    </Page>
  );
};

export default ChannelsVisibility;
