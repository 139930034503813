import React from 'react';
import { ChannelType } from '../../../types';
import { ChannelDetailsLightRgb } from './components/rgb';

type ChannelDetailsLightProps = {
  channel: ChannelType;
};

const ChannelDetailsLight: React.FC<ChannelDetailsLightProps> = ({ channel }) => {
  return <ChannelDetailsLightRgb channel={channel} />;
};

export default ChannelDetailsLight;
