import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { useOptimizerSetConfig, useOptimizerSetMode } from '../../../../../api/modules/optimizer/optimizer.hooks';
import { OptimizerModeInternal } from '../../../../../data-access/gql-types/graphql';
import { useBackdropContext } from '../../../../../hooks';
import { ChannelType } from '../../../../../types';
import { getEnumKeyByValue } from '../../../../../utils/helpers';
import { toastSuccess } from '../../../../../utils/toast';
import { useOptimizerConfigurationContext } from '../../context/configuration-context';
import { EcoFormBody } from '../types';
import { prepareRelaysToSend } from '../utils';
import { useOptimizerErrors } from './use-optimizer-errors';

export const useOptimizerEco = (channel: ChannelType) => {
  const { t } = useTranslation('channel-details');
  const { mutate: setMode } = useOptimizerSetMode();
  const { mutate: setConfig } = useOptimizerSetConfig();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleOptimizerErrors } = useOptimizerErrors();
  const { clearErrors } = useOptimizerConfigurationContext();

  const submitConfiguration = (mode: OptimizerModeInternal, formValues: EcoFormBody) => {
    const {
      tempMax,
      voltageThreshold1,
      voltageThreshold2,
      voltageThreshold3,
      relayConfig1,
      relayConfig2,
      relayConfig3,
    } = formValues;
    clearErrors();

    turnOnBackdrop();
    setMode(
      {
        deviceId: channel.deviceId,
        channelId: channel.id,
        mode: getEnumKeyByValue(OptimizerModeInternal, mode),
      },
      {
        onSuccess: ({ data }) => {
          if (data) {
            turnOnBackdrop();

            setConfig(
              {
                deviceId: channel.deviceId,
                channelId: channel.id,
                eco: {
                  tempMax,
                  voltageThreshold: [
                    { index: 1, value: voltageThreshold1 },
                    { index: 2, value: voltageThreshold2 },
                    { index: 3, value: voltageThreshold3 },
                  ],
                  relaysConfig: prepareRelaysToSend({ relayConfig1, relayConfig2, relayConfig3 }),
                },
              },
              {
                onSuccess: () => {
                  turnOffBackdrop();
                  toastSuccess({ content: t('optimizer.configuration.configurationSaved') });
                },
                onError: (error: AxiosError) => {
                  handleOptimizerErrors(error);
                },
              },
            );
          } else {
            turnOffBackdrop();
          }
        },
      },
    );
  };

  return {
    submitConfiguration,
  };
};
