import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTransitionValue from 'react-transition-value';
import { orderBy } from 'lodash';
import { DonutChartWrapper } from '../../../../../../components/donut-chart';
import {
  VectorMeasurementResponseItem,
  VectorParameterTypeInternal,
} from '../../../../../../data-access/gql-types/graphql';
import { PhasesButtonGroup } from '../../../../measurements/components/phases-button-group';
import { SelectedPhase } from '../../../../measurements/types';
import { measurementParameters } from '../../../../measurements/utils';
import './index.scss';
import { EnergyConsumptionTotalValue } from './total-value';

type EnergyConsumptionProps = {
  selectedSinglePhaseMeasurements: VectorMeasurementResponseItem[] | undefined;
  maxValue: number;
  averageValue: number;
  changeSelectedParameter: () => void;
  selectedParameter: string;
  isActivePowerSelected: boolean;
  isStandalone: boolean;
  totalForwardActiveEnergy: VectorMeasurementResponseItem[];
  itemPrefix?: string;
  ecoIndicator?: number | null;
};

export const EnergyConsumption: React.FC<EnergyConsumptionProps> = ({
  selectedSinglePhaseMeasurements,
  maxValue,
  averageValue,
  changeSelectedParameter,
  isActivePowerSelected,
  selectedParameter,
  isStandalone,
  totalForwardActiveEnergy,
  itemPrefix,
  ecoIndicator,
}) => {
  const { t } = useTranslation('channel-details');
  const [selectedPhases, setSelectedPhases] = useState<SelectedPhase[]>([]);
  const [lazyFormattedSum, setLazyFormattedSum] = useTransitionValue(0, { duration: 800, from: 0 });

  const defaultPhases = useMemo(() => {
    const phases = orderBy(selectedSinglePhaseMeasurements || [], 'index')?.map((measurement) => measurement.index);
    return selectedParameter !== VectorParameterTypeInternal.Voltage ? [0, ...(phases ?? [])] : [...(phases ?? [])];
  }, [selectedSinglePhaseMeasurements, selectedParameter]);

  const sumFromSelectedPhases = useMemo(
    () =>
      selectedSinglePhaseMeasurements
        ?.filter((measurement) => {
          if (!selectedPhases.length || selectedPhases[0].value === 0) return true;
          else return !!selectedPhases.find((phase) => phase.value === measurement.index);
        })
        ?.reduce((prev, curr) => prev + curr.value, 0) || 0,
    [selectedSinglePhaseMeasurements, selectedPhases],
  );

  const isGreaterUnit = useMemo(() => {
    if (isActivePowerSelected) {
      return Math.abs(sumFromSelectedPhases) > 1000;
    }
  }, [isActivePowerSelected, sumFromSelectedPhases]);

  const formattedSum = useMemo(
    () => +(sumFromSelectedPhases / (isGreaterUnit ? 1000 : 1)).toFixed(1),
    [selectedSinglePhaseMeasurements, sumFromSelectedPhases, selectedPhases, isGreaterUnit],
  );
  const power = Math.floor(Math.abs(sumFromSelectedPhases) / (isGreaterUnit ? 1000 : 1)).toString().length;
  const scaleValueWithPower = 10 ** power;
  const multiplier = power > 3 ? 16.0 : 4.0;
  const maxScaleValue =
    maxValue > 0 ? multiplier * Math.ceil(maxValue / (isGreaterUnit ? 1000 : 1) / multiplier) : scaleValueWithPower;
  useEffect(() => {
    if (formattedSum !== 0) {
      setTimeout(() => setLazyFormattedSum(Math.abs(formattedSum), { from: 0 }), 200);
    } else {
      setLazyFormattedSum(0.000000001, { from: 0 }, 200);
    }
  }, [selectedParameter, selectedPhases, formattedSum, isGreaterUnit]);

  const totalForwardEnergy = useMemo(
    () =>
      totalForwardActiveEnergy
        ?.filter((measurement) => {
          if (!selectedPhases.length || selectedPhases[0].value === 0) return true;
          else return !!selectedPhases.find((phase) => phase.value === measurement.index);
        })
        ?.reduce((prev, curr) => prev + curr.value, 0) || 0,
    [selectedSinglePhaseMeasurements, selectedPhases],
  );

  return (
    <div className="energy-consumption">
      <DonutChartWrapper
        value={formattedSum}
        lazyValue={lazyFormattedSum}
        averageValue={+(averageValue / (isActivePowerSelected ? 1000 : 1)).toFixed(2)}
        maxScaleValue={maxScaleValue}
        label={t('now')}
        clickIndicator
        twoDirections={isStandalone && selectedParameter !== VectorParameterTypeInternal.Voltage}
        ecoIndicator={ecoIndicator}
        unit={
          isGreaterUnit ? measurementParameters[selectedParameter].unit2 : measurementParameters[selectedParameter].unit
        }
        changeSelectedParameter={changeSelectedParameter}
        selectedPhases={selectedPhases}
        isGreaterUnit={isGreaterUnit}
        phaseMeasurements={selectedSinglePhaseMeasurements?.map((phase) => ({
          ...phase,
          value: +(phase.value / (isGreaterUnit ? 1000 : 1)).toFixed(1),
        }))}
        {...(selectedParameter !== VectorParameterTypeInternal.Voltage
          ? { arrowDirection: formattedSum < 0 ? 'up' : 'down', arrow: true }
          : {})}
      >
        {isActivePowerSelected ? (
          <EnergyConsumptionTotalValue totalValue={totalForwardEnergy} ecoIndicator={ecoIndicator} />
        ) : null}
      </DonutChartWrapper>

      {selectedSinglePhaseMeasurements?.length ? (
        <div className="energy-consumption__button-group">
          <PhasesButtonGroup
            onSelectedPhases={setSelectedPhases}
            defaultPhases={defaultPhases}
            itemPrefix={itemPrefix}
            selectedParameter={selectedParameter}
            singleChoice
          />
        </div>
      ) : null}
    </div>
  );
};
