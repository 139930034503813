import { ReactNode } from 'react';

type ActionHeaderProps = {
  actionName: string;
  actionType: string;
  icon?: ReactNode;
  iconAdvanced?: ReactNode;
  isAdvanced?: boolean;
  statusIcon?: ReactNode;
};

const ActionHeader: React.FC<ActionHeaderProps> = ({
  actionName,
  actionType,
  icon = null,
  iconAdvanced = null,
  statusIcon = null,
  isAdvanced = false,
}) => (
  <div className="details-header p-t-0">
    <div className="details-header--left">
      {isAdvanced ? (
        <div className="action-icon action-icon--quantity-1 action-icon--header">
          <div className="action-icon__icon">
            {iconAdvanced}
            {statusIcon}
          </div>
        </div>
      ) : (
        <div className="details-header__relative-wrapper">
          {icon}
          {statusIcon}
        </div>
      )}
      <div className="details-header__name">
        <h2 className="text-ellipsis">{actionName}</h2>
        <div className="details-header__type">{actionType}</div>
      </div>
    </div>
  </div>
);

export default ActionHeader;
