import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SceneTaskType } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { PopUpNav } from '../../../../../../components';
import { IconDelete, IconEdit } from '../../../../../../components/popup-nav/icons';
import { ROUTES } from '../../../../../../routes';
import { useActionDetailsPresentation } from '../../../action-details/hooks/use-action-details-presentation';
import { useExalusCreateActionContext } from '../../context';
import { DeviceTaskParams, Task } from '../../types';
import { actionTaskVariants, actionTasks } from '../../utils/action-tasks';
import './index.scss';

type TaskItemProps = {
  task: Task;
};

const TaskItem: React.FC<TaskItemProps> = ({ task }) => {
  const history = useHistory();
  const { t } = useTranslation('action');
  const { builder, removeTask, setTaskVariant, setChannels, setTaskType } = useExalusCreateActionContext();
  const { getTaskState } = useActionDetailsPresentation();

  const handleDelete = () => {
    const id = task.id;
    builder?.Commit().RemoveTask(id);
    removeTask(id);
  };

  const editParameters = () => {
    setTaskType(task.taskType);

    switch (task.taskType) {
      case SceneTaskType.DeviceTask: {
        setChannels([(task.taskParams as DeviceTaskParams).channel]);
        setTaskVariant((task.taskParams as DeviceTaskParams).variant);
        history.push(`${ROUTES.ActionChannelSettings()}?id=${task.id}`);
        break;
      }
      case SceneTaskType.DelayTask: {
        history.push(`${ROUTES.ExalusActionTaskDelay()}?id=${task.id}`);
        break;
      }
      case SceneTaskType.LockExecution: {
        history.push(`${ROUTES.ExalusActionTaskLock()}?id=${task.id}`);
        break;
      }
      case SceneTaskType.NotifyTask: {
        history.push(`${ROUTES.ExalusActionTaskNotify()}?id=${task.id}`);
        break;
      }
      default: {
        break;
      }
    }
  };

  const value = useMemo(() => getTaskState(task), [task]);

  const taskOption = useMemo(() => {
    if (task.taskType === SceneTaskType.DeviceTask) {
      return actionTaskVariants.find((x) => x.variant === (task.taskParams as DeviceTaskParams).variant);
    }

    return actionTasks.find((x) => x.type === task.taskType);
  }, [task]);

  const taskTitle = useMemo(() => {
    switch (task.taskType) {
      case SceneTaskType.DeviceTask:
        return (task.taskParams as DeviceTaskParams).channel.Name;
      case SceneTaskType.DelayTask:
        return t('action.create.tasks.delay');
      case SceneTaskType.LockExecution:
        return t('action.create.tasks.lockExecution');
      case SceneTaskType.NotifyTask:
        return t('action.create.tasks.notify');
      default:
        return '';
    }
  }, [task]);

  return (
    <div className="exalus-task-item-wrapper">
      <div className="exalus-task-item">
        <div className="icon-wrapper">{taskOption?.icon}</div>
        <div className="exalus-task-item">
          <div className="exalus-task-item__content">
            <h4 className="exalus-task-item__title">{taskTitle}</h4>
            <div className="exalus-task-item__text">{value}</div>
          </div>
        </div>
      </div>
      <PopUpNav
        dark
        links={[
          {
            onClick: editParameters,
            label: t('action.create.conditions.editConditionParameters'),
            icon: <IconEdit />,
          },
          {
            onClick: handleDelete,
            label: t('remove'),
            icon: <IconDelete />,
          },
        ]}
      />
    </div>
  );
};

export default TaskItem;
