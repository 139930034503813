import { useRef } from 'react';

type HoldParams = {
  holdMillisecond: number;
  onHold?: () => void;
  onClick?: () => void;
};

export const useHold = ({ holdMillisecond, onHold, onClick }: HoldParams) => {
  const timeout = useRef<NodeJS.Timeout>();

  const removeTimeout = () => {
    clearTimeout(timeout.current as number | undefined);
    timeout.current = undefined;
  };

  const onPointerDown = () => {
    removeTimeout();

    timeout.current = setTimeout(() => {
      removeTimeout();
      onHold?.();
    }, holdMillisecond);
  };

  const onPointerUp = () => {
    if (timeout.current) {
      removeTimeout();
      onClick?.();
    }
  };

  const onPointerLeave = () => {
    if (timeout.current) {
      removeTimeout();
    }
  };

  return { onPointerDown, onPointerUp, onPointerLeave };
};
