import { PointerEvent } from 'react';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { IDeviceTask } from 'lavva.exalushome/build/js/Services/Devices/IDeviceTask';
import { FacadeActionEnum, SetFacadePosition } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { useNative } from '../../../../../hooks';
import { useExalusServicesContext } from '../../../context/services';

export const useCentralActionFacadeControl = (channels: IDeviceChannel[]) => {
  const { devicesApi } = useExalusServicesContext();
  const { fireVibration } = useNative();

  const open = async () => {
    fireVibration();

    const mappedChannels: [Device: IDevice, Task: IDeviceTask][] = channels.map((channel) => {
      const task = new SetFacadePosition();
      task.FacadeAction = FacadeActionEnum.Open;
      task.DeviceGuid = channel.GetDevice().Guid;
      task.Channel = channel.Number;

      return [channel.GetDevice(), task];
    });

    return await devicesApi.ExecuteDevicesTasksAsync(mappedChannels);
  };

  const close = async () => {
    fireVibration();

    const mappedChannels: [Device: IDevice, Task: IDeviceTask][] = channels.map((channel) => {
      const task = new SetFacadePosition();
      task.FacadeAction = FacadeActionEnum.Close;
      task.DeviceGuid = channel.GetDevice().Guid;
      task.Channel = channel.Number;

      return [channel.GetDevice(), task];
    });

    return await devicesApi.ExecuteDevicesTasksAsync(mappedChannels);
  };

  const stop = async () => {
    fireVibration();

    const mappedChannels: [Device: IDevice, Task: IDeviceTask][] = channels.map((channel) => {
      const task = new SetFacadePosition();
      task.FacadeAction = FacadeActionEnum.Stop;
      task.DeviceGuid = channel.GetDevice().Guid;
      task.Channel = channel.Number;

      return [channel.GetDevice(), task];
    });

    return await devicesApi.ExecuteDevicesTasksAsync(mappedChannels);
  };

  const handleAction = async (action: FacadeActionEnum, value?: number) => {
    fireVibration();

    const mappedChannels: [Device: IDevice, Task: IDeviceTask][] = channels.map((channel) => {
      const task = new SetFacadePosition();
      task.FacadeAction = action;
      task.DeviceGuid = channel.GetDevice().Guid;
      task.Channel = channel.Number;

      if (typeof value === 'number' && value >= 0 && value <= 100) {
        switch (action) {
          case FacadeActionEnum.Percentage:
            task.Position = value;
            break;
          case FacadeActionEnum.Tilt:
            task.Tilt = value;
            break;
        }
      }

      return [channel.GetDevice(), task];
    });

    return await devicesApi.ExecuteDevicesTasksAsync(mappedChannels);
  };

  const handleSliderEvent = (event: PointerEvent<HTMLInputElement>) => {
    handleAction(FacadeActionEnum.Percentage, Number((event.target as HTMLInputElement).value));
  };

  const handleTiltEvent = (value: number) => handleAction(FacadeActionEnum.Tilt, value);

  return {
    open,
    close,
    stop,
    handleAction,
    handleSliderEvent,
    handleTiltEvent,
  };
};
