import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { format } from 'date-fns';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  BlindRemoteButtonState,
  BlindRemoteButtonStateEnum,
  FacadeRemoteButtonState,
  FacadeRemoteButtonStateEnum,
  IDeviceState as IChannelState,
  RemoteButtonState,
  RemoteButtonStateEnum,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { ControlWrapper, IconChevron, IconPause } from '../../../../../../components';
import { ChannelBox } from '../../box';
import { ChannelBoxIcon } from '../../box-icon';
import { PowerState } from '../../power';
import './index.scss';

type RemoteDetailsControlsProps = {
  channel: IDeviceChannel;
};

export const RemoteDetailsControls: React.FC<RemoteDetailsControlsProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');

  const remoteButtonStateThree = useMemo(() => {
    return channel.States?.find(
      (state) =>
        state.TypeAsEnum === ChannelResponseType.BlindRemoteButtonState ||
        state.TypeAsEnum === ChannelResponseType.FacadeRemoteButtonState,
    ) as IChannelState<BlindRemoteButtonState | FacadeRemoteButtonState>;
  }, [channel.States]);

  const remoteButtonStateTwo = useMemo(() => {
    return channel.States?.find(
      (state) => state.TypeAsEnum === ChannelResponseType.RemoteButtonState,
    ) as IChannelState<RemoteButtonState>;
  }, [channel.States]);

  const time = useMemo(() => {
    return remoteButtonStateTwo?.Data.State ? format(Date.parse(remoteButtonStateTwo.Data.Time), 'dd.MM, HH:mm') : null;
  }, [remoteButtonStateTwo?.Data.Time]);

  return (
    <>
      {remoteButtonStateThree ? (
        <ControlWrapper>
          <div className="remote-control">
            <div
              className={classNames('remote-control__icon', {
                'remote-control__icon--active':
                  remoteButtonStateThree?.Data.State === BlindRemoteButtonStateEnum.OpenPressed ||
                  remoteButtonStateThree?.Data.State === FacadeRemoteButtonStateEnum.OpenPressed,
              })}
            >
              <IconChevron withCircle direction="up" size={80} />
            </div>
            <div
              className={classNames('remote-control__icon', {
                'remote-control__icon--active':
                  remoteButtonStateThree?.Data.State === BlindRemoteButtonStateEnum.StopPressed ||
                  remoteButtonStateThree?.Data.State === FacadeRemoteButtonStateEnum.StopPressed,
              })}
            >
              <IconPause size={80} />
            </div>
            <div
              className={classNames('remote-control__icon', {
                'remote-control__icon--active':
                  remoteButtonStateThree?.Data.State === BlindRemoteButtonStateEnum.ClosePressed ||
                  remoteButtonStateThree?.Data.State === FacadeRemoteButtonStateEnum.ClosePressed,
              })}
            >
              <IconChevron withCircle direction="down" size={80} />
            </div>
          </div>
        </ControlWrapper>
      ) : (
        <>
          <div className="details-controls m-t-8">
            <ChannelBox channel={channel} isListItem className="device-box--details">
              <div className="device-box__head">
                <ChannelBoxIcon channel={channel} />
                {remoteButtonStateTwo?.Data.State && (
                  <span>
                    {t(
                      `exalus.remote.${
                        remoteButtonStateTwo.Data.State === RemoteButtonStateEnum.Pressed ? 'pressed' : 'released'
                      }`,
                    )}
                  </span>
                )}
              </div>
            </ChannelBox>
            {time && <div className="details-controls__detection-time">{`${t('exalus.checked')} ${time}`}</div>}
          </div>
          <PowerState channel={channel} />
        </>
      )}
    </>
  );
};
