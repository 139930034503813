import React, { useMemo } from 'react';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  BlindRemoteButtonState,
  IDeviceState as IChannelState,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { BlindControlButtons } from '../../../../control-buttons/blind';
import { useBlindControl } from '../../../hooks/use-blind-control';

type BlindControlsProps = {
  channel: IDeviceChannel;
  isListItem?: boolean;
};

const BlindControls: React.FC<BlindControlsProps> = ({ channel, isListItem }) => {
  const { open, close, stop } = useBlindControl(channel);

  const remoteButtonState = useMemo(
    () =>
      channel.States?.find(
        (state) => state.TypeAsEnum === ChannelResponseType.BlindRemoteButtonState,
      ) as IChannelState<BlindRemoteButtonState>,
    [channel.States],
  );

  return (
    <BlindControlButtons
      close={close}
      stop={stop}
      open={open}
      isListItem={isListItem}
      remoteButtonState={remoteButtonState}
    />
  );
};

export default BlindControls;
