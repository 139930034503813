import { gql } from '@apollo/client';

export const DEVICES_GET_ALL = gql`
  query DevicesGetAll($installationId: UUID!) {
    allUserDevices(installationId: $installationId) {
      id
      installationId
      payload {
        id: deviceId
        deviceId
        model
        name
        connectedToSsid
        currentFirmwareVersion
        macAddress
        lastConnectedAtUtc
        registeredAtUtc
        newestFirmwareVersion
        timeZone
        isUpdateAvailable
        channelInfos {
          channelId
          channelNumber
          channelType
        }
      }
    }
  }
`;

export const DEVICE_DETAILS = gql`
  query DeviceDetails($installationId: UUID!, $deviceId: UUID!) {
    userDevice(installationId: $installationId, id: $deviceId) {
      id
      installationId
      payload {
        id: deviceId
        deviceId
        model
        name
        connectedToSsid
        currentFirmwareVersion
        macAddress
        lastConnectedAtUtc
        registeredAtUtc
        newestFirmwareVersion
        timeZone
        isUpdateAvailable
        channelInfos {
          channelId
          channelNumber
          channelType
        }
      }
    }
  }
`;

export const DEVICE_GET_BINDINGS = gql`
  query DeviceGetBindings($installationId: UUID!, $deviceId: UUID!) {
    channelBindings: allUserChannels(
      installationId: $installationId
      where: { deviceId: { eq: $deviceId } }
      order: { deviceId: ASC, alias: ASC }
    ) {
      id
      alias
      deviceId
      channelType
      payload {
        id: channelId
        deviceId
        channelId
        ... on GenericInputChannelStateResponse {
          deviceId
          channelId
          genericInputType
          areCloudActionsSupported
          actionBindings {
            isDefaultAction
            inputTrigger
            destination {
              deviceId
              channelId
            }
            ... on SwitchActionConfigResponse {
              switchSlot: slot
              setOnTimeValue
            }
            ... on LightActionConfigResponse {
              lightSlot: slot
              setOnTimeValue
              brightness
              temperature
              r
              g
              b
            }
            ... on BlindActionConfigResponse {
              blindSlot: slot
              position
            }
            ... on GateActionConfigResponse {
              gateSlot: slot            
              position
              dir
            }
            ... on CloudActionConfigResponse {
              onSignalActionId
              offSignalActionId
            }
          }
        }
        ... on SwitchChannelStateResponse {
          switchSupportedSlots: supportedSlots
        }
        ... on BlindChannelStateResponse {
          blindSupportedSlots: supportedSlots
        }
        ... on LightChannelStateResponse {
          lightSupportedSlots: supportedSlots
        }
        ... on GateChannelStateResponse {
          gateSupportedSlots: supportedSlots
        }
      }
    }
  }
`;

export const VERSION_MAP = gql`
  query VersionMap {
    versionMap {
      key
      versionedFeatures {
        feature
        minVersion {
          major
          minor
          build
          asString
        }
      }
    }
  }
`;

export const RSSI = gql`
  query Rssi($installationId: UUID!, $deviceId: UUID!) {
    rssi(installationId: $installationId, deviceId: $deviceId) {
      rssi
    }
  }
`;