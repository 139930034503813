import React from 'react';

type IconEntryPhoneProps = {
  isOn?: boolean;
};

export const IconEntryPhone: React.FC<IconEntryPhoneProps> = ({ isOn }) => (
  <svg
    className="icon-sensor icon-entry-phone"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.66699 4.44379C7.66699 3.46127 11.2451 2.66602 15.667 2.66602"
      stroke="#009EE3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.667 4.44379C23.667 3.46127 20.0889 2.66602 15.667 2.66602"
      stroke="#009EE3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.66699 4.44434V16.0532"
      stroke="#009EE3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M23.668 4.44434V16.0532" stroke="#009EE3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M23.667 16.0537C23.667 19.0019 20.0889 21.387 15.667 21.387"
      stroke="#009EE3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.66699 16.0537C7.66699 19.0019 11.2451 21.387 15.667 21.387"
      stroke="#009EE3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2227 9.77734H20.1115"
      stroke="#009EE3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M18.3333 16.8896H13" stroke="#009EE3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M11.2227 9.77734V15.1107"
      stroke="#009EE3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.1123 9.77734V15.1107"
      stroke="#009EE3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    {isOn && (
      <>
        <path
          d="M10.6299 23.7998C13.4139 26.5263 17.92 26.5263 20.704 23.7998"
          stroke="#009EE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.1123 26.2656C12.2883 30.3557 19.0474 30.3557 23.2234 26.2656"
          stroke="#009EE3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}
    <path
      d="M11.2227 15.1104C11.2227 16.0929 12.0179 16.8881 13.0004 16.8881"
      stroke="#009EE3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.1127 15.1104C20.1127 16.0929 19.3175 16.8881 18.335 16.8881"
      stroke="#009EE3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="15.6676"
      cy="6.22233"
      r="0.666667"
      fill="#1D1D1B"
      stroke="#009EE3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
