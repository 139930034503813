import { useTranslation } from 'react-i18next';
import { useSetDailyMode } from '../../../../../api/modules/gate/gate.hooks';
import { InitiateButtonRectangle } from '../../../../../components';
import { useChannelsState } from '../../../../../hooks';
import { ChannelGateType, ChannelGateStateType, ChannelType } from '../../../../../types';
import { useGateSupportedFeatures } from '../../hooks/use-gate-supported';

type DailyModeProps = {
  channel: ChannelType;
};

export const DailyMode: React.FC<DailyModeProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { mutate } = useSetDailyMode();
  const { dailyModeModeSupported } = useGateSupportedFeatures(channel);
  const { channelState } = useChannelsState();
  const state = channelState[channel.id] as ChannelGateStateType | undefined;

  const handleSetDailyMode = () => {
    mutate({
      channelId: channel.id,
      deviceId: channel.deviceId,
      value: !state?.dailyModeStatus,
    });
  };

  if (!(channel.data as ChannelGateType).isDailyModeVisible || !dailyModeModeSupported) return null;

  return (
    <InitiateButtonRectangle isOn={!!state?.dailyModeStatus} onClickControl={handleSetDailyMode} className="m-t-16">
      {t('dailyMode')}
    </InitiateButtonRectangle>
  );
};
