import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useSubscription } from '@apollo/client';
import {
  AmpTransformersInternal,
  ChannelTypeInternal,
  MeterPurposeInternal,
  MeterTypeInternal,
  OnPartialConfigChangedSubscription,
  OnPartialConfigChangedSubscriptionVariables,
} from '../../../data-access/gql-types/graphql';
import { ON_PARTIAL_CONFIG_CHANGED } from '../../../data-access/subscriptions/lavva-devices';
import { useBackdropContext, useInstallation } from '../../../hooks';
import { useDevicesAndChannelsContext } from '../../../hooks/devices-and-channels/provider';
import { useRefetchData } from '../../../hooks/refresh-data';
import { useTimeout } from '../../../hooks/timeout/use-timeout';
import { ChannelMeterType } from '../../../types';
import { toastSuccess } from '../../../utils/toast';
import AmpTransformers from '../components/amp-transformers';
import MeterPurpose from '../components/meter-purpose';
import Tariff from '../components/tariff';
import MeasurementsConfiguration from './measurements';

const MeterConfiguration: React.FC = () => {
  const { t } = useTranslation('channel-settings');
  const { channelId } = useParams<{ channelType: ChannelTypeInternal; channelId: string }>();
  const { channel, updateChannelDetails } = useDevicesAndChannelsContext();
  const { skipLavvaFetch } = useInstallation();
  const { setTimeoutError, clearTimeoutError } = useTimeout();
  const { turnOffBackdrop } = useBackdropContext();
  const { refetchChannelDetails } = useRefetchData();
  const { data: configurationData } = useSubscription<
    OnPartialConfigChangedSubscription,
    OnPartialConfigChangedSubscriptionVariables
  >(ON_PARTIAL_CONFIG_CHANGED, {
    variables: { channelId },
    skip: skipLavvaFetch,
  });

  useEffect(() => {
    if (configurationData?.onPartialConfigChanged) {
      clearTimeoutError();
      const { numericalProperties, updatedProperties, channelId } = configurationData.onPartialConfigChanged;
      console.log('CONFIGURATION DATA', { numericalProperties, updatedProperties });

      const ampTransformers = numericalProperties.find((x) => x.property === 'AmpTransformers');
      const meterPurpose = numericalProperties.find((x) => x.property === 'MeterPurpose');
      const tariff = updatedProperties.find((x) => x === 'Tariff');

      updateChannelDetails((prev) => {
        const tempChannel = channelId === prev?.id ? cloneDeep(prev) : undefined;
        if (!tempChannel) return;

        if (tempChannel && ampTransformers?.value) {
          (tempChannel.data as ChannelMeterType).ampTransformers =
            AmpTransformersInternal[ampTransformers.value as keyof typeof AmpTransformersInternal];
        }
        if (tempChannel && meterPurpose?.value) {
          (tempChannel.data as ChannelMeterType).meterPurpose =
            MeterPurposeInternal[meterPurpose.value as keyof typeof MeterPurposeInternal];
        }

        return tempChannel;
      });

      if (tariff) refetchChannelDetails();

      if (numericalProperties.length || updatedProperties.length) {
        toastSuccess({ content: t('toast.editConfigurationSuccess') });
      }

      turnOffBackdrop();
    }
  }, [configurationData]);

  const isStandalone = useMemo(() => {
    return channel?.data.type === ChannelTypeInternal.Meter && channel.data.meterType === MeterTypeInternal.Standalone;
  }, [channel]);

  if (!channel) return null;

  return (
    <>
      {isStandalone && (
        <>
          <AmpTransformers setTimeoutError={setTimeoutError} />
          <hr />
          <MeterPurpose setTimeoutError={setTimeoutError} />
          <hr />
        </>
      )}
      <Tariff setTimeoutError={setTimeoutError} />
      <hr />
      <MeasurementsConfiguration meterChannelId={channelId} />
    </>
  );
};

export default MeterConfiguration;
