import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOptionType, IconLavva, IconExalus } from '../../components';
import { IntegrationType } from '../../data-access/gql-types/graphql';
import { environment } from '../../environment';
import { IntegrationDefaultEnum } from '../../types';

export const useIntegrationOptions = () => {
  const { t } = useTranslation('installation');

  const integrationsOptions: SelectOptionType<IntegrationType>[] = useMemo(
    () => [
      {
        value: IntegrationType.Lavva,
        label: t('types.lavva'),
        icon: <IconLavva />,
        weight: environment.INTEGRATION_DEFAULT === IntegrationDefaultEnum.Exalus ? 20 : 10,
      },
      {
        value: IntegrationType.Exalus,
        label: t('types.exalus'),
        icon: <IconExalus />,
        weight: environment.INTEGRATION_DEFAULT === IntegrationDefaultEnum.Exalus ? 10 : 20,
      },
    ],
    [],
  );

  return {
    integrationsOptions,
  };
};
