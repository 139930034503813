import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Accordion, Checkbox, ChannelItem, SubmitButton } from '../../../../components';
import { ChannelTypeInternal, GateModeInternal } from '../../../../data-access/gql-types/graphql';
import { useDevicesAndChannels } from '../../../../hooks';
import { ChannelGateType, Visibility } from '../../../../types';
import { useTriggerFormContext } from '../../context';
import { ChosenConditionType } from '../../enums';
import { StepComponentProps } from '../../steps';
import { TriggerChannel } from '../../types';

type Item = { elements: TriggerChannel[]; label: string };

export const TriggerChannelsStep: React.FC<StepComponentProps> = ({ goToSummary, conditionType }) => {
  const { t } = useTranslation('action');
  const { t: tc } = useTranslation('common');
  const { channelGroups } = useDevicesAndChannels({
    visibility: Visibility.All,
  });
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [disabledChannels, setDisabledChannels] = useState<string[]>([]);
  const [unavailableChannels, setUnavailableChannels] = useState<string[]>([]);
  const { channels, transformChannelsToActions, stateConditions } = useTriggerFormContext();

  const channelTypes = useMemo(
    () =>
      conditionType === ChosenConditionType.PRICE
        ? [ChannelTypeInternal.Switch, ChannelTypeInternal.Light]
        : [ChannelTypeInternal.Switch, ChannelTypeInternal.Blind, ChannelTypeInternal.Light, ChannelTypeInternal.Gate],
    [conditionType],
  );

  useEffect(() => {
    setSelectedChannels(channels.filter((d) => d.value).map((de) => de.id));
    setDisabledChannels(channels.filter((d) => d.value).map((de) => de.id));
  }, [channels]);

  const handleChannelOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = e.target;

      if (checked && !selectedChannels.includes(value)) {
        const values = [...selectedChannels, value];
        setSelectedChannels(values);
      }

      if (!checked && selectedChannels.includes(value)) {
        const values = selectedChannels.filter((v) => v !== value);
        setSelectedChannels(values);
      }
    },
    [setSelectedChannels, selectedChannels],
  );

  const itemList = channelGroups
    .flatMap((element) => element.channels)
    .map(
      (item, index) =>
        ({
          id: item.id,
          type: item.data.type,
          index,
          value: channels.find((channel) => channel.id === item.id)?.value,
        } as TriggerChannel),
    );

  const itemGroupsList = channelGroups
    .filter((x) => channelTypes.includes(x.type))
    .map((group) => {
      const filteredChannels =
        group.type === ChannelTypeInternal.Gate
          ? group.channels.filter((x) => (x.data as ChannelGateType).gateMode === GateModeInternal.RollUp)
          : group.channels;

      return {
        ...group,
        channels: filteredChannels,
        elements: filteredChannels.map(
          (item) =>
            ({
              id: item.id,
              type: item.data.type,
              index: itemList.findIndex((el) => el.id === item.id),
              value: channels.find((channel) => channel.id === item.id)?.value,
            } as TriggerChannel),
        ),
      };
    });
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      availableChannels: itemList,
    },
  });

  useEffect(() => {
    setUnavailableChannels(
      itemList
        .filter((triggerChannel) => stateConditions.find((condition) => condition.id === triggerChannel.id))
        .map((de) => de.id),
    );
  }, [channels]);

  const tabs = useMemo(() => {
    const totalGroupsChannels = itemGroupsList.map((group) => group.elements).flat().length;
    return `${t('advancedActionForm.selectChannels')} (${selectedChannels.length}/${totalGroupsChannels})`;
  }, [itemGroupsList, selectedChannels, t]);

  const onSubmit = useCallback(
    (formData) => {
      transformChannelsToActions(formData.availableChannels, conditionType);
      if (goToSummary) {
        goToSummary();
      }
    },
    [t],
  );

  const itemGroupsWithElements = useMemo(() => {
    return itemGroupsList.filter((item) => item.elements.length > 0);
  }, [itemGroupsList]);

  const handleToggleAll = (item: Item, allChecked: boolean) => {
    const selectedChannelItems = itemList.filter((x) => selectedChannels.includes(x.id));

    const filteredChannels = item.elements.filter(
      (channel) => !disabledChannels.includes(channel.id) && !unavailableChannels.includes(channel.id),
    );

    if (!allChecked) {
      setSelectedChannels([...selectedChannelItems.map((x) => x.id), ...filteredChannels.map((x) => x.id)]);
      setValue(
        'availableChannels',
        itemList.map((x) => {
          if (item.elements.find((item) => item.id === x.id)) return { ...x, value: true };
          return { ...x };
        }),
      );
    } else {
      const selected = Array.from(selectedChannelItems);

      for (const channel of filteredChannels) {
        const index = selected.findIndex((ch) => channel.id === ch.id);
        if (index !== -1) {
          selected.splice(index, 1);
        }
      }

      setSelectedChannels(selected.map((x) => x.id));
      setValue(
        'availableChannels',
        itemList.map((x) => ({ ...x, value: false })),
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className="action-advanced-form__list-header">{tabs}</p>

      <ul className="action-advanced-form__list">
        {itemGroupsWithElements.map((item: Item) => {
          const selectedItems = item.elements.filter((element) => selectedChannels.includes(element.id)).length;
          const allChecked = item.elements
            .filter((channel) => !disabledChannels.includes(channel.id) && !unavailableChannels.includes(channel.id))
            .map((x) => x.id)
            .every((v) => selectedChannels.includes(v));

          return (
            <li key={item.label} className="action-advanced-form__list-item m-t-0">
              <Accordion
                kind="custom"
                className="accordion--small-content"
                title={item.label}
                selectedItems={selectedItems}
                totalItems={item.elements.length}
                hideExpandedBorder
                expanded={itemGroupsWithElements.length === 1}
              >
                <ul>
                  <Checkbox id={item.label} checked={allChecked} onChange={() => handleToggleAll(item, allChecked)}>
                    <div />
                  </Checkbox>
                  {item.elements?.map((channel: TriggerChannel) => (
                    <Controller
                      key={channel.id}
                      control={control}
                      name={`availableChannels.${channel.index}.value`}
                      defaultValue={Boolean(selectedChannels.includes(channel.id))}
                      render={({ field }) => (
                        <Checkbox
                          id={channel.id}
                          checked={selectedChannels.includes(channel.id)}
                          disabled={disabledChannels.includes(channel.id) || unavailableChannels.includes(channel.id)}
                          isUnavailable={unavailableChannels.includes(channel.id)}
                          onChange={(e) => {
                            field.onChange(e);
                            handleChannelOnChange(e);
                          }}
                        >
                          <ChannelItem id={channel.id} isOn />
                        </Checkbox>
                      )}
                    />
                  ))}
                </ul>
              </Accordion>
            </li>
          );
        })}
      </ul>

      <SubmitButton disabled={selectedChannels.length === 0}>{tc('buttons.add')}</SubmitButton>
    </form>
  );
};
