import { useTranslation } from 'react-i18next';
import { useActionBatch } from '../../api/modules/action/action.hooks';
import {
  BatchChannel,
  GateDirectionBatchChannel,
  GatePositionBatchChannel,
  TypeOfActionType,
} from '../../api/modules/action/action.types';
import { GateModeInternal } from '../../data-access/gql-types/graphql';
import { ChannelType } from '../../types';
import { ChannelGateType, GateDirection } from '../../types/channel/gate';
import { toastInfo } from '../../utils/toast';

export const useGateAction = (channels: ChannelType[]) => {
  const { t: ta } = useTranslation('action');
  const actionBatch = useActionBatch();

  const handleAction = (value: string | number) => {
    if (!channels.length) {
      toastInfo({ content: ta('trigger.validationErrors.deviceNotFound') });
      return;
    }

    if (typeof value === 'string') {
      actionBatch.mutate({
        controls: channels
          .filter((x) => (x.data as ChannelGateType).gateMode === GateModeInternal.RollUp)
          .map((channel) => ({
            channelId: channel.id,
            deviceId: channel.deviceId,
            direction: GateDirection[value],
            $type: TypeOfActionType.BatchGateSetDirectionRequest,
          })) as GateDirectionBatchChannel[],
      });
    } else {
      actionBatch.mutate({
        controls: channels
          .filter((x) => (x.data as ChannelGateType).gateMode === GateModeInternal.RollUp)
          .map((channel) => ({
            channelId: channel.id,
            deviceId: channel.deviceId,
            position: value,
            $type: TypeOfActionType.BatchGateSetPositionRequest,
          })) as GatePositionBatchChannel[],
      });
    }
  };

  const handleVentilation = () => {
    actionBatch.mutate({
      controls: channels.map((channel) => ({
        channelId: channel.id,
        deviceId: channel.deviceId,
        $type: TypeOfActionType.BatchGateTiltRequest,
      })) as BatchChannel[],
    });
  };

  return {
    handleAction,
    handleVentilation,
  };
};
