import React from 'react';
import classNames from 'classnames';
import { ChannelType } from '../../../types';
import { HeadContent } from '../elements';
import './index.scss';

type ChannelBoxProps = {
  channel: ChannelType;
  isListItem?: boolean;
  isSelected?: boolean;
};

export const ChannelBoxSimple: React.FC<ChannelBoxProps> = ({ channel, isListItem = true, isSelected = false }) => {
  return (
    <div
      className={classNames('device-box device--simple', {
        'device-box--list-item': isListItem,
        'device-box--selected': isSelected,
      })}
    >
      <div className="device-box__head">
        <HeadContent channel={channel} />
      </div>
    </div>
  );
};
