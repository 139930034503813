import { useMemo } from 'react';
import { InitiateButton } from '../../../../../../../components';
import { useChannelsState } from '../../../../../../../hooks';
import { useGate } from '../../../../../../../hooks/use-gate';
import { ChannelGateStateType, ChannelType } from '../../../../../../../types';
import { GateStepIcon } from '../../../gate-step-icon';

type LockStatusControlProps = {
  channel: ChannelType;
  handleAction: () => void;
};

export const LockStatusControl: React.FC<LockStatusControlProps> = ({ channel, handleAction }) => {
  const { channelState } = useChannelsState();
  const { gateActive } = useGate(channel);

  const status = useMemo(() => {
    const lockStatus = (channelState[channel.id] as ChannelGateStateType)?.lockStatus;
    return lockStatus ? 1 : 2;
  }, [channelState[channel.id]]);

  return (
    <InitiateButton isOn={false} onClickControl={handleAction} active={gateActive}>
      <GateStepIcon step={status} />
    </InitiateButton>
  );
};
