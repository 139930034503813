import React, { useMemo } from 'react';
import { isNumber } from 'lodash';
import { useSubscription } from '@apollo/client';
import {
  MeasurementChannelKind,
  MeasurementSubscriptionResponse,
  OnMeasurementSubscription,
  OnMeasurementSubscriptionVariables,
  SubPriority,
} from '../../../data-access/gql-types/graphql';
import { ON_MEASUREMENT } from '../../../data-access/subscriptions/on-measurement';
import { useInstallation } from '../../../hooks';
import { ChannelType } from '../../../types';
import { EcoBar } from '../eco-bar';

type EcoRatioProps = {
  channel: ChannelType;
  ecoIndicatorNumberType: number;
};

export const EcoRatio: React.FC<EcoRatioProps> = ({ channel, ecoIndicatorNumberType }) => {
  const { skipLavvaFetch } = useInstallation();
  const { data: meterMeasurement } = useSubscription<OnMeasurementSubscription, OnMeasurementSubscriptionVariables>(
    ON_MEASUREMENT,
    {
      variables: {
        input: {
          channelId: channel.id,
          kind: MeasurementChannelKind.Meter,
          scalarParameterTypes: [ecoIndicatorNumberType],
          vectorParameterTypes: [],
          indices: [],
          priority: SubPriority.Low,
        },
      },
      skip: !ecoIndicatorNumberType || !channel || skipLavvaFetch,
    },
  );

  const ecoIndicator = useMemo(() => {
    const measurements =
      (meterMeasurement?.onMeasurement.data as MeasurementSubscriptionResponse)?.scalarMeasurements || [];
    return measurements.find((x) => x.type === ecoIndicatorNumberType)?.value || null;
  }, [meterMeasurement?.onMeasurement]);

  return isNumber(ecoIndicator) ? <EcoBar value={ecoIndicator} maxValue={100} className="m-t-4" /> : null;
};
