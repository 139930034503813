import React from 'react';
import { useTranslation } from 'react-i18next';
import { SbrServiceInfo } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/GateControler/ISbrConfigService';
import TextInfo from '../../../../../../../components/text-info';

type SbrBidiStatisticsProps = {
  statistics: SbrServiceInfo | null;
};

export const SbrBidiStatistics: React.FC<SbrBidiStatisticsProps> = ({ statistics }) => {
  const { t } = useTranslation('device-info');

  if (!statistics) return null;

  return (
    <div>
      <TextInfo
        label={t('exalus.params.SbrParams.InertionBreaksTrgCount')}
        value={statistics.InertionBreaksTrgCount}
        className="m-b-24"
        big
        column
      />
      <TextInfo
        label={t('exalus.params.SbrParams.PhotocelTrgCount')}
        value={statistics.PhotocelTrgCount}
        className="m-b-24"
        big
        column
      />
      <TextInfo
        label={t('exalus.params.SbrParams.SafetyBarTrgCount')}
        value={statistics.SafetyBarTrgCount}
        className="m-b-24"
        big
        column
      />
      <TextInfo
        label={t('exalus.params.SbrParams.StartsCount')}
        value={statistics.StartsCount}
        className="m-b-24"
        big
        column
      />
    </div>
  );
};
