import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { useSwitchControl } from '../../../hooks/use-switch-control';

type OnOffControlsProps = {
  channel: IDeviceChannel;
  active: boolean;
};

export const OnOffControls: React.FC<OnOffControlsProps> = ({ channel, active }) => {
  const { t } = useTranslation('common');
  const { turnOn, turnOff, isOn } = useSwitchControl(channel);

  return (
    <div className="on-off-control on-off-control--detail">
      <div
        className={classNames('on-off-control__bg-element', { active: active, 'turn-on': isOn })}
        style={{ height: 120 }}
      />
      <div className="on-off-control__btn-wrapper">
        <button
          onClick={turnOn}
          className={classNames('on-off-control__btn on-off-control__btn--on', {})}
          style={{ height: 92 }}
        >
          {t('buttons.on')}
        </button>
        <div className="on-off-control__divider" />
        <button onClick={turnOff} className="on-off-control__btn on-off-control__btn--off" style={{ height: 92 }}>
          {t('buttons.off')}
        </button>
      </div>
    </div>
  );
};
