import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { debounce } from 'lodash';
import './index.scss';

type SliderProps = {
  value: number;
  targetValue?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPointerUp?: (event: React.PointerEvent<HTMLInputElement>) => void;
  isReverse?: boolean;
  offMode?: boolean;
  active?: boolean;
  error?: boolean;
  unit?: string;
  max?: number;
  min?: number;
  whiteLight?: boolean;
  showValue?: boolean;
  className?: string;
  disabled?: boolean;
  wider?: boolean;
  accentBar?: boolean;
  position?: 'horizontal' | 'vertical';
  noBgElement?: boolean;
  animationWhenActive?: boolean;
};

export const Slider: React.FC<SliderProps> = ({
  max = 100,
  min = 0,
  value,
  targetValue,
  onChange,
  onPointerUp,
  isReverse,
  offMode,
  active,
  error,
  unit = '%',
  showValue = true,
  className,
  whiteLight,
  disabled,
  wider,
  accentBar,
  position = 'vertical',
  noBgElement,
  animationWhenActive,
}) => {
  const { t } = useTranslation('common');
  const [sliderValue, setSliderValue] = useState<string>('0');
  const valueText = useMemo(
    () => (offMode ? t('offMode') : `${targetValue ?? value}${unit}`),
    [offMode, value, targetValue, t],
  );

  useEffect(() => {
    setSliderValue(offMode ? t('offMode') : `${value}${unit}`);
  }, [offMode, value]);

  useEffect(() => {
    if (!sliderValue) {
      setSliderValue(value.toString());
    }
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
    setSliderValue(event.target.value);
  };

  const handleOnPointerUp = debounce((event: React.PointerEvent<HTMLInputElement>) => {
    onPointerUp?.(event);
    setSliderValue((event.target as HTMLInputElement).value);
  }, 500);

  const style = useMemo(() => {
    const dividend = value === 0 ? 0 : value - min;
    const divider = max - min;
    const calculation = (dividend / divider) * 100;
    return {
      value: { '--slider-position': `${calculation}%` } as React.CSSProperties,
      targetValue: { '--target-position': `${targetValue ?? calculation}%` } as React.CSSProperties,
    };
  }, [value, targetValue, min, max]);

  return (
    <div
      className={classNames('slider', {
        'slider--reverse': isReverse,
        'white-light': whiteLight,
        horizontal: position === 'horizontal',
        wider: wider,
        [className as string]: className,
      })}
    >
      <div
        className={classNames('slider__bg-element', {
          active: active,
          error: error,
          'no-bg-element': noBgElement,
        })}
      />
      <div className="slider__slider-element">
        <input
          style={style.value}
          className={classNames('slider__input', {
            'bar-accent': accentBar,
            'animation-active': animationWhenActive && active,
          })}
          min={min}
          max={max}
          type="range"
          value={targetValue ?? value}
          onChange={handleChange}
          onPointerUp={handleOnPointerUp}
          disabled={disabled}
        />
      </div>
      {showValue ? (
        <div style={style.targetValue} className="slider__value">
          <h2 className="slider__value-text">{valueText}</h2>
          <svg
            className="slider__value-handle"
            width="19"
            height="6"
            viewBox="0 0 19 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 3H13" />
            <circle cx="16" cy="3" r="2.5" />
          </svg>
        </div>
      ) : null}
    </div>
  );
};
