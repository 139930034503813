import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceResponseType as ChannelResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import {
  BatteryDeviceState,
  BatteryStateEnum,
  BlindStatusCode,
  ConfigurationDeviceState,
  ConfigurationStateEnum,
  FloodSensorStates,
  LightThresholdStateEnum,
  LinkQualityEnum,
  OvercurrentProtectionDeviceState,
  SignalStrenghtDeviceState,
  WindThresholdStateEnum,
  GateControllerHealthState,
  IDeviceState as IChannelState,
  ResistanceOrOpticBarStatusEnum,
  BlindErrorCode,
} from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { isNumber } from 'lodash';
import { toastError, toastInfo } from '../../../../../utils/toast';
import { useBrightnessControl } from '../../../components/channel/hooks/use-brightness-control';
import { useFloodControl } from '../../../components/channel/hooks/use-flood-control';
import { useMovementControl } from '../../../components/channel/hooks/use-movement-control';
import { useWindControl } from '../../../components/channel/hooks/use-wind-control';
import { ChannelType, SensorType } from '../../../enums';
import { getChannelType, getSensorType } from '../../../utils';
import { useGateErrors } from './use-gate-errors';

type SomethingWrongParams = {
  detailsView: boolean;
};

export const useSomethingWrong = (channel: IDeviceChannel, params?: SomethingWrongParams) => {
  const { t: td } = useTranslation('channel-details');
  const { windThreshold } = useWindControl(channel);
  const { brightnessThreshold } = useBrightnessControl(channel);
  const { isTamperProtection } = useMovementControl(channel);
  const { flood } = useFloodControl(channel);
  const { blindPositionState, blindErrors, blindError, setBlindError } = useGateErrors(channel);

  const isStatusError = (status?: BlindStatusCode) => {
    return status === BlindStatusCode.UnableToMove || status === BlindStatusCode.MotorOverheatedPleaseWait;
  };

  const signalQuality = useMemo(
    () =>
      (
        channel.States?.find((state) => state.TypeAsEnum === ChannelResponseType.SignalStrength) as
          | SignalStrenghtDeviceState
          | undefined
      )?.Data.Quality,
    [channel.States],
  );

  useEffect(() => {
    if (params?.detailsView && isNumber(signalQuality)) {
      if ([LinkQualityEnum.Acceptable, LinkQualityEnum.Low, LinkQualityEnum.Terrible].includes(signalQuality)) {
        toastInfo({ content: td('exalus.weakSignal') });
      } else if (signalQuality === LinkQualityEnum.NoLink) {
        toastInfo({ content: td('exalus.noSignal') });
      }
    }
  }, [params?.detailsView, signalQuality]);

  useEffect(() => {
    if (params?.detailsView) {
      switch (getChannelType(channel)) {
        case ChannelType.Blind: {
          if (blindErrors.length) {
            switch (blindErrors[0]) {
              case BlindErrorCode.ObstacleDetected: {
                if (blindError !== BlindErrorCode.ObstacleDetected) {
                  toastError({ content: td('exalus.obstacleDetected') });
                  setBlindError(BlindErrorCode.ObstacleDetected);
                }
                break;
              }
              case BlindErrorCode.MotorFailure: {
                if (blindError !== BlindErrorCode.MotorFailure) {
                  toastError({ content: td('exalus.motorFailure') });
                  setBlindError(BlindErrorCode.MotorFailure);
                }
                break;
              }
            }
          } else {
            const status = blindPositionState?.GetBlindStatus ? blindPositionState.GetBlindStatus() : null;
            if (!status) return;

            switch (status) {
              case BlindStatusCode.UnableToMove: {
                if (blindError !== BlindStatusCode.UnableToMove) {
                  toastError({ content: td('exalus.unableToMove') });
                  setBlindError(BlindStatusCode.UnableToMove);
                }
                break;
              }
              case BlindStatusCode.MotorOverheatedPleaseWait: {
                if (blindError !== BlindStatusCode.MotorOverheatedPleaseWait) {
                  toastError({ content: td('exalus.motorOverheatedPleaseWait') });
                  setBlindError(BlindStatusCode.MotorOverheatedPleaseWait);
                }
                break;
              }
            }
          }

          break;
        }
        default:
          break;
      }
    }
  }, [params?.detailsView, blindError, blindPositionState, blindErrors]);

  const connectionIcon = useMemo(() => {
    const signalStrength = channel.States?.find((state) => state.TypeAsEnum === ChannelResponseType.SignalStrength) as
      | SignalStrenghtDeviceState
      | undefined;

    if (!signalStrength?.Data) return;
    return !![LinkQualityEnum.NoLink, LinkQualityEnum.Terrible, LinkQualityEnum.Low].includes(
      signalStrength.Data.Quality,
    );
  }, [channel.States]);

  const channelOrientedError = useMemo(() => {
    switch (getChannelType(channel)) {
      case ChannelType.Blind: {
        if (blindErrors.length) {
          if ([BlindErrorCode.ObstacleDetected || BlindErrorCode.MotorFailure].includes(blindErrors[0])) return true;
        }

        const status = blindPositionState?.GetBlindStatus ? blindPositionState.GetBlindStatus() : null;

        if (!status) return false;

        const overcurrentState = channel.States?.find(
          (state) => state.TypeAsEnum === ChannelResponseType.OvercurrentProtectionState,
        ) as OvercurrentProtectionDeviceState | undefined;

        return isStatusError(status) || overcurrentState?.Data?.OverrcurrentDetected;
      }
      case ChannelType.Switch: {
        return (
          channel.States?.find((state) => state.TypeAsEnum === ChannelResponseType.OvercurrentProtectionState) as
            | OvercurrentProtectionDeviceState
            | undefined
        )?.Data.OverrcurrentDetected;
      }
      case ChannelType.Gate: {
        const health = (
          channel.States?.find((state) => state.TypeAsEnum === ChannelResponseType.GateControllerHealth) as
            | IChannelState<GateControllerHealthState>
            | undefined
        )?.Data;

        if (health) {
          if (health.AutotestSwitchOn === false) return td('exalus.gateHealth.AutotestSwitchOn');
          if (health.LastAutotestFailed) return td('exalus.gateHealth.LastAutotestFailed');
          if (health.InertionBreaksOk === false) return td('exalus.gateHealth.InertionBreaksOk');
          if (health.ResistanceOrOpticBarStatus === ResistanceOrOpticBarStatusEnum.Active) {
            return td('exalus.gateHealth.ResistanceOrOpticBarActive');
          }
          if (health.ResistanceOrOpticBarStatus === ResistanceOrOpticBarStatusEnum.UnconnectedOrMalfunction) {
            return td('exalus.gateHealth.ResistanceOrOpticBarUnconnected');
          }
          if (health.PhotoCellsActive) return td('exalus.gateHealth.PhotoCellsActive');
        }

        return false;
      }
      case ChannelType.Sensor:
        switch (getSensorType(channel)) {
          case SensorType.Flood:
            return flood === FloodSensorStates.FloodDetected;
          case SensorType.Movement:
            return isTamperProtection;
          default:
            return false;
        }
      default:
        return false;
    }
  }, [channel.States, flood, isTamperProtection]);

  const channelOrientedWarning = useMemo(() => {
    switch (getChannelType(channel)) {
      case ChannelType.Sensor:
        switch (getSensorType(channel)) {
          case SensorType.Wind:
            return windThreshold === WindThresholdStateEnum.Exceeded;
          case SensorType.MeasuredBrightness:
            return brightnessThreshold === LightThresholdStateEnum.Exceeded;
          default:
            return false;
        }
      default:
        return false;
    }
  }, [channel.States]);

  const calibrationNeed = useMemo(() => {
    const calibration = channel.States?.find((state) => state.TypeAsEnum === ChannelResponseType.ConfigurationState) as
      | ConfigurationDeviceState
      | undefined;

    if (calibration?.Data) {
      if (
        [
          ConfigurationStateEnum.OngoingConfiguration,
          ConfigurationStateEnum.NotConfigured,
          ConfigurationStateEnum.PartiallyConfigured,
        ].includes(calibration.Data.Configuration)
      ) {
        return calibration.Data.Configuration;
      }
    }

    return null;
  }, [channel.States]);

  const battery = useMemo(() => {
    const batteryState = channel?.States?.find((state) => state.TypeAsEnum === ChannelResponseType.BatteryState) as
      | BatteryDeviceState
      | undefined;

    return batteryState?.Data;
  }, [channel?.States]);

  const gateHealth = useMemo(() => {
    return channel.States?.find((state) => state.TypeAsEnum === ChannelResponseType.GateControllerHealth) as
      | IChannelState<GateControllerHealthState>
      | undefined;
  }, [channel.States]);

  return {
    connectionIcon,
    calibrationNeed,
    channelOrientedError,
    channelOrientedWarning,
    batteryError: battery?.State === BatteryStateEnum.Low || battery?.State === BatteryStateEnum.Empty,
    gateHealth,
  };
};
