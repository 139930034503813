import React, { Dispatch, SetStateAction } from 'react';
import { IconArrowLink } from '../../../../../../../components';

type ChartPaginationProps = {
  offSet: number;
  isNextAvailable: boolean;
  setOffSet: Dispatch<SetStateAction<number>>;
  limit?: number;
};

export const defaultLimit = 100;

const ChartPagination: React.FC<ChartPaginationProps> = ({ offSet, isNextAvailable, setOffSet, limit }) => {
  const currentLimit = limit || defaultLimit;

  return (
    <>
      {(isNextAvailable || offSet > 0) && (
        <div className="chart-pagination">
          <button className="back" onClick={() => setOffSet(offSet + currentLimit)} disabled={!isNextAvailable}>
            <IconArrowLink />
          </button>
          <button onClick={() => setOffSet(offSet - currentLimit)} disabled={offSet === 0}>
            <IconArrowLink />
          </button>
        </div>
      )}
    </>
  );
};

export default ChartPagination;
