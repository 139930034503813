import React from 'react';
import c from 'classnames';
import './index.scss';

type IconDoorProps = {
  position?: number;
  big?: boolean;
  noState?: boolean;
  className?: string;
  isSunIcon?: boolean;
};

export const IconDoor: React.FC<IconDoorProps> = ({ position = 100, big, noState, className, isSunIcon }) => {
  return (
    <>
      {position < 100 ? (
        <svg
          className={c('icon-cover icon-door', { big: big, 'no-state': noState, [className as string]: className })}
          width="23"
          height="42"
          viewBox="0 0 23 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1H20.0395C21.144 1 22.0395 1.89543 22.0395 3V33.5263C22.0395 34.6309 21.144 35.5263 20.0395 35.5263H15.5658M1 1V34.2266C1 35.0169 1.46547 35.7332 2.18772 36.0542L12.7535 40.7501C14.0761 41.3379 15.5658 40.3698 15.5658 38.9225V35.5263M1 1L13.6077 5.66953C14.7846 6.10541 15.5658 7.22778 15.5658 8.48278V35.5263"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M11.25 21.2632V26.6579"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          {isSunIcon && <IconSun />}
        </svg>
      ) : (
        <svg
          className={c('icon-cover icon-door', { big: big, 'no-state': noState, [className as string]: className })}
          width="23"
          height="37"
          viewBox="0 0 23 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.0395 1H3C1.89543 1 1 1.89543 1 3V33.5263C1 34.6309 1.89543 35.5263 3 35.5263H20.0395C21.144 35.5263 22.0395 34.6309 22.0395 33.5263V3C22.0395 1.89543 21.144 1 20.0395 1Z"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M17.25 17.2632V22.6579"
            stroke="#15141D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          {isSunIcon && <IconSun />}
        </svg>
      )}
    </>
  );
};

const IconSun: React.FC = () => (
  <>
    <circle cx="21" cy="6" r="5.5" fill="#15141D" stroke="black" />
    <circle
      cx="21.0986"
      cy="6.09886"
      r="1.86302"
      stroke="white"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="sun"
    />
    <path
      d="M21.0981 3.11768L21.0981 1.99986"
      stroke="white"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="sun"
    />
    <path
      d="M21.0984 3.11768L21.0984 1.99986"
      stroke="white"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="sun"
    />
    <path
      d="M21.0984 10.1973L21.0984 9.07945"
      stroke="white"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="sun"
    />
    <path
      d="M24.0789 6.09863L25.1967 6.09863"
      stroke="white"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="sun"
    />
    <path
      d="M17 6.09863L18.1178 6.09863"
      stroke="white"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="sun"
    />
    <path
      d="M23.2063 8.20654L23.9967 8.99696"
      stroke="white"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="sun"
    />
    <path
      d="M18.2004 3.20068L18.9909 3.9911"
      stroke="white"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="sun"
    />
    <path
      d="M18.99 8.20654L18.1996 8.99696"
      stroke="white"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="sun"
    />
    <path
      d="M23.9961 3.20068L23.2057 3.9911"
      stroke="white"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="sun"
    />
  </>
);
