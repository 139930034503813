import React, { useMemo } from 'react';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { EnergyMeasurementParameter } from 'lavva.exalushome/build/js/Services/Devices/IDeviceState';
import { useMeterControl } from '../../../hooks/use-meter-control';
import { SensorControlBox } from '../../../sensor-control-box';

type MeterControlProps = {
  channel: IDeviceChannel;
  isListItem?: boolean;
};

const MeterControl: React.FC<MeterControlProps> = ({ channel, isListItem }) => {
  const { state } = useMeterControl(channel);

  const stateValue = useMemo(
    () => state?.Data?.MeasurementParameters.get(EnergyMeasurementParameter.ActivePower)?.toFixed(1) || '-',
    [state],
  );

  return <SensorControlBox isListItem={isListItem} value={stateValue} unit={'W'} />;
};

export default MeterControl;
