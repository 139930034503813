import React from 'react';
import { useTranslation } from 'react-i18next';
import { SupportedDevice } from 'lavva.exalushome/build/js/Services/SupportedDevices/ISupportedDevicesService';
import { Header } from '../../../../../components';

type DeviceParametersProps = {
  device: SupportedDevice;
};

export const DeviceParameters: React.FC<DeviceParametersProps> = ({ device }) => {
  const { t } = useTranslation('store');
  const { t: tc } = useTranslation('common');

  const renderParameter = (label: string, value: string) => (
    <p>
      {`${label}:`} <span>{value}</span>
    </p>
  );

  return (
    <>
      <hr className="m-t-24 m-b-24" />

      <Header title={t('parameters')} />
      <div className="store-device-details--parameters">
        {renderParameter(t('manufacturer'), device.ManufacturerName)}
        {renderParameter(t('name'), device.Name)}
        {renderParameter(t('model'), device.ModelName)}
        {renderParameter(t('controllerRequired'), tc(`buttons.yes`))}
      </div>
    </>
  );
};
