import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { ColorPicker, ControlWrapper } from '../../../../../../components';
import { LightSlider } from '../../../../../../components/light-slider';

type RgbPickerProps = {
  rgbValue: string;
  handleColorPicked: (hue: number, saturation: number) => void;
  sliderColor: string;
  brightness?: number;
  handleLeftClick?: () => void;
  handleChange?: (val: number) => void;
  handleBrightness?: (val: number) => void;
  handleRightClick?: () => void;
  handleButtonClick?: (status: boolean) => void;
  isOn?: boolean;
  activeColor?: string;
  pickerCircleRadius?: number;
  innerRadius?: number;
  brightnessLabel?: string;
  children?: ReactNode;
  setPreventSwipeViews?: Dispatch<SetStateAction<boolean>>;
};

const RgbPicker: React.FC<RgbPickerProps> = ({
  rgbValue,
  handleColorPicked,
  brightness,
  handleChange,
  handleBrightness,
  sliderColor,
  handleLeftClick,
  handleRightClick,
  handleButtonClick,
  isOn,
  activeColor,
  pickerCircleRadius = 16,
  innerRadius = 48,
  brightnessLabel,
  children,
  setPreventSwipeViews,
}) => {
  return (
    <>
      <ControlWrapper className="control-wrapper--medium">
        <ColorPicker
          onColorPicked={handleColorPicked}
          rgbValue={rgbValue}
          handleLeftClick={handleLeftClick}
          handleRightClick={handleRightClick}
          handleButtonClick={handleButtonClick}
          isOn={isOn}
          activeColor={activeColor}
          pickerCircleRadius={pickerCircleRadius}
          innerRadius={innerRadius}
          setPreventSwipeViews={setPreventSwipeViews}
        />
      </ControlWrapper>
      {children}
      {handleBrightness && handleChange && brightness !== undefined ? (
        <div className="details-page__controls-slider p-l-24 p-r-24">
          <LightSlider
            value={brightness}
            onChange={handleChange}
            onPointerUp={handleBrightness}
            gradientColor={sliderColor}
            {...(brightnessLabel ? { label: brightnessLabel } : {})}
          />
        </div>
      ) : null}
    </>
  );
};
export default RgbPicker;
